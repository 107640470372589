<template>
  <div class="login-container">
    <br>
    <h2 v-if="!selectedModule">モジュールを選択してください</h2>
    <h2 v-else>{{ mode === 'signup' ? 'サインイン - ' : 'ログイン - ' }}{{ selectedModuleName }}</h2>

    <!-- モジュール選択ボタン -->
    <div v-if="!selectedModule" class="module-buttons">
      <button @click="selectModule('accounting')" class="module-button">会計</button>
      <button @click="selectModule('consolidation')" class="module-button">連結 [予定]</button>
      <button @click="selectModule('expense_report')" class="module-button">経費精算 [予定]</button>
      <button @click="selectModule('fixed_assets')" class="module-button">固定資産 [予定]</button>
    </div>

    <!-- Google/Microsoft ログインボタン -->
    <div v-else class="login-buttons">
      <div id="g_id_onload" :data-client_id="googleClientId" :data-login_uri="googleLoginUri" data-auto_prompt="false"></div>
      <div id="g_id_signin" class="login-button"></div>

      <button @click="loginWithMicrosoft" class="ms-login-button">
        <img src="@/assets/microsoft-logo.png" alt="Microsoft logo" class="ms-logo" />
        Microsoftでログイン
      </button>
    </div>

    <!-- 戻るリンク -->
    <a v-if="selectedModule" @click="resetModule" class="back-link">モジュール選択に戻る</a>
  </div>
</template>

<script>
import msalInstance from '@/msalInstance';

export default {
  data() {
    return {
      selectedModule: null, // 選択されたモジュールのキー
      googleClientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
      googleLoginUri: process.env.VUE_APP_GOOGLE_LOGIN_URI,
      azureClientId: process.env.VUE_APP_AZURE_CLIENT_ID, // 追加
      azureRedirectUri: process.env.VUE_APP_AZURE_REDIRECT_URI, // 追加
      mode: 'login', // デフォルトはログインモード
    };
  },
  computed: {
    selectedModuleName() {
      const modules = {
        accounting: '会計',
        consolidation: '連結',
        expense_report: '経費精算',
        fixed_assets: '固定資産',
      };
      return modules[this.selectedModule] || '';
    },
  },
  methods: {
    selectModule(moduleKey) {
      if (moduleKey === 'consolidation' || moduleKey === 'fixed_assets' || moduleKey === 'expense_report') {
        alert('このモジュールは現在未実装です。');
        return;
      }
      this.selectedModule = moduleKey; // 選択されたモジュールを記録
      this.initializeGoogleSignIn(); // Google ログインボタンを初期化
    },
    resetModule() {
      this.selectedModule = null; // 選択をリセット
    },
    initializeGoogleSignIn() {
      let retryCount = 0;
      const maxRetries = 10; // 最大リトライ回数
      const retryInterval = 500; // チェック間隔（ミリ秒）

      const checkGoogleApiLoaded = () => {
        const googleSignInElement = document.getElementById('g_id_signin');
        if (typeof google !== 'undefined' && googleSignInElement) {
          // Google API がロードされ、要素が存在している場合
          google.accounts.id.initialize({
            client_id: this.googleClientId,
            callback: this.handleCredentialResponse,
          });
          google.accounts.id.renderButton(
            googleSignInElement,
            { theme: 'outline', size: 'large', width: 280, text: 'signin_with', logo_alignment: 'left', height: 50 }
          );
          clearInterval(intervalId); // タイマーをクリアして繰り返し処理を停止
        } else {
          retryCount++;
          if (retryCount >= maxRetries) {
            clearInterval(intervalId); // タイマーをクリアして処理を停止
            console.error('Google API または要素がロードされませんでした。最大リトライ回数に達しました。');
          }
        }
      };

      const intervalId = setInterval(checkGoogleApiLoaded, retryInterval);
    },
    handleCredentialResponse(response) {
      fetch('/api/authenticate.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token: response.credential, provider: 'google', module: this.selectedModule }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error('Network response was not ok.');
        })
        .then((data) => {
          this.handleSuccessfulLogin(data);
        })
        .catch((error) => {
          console.error('There has been a problem with your fetch operation:', error);
          this.$router.push({ name: 'LoginFailed' });
        });
    },
    async loginWithMicrosoft() {
      try {
        const loginRequest = { scopes: ['User.Read'] };
        const response = await msalInstance.loginPopup(loginRequest);
        const userInfoResponse = await fetch('https://graph.microsoft.com/v1.0/me', {
          headers: { Authorization: `Bearer ${response.accessToken}` },
        });
        if (!userInfoResponse.ok) {
          throw new Error('Failed to fetch Microsoft user information');
        }
        const userData = await userInfoResponse.json();
        const authResult = await this.authenticateWithServer(userData, 'microsoft');
        
        this.handleSuccessfulLogin(authResult);
      } catch (error) {
        console.error('Microsoftログインに問題がありました:', error);
        this.$router.push({ name: 'LoginFailed' });
      }
    },
    async authenticateWithServer(userData, provider) {
      const response = await fetch('/api/authenticate.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userData, provider, module: this.selectedModule }),
      });

      if (!response.ok) {
        throw new Error('サーバー認証に失敗しました');
      }

      return response.json();
    },
    handleSuccessfulLogin(data) {      
      let targetCompanyFormatted;
      let wireguardFormatted = [];

      if (Array.isArray(data.targetCompany)) {
        targetCompanyFormatted = data.targetCompany.length === 1 ? data.targetCompany[0] : '未選択';
      } else {
        targetCompanyFormatted = data.targetCompany || '未選択';
      }

      if (Array.isArray(data.wireguard) && data.wireguard.length > 0) {
        wireguardFormatted = data.wireguard.map(wg => ({
          id: wg.id,
          name: wg.name,
          wireguard: wg.wireguard,
        }));
      }

      this.$store.commit('setLoginDetails', {
        username: data.username,
        email: data.email,
        targetCompany: targetCompanyFormatted,
        targetPeriod: '',
        wireguard: wireguardFormatted,
        plan_status: data.plan_status,
        end_date: data.end_date,
      });
      this.$store.commit('login');
      this.$router.push({ name: 'Menu', query: { module: this.selectedModule } });
    },
  },
  created() {
    this.mode = this.$route.query.mode || 'login';
  },
};
</script>

<style>
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.module-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.module-button {
  width: 280px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  cursor: pointer;
  background-color: #ffffff;
  color: #333;
}

.module-button:hover {
  background-color: #f7f7f7;
}

.login-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.ms-login-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 280px;
  height: 50px;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  cursor: pointer;
  background-color: #ffffff;
  gap: 12px;
}

.ms-logo {
  width: 20px;
  height: 20px;
}

.back-link {
  margin-top: 20px;
  color: #007bff;
  text-decoration: none;
  cursor: pointer;
}

.back-link:hover {
  text-decoration: underline;
}
</style>
