<template>
    <div>
        <!-- 会計ソフトの選択ダイアログ -->
        <v-dialog v-model="showSoftwareDialog" max-width="500px">
        <v-card>
            <v-card-title class="text-h5">他の会計ソフトを選択</v-card-title>
            <v-card-text>
            <v-select
                v-model="selectedSoftware"
                :items="softwareOptions"
                label="会計ソフトを選択してください"
            ></v-select>
            </v-card-text>
            <v-card-actions>
            <v-btn color="primary" @click="fetchConversionData">決定</v-btn>
            <v-btn color="grey" @click="cancelSoftwareDialog">キャンセル</v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>

        <!-- 他の会計ソフトからのファイル選択ダイアログ -->
        <v-dialog v-model="showFileDialog" max-width="500px">
        <v-card>
            <v-card-title class="text-h5">転記対象のcsvファイルを選択</v-card-title>
            <v-card-text>
            <v-file-input
                label="データファイルを選択"
                accept=".csv"
                @change="processImportFile"
            />
            </v-card-text>
            <v-card-actions>
            <v-btn color="grey" @click="cancelFileDialog">キャンセル</v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>

        <!-- ローディング表示を統合 -->
        <div v-if="isLoading || isProcessing" class="loading-overlay">
            <template v-if="isProcessing">
                <div class="progress-content">
                    <h3>ファイル処理中...</h3>
                    <!-- 処理情報を追加 -->
                    <div class="processing-info mb-4">
                        <div class="text-body-1">
                            処理対象: {{ totalRecords.toLocaleString() }}件
                        </div>
                        <div class="text-body-2">
                            開始時間: {{ startTime }}
                        </div>
                        <div class="text-body-2">
                            完了予定: {{ estimatedEndTime }}
                        </div>
                    </div>
                    <v-progress-circular
                        indeterminate
                        color="primary"
                        class="mb-4"
                    ></v-progress-circular>
                    <v-btn
                        color="error"
                        class="mt-4"
                        @click="cancelProcessingHandler"
                    >
                        処理を中断
                    </v-btn>
                </div>
            </template>
            <template v-else>
                処理中...
            </template>
        </div>
    
        <!-- テンプレートCSVをインポートフォーム -->
        <v-dialog v-model="showImportDialog" persistent max-width="500px">
            <v-card>
                <v-card-title class="text-h5">テンプレートCSVをインポート</v-card-title>
                <v-card-text>
                <v-file-input
                    label="CSVファイルを選択"
                    accept=".csv"
                    @change="importData"
                />
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="confirmImport">インポート</v-btn>
                <v-btn color="grey" @click="cancelImport">キャンセル</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <div v-if="showRowSelector" class="row-selector-modal" @keydown.enter="handleEnterKey">
            <div class="modal-content">
                <div class="modal-header">
                    追加する行数を選択してください
                </div>
                <div class="modal-body">
                    <button 
                        v-for="(count, index) in rowOptions" 
                        :key="count"
                        :autofocus="index === 0"
                        @click="selectAndAddRows(count, activeTab)"
                        class="row-option-button"
                    >
                        {{ count }}行
                    </button>
                </div>
                <div class="modal-footer">
                    <button @click="showRowSelector = false" class="cancel-button">
                        キャンセル
                    </button>
                </div>
            </div>
        </div>

        <div v-if="menuVisible" class="menu-container">
            <div class="menu-fixed-items">
                <select
                    v-model="selectedDisplayType"
                    @change="onDisplayTypeChange"
                    class="display-type-select"
                >
                    <option v-for="type in displayTypes" :key="type" :value="type">{{ type }}</option>
                </select>
            </div>
            <div class="menu-scroll-wrapper">
                <!-- ボタンのみをスクロール可能に -->
                <template v-for="(menu, index) in menus" :key="index">
                    <!-- 承認ﾒﾆｭｰの場合 -->
                    <v-menu v-if="menu.label === '8) 承認ﾒﾆｭｰ'" offset-y>
                        <template v-slot:activator="{ props }">
                            <v-btn 
                                v-bind="props"
                                :style="{ 
                                    height: '40px', 
                                    backgroundColor: '#cdeef6', 
                                    color: menuDisabled(menu.name) ? '#ffffff' : '#000000' 
                                }"
                                :outlined="!menuDisabled(menu.name)"
                                :disabled="menuDisabled(menu.name)" 
                                class="menu-button"
                            >
                                {{ menu.label }}
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item @click="executeAction('承認実行')">
                                <v-list-item-title style="font-size: 0.9rem;">承認実行</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="executeAction('差戻実行')">
                                <v-list-item-title style="font-size: 0.9rem;">差戻実行</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>

                    <!-- 登録ﾒﾆｭｰの場合 -->
                    <v-menu v-else-if="menu.label === '2) 登録ﾒﾆｭｰ'" offset-y>
                        <template v-slot:activator="{ props }">
                            <v-btn 
                                v-bind="props"
                                :style="{ 
                                    height: '40px', 
                                    backgroundColor: '#cdeef6', 
                                    color: menuDisabled(menu.name) ? '#ffffff' : '#000000' 
                                }"
                                :outlined="!menuDisabled(menu.name)"
                                :disabled="menuDisabled(menu.name)" 
                                class="menu-button"
                            >
                                {{ menu.label }}
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item @click="executeAction('登録実行')">
                                <v-list-item-title style="font-size: 0.9rem;">登録実行</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="executeAction('importﾌｫｰﾑ')">
                                <v-list-item-title style="font-size: 0.9rem;">importﾌｫｰﾑ</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="openImportDialog">
                                <v-list-item-title style="font-size: 0.9rem;">import実行</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="executeAction('他ｿﾌﾄから転記')">
                                <v-list-item-title style="font-size: 0.9rem;">他ｿﾌﾄから転記</v-list-item-title>
                            </v-list-item>                            
                        </v-list>
                    </v-menu>

                    <!-- その他のメニュー -->
                    <v-btn 
                        v-else
                        :style="{ 
                            height: '40px', 
                            backgroundColor: '#cdeef6', 
                            color: menuDisabled(menu.name) ? '#ffffff' : '#000000' 
                        }"
                        :outlined="!menuDisabled(menu.name)"
                        :disabled="menuDisabled(menu.name)" 
                        @click="executeAction(menu.name)"
                        class="menu-button"
                    >
                        {{ menu.label }}
                    </v-btn>
                </template>
            </div>
        </div>

        <div v-if="searchListVisible" class="search-list">
            <div style="overflow-x: auto; white-space: nowrap;">
            <v-row>
                <v-col cols="auto">
                    <!-- 仕訳画面以外の場合 -->
                    <template v-if="!activeTab.includes('仕訳画面')">
                        <v-btn
                            style="height: 35px; margin-top: 10px; background-color: #ffffcc; color: #000;"
                            @click="fetchData"
                        >
                            全件DL
                        </v-btn>
                        <v-btn
                            style="height: 35px; margin-top: 10px; background-color: #ffffcc; color: #000;"
                            @click="executeSearch('仕訳以外')"
                        >
                            検索実行
                        </v-btn>
                    </template>

                    <!-- 仕訳画面の場合 -->
                    <template v-if="activeTab.includes('仕訳画面')">
                        <v-menu>
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    style="height: 35px; margin-top: 10px; background-color: #ffffcc; color: #000;"
                                    v-bind="props"
                                >
                                    データDL
                                    <v-icon right>mdi-menu-down</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item @click="executeJeSearch('特定年月DL')">
                                    <v-list-item-title style="font-size: 0.9rem;">特定年月DL</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="executeJeSearch('最終更新DL')">
                                    <v-list-item-title style="font-size: 0.9rem;">最終更新DL</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="executeJeSearch('申請承認DL')">
                                    <v-list-item-title style="font-size: 0.9rem;">申請承認DL</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                    <v-btn
                        style="height: 35px; margin-top: 10px; background-color: #ffffcc; color: #000;"
                        @click="executeSearch('伝票番号')"
                    >
                        検索実行
                    </v-btn>
                    <v-btn
                        style="height: 35px; margin-top: 10px; background-color: #ffffcc; color: #000;"
                        @click="cancelSearch"
                    >
                        Close
                    </v-btn>
                    <v-btn
                        style="height: 35px; margin-top: 10px; background-color: #ffffcc; color: #000;"
                        @click="clearConditions"
                    >
                        条件クリア
                    </v-btn>
                </v-col>
            </v-row>
            </div>


            <div style="overflow-x: auto; white-space: nowrap;"> <!-- 横スクロールを追加 -->
            <table class="styled-table">
                <thead>
                    <tr>
                        <th>検索列</th>
                        <th v-for="(entry, index) in searchEntries" :key="index">
                            {{ entry.searchColumnName }}
                            <v-btn 
                                v-if="['部門情報','自社口座cd','勘定及び補助','税コード','相手情報', 'PJ情報','年月','銀行情報','振込情報','表示区分','更新者','status','勘定情報','相手科目','table_name'].includes(entry.searchColumnName)"
                                class="wide-btn"
                                @click="openMasterList(entry.searchColumnName, index)"
                            >
                            <v-icon small>mdi-menu-down</v-icon>
                            </v-btn>
                            <!-- created と modified の列にボタンを追加 -->
                            <v-btn
                                v-if="['created', 'modified','action_date'].includes(entry.searchColumnName)"
                                class="wide-btn"
                                @click="openDateTimeDialog(index)"
                            >
                            <v-icon small>{{ xs ? 'mdi-calendar' : 'mdi-calendar-clock' }}</v-icon>
                            <span v-if="xs" class="ml-1">日時</span>
                            </v-btn>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>検索値</td>
                        <td v-for="(entry, index) in searchEntries" :key="index">
                        <input 
                            v-model="entry.input" 
                            :style="{ width: entry.expanded ? '400px' : '100px' }" 
                            @keydown="handleF2Key(index, $event)" 
                        />
                        </td>
                    </tr>
                    <tr v-if="showAttributes"> <!-- 属性行を隠す場合 -->
                        <td>属性</td>
                        <td v-for="(entry, index) in searchEntries" :key="index">{{ entry.searchColumnType }}</td>
                    </tr>
                </tbody>
            </table>
            
            <!-- モーダル -->
            <v-dialog v-model="showMasterDialog" max-width="500">
                <v-card class="pa-3" color="white">
                    <v-card-title class="text-black">{{ currentMasterTitle }}</v-card-title>
                    <v-card-text>
                        <!-- 検索フィールドを追加 -->
                        <v-text-field
                            v-model="filterText"
                            placeholder="入力して検索"
                            label="検索"
                            dense
                        ></v-text-field>

                        <!-- リスト部分をスクロール可能に -->
                        <div style="max-height: 300px; overflow-y: auto; overflow-x: auto; white-space: nowrap;">
                            <v-list dense class="compact-list" style="min-width: 700px;">
                                <v-list-item
                                    v-for="(item, i) in filteredCurrentMasterList"
                                    :key="i"
                                    class="compact-list-item"
                                    @click="toggleSelection(item)"
                                    :class="{ 'selected-item': selectedItems.includes(item) }"
                                >
                                    <v-list-item-title class="compact-list-item-title">
                                        {{ item }}
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn text @click="confirmSelection">選択確定</v-btn> <!-- 選択を確定 -->
                        <v-btn text @click="confirmAndSearch">即時検索</v-btn>
                        <v-btn text @click="clearSelection">クリア</v-btn> <!-- 選択をクリア -->
                        <v-btn text @click="showMasterDialog = false">閉じる</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

        </div>
        <!-- 日時選択のダイアログをここに移動 -->
        <v-dialog v-model="showDateTimeDialog" max-width="500px">
            <v-card>
            <v-card-title>{{ currentDateTimeEntry?.searchColumnName }} の日時範囲を選択</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="6">
                        <v-text-field
                        v-model="currentDateTimeEntry.startDate"
                        label="開始日"
                        prepend-icon="mdi-calendar"
                        placeholder="YYYY-MM-DD"
                        @input="validateDate('startDate')"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field
                        v-model="currentDateTimeEntry.startTime"
                        label="開始時間"
                        type="time"
                        prepend-icon="mdi-clock-time-four-outline"
                        ></v-text-field>
                    </v-col>
                    </v-row>
                    <v-row>
                    <v-col cols="6">
                        <v-text-field
                        v-model="currentDateTimeEntry.endDate"
                        label="終了日"
                        prepend-icon="mdi-calendar"
                        placeholder="YYYY-MM-DD"
                        @input="validateDate('endDate')"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field
                        v-model="currentDateTimeEntry.endTime"
                        label="終了時間"
                        type="time"
                        prepend-icon="mdi-clock-time-four-outline"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>                
                <v-btn color="blue darken-1" text @click="applyDateTimeRange">選択確定</v-btn>
                <v-btn color="green darken-1" text @click="applyDateTimeRangeAndSearch">即時検索</v-btn>
                <v-btn color="blue darken-1" text @click="showDateTimeDialog = false">閉じる</v-btn>
            </v-card-actions>
            </v-card>
        </v-dialog>
    </div>

     <!-- 簡易入力画面 -->
     <div v-if="showSimpleInput" class="simple-input">
        <v-row>
            <v-col cols="auto">
            <v-btn style="height: 35px; margin-top: -10px;" @click="confirmSimpleInput">選択確定</v-btn>
            <v-btn style="height: 35px; margin-top: -10px;" @click="cancelSimpleInput">Close</v-btn>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12" sm="4">
            <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
                <template v-slot:activator="{ props }">
                <v-text-field
                    v-model="simpleInput.伝票日付"
                    label="伝票日付"
                    readonly
                    v-bind="props"
                    dense
                ></v-text-field>
                </template>
                <v-card>
                    <v-card-text>
                        <!-- 検索フィールドを追加 -->
                        <v-text-field
                        v-model="dateSearchText"
                        label="日付を検索"
                        placeholder="YYYY-MM-DD"
                        dense
                        clearable
                        class="mb-2"
                        ></v-text-field>

                        <v-list height="300" class="overflow-y-auto">
                        <v-list-item
                            v-for="date in filteredDates"
                            :key="date"
                            @click="selectDate(date)"
                            :active="simpleInput.伝票日付 === date"
                        >
                            <v-list-item-title>{{ formatDateDisplay(date) }}</v-list-item-title>
                        </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-menu>
            </v-col>
            <v-col cols="12" sm="4">
            <v-text-field 
                v-model="simpleInput.税込金額" 
                label="税込金額" 
                type="number"
                dense
            ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
            <v-text-field 
                v-model="simpleInput.相手情報" 
                label="相手情報"
                @click="openSimpleInputMasterList('相手情報')"
                readonly
                dense
            ></v-text-field>
            </v-col>
        </v-row>

        <!-- 相手情報用のモーダル -->
        <v-dialog v-model="showSimpleInputMasterDialog" max-width="500">
            <v-card class="pa-3" color="white">
            <v-card-title class="text-black">{{ currentSimpleInputMasterTitle }}</v-card-title>
            <v-card-text>
                <v-text-field
                v-model="simpleInputFilterText"
                placeholder="入力して検索"
                label="検索"
                dense
                ></v-text-field>

                <div style="max-height: 300px; overflow-y: auto;">
                    <v-list dense class="compact-list">
                        <v-list-item
                            v-for="(item, i) in filteredSimpleInputMasterList"
                            :key="i"
                            class="compact-list-item"
                            @click="toggleSimpleInputSelection(item)"
                            :class="{ 'selected-item': simpleInputSelectedItems.includes(item) }"
                        >
                            <v-list-item-title class="compact-list-item-title">{{ item }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-btn text @click="confirmSimpleInputSelection">確定</v-btn>
                <v-btn text @click="clearSimpleInputSelection">クリア</v-btn>
                <v-btn text @click="showSimpleInputMasterDialog = false">閉じる</v-btn>
            </v-card-actions>
            </v-card>
        </v-dialog>
    </div>

    <div v-if="apArListVisible" class="apar-list">
        <v-row>
            <v-col colr="auto">
                <!-- 横スクロール可能なコンテナを追加 -->
                <div style="overflow-x: auto; white-space: nowrap;">
                    <v-btn v-if="activeTab === '債務画面'" style="height: 35px; margin-top: 10px; margin-right: 5px;" @click="apArSearch('債務表示')">債務表示</v-btn>
                    <v-btn v-if="activeTab === '債務画面'" style="height: 35px; margin-top: 10px; margin-right: 5px;" @click="createApJe()">支払仕訳作成</v-btn>
                    <v-btn v-if="activeTab === '債務画面'" style="height: 35px; margin-top: 10px; margin-right: 5px;" @click="createFbData()">FBデータ作成</v-btn>
                    <v-btn v-if="activeTab === '債権画面'" style="height: 35px; margin-top: 10px; margin-right: 5px;" @click="apArSearch('債権表示')">債権表示</v-btn>
                    <v-btn v-if="activeTab === '債権画面'" style="height: 35px; margin-top: 10px; margin-right: 5px;" @click="createArJe()">消込仕訳作成</v-btn>
                    <v-btn v-if="activeTab === '源泉画面'" style="height: 35px; margin-top: 10px; margin-right: 5px;" @click="gensenSearch()">源泉表示</v-btn>
                    <v-btn v-if="activeTab === '源泉画面'" style="height: 35px; margin-top: 10px; margin-right: 5px;" @click="createGensenJe()">源泉仕訳作成</v-btn>
                    <v-btn style="height: 35px; margin-top: 10px; margin-right: 5px;" @click="cancelApArList">Close</v-btn>
                </div>
                <template v-if="activeTab === '債務画面'">
                    <thead><tr><th style="margin-top: 15px; display: block;">■報酬源泉がある場合の預り金科目を選択して下さい(該当ある場合のみ必要)</th></tr></thead>
                    <v-select
                        label="預り金及び補助を選択"
                        :items="withHoldingAccOptions"
                        v-model="selectedwithHoldingAcc"
                        dense
                    ></v-select>
                </template> 
                <!-- 債権画面の場合のリストボックスを追加 -->
                <template v-if="activeTab === '債権画面'">
                    <thead>
                        <tr>
                            <th style="margin-top: 15px; display: block;">■消込仕訳の勘定及び補助を選択して下さい</th>
                        </tr>
                    </thead>
                    <v-row dense>
                        <v-col cols="12" sm="3">
                            <v-select
                                label="預金科目"
                                :items="depositAccountOptions"
                                v-model="selectedDepositAccount"
                                dense
                            ></v-select>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-select
                                label="為替損益"
                                :items="exchangeGainLossOptions"
                                v-model="selectedExchangeGainLoss"
                                dense
                            ></v-select>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-select
                                label="支払手数料"
                                :items="paymentFeeOptions"
                                v-model="selectedPaymentFee"
                                dense
                            ></v-select>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-select
                                label="支払手数料の税コード"
                                :items="feeTaxOptions"
                                v-model="selectedFeeTax"
                                dense
                            ></v-select>
                        </v-col>
                    </v-row>
                </template>

                <template v-if="activeTab === '源泉画面'">
                    <thead>
                        <tr>
                            <th style="margin-top: 15px; display: block;">
                                ■預り金科目・預金科目(支払口座)・源泉対象月・源泉納付日を選択し、[源泉表示]ボタンを押下
                            </th>
                        </tr>
                    </thead>
                    <v-row dense>
                        <v-col cols="12" sm="4">
                            <v-select
                                label="預り金及び補助を選択"
                                :items="withHoldingAccOptions"
                                v-model="selectedwithHoldingAcc"
                                dense
                            ></v-select>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-select
                                label="預金科目を選択"
                                :items="depositAccountOptions"
                                v-model="selectedDepositAccount"
                                dense
                            ></v-select>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-select
                                label="源泉納付日の前月を選択(yyyymm)"
                                :items="gensenYms"
                                v-model="selectedGensenYm"
                                dense
                            ></v-select>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-container>
                                <v-date-picker
                                    color="primary"
                                    v-model="selectedGensenPayDate"
                                    @input="dateValue = false"
                                    title="源泉納付日を選択"
                                ></v-date-picker>
                            </v-container>
                        </v-col>
                    </v-row>
                </template>
            </v-col>
        </v-row>

        <template v-if="activeTab !== '源泉画面'">
            <thead><tr><th style="margin-top: 15px; display: block;">予定日</th></tr></thead>
            <tbody>
                <template v-if="items.length > 0">
                    <v-checkbox
                        v-for="item in items"
                        :key="item.value"
                        v-model="dateSelect"
                        :value="item.label"
                        :label="item.label"
                        :color="item.color"
                        density="compact"
                    ></v-checkbox>
                </template>
                <template v-else>
                    <tr><td class="no-data-message" style="padding-bottom: 1rem;">該当データがありません</td></tr>
                </template>
            </tbody>
        </template>
    </div>
    
    <div v-if="apJeVisible" class="apJe-list">
        <v-row>
        <v-col colr="auto">
            <v-btn style="height: 35px; margin-top: 10px;" @click="insertData('債務支払')">支払登録</v-btn>
            <v-btn style="height: 35px; margin-top: 10px;" @click="cancelApjeVisible">キャンセル</v-btn>
        </v-col>
        </v-row>
    </div>
    <div v-if="arJeVisible" class="arJe-list">
        <v-row>
        <v-col colr="auto">
            <v-btn style="height: 35px; margin-top: 10px;" @click="insertData('債権消込')">消込登録</v-btn>
            <v-btn style="height: 35px; margin-top: 10px;" @click="cancelArjeVisible">キャンセル</v-btn>
        </v-col>
        </v-row>
    </div>
    <div v-if="gensenJeVisible" class="gensenJe-list">
        <v-row>
        <v-col colr="auto">
            <v-btn style="height: 35px; margin-top: 10px;" @click="insertData('源泉支払')">源泉支払登録</v-btn>
            <v-btn style="height: 35px; margin-top: 10px;" @click="cancelGensenjeVisible">キャンセル</v-btn>
        </v-col>
        </v-row>
    </div>

    <v-dialog v-model="dialog" max-width="400">
        <v-card>
            <v-card-title class="headline text-center">
            <span v-if="dialogType === 'ledgerDisplay'">元帳表示</span>
            <span v-else-if="dialogType === 'updateDisplay'">更新仕訳表示</span>
            </v-card-title>

            <v-card-text>
            <v-row justify="center" class="mt-2">
                <!-- 元帳表示用のボタン -->
                <template v-if="dialogType === 'ledgerDisplay'">
                <v-col cols="12" sm="6" class="text-center">
                    <v-btn style="background-color: #ADD8E6; color: black;" block @click="showSelectedLedger()">選択勘定表示</v-btn> <!-- 薄い青に変更 -->
                </v-col>
                </template>

                <!-- 更新仕訳表示用のボタン -->
                <template v-else-if="dialogType === 'updateDisplay'">
                <v-col cols="12" sm="6" class="text-center">
                    <v-btn style="background-color: #D3D3D3; color: black;" block @click="lastUpdated()">更新仕訳表示</v-btn> <!-- 薄いグレーに変更 -->
                </v-col>
                </template>

                <!-- 共通のキャンセルボタン -->
                <v-col cols="12" sm="6" class="text-center">
                <v-btn style="background-color: #D3D3D3; color: black;" block @click="closeDialog()">Close</v-btn> <!-- 薄いグレーに変更 -->
                </v-col>
            </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>

    <div v-if="periodListVisible" class="period-list">
        <v-row>
            <v-col cols="auto">
                <v-btn style="height: 35px; margin-top: 10px;" @click="executeRefresh()">表示更新</v-btn>
                <v-btn style="height: 35px; margin-top: 10px;" @click="cancelTb">Close</v-btn>
                <v-btn style="height: 35px; margin-top: 10px;" @click="clearSearchValues()">検索値クリア</v-btn>
                <!-- 「期間選択」ラベル -->
                <div style="margin-top: 15px; margin-left:15px;">期間選択</div>
            </v-col>
        </v-row>
        
        <!-- 期間選択スライダー -->
        <v-slider
            :ticks="isSmallScreen ? alternateTickLabels : shorttickLabels"
            :max="Object.keys(shorttickLabels).length - 1"
            step="1"
            show-ticks="always"
            tick-size="5"
            v-model="selectedPeriodIndex"
            direction="horizontal" 
            class="ma-4" 
        ></v-slider>

        <!-- 消費税集計データの表示 -->
        <div v-if="activeTab === '消費税ck画面' && ctaxSummary && ctaxSummary.length > 0" class="tax-summary-container">
            <div class="tax-summary-header">消費税集計</div>
            <table class="tax-summary-table">
                <thead>
                    <tr>
                        <th style="font-size: 0.9rem; padding: 4px;">税コード</th>
                        <th style="font-size: 0.9rem; padding: 4px;" class="text-center">税抜金額</th>
                        <th style="font-size: 0.9rem; padding: 4px;" class="text-center">税額</th>
                        <th style="font-size: 0.9rem; padding: 4px;" class="text-center">税込金額</th>
                        <th style="font-size: 0.9rem; padding: 4px;" class="text-center">税率</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in ctaxSummary" :key="item.税コード">
                        <td style="font-size: 0.85rem; padding: 3px;">{{ item.税コード }}</td>
                        <td style="font-size: 0.85rem; padding: 3px;" class="text-right">{{ formatNumber(item.税抜金額) }}</td>
                        <td style="font-size: 0.85rem; padding: 3px;" class="text-right">{{ formatNumber(item.税額) }}</td>
                        <td style="font-size: 0.85rem; padding: 3px;" class="text-right">{{ formatNumber(item.税込金額) }}</td>
                        <td style="font-size: 0.85rem; padding: 3px;" class="text-right">{{ calculateTaxRate(item.税抜金額, item.税額) }}%</td>
                    </tr>
                </tbody>
            </table>
        </div>
        
        <div style="overflow-x: auto; white-space: nowrap;">
            <table class="styled-table">
                <thead>
                    <tr>
                        <th>項目名</th>
                        <th v-for="(entry, index) in filteredSearchEntries" :key="index">
                            {{ entry.searchColumnName }}
                            <v-btn class="wide-btn" @click="openMasterList(entry.searchColumnName, index)">
                            <v-icon small>mdi-menu-down</v-icon>
                            </v-btn>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>検索値</td>
                        <td v-for="(entry, index) in filteredSearchEntries" :key="index">
                            <input v-model="entry.input" style="width: 400px;" />
                        </td>
                    </tr>
                    <tr v-if="showAttributes">
                        <td>属性</td>
                        <td v-for="(entry, index) in filteredSearchEntries" :key="index">{{ entry.searchColumnType }}</td>
                    </tr>
                </tbody>
            </table>
            <!-- モーダル -->
            <v-dialog v-model="showMasterDialog" max-width="500">
                <v-card class="pa-3" color="white">
                    <v-card-title class="text-black">{{ currentMasterTitle }}</v-card-title>
                    <v-card-text>
                        <!-- 検���フィールドを追加 -->
                        <v-text-field
                            v-model="filterText"
                            placeholder="入力して検索"
                            label="検索"
                            dense
                        ></v-text-field>

                        <!-- リスト部分をスクロール可能にするためにスタイルを追加 -->
                        <div style="max-height: 300px; overflow-y: auto;">
                            <v-list dense class="compact-list">
                                <v-list-item
                                    v-for="(item, i) in filteredCurrentMasterList"
                                    :key="i"
                                    class="compact-list-item"
                                    @click="toggleSelection(item)"
                                    :class="{ 'selected-item': selectedItems.includes(item) }"
                                >
                                    <v-list-item-title class="compact-list-item-title">{{ item }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </div>
                    </v-card-text>
                    <v-card-actions class="sticky-buttons">
                        <v-btn text @click="confirmSelection">確定</v-btn> <!-- 選択を確定 -->
                        <v-btn text @click="clearSelection">クリア</v-btn> <!-- 選択をクリア -->
                        <v-btn text @click="showMasterDialog = false">閉じる</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
    </div>

    <div>
         <!-- Normal Mode Grid -->
        <ag-grid-vue
            style="width: 100%; height: 100vh;"
            class="ag-theme-alpine"
            :grid-options="gridOptions"
            :columnDefs="columnDefs"
            :enableClipboard="true"
            :enableRangeSelection="true"
            :suppressCopySingleCellRanges="true"
            :suppressRowClickSelection="true"
            :rowData="rowData"
            :defaultColDef="defaultColDef"
            @grid-ready="ongridReady"
            @cell-value-changed="onCellValueChanged"
            :enableFillHandle="true"
            :undoRedoCellEditing="true"
            :undoRedoCellEditingLimit="undoRedoCellEditingLimit"
            :enableCellChangeFlash="true"
            :getContextMenuItems="getContextMenuItems"
            :processPivotResultColDef="processPivotResultColDef"
            :sideBar="sideBar"
            :on-first-data-rendered="onFirstDataRendered"
            :rowHeight="22"
            :rowClassRules="rowClassRules"
        ></ag-grid-vue>
    </div>
    <!-- ... 他のタブの内容 ... -->
    </div>
  </template>
<script>
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { AgGridVue } from '@ag-grid-community/vue3';
import { mapState, mapActions} from 'vuex';
import { debounce } from 'lodash';
import { useDisplay } from 'vuetify';
import { computed } from 'vue';
import Encoding from 'encoding-japanese';

ModuleRegistry.registerModules([ClientSideRowModelModule]);

// データをソートする汎用関数
const sortDataByType = (data, tbType, includeDepartment = false) => {
    tbType = tbType.substring(0, 4);
    console.log('Sorting data. Type:', tbType, 'Include Department:', includeDepartment);
    const sortedData = data.sort((a, b) => {
        const primarySortKey = tbType === '補助元帳' ? '勘定及び補助' : '勘定情報';
        const secondarySortKey = tbType === '相手元帳' ? '相手情報' : (tbType === 'PJ元帳' ? 'PJ情報' : null);

        // 部門情報を考慮する場合、部門情報を最初の比較条件に追加
        if (includeDepartment) {
            if (a['部門情報'] !== b['部門情報']) {
                return a['部門情報'].localeCompare(b['部門情報']);
            }
        }

        if (a[primarySortKey] !== b[primarySortKey]) {
            return a[primarySortKey].localeCompare(b[primarySortKey]);
        } else if (secondarySortKey && a[secondarySortKey] !== b[secondarySortKey]) {
            return a[secondarySortKey].localeCompare(b[secondarySortKey]);
        } else if (a['年月'] - b['年月'] !== 0) {
            return a['年月'] - b['年月'];
        } else if (new Date(a['伝票日付']) - new Date(b['伝票日付']) !== 0) {
            return new Date(a['伝票日付']) - new Date(b['伝票日付']);
        } else {
            return a['伝票番号'] - b['伝票番号'];
        }
    });
    console.log('Sorted data:', sortedData.slice(0, 5)); // 最初の5件のみログ出力
    return sortedData;
};

// 勘定及び補助ごと及び年月ごとに残高を累積的に計算する関数
const calculateBalancePerAccountAndMonth = (sortedData, tbType, callSrc, includeDepartment = false) => {
    tbType = tbType.substring(0, 4);
    console.log('Calculating balance. Type:', tbType, 'Call Source:', callSrc, 'Include Department:', includeDepartment);
    let balance = 0;
    let previousKey = null;
    let previousMonth = null;

    const result = sortedData.map((row, index) => {
        let currentKey;
        if (tbType === '勘定元帳') {
            currentKey = includeDepartment ? `${row['部門情報']}_${row['勘定情報']}` : row['勘定情報'];
        } else if (tbType === '補助元帳') {
            currentKey = includeDepartment ? `${row['部門情報']}_${row['勘定及び補助']}` : row['勘定及び補助'];
        } else if (tbType === '相手元帳') {
            currentKey = includeDepartment ? `${row['部門情報']}_${row['勘定情報']}_${row['相手情報']}` : `${row['勘定情報']}_${row['相手情報']}`;
        } else if (tbType === 'PJ元帳') {
            currentKey = includeDepartment ? `${row['部門情報']}_${row['勘定情報']}_${row['PJ情報']}` : `${row['勘定情報']}_${row['PJ情報']}`;
        }

        // 初期化の条件
        const shouldResetBalance = (
            index === 0 || // 最初の行
            currentKey !== previousKey || // 勘定情報や情報が変更された場合
            (callSrc === 'fromTB' && row['年月'] !== previousMonth) // callSrcがfromTBなら年月が変わったらリセット
        );

        if (shouldResetBalance) {
            balance = (row['残高'] || 0);
            console.log('Resetting balance:', currentKey, balance);
        }

        // 借方税抜から貸方税抜を差し引いて残高を更新
        const diff = (row['借方税抜'] || 0) - (row['貸方税抜'] || 0);
        balance += diff;

        console.log('Row:', index, 'Key:', currentKey, 'Diff:', diff, 'New Balance:', balance);

        if (row['税コード'] === undefined) {
            row['借方税抜'] = row['借方税抜'] !== undefined ? '' : '';
            row['貸方税抜'] = row['貸方税抜'] !== undefined ? '' : '';
        }

        // 前のキーと年月を更新
        previousKey = currentKey;
        previousMonth = row['年月'];

        // 残高を更新した行を返す
        return { ...row, 残高: balance };
    });

    console.log('Calculation result:', result.slice(0, 5)); // 最初の5件のみログ出力
    return result;
};

// 行のスタイル設定関数
const getRowStyle = (params, tbType) => {
    if (params.node.rowIndex === 0) {
        return {};
    }

    let currentKey, previousKey;
    let previousNode = params.api.getDisplayedRowAtIndex(params.node.rowIndex - 1);

    if (!previousNode) {
        return {};
    }

    if (tbType === '勘定元帳') {
        currentKey = params.data.勘定情報;
        previousKey = previousNode.data.勘定情報;
    } else if (tbType === '補助元帳') {
        currentKey = params.data.勘定及び補助;
        previousKey = previousNode.data.勘定及び補助;
    } else if (tbType === '相手元帳') {
        currentKey = params.data.勘定情報 + '_' + params.data.相手情報;
        previousKey = previousNode.data.勘定情報 + '_' + previousNode.data.相手情報;
    } else if (tbType === 'PJ元帳') {
        currentKey = params.data.勘定情報 + '_' + params.data.PJ情報;
        previousKey = previousNode.data.勘定情報 + '_' + previousNode.data.PJ情報;
    }

    if (currentKey !== previousKey) {
        return { 'border-top': '1.5px solid black' };
    }

    return {};
};

export default {
    setup() {
        const { xs } = useDisplay(); // useDisplayをsetup内で呼び出す
        return {
            xs: computed(() => xs.value), // xsを計算プロパティとして返す
        };
    },
    emits: ['request-tab-switch'],
    components: {
        "ag-grid-vue": AgGridVue,
    },
    props: {
        tabs: Array,
        activeTab: String, // active-tabの値を受け取るためのprop
    },
    computed: {
        ...mapState([
            'allTabs', 
            'currentActiveTab',
            'targetCompany',
            'targetPeriod',
            'masterMappingFetched',
            'accMapping',
            'subMapping',
            'bumonMapping',
            'aiteMapping',
            'pjMapping',
            'taxMapping',
            'ymMapping',
            'fbMapping',
            'fyMapping',
            'tmpJeMapping',
            'bankOrgMapping',
            'bankCdMapping',
            'userMapping',
            'categoryMapping',
        ]),
        filteredDates() {
            if (!this.dateSearchText) {
                return this.allowedDates;
            }
            const searchText = this.dateSearchText.toLowerCase();
            return this.allowedDates.filter(date => 
            date.toLowerCase().includes(searchText) || 
            this.formatDateDisplay(date).toLowerCase().includes(searchText)
            );
        },
        filteredSimpleInputMasterList() {
            if (this.simpleInputFilterText) {
                const filter = this.simpleInputFilterText.toLowerCase();
                return this.currentMasterList.filter(item => item.toLowerCase().includes(filter));
            }
            return this.currentMasterList;
        },
        filteredCurrentMasterList() {
            if (this.filterText) {
                const filter = this.filterText.toLowerCase();
                return this.currentMasterList.filter(item => item.toLowerCase().includes(filter));
            }
            return this.currentMasterList;
        },
        isSmallScreen() {
            return this.windowWidth < 600; // スマートフォンの一般的な幅（600px未満）スマホ
        },
        alternateTickLabels() {
            const alternate = {};
            Object.entries(this.shorttickLabels).forEach(([key, value], index) => {
                if (index % 2 === 0) {
                alternate[key] = value;
                }
            });
            return alternate;
        },
        rowClassRules() {
            return {
                'highlight-row': (params) => {
                    // activeTab に '仕訳' が含まれていない場合はハイライトしない
                    if (!this.activeTab.includes('仕訳')) {
                        return false;
                    }

                    // 以下、既存の仕訳表示用のハイライト処理
                    const queries = this.queriesForStyle; 

                    const isQueryMatch = queries && queries.some(query => {
                        if (!query.conditions || !Array.isArray(query.conditions)) return false;
                        return query.conditions.every(condition => {
                            return params.data[condition.column] === condition.value;
                        });
                    });

                    const searchEntries = this.searchEntries;

                    if (!searchEntries || searchEntries.length === 0) return isQueryMatch;

                    const isSearchMatch = searchEntries.some((searchEntry) => {
                        const columnName = searchEntry.searchColumnName;
                        const searchValue = searchEntry.input;

                        if (!searchValue) return false;

                        const columnValue = params.data[columnName];
                        if (!columnValue) return false;

                        return columnValue.toString().toLowerCase().includes(searchValue.toLowerCase());
                    });

                    return isQueryMatch || isSearchMatch;
                }
            };
        }
    },
    data() {
      return {
        isProcessing: false, // 処理中フラグ
        cancelProcessing: false, // 処理中断フラグ
        totalRecords: 0,
        startTime: '',
        estimatedEndTime: '',

        showSoftwareDialog: false, // 会計ソフト選択ダイアログ表示フラグ
        showFileDialog: false, // ファイル選択ダイアログ表示フラグ
        softwareOptions: ["弥生会計", "勘定奉行", "freee", "MFクラウド"], // 選択肢
        selectedSoftware: "", // 選択された会計ソフト
        conversionData: null, // conversionテーブルから取得したデータ
        uploadedFile: null, // ユーザーが選択したファイル

        showImportDialog: false, // ダイアログの表示/非表示を制御
        showImportForm: false, // フォームの表示/非表示を制御
        selectedFile: null, // 選択されたファイル
        showRowSelector: false,
        rowOptions: [5, 10, 100, 1000],
        ctaxSummary: [],
        dateSearchText: '', // 日付検索用のテキスト
        startDateMenu: false,
        endDateMenu: false,
        showDateTimeDialog: false,
        currentDateTimeEntry: {
            startDate: null,
            startTime: null,
            endDate: null,
            endTime: null,
            searchColumnName: ''
        },
        dateMenu: false,
        allowedDates: [],
        showSimpleInputMasterDialog: false,
        currentSimpleInputMasterTitle: '',
        simpleInputFilterText: '',
        simpleInputSelectedItems: [],
        showSimpleInput: false,
        simpleInput: {
            伝票日付: '',
            税込金額: 0,
            相手情報: '',
        },
        windowWidth: window.innerWidth,
        isLoading: false,
        filterText: '', // フィルタ用のテキスト
        selectedItems: [], // 選択された値を格納する配列
        showMasterDialog: false, // モーダル表示フラグ
        currentMasterTitle: '', // モーダルのタイトル
        currentMasterList: [],  // モーダルに表示するリスト
        selectedInputIndex: null, // どのカラムのinputに値をセットするか
        showAttributes: false,  // 属性行を非表示にするためのフラグ
        dateList: [],  // 日付リストを保存するための変数
        departmentOption: "all",  // 部門選択 (all: 全部門, individual: 個別部門)
        departmentCodes: [],  // 部門マスタのコード一覧
        departmentPlaceholder: "部門コードを取得中...",  // プレースホルダーの初期値
        dateValue: false, // 'menu' を 'dateValue' に変更
        selectedGensenPayDate: null,
        withHoldingAccOptions:[],
        gensenYms:[],
        depositAccountOptions:[],
        paymentFeeOptions:[],
        exchangeGainLossOptions:[],
        feeTaxOptions:[],
        selectedwithHoldingAcc:null,
        selectedGensenYm: null,
        selectedDepositAccount: null,
        selectedPaymentFee: null,
        selectedExchangeGainLoss: null,
        selectedFeeTax:null,
        shouldShow: false,
        dialog: false,
        dialogType: 'displaySwitch', // または 'ledgerDisplay'
        pivotMode: false, // 切り替えるフラグ
        gridOptions: {            
            onFirstDataRendered: this.onFirstDataRendered,
            onCellEditingStarted: this.onCellEditingStarted,
            onCellValueChanged: this.onCellValueChanged,
            copyHeadersToClipboard: true, // これも必要
        },
        defaultColDef: {
            suppressMovable: true, // カラムの移動を無効にする
            sortable: false,
            editable: true,
            resizable: true,
            useValueFormatterForExport: true,
            useValueParserForImport: true,
            floatingFilter: true,
            minWidth: 100,
        },
        queriesForStyle:[],
        items: [],
        dateSelect: [],
        processPivotResultColDef: null,
        sideBar: null, // sideBarの状態を保持するためのプロパティを追加
        pivotModeOn: true,
        startYearMonth: '',
        endYearMonth: '',
        tickLabels:[],
        shorttickLabels: {},
        searchListVisible: false,
        periodListVisible: false,
        menuVisible: true,
        apJeVisible: false,
        arJeVisible: false,
        gensenJeVisible: false,
        selectedPeriodIndex: 100,
        searchEntries: [],  // Populate this array with your search entries data
        filteredSearchEntries: [],  // Populate this array with your search entries data
        isProgrammaticFilterChange: false,
        // isAddingRow: false,
        showErrorAlerts: true,
        undoRedoCellEditingLimit: null,
        columnDefs: [], 
        accountSubjects: [],  // 勘定及び補助リストを格納するためのデータプロパティ
        rowData: [],
        apArListVisible: false,
        focusedMenuIndex: null, // フォーカスされているメニュー項目のインデックス
        selectedDisplayType: '部分表示', // デフォルトの表示タイプ
        displayTypes: ['部分表示', '全部表示' ], // 表示タイプのリスト
        menus: [
            {name: 'DLﾒﾆｭｰ',label: '1) DLﾒﾆｭｰ'},
            {name: '登録ﾒﾆｭｰ',label: '2) 登録ﾒﾆｭｰ'},
            {name: '更新実行',label: '3) 更新実行'},
            {name: '削除実行',label: '4) 削除実行'},
            {name: '表示ｸﾘｱ',label: '5) 表示ｸﾘｱ'},
            {name: 'ﾌｨﾙﾀｸﾘｱ',label: '6) ﾌｨﾙﾀｸﾘｱ'},
            {name: '行の追加',label: '7) 行の追加'},
            {name: '承認ﾒﾆｭｰ',label: '8) 承認ﾒﾆｭｰ'},
            // {name: '表示切替',label: ') 表示切替'},            
        ],
        menuItemStyle: {
            fontSize: '0.9rem'
        }
      };
    },
    methods: {
        ...mapActions(['fetchMasterMapping']),
        
        showErrorMessage(message) {
            alert(message);
        },
        onResize() {
            this.windowWidth = window.innerWidth;
        },
        transformMasterMapping(data) {
            // data が存在しない場合はログを出力し、空配列を返す
            if (!data || !Array.isArray(data)) {
                console.error('transformMasterMapping: 該当データが存在しません');
                return []; // エラーを回避するために空配列を返す
            }
            // 各カラムに対応するマッピング情報をリスト化
            let mappings = [];

            if (this.activeTab.includes('補助TB')){
                mappings = [
                    { key: '勘定情報', mappingData: this.$store.state.subMapping, column: '結合cd', result: '勘定及び補助' },
                ];
            } else {
                mappings = [
                    { key: '相手情報', mappingData: this.$store.state.aiteMapping, column: '相手先cd', result: '結合' },
                    { key: '部門情報', mappingData: this.$store.state.bumonMapping, column: '部門コード', result: '結合' },
                    { key: '税コード', mappingData: this.$store.state.taxMapping, column: 'Taxコード', result: '結合' },
                    { key: '勘定及び補助', mappingData: this.$store.state.subMapping, column: '結合cd', result: '勘定及び補助' },
                    { key: '相手科目', mappingData: this.$store.state.subMapping, column: '結合cd', result: '勘定及び補助' },
                    { key: 'PJ情報', mappingData: this.$store.state.pjMapping, column: 'PJ_cd', result: '結合' },
                    { key: '振込情報', mappingData: this.$store.state.fbMapping, column: '結合cd', result: '振込情報' }
                ];
            }
                
            return data.map(row => {
                // 各マッピングに対して変換を実行
                mappings.forEach(({ key, mappingData, column, result }) => {
                    if (row[key] !== undefined && row[key] !== null) { // falsyな値も処理
                        const mapping = mappingData.find(item => String(item[column]) === String(row[key]));
                        if (mapping) {
                            // 結果カラムに変換
                            row[key] = String(mapping[result]);
                            // 振込情報以外は ":" でsplitして左側を取得
                            if (key !== '振込情報') {
                                row[key] = row[key].split(':')[0];
                            }
                        } else {
                            // 振込情報以外は ":" でsplitして左側を取得
                            if (key !== '振込情報') {
                                row[key] = String(row[key]).split(':')[0];
                            }
                        }
                    }
                });
                return row;
            });
        },

        mapSearchColumnValue(columnName, value) {
            // store からマッピングデータを取得
            const masterMappings = [
                { key: '相手情報', mappingData: this.$store.state.aiteMapping, column: '相手先cd', matchColumn: '結合' },
                { key: '部門情報', mappingData: this.$store.state.bumonMapping, column: '部門コード', matchColumn: '結合' },
                { key: '税コード', mappingData: this.$store.state.taxMapping, column: 'Taxコード', matchColumn: '結合' },
                { key: '勘定及び補助', mappingData: this.$store.state.subMapping, column: '結合cd', matchColumn: '勘定及び補助' },
                { key: 'PJ情報', mappingData: this.$store.state.pjMapping, column: 'PJ_cd', matchColumn: '結合' },
                { key: '振込情報', mappingData: this.$store.state.fbMapping, column: '結合cd', matchColumn: '振込情報' }
            ];

            // マッピングに一致するか確認
            const mapping = masterMappings.find(m => m.key === columnName);

            if (mapping) {
                const { mappingData, matchColumn } = mapping;

                // マッチング対象カラムで value を検索して対応するコードを取得
                const masterEntry = mappingData.find(item => item[matchColumn] === value);

                if (masterEntry) {
                    return { value: String(masterEntry[mapping.column]), isCd: true }; // 一致する場合に isCd フラグを追加
                }
            }

            // 一致しない場合はそのまま返す
            return { value, isCd: false }; // isCd を false として返す
        },

        importFromOther() {
            this.showSoftwareDialog = true; // ダイアログを表示
        },

        async calculateProcessingEstimates(file) {
            const text = await file.text();
            const lines = text.split('\n').filter(line => line.trim()).length - 1;
            this.totalRecords = lines;

            // 処理時間の見積もり（1000行あたり3秒と仮定）
            const estimatedMinutes = Math.ceil((lines / 1000) * 3 / 60);
            
            // 開始時間と予想終了時間を設定
            const now = new Date();
            const end = new Date(now.getTime() + (estimatedMinutes * 60 * 1000));
            
            this.startTime = now.toLocaleTimeString();
            this.estimatedEndTime = end.toLocaleTimeString();
            
            return lines;
        },

        parseCSVLine(line) {
            const result = [];
            let cell = '';
            let isQuoted = false;
            let i = 0;
            
            while (i < line.length) {
                const char = line[i];
                
                if (char === '"') {
                    if (!isQuoted) {
                        // クォートの開始
                        isQuoted = true;
                    } else if (i + 1 < line.length && line[i + 1] === '"') {
                        // エスケープされたクォート
                        cell += '"';
                        i++;
                    } else {
                        // クォートの終了
                        isQuoted = false;
                    }
                } else if (char === ',' && !isQuoted) {
                    // セルの区切り（クォート外のカンマ）
                    result.push(cell.trim());
                    cell = '';
                } else {
                    cell += char;
                }
                i++;
            }
            
            // 最後のセルを追加
            result.push(cell.trim());
            return result;
        },

        async processImportFile(event) {
            const file = event.target.files[0];
            if (!file) {
                alert("転記対象のcsvファイルを選択してください");
                return;
            }
            
            if (!this.conversionData || this.conversionData.length === 0) {
                alert("変換データが不足しています");
                this.isProcessing = false;
                return;
            }

            try {
                // 処理前の見積もり計算
                await this.calculateProcessingEstimates(file);

                this.showFileDialog = false;
                this.isProcessing = true;
                this.cancelProcessing = false;

                const reader = new FileReader();
                reader.onload = async (e) => {
                    try {
                        const raw = new Uint8Array(e.target.result);

                        // BOMの確認
                        const hasUtf8Bom = raw.length >= 3 && 
                            raw[0] === 0xEF && 
                            raw[1] === 0xBB && 
                            raw[2] === 0xBF;
                        
                        const hasUtf16Bom = raw.length >= 2 && 
                            ((raw[0] === 0xFE && raw[1] === 0xFF) || 
                            (raw[0] === 0xFF && raw[1] === 0xFE));

                        let content;  // ここで変数を定義
                        if (hasUtf8Bom || hasUtf16Bom) {
                            content = new TextDecoder().decode(raw);  // contentを使用
                            console.log('UNICODEファイルとして処理');
                        } else {
                            content = Encoding.convert(raw, {  // contentを使用
                                to: 'UNICODE',
                                from: 'SJIS',
                                type: 'string'
                            });
                            console.log('SJISファイルとして処理');
                        }

                        // デバッグ用に変換後の内容を確認
                        console.log('変換後の最初の100文字:', content.substring(0, 100));

                        // CSVデータの処理部分を修正
                        const importData = content
                            .split(/\r?\n/)
                            .filter(row => row.trim() !== "")
                            .map(row => {
                                try {
                                    const cells = this.parseCSVLine(row);  // thisを使用してメソッドを呼び出し
                                    return cells.map(cell => 
                                        cell.trim()
                                            .replace(/^"/, '')
                                            .replace(/"$/, '')
                                            .replace(/\\"/g, '"')
                                            .replace(/\\\\/g, '\\')
                                    );
                                } catch (error) {
                                    console.error('行のパースエラー:', row, error);
                                    return row.split(',').map(cell => cell.trim());
                                }
                            });

                        // デバッグ用に変換後のデータを確認
                        console.log('変換後のデータ（先頭3行）:', importData.slice(0, 3));

                        if (importData.length < 2) {
                            alert("インポートデータが不足しています");
                            this.isProcessing = false;
                            return;
                        }

                        const rows = importData.slice(1).filter((row) => row.length === importData[0].length);

                        // 変換データを単純なオブジェクトに変換
                        const simplifiedDebitMappings = this.conversionData
                            .filter(mapping => mapping && !mapping.タイトル1?.includes("貸方") && mapping.転記cd > 0)
                            .map(mapping => ({
                                タイトル1: mapping.タイトル1 || '',
                                転記タイトル: mapping.転記タイトル || '',
                                加工: mapping.加工 || ''
                            }));

                        const simplifiedCreditMappings = this.conversionData
                            .filter(mapping => mapping && !mapping.タイトル1?.includes("借方") && mapping.転記cd > 0)
                            .map(mapping => ({
                                タイトル1: mapping.タイトル1 || '',
                                転記タイトル: mapping.転記タイトル || '',
                                加工: mapping.加工 || ''
                            }));

                        // ヘッダーインデックスキャッシュの作成（nullチェック追加）
                        const headerIndexCache = {};
                        if (importData && importData[0]) {
                            const headerRow = importData[0].map(header => 
                                header.trim().replace(/[\ufeff\u200b]/g, '').replace(/\s+/g, '')
                            );
                            
                            // デバッグ用にヘッダー行の内容を出力
                            console.log('CSVヘッダー行:', headerRow);
                            
                            this.conversionData.forEach(mapping => {
                                if (mapping && mapping.タイトル1) {
                                    const cleanTitle = mapping.タイトル1.trim()
                                        .replace(/[\ufeff\u200b]/g, '')  // BOMと制御文字を除去
                                        .replace(/\s+/g, '');            // 空白文字を除去
                                    
                                    const index = headerRow.indexOf(cleanTitle);
                                    headerIndexCache[mapping.タイトル1] = index;
                                    
                                }
                            });
                            
                            // 作成されたキャッシュの全体を確認
                            console.log('ヘッダーキャッシュ作成結果:', headerIndexCache);
                            
                            // ヘッダーマッピングの検証
                            const validMappings = Object.values(headerIndexCache).filter(index => index !== -1);
                            if (validMappings.length === 0) {
                                console.error('ヘッダーマッピングエラー:', {
                                    CSVヘッダー: importData[0],
                                    マッピング設定: this.conversionData.map(m => m.タイトル1)
                                });
                                alert('CSVファイルのヘッダーと変換設定が一致しません。\nヘッダーの内容とマッピング設定を確認してください。');
                                this.isProcessing = false;
                                return;
                            }
                        }

                        // Web Workerを作成
                        this.worker = new Worker(new URL('../workers/importWorker.js', import.meta.url));
                        
                        // Workerからのメッセージを処理
                        this.worker.onmessage = (e) => {
                            if (this.cancelProcessing) {
                                this.terminateWorker();
                                return;
                            }

                            const { type, processedData, message, errorRow } = e.data;  // errorを削除
    
                            switch (type) {
                                case 'started':
                                    console.log('処理開始:', e.data.totalRows + '件');
                                    break;

                                case 'error': {  // ブロックスコープを作成
                                    console.error('処理エラー:', message);
                                    const errorMessage = errorRow 
                                        ? `${message}\n\nCSVファイルの${errorRow}行目を確認してください。`
                                        : message;
                                    this.showErrorMessage(errorMessage);
                                    this.terminateWorker();
                                    break;
                                }

                                case 'completed':
                                    if (!processedData || processedData.length === 0) {
                                        alert('処理されたデータが空です');
                                        this.terminateWorker();
                                        return;
                                    }

                                    try {
                                        this.gridOptions.api.setRowData(processedData);
                                        alert('データがインポートされました');
                                        this.terminateWorker(true);
                                    } catch (gridError) {
                                        console.error('グリッド更新エラー:', gridError);
                                        alert(`データの表示中にエラーが発生しました: ${gridError.message}`);
                                        this.terminateWorker();
                                    }
                                    break;
                            }
                        };

                        // Workerのエラーハンドリング
                        this.worker.onerror = (error) => {
                            console.error('Worker error:', error);
                            alert(`Workerでエラーが発生しました: ${error.message}`);
                            this.terminateWorker();
                        };

                        // Workerにデータを送信
                        const workerData = {
                            rows,
                            debitMappings: simplifiedDebitMappings,
                            creditMappings: simplifiedCreditMappings,
                            headerIndexCache,
                            chunkSize: 1000,
                            conversionData: this.conversionData.map(item => ({
                                cd1: item.cd1,
                                タイトル1: item.タイトル1,
                                転記タイトル: item.転記タイトル,
                                加工: item.加工
                            }))
                        };

                        this.worker.postMessage(workerData);

                    } catch (error) {
                        console.error('ファイル処理エラー:', error);
                        alert(`ファイルの処理中にエラーが発生しました: ${error.message}`);
                        this.terminateWorker();
                    }
                };

                 // ファイル読み込みエラーのハンドリング
                reader.onerror = (error) => {
                    console.error('ファイル読み込みエラー:', error);
                    alert(`ファイルの読み込み中にエラーが発生しました: ${error.message}`);
                    this.terminateWorker();
                };

                reader.readAsArrayBuffer(file);

            } catch (error) {
                console.error('初期化エラー:', error);
                alert(`処理の初期化中にエラーが発生しました: ${error.message}`);
                this.terminateWorker();
            }
        },

        // Worker終了処理を共通化
        terminateWorker() {
            if (this.worker) {
                this.worker.terminate();
                this.worker = null;
            }
            this.isProcessing = false;
            this.cancelProcessing = false;
        },

        // 中断ハンドラー
        cancelProcessingHandler() {
            if (this.worker) {
                this.cancelProcessing = true;  // キャンセルフラグを設定
                this.worker.postMessage({ type: 'cancel' });
                this.worker.terminate();  // Workerを強制終了
                this.worker = null;
                this.isProcessing = false;
                alert('処理を中断しました');
            }
        },
        
        // 会計ソフト選択ダイアログキャンセル
        cancelSoftwareDialog() {
            this.showSoftwareDialog = false;
            this.showFileDialog = false;
        },
        // conversionテーブルのデータを取得
        async fetchConversionData() {
            if (!this.selectedSoftware) {
                alert("会計ソフトを選択してください");
                return;
            }
            let tableName = this.activeTab;
            if (this.activeTab.includes("_")) {
                const parts = this.activeTab.split("_");
                tableName = parts[1];
            }

            try {
                const response = await fetch("/api/select_conversion.php", {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        activeTab: tableName,
                        software: this.selectedSoftware,
                    }),
                });

                if (!response.ok) throw new Error("データ取得に失敗しました");

                this.conversionData = await response.json();
                console.log("Conversion Data:", this.conversionData);
                this.showSoftwareDialog = false;
                this.showFileDialog = true; // ファイル選択画面を表示
            } catch (error) {
                console.error(error);
                alert("データ取得中にエラーが発生しました");
            }
        },
        // ファイル選択ダイアログキャンセル
        cancelFileDialog() {
            this.showFileDialog = false;
            this.uploadedFile = null;
        },
        // ファイル選択処理
        processFile(event) {
            this.uploadedFile = event.target.files[0];
        },
        
        // ファイルインポート処理
        async confirmFileDialog() {
            if (!this.uploadedFile || !this.conversionData) {
                alert("ファイルまたは変換データが不足しています");
                return;
            }

            try {
                const reader = new FileReader();
                reader.onload = async (e) => {
                const csvContent = e.target.result;
                const rows = csvContent.split("\n").map((row) => row.split(","));

                // Conversionデータを基に変換
                const convertedData = rows.map((row) => {
                    const convertedRow = {};
                    this.conversionData.forEach((mapping) => {
                    if (mapping.cd1 && mapping.cd2) {
                        convertedRow[mapping.cd2] = row[mapping.cd1 - 1]; // cd1は1-based index
                    }
                    });
                    return convertedRow;
                });

                console.log("Converted Data:", convertedData);

                // AG-Gridに反映
                this.gridOptions.api.setRowData(convertedData);
                alert("データがインポートされました");
                };

                reader.readAsText(this.uploadedFile, "utf-8");
                this.showFileDialog = false;
            } catch (error) {
                console.error(error);
                alert("ファイル処理中にエラーが発生しました");
            }
        },
        importFormOut() {
            // 現在のactiveTabに対応するcolumnDefsを取得
            const columnDefs = this.columnDefs || [];

            // validationに'readonly'が含まれないカラム、またはvalidationが空のカラムをフィルタリング
            const requiredColumns = columnDefs.filter(
                col => !col.validation || !col.validation.includes('readonly')
            );

            if (requiredColumns.length === 0) {
                alert('出力対象のカラムがありません');
                return;
            }

            // CSVヘッダーを取得（fieldが未定義の場合に対処）
            const headers = requiredColumns
                .map(col => (col.field ? col.field.trim() : "")) // fieldが未定義の場合は空文字を設定
                .filter(header => header !== ""); // 空文字の列名は除外

            if (headers.length === 0) {
                alert('有効なカラムが見つかりません');
                return;
            }

            // ダミーデータの生成（ヘッダーに対応する空データ）
            const dummyRow = headers.map(() => ''); // 空のテンプレート行

            // CSV内容を構築
            const csvContent = [headers.join(","), dummyRow.join(",")].join("\n");

            // UTF-8 BOMを追加
            const BOM = "\uFEFF"; // UTF-8 BOM
            const blob = new Blob([BOM + csvContent], { type: "text/csv;charset=utf-8;" });

            // ダウンロード処理
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = `${this.activeTab}_template.csv`; // ファイル名にactiveTabを反映
            link.click();

            alert('CSVテンプレートがダウンロードされました');
        },
        openImportDialog() {
            this.showImportDialog = true;
        },
        // ダイアログを閉じる
        cancelImport() {
            this.selectedFile = null; // 選択されたファイルをクリア
            this.showImportDialog = false;
        },
        // インポート実行
        confirmImport() {
            if (!this.selectedFile) {
                alert('CSVファイルを選択してください');
                return;
            }
            this.importData(this.selectedFile);
            this.cancelImport(); // インポート後にダイアログを閉じる
        },
        // CSVインポート処理
        async importData(event) {
            // ファイル選択イベントからCSVファイルを取得
            const file = event.target.files[0];
            if (!file) {
                alert('ファイルが選択されていません');
                return;
            }

            // ファイル内容を読み込み
            const csvContent = await file.text();

            // CSVをパース
            const rows = csvContent.split("\n").map(row => row.split(",").map(cell => cell.trim()));

            if (rows.length < 2) {
                alert('CSVにデータが含まれていません');
                this.cancelImport();
                return;
            }

            // CSVのヘッダー（1行目）とデータ（2行目以降）を分離
            const headers = rows[0]; // 1行目のカラム名
            const dataRows = rows.slice(1).filter(row => row.some(cell => cell !== "")); // 空行を除外

            // ActiveTabのカラム名を取得
            const columnDefs = this.columnDefs || [];
            const activeTabColumns = columnDefs.map(col => col.field);

            // テンプレートCSVのヘッダーに一致するActiveTabのカラムのみを取得
            const validColumns = headers.filter(header => activeTabColumns.includes(header));

            if (validColumns.length === 0) {
                alert('テンプレートCSVにActiveTabに一致するカラムがありません');
                this.cancelImport();
                return;
            }

            // rowDataを生成（テンプレートCSVのデータをActiveTabのカラムにマッピング）
            const rowData = dataRows.map(row => {
                const rowDataEntry = {};
                headers.forEach((header, index) => {
                    if (validColumns.includes(header)) {
                        rowDataEntry[header] = row[index] || ""; // 一致するカラムにデータを設定
                    }
                });
                return rowDataEntry;
            });

            // AG-Gridのデータをクリアして、新しいデータを設定
            this.gridOptions.api.setRowData(rowData);

            alert('テンプレートCSVのデータを転記しました');
            this.cancelImport();
        },

        handlePasteEvent(event) {
            // クリップボードのデータを取得
            const clipboardData = event.clipboardData || window.clipboardData;
            const pastedText = clipboardData.getData('Text');
            const pastedRows = pastedText.split('\n'); // 行ごとに分割

            // 空行を取り除く
            const filteredRows = pastedRows.filter(row => row.trim() !== '');

            // 行数を取得
            const pastedRowCount = filteredRows.length;
            console.log('ペーストされたデータの行数:', pastedRowCount);

            // ペーストを開始する行のインデックスを取得
            const focusedCell = this.gridOptions.api.getFocusedCell();
            if (!focusedCell) {
                console.error('フォーカスされたセルが見つかりません。');
                return;
            }

            const startRowIndex = focusedCell.rowIndex;
            console.log('ペースト開始行のインデックス:', startRowIndex);

            // 不足している行数を計算
            const currentRowCount = this.rowData.length;
            const rowsRemaining = currentRowCount - startRowIndex; // ペースト開始位置から現在の行の残りの数
            const rowsToAdd = pastedRowCount - rowsRemaining;

            // 不足している行数のみ追加
            if (rowsToAdd > 0) {
                this.selectAndAddRows(rowsToAdd, this.activeTab);
            }

            // グリッドに新しいデータを反映する
            if (this.gridOptions && this.gridOptions.api) {
                this.gridOptions.api.setRowData(this.rowData);
            }
        },
        
        async updateInputLists() {
            try {
                // マスターデータを再取得
                await this.getMasterMappingFromServer();                
                // グリッドが存在する場合のみ処理を実行
                if (this.gridApi) {
                    // 現在のグリッドの状態を保存
                    const currentData = this.rowData;
                    const selectedRows = this.gridApi.getSelectedRows();
                    const focusedCell = this.gridApi.getFocusedCell();                    
                    // カラム定義を再取得して更新
                    await this.getColumnDefsFromServer();                    
                    // グリッドを更新
                    this.gridApi.setColumnDefs(this.columnDefs);                    
                    // データを再セット
                    this.gridApi.setRowData(currentData);                    
                    // 選択状態と編集位置を復元
                    if (selectedRows.length > 0) {
                        this.gridApi.forEachNode(node => {
                            if (selectedRows.find(row => row.id === node.data.id)) {
                                node.setSelected(true);
                            }
                        });
                    }
                    if (focusedCell) {
                        this.gridApi.setFocusedCell(focusedCell.rowIndex, focusedCell.column);
                    }
                }                
                console.log('入力リストが更新されました');
            } catch (error) {
                console.error('入力リストの更新中にエラーが発生しました:', error);
                alert('入力リストの更新に失敗しました。');
            }
        },
        // マスターデータ更新後に呼び出す関数
        async handleMasterDataUpdate() {
            await this.updateInputLists();
            await this.updateMasterData();
        },
        getInitialDate() {
            const today = new Date().toISOString().split('T')[0];
            if (this.allowedDates && this.allowedDates.includes(today)) {
                return today;
            }
            return this.allowedDates && this.allowedDates.length > 0 ? this.allowedDates[0] : ''; // 最初の要素（最新の日付）を返す
        },
        addSearchItem() {
            // データが存在しないかチェック
            if (!this.gridOptions.api.getModel().getRowCount()) {
                return;
            }

            // セル選択範囲の取得とチェック
            let selectedRanges = this.gridOptions.api.getCellRanges();
            if (!selectedRanges || !selectedRanges.length) {
                alert("セルが選択されていません");
                return;
            }

            // 列ごとにユニークな値を収集
            const columnValues = {};
            selectedRanges.forEach(range => {
                const startRow = Math.min(range.startRow.rowIndex, range.endRow.rowIndex);
                const endRow = Math.max(range.startRow.rowIndex, range.endRow.rowIndex);
                range.columns.forEach(column => {
                    const field = column.getColId();
                    if (!columnValues[field]) {
                        columnValues[field] = new Set();
                    }
                    // 選択範囲内の各セルの値を収集
                    for (let rowIndex = startRow; rowIndex <= endRow; rowIndex++) {
                        const node = this.gridOptions.api.getDisplayedRowAtIndex(rowIndex);
                        if (node && node.data[field]) {
                            columnValues[field].add(node.data[field]);
                        }
                    }
                });
            });

            // 既存のsearchEntriesを維持しながら、選択したセルの値を更新
            Object.entries(columnValues).forEach(([field, values]) => {
                if (values.size > 0) {
                    // 既存のエントリーを探す
                    const existingEntryIndex = this.searchEntries.findIndex(
                        entry => entry.searchColumnName === field
                    );
                    
                    const newValue = Array.from(values).join('||');

                    if (existingEntryIndex !== -1) {
                        // 既存のエントリーがある場合は更新
                        this.searchEntries[existingEntryIndex].input = newValue;
                    } else {
                        // 新しいエントリーを追加
                        this.searchEntries.push({
                            searchColumnName: field,
                            input: newValue,
                            searchColumnType: 'string'
                        });
                    }
                }
            });
        },
        calculateTaxRate(baseAmount, taxAmount) {
            if (!baseAmount || baseAmount === 0) return '0.000';
            return ((taxAmount / baseAmount) * 100).toFixed(3);
        },
        showSelectRowOnly() {
            // セル範囲から行を取得
            const cellRanges = this.gridApi.getCellRanges();
            if (!cellRanges || cellRanges.length === 0) {
                alert("選択された行がありません。");
                return;
            }

            // 選択された範囲内のすべての行を取得
            const selectedRows = new Set(); // 重複を避けるためにSetを使用
            cellRanges.forEach(range => {
                const startIndex = Math.min(range.startRow.rowIndex, range.endRow.rowIndex);
                const endIndex = Math.max(range.startRow.rowIndex, range.endRow.rowIndex);
                
                for (let i = startIndex; i <= endIndex; i++) {
                    const row = this.gridApi.getDisplayedRowAtIndex(i);
                    if (row) {
                        selectedRows.add(row.data);
                    }
                }
            });

            // Set を配列に変換
            const selectedData = Array.from(selectedRows);
            
            if (selectedData.length === 0) {
                alert("選択された行がありません。");
                return;
            }

            // データをフィルタリング
            this.gridApi.setRowData(selectedData); // 選択行のみを表示

            console.log("選択されたデータ:", selectedData);
        },
        LogDiffDownload(){
            this.logDownload('LogDiffDownload');
        },
        jeLogDownload(){
            this.logDownload('jeLogDownload');
        },
        masterLogDownload(){
            this.logDownload('masterLogDownload');
        },
        async logDownload(logtype) {
            console.log('logDownload:', logtype);

            if (!this.gridOptions.api.getModel().getRowCount()) {
                alert("データがありません");
                return;
            }

            let selectedRanges = this.gridOptions.api.getCellRanges();
            if (!selectedRanges || !selectedRanges.length) {
                alert("セルが選択されていません");
                return;
            }

            let parsedData = [];
            let tableName = null;

            const hasBlankIdOrMixedTable = selectedRanges.some(range => {
                let startRowIndex = Math.min(range.startRow.rowIndex, range.endRow.rowIndex);
                let endRowIndex = Math.max(range.startRow.rowIndex, range.endRow.rowIndex);
                for (let i = startRowIndex; i <= endRowIndex; i++) {
                    const displayedRow = this.gridOptions.api.getDisplayedRowAtIndex(i);
                    if (!displayedRow) {
                        continue;
                    }
                    const rowData = displayedRow.data;
                    if (!rowData) {
                        alert("データが欠落しています");
                        return true;
                    }
                    
                    if (!tableName) {
                        tableName = rowData['table_name'];
                    } else if (tableName !== rowData['table_name']) {
                        alert("異なる table_name を選択しています");
                        return true;
                    }                    

                    const id = rowData.id;
                    const jsonBeforeChange = rowData['変更前'];
                    const jsonAfterChange = rowData['変更後'];
                    if (!id || !jsonBeforeChange || !jsonAfterChange) {
                        alert("IDまたは変更前/変更後のデータが欠落しています");
                        return true;
                    }

                    const jsonDataBefore = JSON.parse(jsonBeforeChange);
                    const jsonDataAfter = JSON.parse(jsonAfterChange);

                    let diffData = {};
                    if (logtype === 'LogDiffDownload') {
                        // IDを必ず含める
                        const beforeId = jsonDataBefore.id || rowData.id;
                        diffData['id'] = beforeId;

                        // 変更のあるフィールドを追加
                        Object.keys(jsonDataBefore).forEach(key => {
                            // idフィールドはスキップ（既に追加済み）
                            if (key !== 'id' && jsonDataBefore[key] !== jsonDataAfter[key]) {
                                diffData[key] = `${jsonDataBefore[key]} => ${jsonDataAfter[key]}`;
                            }
                        });

                        // 空のdiffDataの場合でもIDは保持
                        if (Object.keys(diffData).length === 1 && diffData['id']) {
                            console.log('Only ID found in diffData');
                        }
                    } else {
                        diffData = { ...jsonDataBefore };
                    }

                    parsedData.push({
                        ...(logtype === 'masterLogDownload' ? { table_name: tableName } : {}),
                        ...diffData
                    });
                }
                return false;
            });

            if (hasBlankIdOrMixedTable) {
                return;
            }

            let tableColumn = [];
            const postData = JSON.stringify({ table: tableName });
            const response = await fetch('/api/get_column.php', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: postData
            });
            const columnData = await response.json();

            columnData.sort((a, b) => a.columnCd - b.columnCd);
            tableColumn = columnData.map(col => col.columnName);

            const rows = parsedData.map(row => {
                return tableColumn.map(column => `"${row[column] || ''}"`).join(',');
            });

            const csvContent = ['\uFEFF' + tableColumn.join(','), ...rows].join('\n');

            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            
            const fileName = `${logtype === 'jeLogDownload' ? '仕訳変更ログ' : logtype === 'LogDiffDownload' ? '変更差分ログ' : 'マスタ変更ログ'}_${tableName}_${new Date().toISOString().split('T')[0]}.csv`;

            const link = document.createElement('a');
            if (navigator.msSaveBlob) {
                navigator.msSaveBlob(blob, fileName);
            } else {
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', fileName);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }

            alert("ログデータがCSV形式でダウンロードされました");
        },
        validateDate(field) {
            const datePattern = /^\d{4}-\d{2}-\d{2}$/;
            if (!datePattern.test(this.currentDateTimeEntry[field])) {
                console.warn(`無効な日付形式です。YYYY-MM-DD形式で入力してください。`);
                return;
            }

            const date = new Date(this.currentDateTimeEntry[field]);
            if (isNaN(date.getTime())) {
                console.warn(`無効な日付です。`);
                return;
            }

            this.currentDateTimeEntry[field] = this.formatDate(date);
        },
        openDateTimeDialog(index) {
            this.selectedInputIndex = index;
            const entry = this.searchEntries[index];
            if (!entry.startDate || !entry.endDate) {
                const now = new Date();
                const yesterday = new Date(now.getTime() - 24*60*60*1000);
                this.currentDateTimeEntry = {
                    startDate: this.formatDate(yesterday),
                    startTime: "00:00",
                    endDate: this.formatDate(now),
                    endTime: "23:59",
                    searchColumnName: entry.searchColumnName
                };
            } else {
                this.currentDateTimeEntry = { 
                    startDate: entry.startDate,
                    startTime: entry.startTime,
                    endDate: entry.endDate,
                    endTime: entry.endTime,
                    searchColumnName: entry.searchColumnName
                };
            }
            this.showDateTimeDialog = true;
        },
        // 日時範囲を適用して即時検索を実行するメソッド
        applyDateTimeRangeAndSearch() {
            // 現在選択中のカラムの情報を一時保存
            
            this.applyDateTimeRange();
            const currentIndex = this.selectedInputIndex;
            
            const currentEntry = { ...this.searchEntries[currentIndex] };
            console.log('currentEntry:',currentEntry);
            
            // すべての検索条件をクリア
            this.clearConditions();

            // 選択中のカラムの情報を復元
            this.searchEntries[currentIndex] = currentEntry;
            
            // 検索を実行
            if (this.activeTab.includes('ログ')) {
                this.executeSearch('仕訳以外');
            } else if (this.activeTab.includes('仕訳')) {
                this.executeSearch('伝票番号');
            } else {
                this.executeSearch('仕訳以外');
            }
        },
        applyDateTimeRange() {
            if (this.currentDateTimeEntry) {
                const startDateTime = `${this.currentDateTimeEntry.startDate}_${this.currentDateTimeEntry.startTime}`;
                const endDateTime = `${this.currentDateTimeEntry.endDate}_${this.currentDateTimeEntry.endTime}`;
                
                if (this.selectedInputIndex !== null && this.selectedInputIndex >= 0) {
                    const formattedValue = `>=${startDateTime} <=${endDateTime}`;
                    this.searchEntries[this.selectedInputIndex].input = formattedValue;
                    // 日時情報も更新
                    Object.assign(this.searchEntries[this.selectedInputIndex], {
                        startDate: this.currentDateTimeEntry.startDate,
                        startTime: this.currentDateTimeEntry.startTime,
                        endDate: this.currentDateTimeEntry.endDate,
                        endTime: this.currentDateTimeEntry.endTime
                    });
                }

                console.log('Start DateTime:', startDateTime);
                console.log('End DateTime:', endDateTime);
            } else {
                console.error('currentDateTimeEntry is undefined');
            }

            this.showDateTimeDialog = false;
        },
        clearSearchValues() {
            // filteredSearchEntriesの各エントリーの入力値をクリア
            if (this.filteredSearchEntries) {
                this.filteredSearchEntries.forEach(entry => {
                    entry.input = '';
                });
            }
            
            // グリッドのフィルターもクリアする（存在する場合）
            if (this.gridApi) {
                this.gridApi.setFilterModel(null);
            }
        },
        clearSelectedCells() {
            const selectedRanges = this.gridOptions.api.getCellRanges();
            if (!selectedRanges || selectedRanges.length === 0) {
                alert("セルが選択されていません");
                return;
            }

            selectedRanges.forEach(range => {
                const startRow = Math.min(range.startRow.rowIndex, range.endRow.rowIndex);
                const endRow = Math.max(range.startRow.rowIndex, range.endRow.rowIndex);
                const columns = range.columns;

                for (let rowIndex = startRow; rowIndex <= endRow; rowIndex++) {
                    columns.forEach(column => {
                        const node = this.gridOptions.api.getDisplayedRowAtIndex(rowIndex);
                        if (node) {
                            node.setDataValue(column.getColId(), null);
                        }
                    });
                }
            });

            this.gridOptions.api.refreshCells();
        },
        onDateSelect(date, index, type) {
            const entry = this.searchEntries[index];
            if (type === 'start') {
                entry.startDate = this.formatDate(new Date(date));
            } else {
                entry.endDate = this.formatDate(new Date(date));
            }
            console.log(`Date selected for index ${index}, type ${type}:`, entry[type === 'start' ? 'startDate' : 'endDate']); // デバッグ情報
        },
        openSimpleInputMasterList(columnName) {
            this.currentSimpleInputMasterTitle = columnName;
            this.simpleInputFilterText = '';
            this.simpleInputSelectedItems = [];

            if (columnName === '相手情報') {
                this.currentMasterList = this.aiteMapping.map(item => item['結合']);
            }

            this.generateDateList();
            this.showSimpleInputMasterDialog = true;
        },
        toggleSimpleInputSelection(item) {
            const index = this.simpleInputSelectedItems.indexOf(item);
            if (index === -1) {
                this.simpleInputSelectedItems = [item]; // 単一選択のため、配列を新しい項目で上書き
            } else {
                this.simpleInputSelectedItems = [];
            }
        },
        confirmSimpleInputSelection() {
            if (this.simpleInputSelectedItems.length > 0) {
                this.simpleInput.相手情報 = this.simpleInputSelectedItems[0];
            }
            this.showSimpleInputMasterDialog = false;
        },
        clearSimpleInputSelection() {
            this.simpleInput.相手情報 = '';
            this.simpleInputSelectedItems = [];
        },
        createShorttickLabels() {
            this.shorttickLabels = {};
            Object.entries(this.tickLabels).forEach(([key, value]) => {
                // 年月（例：202310）から年の下2桁と月を取り出す（例：2310）
                this.shorttickLabels[key] = value.slice(2);
            });
        },
        async getMasterMappingIfNeeded() {
            if (!this.masterMappingFetched) {
                await this.fetchMasterMapping(this.getMasterMappingFromServer.bind(this));
            }
        },
        onViewportChanged: debounce(function() {
            console.log('Viewport changed');
            if (this.activeTab && (this.activeTab.includes('TB') || this.activeTab.includes('推移'))) {
                this.applyColorToDisplayCategory();
            }
        }, 100),
        async handleAsyncOperation(operation, options = {}) {
            const {
                maxRetries = 3,
                baseDelay = 1000,
                timeout = 30000,
                errorMessage = 'エラーが発生しました'
            } = options;

            if (this.isLoading) {
                console.log('別の処理が実行中です。しばらくお待ちください。');
                return;
            }
            
            this.isLoading = true;

            const executeWithRetry = async (retryCount = 0) => {
                try {
                    const controller = new AbortController();
                    const id = setTimeout(() => controller.abort(), timeout);

                    const result = await Promise.race([
                        operation(controller.signal),
                        new Promise((_, reject) => 
                            setTimeout(() => reject(new Error('Operation timed out')), timeout)
                        )
                    ]);

                    clearTimeout(id);
                    return result;
                } catch (error) {
                    if (retryCount < maxRetries) {
                        console.log(`リトライ中... (${retryCount + 1}/${maxRetries})`);
                        const delay = baseDelay * Math.pow(2, retryCount);
                        await new Promise(resolve => setTimeout(resolve, delay));
                        return executeWithRetry(retryCount + 1);
                    }
                    throw error;
                }
            };

            try {
                return await executeWithRetry();
            } catch (error) {
                console.error(`${errorMessage}:`, error);
                if (error.name === 'AbortError') {
                    console.error('リクエストがタイムアウトにより中断されました');
                }
                alert(`${errorMessage}: ${error.message}`);
            } finally {
                this.isLoading = false;
            }
        },
        executeRefresh(){
            if (this.activeTab.includes('TB') || this.activeTab.includes('推移') || this.activeTab.includes('PJ分析') || this.activeTab.includes('部門')) {
                this.fetchTbOrTrend();
            } else if (this.activeTab.includes('元帳')) {
                this.fetchLedgerDirect();
            } else if (this.activeTab.includes('消費税ck画面')) {
                this.fetchCtaxCheck();
            }
        },
        async fetchCtaxCheck(){
            const tableName = this.determineTable(this.activeTab);
            const periods = this.tickLabels;
            let selectedPeriod = periods[this.selectedPeriodIndex];
            if (typeof selectedPeriod === 'undefined') {
                selectedPeriod = periods[Object.keys(periods).length - 1];
            }
            
            const cleanedTableName = tableName;

            // status情報を取得
            const selectStatusEntry = this.filteredSearchEntries.find(entry => entry.searchColumnName === 'status');
            const selectStatus = selectStatusEntry ? selectStatusEntry.input : null;

            try {
                // 既存のデータをクリア
                this.rowData = []; 
                this.gridApi.setRowData(this.rowData);
                // JSON形式でデータを準備
                const postData = {
                    targetPeriod: this.targetPeriod,
                    selectPeriod: selectedPeriod,
                    tableName: cleanedTableName,
                    selectStatus: selectStatus,
                };
                // FetchリクエストでJSON形式を指定してPOSTデータを送信
                const response = await fetch('/api/select_ctax_check.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(postData)
                });

                const { ctaxDetail, ctaxSummary } = await response.json(); 
                // ctaxDetail に対して transformMasterMapping を適用
                const transformedCtaxDetail = this.transformMasterMapping(ctaxDetail);
                const transformedCtaxSummary = this.transformMasterMapping(ctaxSummary);
                
                if (transformedCtaxDetail) {
                    // 詳細データをグリッドに設定
                    const enhancedData = transformedCtaxDetail.map(row => ({
                        ...row,
                        税率: row['税抜金額'] !== 0 && row['税額'] !== 0
                            ? (row['税額'] / row['税抜金額'] * 100).toFixed(3)
                            : '0.000'
                    }));
                    
                    this.gridApi.setRowData(enhancedData);
                    this.gridOptions.getRowStyle = (params) => getRowStyle(params, tableName);
                    
                    // サマリーデータを設定
                    this.ctaxSummary = transformedCtaxSummary || []; // summaryがundefinedの場合は空配列を設定
                    this.$nextTick(() => { this.autoSizeAllColumns(); });
                    return;
                } else {
                    console.log('error');// 適切なエラーハンドリングをここに記述する
                }
            } catch (error) {
                console.error('Error fetching ctax_check_data:', error);
            }
            this.$nextTick(() => {this.autoSizeAllColumns();});
        },
        async fetchLedgerDirect(){
            const tableName = this.determineTable(this.activeTab);
            const periods = this.tickLabels;
            let selectedPeriod = periods[this.selectedPeriodIndex];
            if (typeof selectedPeriod === 'undefined') {
                selectedPeriod = periods[Object.keys(periods).length - 1];
            }
            
            const cleanedTableName = tableName.split('(')[0];

            // 勘定情報または勘定及び補助の入力チェック
            for (const entry of this.filteredSearchEntries) {
                if ((entry.searchColumnName === '勘定情報' || entry.searchColumnName === '勘定及び補助') && !entry.input) {
                    alert(`${entry.searchColumnName}が未入力です`);
                    return; // 処理を中断
                }
            }

            this.periodListVisible = false;

            try {
                // 既存のデータをクリア
                this.rowData = []; 
                this.gridApi.setRowData(this.rowData);

                // filteredSearchEntries から searchColumnName と input の値を取得
                const searchConditions = this.filteredSearchEntries
                    .filter(entry => entry.searchColumnName !== 'status')
                    .map(entry => ({
                        searchColumnName: entry.searchColumnName,
                        input: entry.input
                }));
                console.log('searchConditions:', searchConditions);

                // 部門情報を取得
                const selectDeptEntry = this.filteredSearchEntries.find(entry => entry.searchColumnName === '部門情報');
                const selectDept = selectDeptEntry && selectDeptEntry.input ? selectDeptEntry.input.trim() : null;
                
                // status情報を取得
                const selectStatusEntry = this.filteredSearchEntries.find(entry => entry.searchColumnName === 'status');
                const selectStatus = selectStatusEntry ? selectStatusEntry.input : null;

                // JSON形式でデータを準備
                const postData = {
                    targetPeriod: this.targetPeriod,
                    selectPeriod: selectedPeriod,
                    tableName: cleanedTableName,
                    selectStatus: selectStatus,
                    searchConditions: searchConditions // 追加
                };
                // FetchリクエストでJSON形式を指定してPOSTデータを送信
                const response = await fetch('/api/select_Ledger.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(postData)
                });

                const data = await response.json(); 
                
                if (data) {
                    // journal と balance のデータを結合
                    const combinedData = [...data.journal, ...data.balance];
                    // 結合されたデータを使って処理
                    let sortedData = sortDataByType(combinedData, tableName, selectDept !== null && selectDept !== '');
                    this.rowData = calculateBalancePerAccountAndMonth(sortedData, tableName, 'fromLedger', selectDept !== null && selectDept !== '');
                    this.rowData = this.transformMasterMapping(this.rowData);

                    this.gridApi.setRowData(this.rowData);

                    this.gridOptions.getRowStyle = (params) => getRowStyle(params, tableName);
                    this.$nextTick(() => { this.autoSizeAllColumns(); });

                    return;
                } else {
                    console.log('error');// 適切なエラーハンドリングをここに記述する
                }
            } catch (error) {
                console.error('Error fetching select_ledger_data:', error);
            }
            this.$nextTick(() => {this.autoSizeAllColumns();});
        },
        handleF2Key(index, event) {
            if (event.key === 'F2') {
                this.searchEntries[index].expanded = !this.searchEntries[index].expanded;
            }
        },
        handleScroll(event) {
            // スクロールが右方向であれば列幅を調整
            if (event.direction === 'horizontal') {
                this.autoSizeVisibleColumns();
            }
        },
        autoSizeVisibleColumns() {
            // gridColumnApi または getAllDisplayedColumns が undefined の場合はスキップ
            if (!this.gridColumnApi || !this.gridColumnApi.getAllDisplayedColumns) {
                console.warn('gridColumnApi is not ready or getAllDisplayedColumns is undefined');
                return;
            }

            // 画面に表示されているすべての列を取得
            const allVisibleColumnIds = this.gridColumnApi.getAllDisplayedColumns().map(column => column.getColId());

            // 取得した列IDを使って、すべての列のサイズを自動調整
            if (allVisibleColumnIds.length > 0) {
                this.gridColumnApi.autoSizeColumns(allVisibleColumnIds);
            }
        },
        // ピボットされた'表示区分'と'勘定情報'をピン留めする関数 枠固定/列固定
        pinPivotColumns() {
            if (!this.gridColumnApi || typeof this.gridColumnApi.getAllDisplayedColumns !== 'function') {
                console.warn('gridColumnApi is not ready or getAllDisplayedColumns is undefined');
                return;
            }

            const allColumns = this.gridColumnApi.getAllDisplayedColumns();

            // 部門情報を取得
            const selectDeptEntry = this.filteredSearchEntries.find(entry => entry.searchColumnName === '部門情報');
            const selectDept = selectDeptEntry ? selectDeptEntry.input : null;

            let columnsToPin;
            if (this.activeTab === '部門PL') {
                columnsToPin = ['部門情報','表示区分', '勘定情報'];
            } else if (this.activeTab === 'PJ分析') {
                columnsToPin = ['PJ情報','表示区分', '勘定情報'];
            } else {
                columnsToPin = ['表示区分', '勘定情報', '相手情報', 'PJ情報'];
            }
            
            if (selectDept) {
                columnsToPin.unshift('部門情報');
            }

            // 元の列の順序を維持しつつ、ピン留めする列を特定
            const columnState = allColumns.map(column => {
                const colId = column.getColId();
                const shouldPin = columnsToPin.some(pin => colId.includes(pin));
                return {
                    colId: colId,
                    pinned: shouldPin ? 'left' : null
                };
            });

            // 列の状態を適用してピン留め
            this.gridColumnApi.applyColumnState({
                state: columnState,
                applyOrder: false // 元の順序を維持
            });
        },
        // 選択状態をトグルするメソッド
        toggleSelection(item) {
            const index = this.selectedItems.indexOf(item);
            if (index === -1) {
                this.selectedItems.push(item); // 選択されたアイテムを追加
            } else {
                this.selectedItems.splice(index, 1); // 選択解除
            }
            console.log(this.selectedItems); // デバッグ用: 選択されたアイテムを確認
        },
        confirmAndSearch() {
            // 現在選択中のカラムの情報を一時保存
            const currentIndex = this.selectedInputIndex;
            const currentEntry = this.activeTab.includes('元帳') || this.activeTab.includes('TB')
                ? { ...this.filteredSearchEntries[currentIndex] }
                : { ...this.searchEntries[currentIndex] };

            // すべての検索条件をクリア
            this.clearConditions();

            // 選択中のカラムの情報を復元
            if (this.activeTab.includes('元帳') || this.activeTab.includes('TB')) {
                this.filteredSearchEntries[currentIndex] = currentEntry;
            } else {
                this.searchEntries[currentIndex] = currentEntry;
            }

            // 選択を確定
            this.confirmSelection();
            
            // 検索を実行
            if (this.activeTab.includes('ログ')) {
                this.executeSearch('仕訳以外');
            } else if (this.activeTab.includes('仕訳')) {
                this.executeSearch('伝票番号');
            } else {
                this.executeSearch('仕訳以外');
            }
        },
        // 選択を確定して値セルに格納
        confirmSelection() {
            // activeTabが'元帳'を含み、かつ選択された項目が'部門情報'の場合の特別処理
            if (this.activeTab.includes('元帳') && 
                this.filteredSearchEntries && 
                this.filteredSearchEntries[this.selectedInputIndex] &&
                this.filteredSearchEntries[this.selectedInputIndex].searchColumnName === '部門情報') {
                
                if (this.selectedItems.length > 1) {
                    alert('部門情報は1つのみ選択可能です。複数選択はできません。');
                    return; // 処理を中断
                }
            }

            // const selectedValue = this.selectedItems.map(item => item.trim()).join('||');
            const selectedValue = this.selectedItems.map(item => {
                if (typeof item === 'string') {
                    return item.trim();
                } else if (item && typeof item === 'object') {
                    // オブジェクトの場合は適切なプロパティを選択
                    // 例: item.value や item.name などの適切なプロパティを使用
                    return String(item.value || item.name || '').trim();
                }
                // その他の型の場合は文字列に変換
                return String(item).trim();
            }).join('||');

            if (this.selectedInputIndex !== null && this.selectedInputIndex >= 0) {
                // activeTabが「元帳」または「TB」を含む場合はfilteredSearchEntriesに値をセット
                if (this.activeTab.includes('元帳') || this.activeTab.includes('TB')|| this.activeTab.includes('推移')|| this.activeTab.includes('消費税ck画面')|| this.activeTab.includes('部門PL')|| this.activeTab.includes('PJ分析')) {
                    if (this.filteredSearchEntries && this.filteredSearchEntries.length > 0) {
                        this.filteredSearchEntries[this.selectedInputIndex].input = selectedValue;
                    } else {
                        console.error("No valid filteredSearchEntries to update.");
                    }
                } 
                // それ以外の場合はsearchEntriesに値をセット
                else {
                    if (this.searchEntries && this.searchEntries.length > 0) {
                        this.searchEntries[this.selectedInputIndex].input = selectedValue;

                    } else {
                        console.error("No valid searchEntries to update.");
                    }
                }
            } else {
                console.error("Invalid selectedInputIndex:", this.selectedInputIndex);
            }

            this.showMasterDialog = false; // モーダルを閉じる
            this.selectedItems = []; // 選択をリセット
        },
        clearSelection() {
            if (this.selectedInputIndex !== null && this.selectedInputIndex >= 0) {
                // activeTabが「元帳」または「TB」を含む場合はfilteredSearchEntriesに値をクリア
                if (this.activeTab.includes('元帳') || this.activeTab.includes('TB')) {
                    if (this.filteredSearchEntries && this.filteredSearchEntries.length > 0) {
                        this.filteredSearchEntries[this.selectedInputIndex].input = ''; // filteredSearchEntriesのinputをクリア
                    } else {
                        console.error("No valid filteredSearchEntries to clear.");
                    }
                } 
                // それ以外の場合はsearchEntriesに値をクリア
                else {
                    if (this.searchEntries && this.searchEntries.length > 0) {
                        this.searchEntries[this.selectedInputIndex].input = ''; // searchEntriesのinputをクリア
                    } else {
                        console.error("No valid searchEntries to clear.");
                    }
                }
            } else {
                console.error("Invalid selectedInputIndex:", this.selectedInputIndex);
            }

            this.selectedItems = []; // 選択をリセット
        },
        openMasterList(columnName, index) {
            console.log('Opening master list for:', columnName, 'at index:', index);
            this.selectedInputIndex = index;
            this.filterText = '';
            // table_nameの選択肢を動的に生成する関数
            const getTableNameOptions = (activeTab) => {
                if (activeTab.includes('仕訳変更ログ')) {
                    return [{ value: '仕訳変更log' }];
                } else if (activeTab.includes('仕訳承認ログ')) {
                    return [{ value: '仕訳承認log' }];
                } else {
                    return [
                        { value: 'user' },
                        { value: '企業情報' },
                        { value: '伝票番号' },
                        { value: '勘定科目' },
                        { value: '定型処理' },
                        { value: '相手口座' },
                        { value: '相手情報' },
                        { value: '相手残高' },
                        { value: '税コード' },
                        { value: '自社口座' },
                        { value: '表示区分' },
                        { value: '補助残高' },
                        { value: '補助科目' },
                        { value: '部門情報' },
                        { value: 'PJ情報' },
                        { value: 'PJ残高' }
                    ];
                }
            };

            const mappings = {
                '相手情報': { data: this.aiteMapping, property: '結合' },
                '自社口座cd': { data: this.bankOrgMapping, property: '結合cd' },
                'PJ情報': { data: this.pjMapping, property: '結合' },
                '表示区分': { data: this.categoryMapping, property: '表示区分' },                
                '部門情報': { data: this.bumonMapping, property: '結合' },
                '勘定情報': { data: this.accMapping, property: '結合' },
                '相手科目': { data: this.accMapping, property: '結合' },
                '勘定及び補助': { data: this.subMapping, property: '勘定及び補助' },
                '税コード': { data: this.taxMapping, property: '結合' },
                '年月': { data: this.ymMapping, property: '年月' },
                '銀行情報': { data: this.bankCdMapping, property: '結合' },
                '振込情報': { data: this.fbMapping, property: '振込情報' },
                '更新者': { data: this.userMapping, property: 'user_cd' },
                'status': { 
                    data: [
                        { value: '登録差戻' },
                        { value: '削除差戻' },
                        { value: '削除申請' },
                        { value: '登録申請' },
                        { value: '承認完了' }
                    ], 
                    property: 'value'
                },
                'table_name': { 
                    data: getTableNameOptions(this.activeTab),
                    property: 'value' 
                }
            };

            // columnNameがmappingsに存在するか確認
            const mapping = mappings[columnName];
            if (mapping) {
                // 選択したデータから指定されたプロパティのみを抽出
                if (!mapping.data) {
                    console.error(`Data for ${columnName} is undefined`);
                } else {
                    this.currentMasterList = mapping.data.map(item => item[mapping.property]);
                    this.currentMasterTitle = columnName;
                    this.showMasterDialog = true;
                }
            } else {
                this.showMasterDialog = false;
            }
        },
        // 表示タイプが変更されたときに呼ばれる関数
        onDisplayTypeChange() {
            if (this.selectedDisplayType === '全部表示') {
                this.callColumnsVisibility(true, 1);
            } else if (this.selectedDisplayType === '部分表示') {
                this.callColumnsVisibility(false, 1);
            }
        },
        applyRowStyleForData(tableName) {
            return function(params) {
                if (params.node.rowIndex === 0) {
                    return {};
                }

                let previousNode = params.api.getDisplayedRowAtIndex(params.node.rowIndex - 1);
                if (!previousNode) {
                    return {};
                }

                if (tableName.includes('仕訳')) {
                    let currentVoucherNo = params.data.伝票番号;
                    let currentVoucherDate = params.data.伝票日付;
                    let previousVoucherNo = previousNode.data.伝票番号;
                    let previousVoucherDate = previousNode.data.伝票日付;

                    // 伝票番号と伝票日付の両方が異なる場合に罫線を引く
                    if (currentVoucherNo !== previousVoucherNo || currentVoucherDate !== previousVoucherDate) {
                        return { 'border-top': '1.5px solid black' };
                    }
                } else if (tableName === '定型処理') {
                    let currentJournalName = params.data.仕訳名称;
                    let previousJournalName = previousNode.data.仕訳名称;

                    // 仕訳名称が異なる場合に罫線を引く
                    if (currentJournalName !== previousJournalName) {
                        return { 'border-top': '1.5px solid black' };
                    }
                }

                return {};
            };
        },
        async updateMasterData(){
            try {
                // 最新のマスタ情報を取得する
                await this.getMasterInfoForRichSelect();
                // 更新するカラムのcellEditorParamsを設定
                const updatedColumnDefs = this.columnDefs.map(colDef => {
                    // 必要に応じて、RichSelectEditorをセット
                    this.setRichSelectEditorForColumn(colDef.field, colDef);
                    return colDef;
                });
                // グリッドに新しい columnDefs を適用してリフレッシュ
                this.gridOptions.api.setColumnDefs(updatedColumnDefs);
                // consoleログで更新を確認する
            } catch (error) {
                console.error('Master data update failed:', error);
                alert('マスタ情報の更新に失敗しました。');
            }
        },
        calculateSelectedCellsSum() {
            const cellRanges = this.gridApi.getCellRanges();
            let sum = 0;
            if (cellRanges.length > 0) {
            cellRanges.forEach((range) => {
                range.columns.forEach((column) => {
                for (
                    let rowIndex = Math.min(range.startRow.rowIndex, range.endRow.rowIndex);
                    rowIndex <= Math.max(range.startRow.rowIndex, range.endRow.rowIndex);
                    rowIndex++
                ) {
                    const rowModel = this.gridApi.getModel();
                    const rowNode = rowModel.getRow(rowIndex);
                    sum += parseFloat(rowNode.data[column.colId]) || 0;
                }
                });
            });
            }
            return sum;
        },
        // 数値をフォーマットするメソッド
        formatNumber(value) {
            return new Intl.NumberFormat('ja-JP', {
            minimumFractionDigits: 0,  // 小数点以下の最小桁数（必要に応じて変更可能）
            maximumFractionDigits: 0,  // 小数点以下の最大桁数
            }).format(value);
        },
        menuDisabled(menuName) {
            // '勘定TB', '補助TB', '相手TB' の場合に true を返してボタンを無効化 (deactivate/desable)
            const tabsToDisable = ['勘定TB(発生)','補助TB(発生)','相手TB(発生)','PJTB(発生)','勘定TB(残高)','補助TB(残高)','相手TB(残高)','PJTB(残高)','勘定元帳','補助元帳','相手元帳','PJ元帳','消費税ck画面','債務推移','債権推移'];
            const actionsToDisable = ['登録ﾒﾆｭｰ', '更新実行', '削除実行', '行の追加','承認ﾒﾆｭｰ'];
            return tabsToDisable.includes(this.activeTab) && actionsToDisable.includes(menuName);
        },
        async fetchTbOrTrend() {
            const tableName = this.determineTable(this.activeTab);
            let matchResult = tableName.match(/\(([^)]+)\)/);
            let tbType = matchResult ? matchResult[1] : tableName; // 括弧がない場合はtableNameを代入

            const periods = this.tickLabels;
            let selectedPeriod = periods[this.selectedPeriodIndex];
            // selectedPeriod が undefined の場合、最後の要素を選択
            if (typeof selectedPeriod === 'undefined') {
                selectedPeriod = periods[Object.keys(periods).length - 1];
            }

            // ここで `selectedPeriod` を使用して TB_Download処理を実行
            console.log('selectedPeriod1:', selectedPeriod);
            this.periodListVisible = false;

            // 既存のColumnDefsをクリア
            this.gridOptions.api.setColumnDefs([]);

            await this.getColumnDefsforPivot(tbType);

            if (tbType === '発生' || tbType === '残高') {
                await this.fetchTbDataforPivot(tbType, selectedPeriod);
            } else {
                // 債権推移/債務推移/PJ分析/部門PLの場合は、selectedPeriodを指定してデータを取得
                await this.fetchTrendDataforPivot(selectedPeriod);
            }
            
            // データが更新された後に、列のピン留めと自動サイズ調整を実行
            await this.$nextTick();

            // スマートフォン画面でない場合のみピン留め処理を実行_固定/列固定
            if (!this.isSmallScreen) {
                this.pinPivotColumns();
            }

            // 列の自動サイズ調整
            this.autoSizeAllColumns();
        },
        cancelTb() {
            this.periodListVisible = false;
        },
        clearConditions() {
            this.searchEntries.forEach(entry => {
                entry.input = '';
                // created と modified の場合、関連する日時情報もクリア
                if (['created', 'modified'].includes(entry.searchColumnName)) {
                    entry.startDate = null;
                    entry.startTime = null;
                    entry.endDate = null;
                    entry.endTime = null;
                }
            });
            // currentDateTimeEntry もリセット
            this.currentDateTimeEntry = {
                startDate: null,
                startTime: null,
                endDate: null,
                endTime: null,
                searchColumnName: ''
            };
        },
        isDateForAp(value) {
            // valueが未定義またはnullの場合
            if (!value) {
                return null;
            }
            // 文字列型以外の場合は文字列に変換
            const strValue = String(value);

            // 日付形式（YYYY-MM-DDまたはYYYY/MM/DD）かチェック
            const dateRegex = /^(\d{4})([-/])(\d{2})\2(\d{2})$/;
            const match = strValue.match(dateRegex);
            
            if (!match) {
                return null;
            }
            // 日付として有効かチェック
            const year = parseInt(match[1]);
            const month = parseInt(match[3]) - 1; // JavaScriptの月は0-11
            const day = parseInt(match[4]);
            const date = new Date(year, month, day);

            // 無効な日付（例：2024-02-30）の場合
            if (
                date.getFullYear() !== year ||
                date.getMonth() !== month ||
                date.getDate() !== day
            ) {
                return null;
            }
            // yyyy-mm-dd 形式に統一して返す
            return `${match[1]}-${match[3]}-${match[4]}`;
        },
        async executeJeSearch(searchItem) {
            if (searchItem==='特定年月DL') {
                this.clearConditions();                
                // 年月の検索エントリーを探す
                let yearMonthEntry = this.searchEntries.find(entry => entry.searchColumnName === '年月');
                
                // 年月の検索エントリーが存在しない場合は作成
                if (!yearMonthEntry) {
                    yearMonthEntry = {
                        searchColumnName: '年月',
                        searchColumnType: 'text',
                        input: '',
                        expanded: false
                    };
                    // searchEntriesに追加
                    this.searchEntries.push(yearMonthEntry);
                }
                
                // 年月選択用のモーダルを表示
                this.currentMasterTitle = '年月';
                this.filterText = '';
                this.selectedItems = [];
                this.selectedInputIndex = this.searchEntries.indexOf(yearMonthEntry);
                
                // ymMappingが存在することを確認
                if (this.ymMapping && this.ymMapping.length > 0) {
                    this.currentMasterList = this.ymMapping.map(item => item['年月']);
                } else {
                    // ymMappingが存在しない場合は、期間から年月リストを生成
                    const yearMonthList = [];
                    let currentDate = new Date(this.startYearMonth.slice(0, 4), 
                                            parseInt(this.startYearMonth.slice(4)) - 1);
                    const endDate = new Date(this.endYearMonth.slice(0, 4), 
                                        parseInt(this.endYearMonth.slice(4)) - 1);
                    
                    while (currentDate <= endDate) {
                        const ym = currentDate.getFullYear().toString() + 
                                (currentDate.getMonth() + 1).toString().padStart(2, '0');
                        yearMonthList.push(ym);
                        currentDate.setMonth(currentDate.getMonth() + 1);
                    }
                    this.currentMasterList = yearMonthList;
                }
                
                this.showMasterDialog = true;
            } else if (searchItem==='最終更新DL') {
                await this.lastUpdated();
            } else if (searchItem==='申請承認DL') {
                this.clearConditions();
    
                // statusの検索エントリーを探す
                let statusEntry = this.searchEntries.find(entry => entry.searchColumnName === 'status');
                
                // statusの検索エントリーが存在しない場合は作成
                if (!statusEntry) {
                    statusEntry = {
                        searchColumnName: 'status',
                        searchColumnType: 'text',
                        input: '',
                        expanded: false
                    };
                    this.searchEntries.push(statusEntry);
                }
                
                // status選択用のモーダルを表示
                this.currentMasterTitle = 'status';
                this.filterText = '';
                this.selectedItems = [];
                this.selectedInputIndex = this.searchEntries.indexOf(statusEntry);
                
                // 表示する項目を設定
                this.currentMasterList = [
                    '登録差戻',
                    '削除差戻',
                    '削除申請',
                    '登録申請',
                    '承認完了'
                ];
                
                this.showMasterDialog = true;
            } 
        },
        async executeSearch(searchType) {
            const conditions = this.searchEntries.flatMap(entry => {
                const searchValue = entry.input;
                if (!searchValue) return [];

                const dataType = entry.searchColumnType;
                const isNumeric = dataType === 'int' || dataType === 'decimal';
                const isDate = dataType === 'datetime';

                // 分割文字を特定 (|| またはスペース)
                const splitChar = searchValue.includes('||') ? '||' : ' ';
                const operator = splitChar === '||' ? 'OR' : 'AND';

                // 分割された各値に対して mapSearchColumnValue を適用
                const terms = searchValue.split(splitChar).map(term => {
                    // mapSearchColumnValue を適用
                    const { value, isCd } = this.mapSearchColumnValue(entry.searchColumnName, term.trim());
                    console.log('value:', value, 'isCd:', isCd, 'type:', typeof value);

                    if (isNumeric || isDate) {
                        // 数値または日時の条件を処理
                        const operatorValueMatch = value.match(/([<>]=?|=)(.+)/);
                        if (operatorValueMatch) {
                            let [, operator, termValue] = operatorValueMatch;

                            if (isDate) {
                                termValue = termValue.replace('_', ' ');
                            }

                            return { column: entry.searchColumnName, operator, value: termValue, dataType };
                        } else if (isNumeric && !isNaN(value)) {
                            return { column: entry.searchColumnName, operator: '=', value, dataType };
                        } else if (isDate && this.isDateForAp(value)) {
                            return { column: entry.searchColumnName, operator: '=', value, dataType };
                        }
                    } else {
                        // 文字列型の条件を処理
                        if (value.includes('<>')) {
                            // NOT LIKE 条件
                            const termValue = value.replace('<>', '').trim();
                            return { column: entry.searchColumnName, operator: 'NOT LIKE', value: `%${termValue}%`, dataType };
                        } else {
                            // mapSearchColumnValue の結果が master の cd の場合は完全一致 (=)
                            if (isCd) {
                                return { column: entry.searchColumnName, operator: '=', value, dataType }; // 完全一致
                            } else {
                                return { column: entry.searchColumnName, operator: 'LIKE', value: `%${value}%`, dataType }; // 部分一致
                            }
                        }
                    }
                }).filter(Boolean);

                return terms.length > 0 ? { operator, terms } : null;
            }).filter(Boolean);


            let tableName = this.determineTable(this.activeTab);
            if (['補助残高', '相手残高', 'PJ残高'].includes(tableName)) {
                const period = Object.values(this.targetPeriod).sort()[0];
                tableName = period + '_' + tableName;
            } 

            let tableQueries = [];
            console.log('tableName_search_data',tableName);

            if (tableName.includes('仕訳')){
                this.targetPeriod.sort().forEach(period => {
                    const periodTableName = `${period}_${tableName.includes('_') ? tableName.split('_')[1] : tableName}`;
                    console.log('conditions:',conditions);
                    tableQueries.push({ tableName: periodTableName, searchType, conditions });
                });
            } else {
                tableQueries.push({ tableName, searchType, conditions });
            }
            // すべてのクエリ結果を格納する配列
            try {
                const response = await fetch('/api/search_data.php', {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({ tableQueries })
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok ' + response.statusText);
                }
                let rawData = await response.json(); // set the rowData with the server response

                this.rowData = this.transformMasterMapping(rawData);
                
                // データが更新された後に、列のピン留めと自動サイズ調整を実行
                this.$nextTick(() => {this.autoSizeAllColumns();});
                const rowStyleFunction = this.applyRowStyleForData(tableName);
                if (rowStyleFunction) {
                    this.gridOptions.getRowStyle = rowStyleFunction;
                }
                // this.searchListVisible = false;
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },
        getFiscalYearEnd(ym) {
            let fiscalYearEnd = null;
            console.log('fyMapping',this.fyMapping);
            for (const entry of this.fyMapping) {
                const endYm = entry['エンド月'];
                const startYm = entry['スタート月'];

                if (ym >= startYm && ym <= endYm) {
                    fiscalYearEnd = endYm;
                    break;
                }
            }
            return fiscalYearEnd;
        },
        async gensenSearch() {
            // searchValuesが空の場合、関数を早期に終了
            if (this.selectedwithHoldingAcc==null){alert('預り金科目を選択して下さい'); return;}
            if (this.selectedDepositAccount==null){alert('預金科目を選択して下さい'); return;}
            if (this.selectedGensenYm==null){alert('対象年月を選択して下さい');return;}

            let ym = this.selectedGensenYm;
            const fy = this.getFiscalYearEnd(ym);
            console.log(fy);

            let tableQueries = [];
            let conditions = [];
            const searchType = '源泉仕訳';
            const periodTableName = fy+`_仕訳`;

            // 初期条件を設定
            conditions = [
                {operator: "AND",terms: [{column: "源泉請求",operator: "LIKE", value: "%|%",dataType: "varchar"}]},
                {operator: "AND",terms: [{column: "年月",operator: "=",value: ym,dataType: "int"}]},
                {operator: "AND",terms: [{column: "勘定及び補助",operator: "LIKE",value: this.selectedwithHoldingAcc,dataType: "varchar"}]},
            ];
            tableQueries.push({ tableName: periodTableName, searchType, conditions });
            
            // すべてのクエリ結果を格納する配列
            try {
                const response = await fetch('/api/search_data.php', {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({ tableQueries })
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok ' + response.statusText);
                }
                this.rowData = await response.json(); // set the rowData with the server response
                this.rowData = this.transformMasterMapping(this.rawData);

                this.$nextTick(() => {this.autoSizeAllColumns();});
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },
        async apArSearch(searchType) {            
            const searchValues = this.dateSelect;
             // searchValuesが空の場合、関数を早期に終了
            if (searchValues.length === 0) {
                return;
            }
            
            let tableQueries = [];
            let conditions = [];
            // すべての期間に対してクエリを作成
            this.targetPeriod.sort().forEach(period => {
                const periodTableName = `${period}_仕訳`;
                // 初期条件を設定
                if (searchType==='債務表示') {
                    conditions = [
                    {operator: "OR",terms: []},
                    {operator: "AND",
                        terms: [{
                                column: "消込ck", 
                                operator: "LIKE", 
                                value: "na", 
                                dataType: "varchar"
                    }]},
                    {operator: "AND",
                        terms: [{
                                column: "振込情報", 
                                operator: "!=", 
                                value: "0|0", 
                                dataType: "varchar"
                    }]},
                    ];
                } else if (searchType==='債権表示') {
                    conditions = [
                    {operator: "OR",terms: []},
                    {operator: "AND",
                        terms: [{
                                column: "消込ck", 
                                operator: "LIKE", 
                                value: "na", 
                                dataType: "varchar"
                    }]},
                    {operator: "AND",
                        terms: [{
                                column: "振込情報", 
                                operator: "=", 
                                value: "0|0", 
                                dataType: "varchar"
                    }]},
                    ];
                }                
                // searchValuesの値に基づいて2つ目の条件グループに条件を追加
                searchValues.forEach(value => {
                    conditions[0].terms.push({
                        column: "予定日",
                        operator: "=", 
                        value: value, 
                        dataType: "varchar"
                    });
                });

                tableQueries.push({ tableName: periodTableName, searchType, conditions });
            });
            // すべてのクエリ結果を格納する配列
            try {
                const response = await fetch('/api/search_data.php', {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({ tableQueries })
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok ' + response.statusText);
                }
                this.rowData = await response.json(); // set the rowData with the server response
                this.rowData = this.transformMasterMapping(this.rowData);

                this.$nextTick(() => {this.autoSizeAllColumns();});
                // rowDataの処理
                if (searchType==='債務表示'){
                    if (!Array.isArray(this.fbMapping) || this.fbMapping.length === 0) {
                        alert('fbMappingが存在しないか、データがありません。');
                        return; // fbMappingがないため、処理をここで中断します。
                    }
                    this.rowData.forEach(row => {
                        // カラム名'税込金額'が存在する場合、'税込源泉前'に名前を変更  // ���いキーを削���
                        if (Object.prototype.hasOwnProperty.call(row,'借方税込')) {row['税込源泉前'] = -row['税込金額'];delete row['借方税込'];}
                        if (Object.prototype.hasOwnProperty.call(row,'借方税額')) {
                            row['源泉税額'] = 0; 
                            // '源泉請求'が存在し、文字列であることを確認
                            if (row['源泉請求'] && typeof row['源泉請求'] === 'string' && row['源泉請求'].includes('|')) {
                                row['源泉税額'] = row['源泉請求'].split('|')[1];
                            }
                            delete row['借方税額'];
                        }
                        if (Object.prototype.hasOwnProperty.call(row,'貸方税込')) {row['税込源泉後'] = row['税込源泉前']-row['源泉税額'];delete row['貸方税込'];}
                        if (Object.prototype.hasOwnProperty.call(row,'貸方税額')) {row['消込ck金額'] = 0;delete row['貸方税額'];}
                    });
                } else if (searchType==='債権表示'){
                    this.rowData.forEach(row => {
                        // カラム名'税込金額'が存在する場合、'税込源泉前'に名前を変更  // 古いキーを削除
                        if (Object.prototype.hasOwnProperty.call(row,'借方税込')) {row['税込計上額'] = row['税込金額'];delete row['借方税込'];}
                        if (Object.prototype.hasOwnProperty.call(row,'借方税額')) {row['-支払手数料'] = 0;delete row['借方税額'];}                            
                        if (Object.prototype.hasOwnProperty.call(row,'貸方税込')) {row['-/+為替損益'] = 0;delete row['貸方税込'];}
                        if (Object.prototype.hasOwnProperty.call(row,'貸方税額')) {row['入金額'] = 0;delete row['貸方税額'];}
                    });
                }
                // this.apArListVisible = false;
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },
        generateYearMonth(start, end) {
            start = String(start); // 数値を文字列に変換
            end = String(end); // 数値を文字列に変換
            let startYear = parseInt(start.substring(0, 4));
            let startMonth = parseInt(start.substring(4, 6));

            let endYear = parseInt(end.substring(0, 4));
            let endMonth = parseInt(end.substring(4, 6));

            const yearMonthArray = [];

            while (startYear < endYear || (startYear === endYear && startMonth <= endMonth)) {
                yearMonthArray.push({ '年月': `${startYear}${startMonth.toString().padStart(2, '0')}` });

                startMonth++;
                if (startMonth > 12) {
                    startMonth = 1;
                    startYear++;
                }
            }
            return yearMonthArray;
        },
        async togglePeriodList() {            
            const fyMappingData = this.$store.state.fyMapping;
            console.log('fyMappingData:', fyMappingData);
            const periods = Object.values(this.targetPeriod).sort(); // ソートされた配列から最小値を取得
            const minPeriod = parseInt(periods[0]); // 文字列を数値に変換
            const maxPeriod = parseInt(periods[periods.length - 1]); // 文字列を数値に変換

            // fyMappingDataから対応するデータを取得（数値型で比較）
            const fyEntry = fyMappingData.find(entry => parseInt(entry['エンド月']) === minPeriod);
            if (fyEntry && fyEntry['エンド月'] && fyEntry['スタート月']) {
                this.startYearMonth = fyEntry['スタート月'];
                this.endYearMonth = maxPeriod;
            } else {
                console.error('対応する会計期間が見つかりません');
                return; // 早期リターン
            }

            const generatedLabels = this.generateYearMonth(this.startYearMonth, this.endYearMonth);
            const labels = generatedLabels.map(item => item['年月']); // 各オブジェクトから '年月' を抽出
            console.log(labels); // 例: ["202301", "202302", "202303", ...]

            // 生成されたラベルの数が12以上の場合、指定された条件を満たすように結果を調整
            if (generatedLabels.length > 12) {
                const startIndex = 0;
                const endIndex = generatedLabels.length - 1;
                const filteredLabels = [];

                // 最後の11個の要素を追加
                for (let i = 0; i < 11; i++) {
                    filteredLabels.push(generatedLabels[endIndex - i]);
                }

                // 最初の要素を追加
                filteredLabels.push(generatedLabels[startIndex]);

                // インデックスをリセットして配列の形式でtickLabelsに設定
                this.tickLabels = filteredLabels.reverse().map(item => item['年月']);
            } else {
                // 全ての年月をそのままtickLabelsに設定
                this.tickLabels = generatedLabels.map(item => item['年月']);
            }
            console.log('this.tickLabels:',this.tickLabels);
            this.createShorttickLabels();
            this.periodListVisible = !this.periodListVisible;
        },
        async toggleArApList() {
            //源泉画面は先に設定しreturnする
            if (this.activeTab ==='源泉画面'){
                //預金科目、年月の勘定及び補助を取得する処理
                await this.fetchArSelectedOption('預金');
                await this.fetchArSelectedOption('預り金');
                const periodsArray = Object.values(this.targetPeriod).sort();
                const endYm = periodsArray[periodsArray.length - 1];
                this.fyMapping = this.$store.state.fyMapping;
                const entry = this.fyMapping.find(item => item['エンド月'] == endYm);
                let startYm = entry ? String(entry['スタート月']) : null;
                
                // startYmがnullの場合のチェックを追加
                if (!startYm) {
                    console.error('開始年月の取得に失敗しました');
                    return;
                }

                const date = new Date(startYm.substring(0, 4), startYm.substring(4) - 1, 1);
                startYm = date.getFullYear().toString() + ('0' + (date.getMonth())).slice(-2);
                // let yms = this.generateYearMonth(startYm, endYm);
                // this.gensenYms = Object.values(yms).map(item => parseInt(item, 10));
                let yms = this.generateYearMonth(startYm, endYm);
                this.gensenYms = yms.map(item => parseInt(item['年月'], 10)); // '年月'を数値に変換して格納
                console.log(this.gensenYms); // 例: [202301, 202302, 202303, ...]
                this.apArListVisible = !this.apArListVisible;
                return;
            }

            // 与えられたオブジェクトから数値を抽出し、小さい順にソート
            let tableQueries = [];
            let tableName = '仕訳';
            const periodsArray = Object.values(this.targetPeriod).sort();
            const period = periodsArray[periodsArray.length - 1];
            tableName = period + '_' + tableName;
            let conditions;
            if (this.activeTab ==='債務画面'){
                conditions = [
                    {operator: "AND", terms: [{column: "消込ck", operator: "LIKE", value: "na", dataType: "varchar"}]},
                    {operator: "AND", terms: [{column: "予定日", operator: "!=", value: "na", dataType: "varchar"}]},
                    {operator: "AND", terms: [{column: "振込情報", operator: "!=", value: "0|0",dataType: "varchar"}]}];
            } else if (this.activeTab ==='債権画面'){
                conditions = [
                    {operator: "AND", terms: [{column: "消込ck", operator: "=", value: "na", dataType: "varchar"}]},
                    {operator: "AND", terms: [{column: "予定日", operator: "!=", value: "na", dataType: "varchar"}]},
                    {operator: "AND", terms: [{column: "振込情報", operator: "=", value: "0|0", dataType: "varchar"}]}];
            }
            const searchType = this.activeTab;
            tableQueries.push({ tableName, searchType ,conditions });
            // console.log('ApAr_tableQueries:',tableQueries);
            try {
                const response = await fetch('/api/search_data.php', {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({ tableQueries })
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok ' + response.statusText);
                }
                const result = await response.json(); 
                // console.log('ApAr_Result',result);
                this.items = result.map((item, index) => ({
                    label: item.予定日,
                    value: index
                }));
            } catch (error) {
                console.error('Error fetching data:', error);
            }
            //預金科目、支払手数料、為替損益の勘定及び補助を取得する処理
            if (this.activeTab ==='債務画面'){
                await this.fetchArSelectedOption('預り金');
            } else if (this.activeTab ==='債権画面'){
                await this.fetchArSelectedOption('預金');
                await this.fetchArSelectedOption('手数料');
                await this.fetchArSelectedOption('為替');
                await this.fetchArSelectedOption('tax');
            }
            this.apArListVisible = !this.apArListVisible;
        },
        async fetchArSelectedOption(accType){
            // 与えられたオブジェクトから数値を抽出し、小さい順にソート
            let tableQueries = [];
            let tableName = '補助科目';
            if (accType ==='tax') {tableName = '税コード';}
            let conditions;
            if (accType ==='預り金'){
                conditions = [
                    {operator: "AND", terms: [
                            {column: "勘定及び補助", operator: "LIKE", value: "%預り金%", dataType: "varchar"},
                            {column: "勘定及び補助", operator: "LIKE", value: "%源%", dataType: "varchar"},
                    ]},
                ];
            } else if (accType ==='預金'){
                conditions = [
                    {operator: "OR", terms: [
                            {column: "勘定情報", operator: "LIKE", value: "%預金%", dataType: "varchar"},
                            {column: "勘定情報", operator: "LIKE", value: "%郵貯%", dataType: "varchar"},
                            {column: "勘定情報", operator: "LIKE", value: "%貯金%", dataType: "varchar"},
                    ]},
                ];
            } else if (accType ==='手数料'){
                conditions = [
                    {operator: "AND", terms: [{column: "勘定情報", operator: "LIKE", value: "%手数料%", dataType: "varchar"}]},
                ];
            } else if (accType ==='為替'){
                conditions = [
                    {operator: "AND", terms: [{column: "勘定情報", operator: "LIKE", value: "%為替%", dataType: "varchar"}]},
                ];
            } else if (accType ==='tax'){
                conditions = [
                    {operator: "AND", terms: [
                        {column: "結合", operator: "LIKE", value: "%仕入%", dataType: "varchar"},
                        {column: "結合", operator: "LIKE", value: "%10%", dataType: "varchar"},
                    ]},
                ];
            }
            
            const searchType = '仕訳以外';
            tableQueries.push({ tableName, searchType ,conditions });
            try {
                const response = await fetch('/api/search_data.php', {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({ tableQueries })
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok ' + response.statusText);
                }
                const result = await response.json(); 
                // console.log('ApAr_Result',result);

                if (accType==='預り金'){
                    this.withHoldingAccOptions = result.map(item => item.勘定及び補助);
                } else if (accType==='預金'){
                    this.depositAccountOptions = result.map(item => item.勘定及び補助);
                } else if (accType==='手数料'){
                    this.paymentFeeOptions = result.map(item => item.勘定及び補助);
                } else if (accType==='為替'){
                    this.exchangeGainLossOptions = result.map(item => item.勘定及び補助);
                    if (this.exchangeGainLossOptions==''){
                        this.exchangeGainLossOptions=['勘定及び補助を設定して下さい'];
                    }
                } else if (accType==='tax'){
                    this.feeTaxOptions = result.map(item => item.結合);
                }
                 
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },
        toggleSearchList() {
            this.searchListVisible = !this.searchListVisible;
        },
        cancelSearch() {
            this.searchListVisible = false;
        },
        cancelApArList() {
            this.apArListVisible = false;
        },
        cancelApjeVisible() {
            this.apJeVisible = false;
            this.menuVisible = true;
            this.clearData();
        },
        cancelGensenjeVisible() {
            this.gensenJeVisible = false;
            this.menuVisible = true;
            this.clearData();
        },
        cancelArjeVisible() {
            this.arJeVisible = false;
            this.menuVisible = true;
            this.clearData();
        },
        determineTable(tabName) {
            // Logic to determine the table name based on the active tab
            if (tabName.includes('元帳')) {
                // '元帳'を含む場合
                return tabName.substring(0, 4); //勘定元帳01 => 勘定元帳
            } else if (tabName.includes('仕訳変更ログ')) {
                return '仕訳変更log';
            } else if (tabName.includes('仕訳承認ログ')) {
                return '仕訳承認log';
            } else if (tabName.includes('マスタ変更ログ')) {
                return 'マスタ変更log';
            } else if (!tabName.includes('仕訳画面')) {
                // '仕訳画面'を含まない場合
                return tabName;
            } else {
                return this.targetPeriod[0] +`_仕訳`;
            } 
        },
        selectMasterInfo(tabName) {
            switch(tabName) {
                case '勘定科目': return ['categoryMapping^表示区分|表示区分'];
                case '補助科目': return ['accMapping^結合|勘定情報','taxMapping^Taxコード|Taxコード'];
                case '補助残高': return ['subMapping^勘定及び補助|勘定及び補助','bumonMapping^結合|部門情報'];//テーブル^カラム左|カラム右=>対象テーブルでmatch
                case '相手残高': return ['accMapping^結合|勘定情報','aiteMapping^結合|相手情報','bumonMapping^結合|部門情報'];//テーブル^カラム左|カラム右=>対象テーブルでmatch
                case 'PJ残高': return ['accMapping^結合|勘定情報','bumonMapping^結合|部門情報','pjMapping^結合|PJ情報'];//テーブル^カラム左|カラム右=>対象テーブルでmatch
                case 'PJ情報': return ['bumonMapping^結合|部門情報','aiteMapping^結合|相手情報'];//テーブル^カラム左|カラム右=>対象テーブルでmatch
                case '相手口座': return ['aiteMapping^結合|相手情報','bankOrgMapping^結合cd|自社口座cd','bankCdMapping^結合|銀行情報'];//,'BANK^銀行情報|銀行情報'];//テーブル^カラム左|カラム右=>対象テーブルでmatch
                case '自社口座': return ['subMapping^勘定及び補助|勘定及び補助','bankCdMapping^結合|銀行情報'];//'BANK^銀行情報|銀行情報',テーブル^カラム左|カラム右=>対象テーブルでmatch
                // case '補助科目': return ['勘定科目^勘定及び補助'];
                default: 
                if (tabName.includes('仕訳画面')||tabName.includes('定型処理')) {
                    // '仕訳画面'を含まない場合のみfetchDataを実行
                    return ['subMapping^勘定及び補助|勘定及び補助','aiteMapping^結合|相手情報','pjMapping^結合|PJ情報','bumonMapping^結合|部門情報','taxMapping^結合|税コード','fbMapping^振込情報|振込情報'];//テーブル(先)^カラム左(先)|カラム右(当)=>対象テーブルでmatch
                } else {
                    return [];
                }
            }
        },
        autoSizeAllColumns() {
            // gridColumnApi または getAllDisplayedColumns が undefined の場合はスキップ
            if (!this.gridColumnApi || !this.gridColumnApi.getAllDisplayedColumns) {
                console.warn('gridColumnApi is not ready or getAllDisplayedColumns is undefined');
                return;
            }            
            // 表示されているすべての列を取得
            const allColumnIds = this.gridColumnApi.getAllDisplayedColumns().map(column => column.getColId());
            
            // 取得した列IDを使って、すべての列のサイズを自動調整
            if (allColumnIds.length > 0) {
                this.gridColumnApi.autoSizeColumns(allColumnIds);
            }
            // activeTabがTBを含む場合、表示区分に色を塗る処理を実行
            if (this.activeTab && (this.activeTab.includes('TB') || this.activeTab.includes('推移')|| this.activeTab.includes('PJ分析')|| this.activeTab.includes('部門PL')|| this.activeTab.includes('推移'))) {
                this.applyColorToDisplayCategory();
            }
        },
        // 新しいメソッドを追加
        applyColorToDisplayCategory() {
            if (!this.gridApi) {
                console.warn('gridApi is not ready');
                return;
            }

            // gridOptions の getRowStyle を更新
            this.gridOptions.getRowStyle = params => {
                // activeTabが'推移'を含む場合は勘定情報列に色付け、それ以外は表示区分列に色付け
                let targetField;

                if (this.activeTab.includes('推移')) {
                    targetField = '勘定情報';
                } else if (this.activeTab.includes('PJ分析')) {
                    targetField = 'PJ情報';
                } else if (this.activeTab.includes('部門PL')) {
                    targetField = '部門情報';
                } else {
                    targetField = '表示区分';
                }
                
                if (params.node.group && params.node.field === targetField) {
                    return { backgroundColor: '#e6f7ff' };
                }
                return null;
            };

            // グリッドを再描画して変更を反映
            this.gridApi.redrawRows();
        },
        onFirstDataRendered() { // この行を追加
            console.log('onFirstDataRendered');
            this.autoSizeAllColumns();
            // this.callColumnsVisibility(false, 1);
        },
        async getColumnDefsFromServer() {
            await this.handleAsyncOperation(async (signal) => {
                let tableName = this.determineTable(this.activeTab); // Determine the table name based on the active tab
                console.log('tableName:',tableName);
                if (this.activeTab === '債務画面' || this.activeTab === '債権画面'|| this.activeTab === '源泉画面') {
                    tableName = '仕訳';
                }
                const postData = JSON.stringify({ table: tableName });
                const response = await fetch('/api/get_column.php', {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: postData,
                    signal: signal
                });
                const data = await response.json();
                const renderer = this.tokyoTimezoneCellRenderer; // ここで、tokyoTimezoneCellRendererを一時変数に格納
                // console.log('tableName:',tableName);
                if (this.activeTab === '債務画面' || this.activeTab === '債権画面'|| this.activeTab === '源泉画面') {
                    tableName = this.activeTab;
                }
                // ここでカスタム行番号列を定義
                const rowNumberColumn = {
                    headerName: '#',
                    valueGetter: 'node.rowIndex + 1',
                    width: 20,
                    minWidth: 20, // 行番号列の最小幅も20に設定
                    pinned: 'left', // 行番号を左側に固定
                    suppressMenu: true, // メニューを無効化
                    suppressSorting: true, // ソートを無効化
                    suppressMovable: true, // 移動を無効化
                };

                this.searchEntries = data.map(column => {
                    return {
                        searchColumnName: column.columnName, // Save the column name in the searchEntries
                        searchColumnType: column.columnType, 
                        input: '',  // You can initialize input and reserve with empty strings or another default value
                        reserve: ''
                    };
                });

                // 元帳を含む場合、statusを追加
                if (this.activeTab.includes('元帳')) {
                    this.searchEntries.push({
                        searchColumnName: 'status',
                        searchColumnType: 'varchar',
                        input: '',
                        reserve: ''
                    });
                }

                // 条件に応じてfilteredSearchEntriesをフィルタリングしてセット（元帳表示の条件抽出のため)
                if (this.activeTab.includes('勘定元帳')) {
                    // 勘定元帳・補助元帳の場合：searchColumnNameが、科目情報、部門情報 のみ
                    this.filteredSearchEntries = this.searchEntries.filter(entry =>
                        ['status','勘定情報', '部門情報'].includes(entry.searchColumnName)
                    );
                } else if (this.activeTab.includes('補助元帳')) {
                    // 相手元帳の場合：searchColumnNameが、勘定及び補助、部門情報、相手情報 のみ
                    this.filteredSearchEntries = this.searchEntries.filter(entry =>
                        ['status','勘定及び補助', '部門情報'].includes(entry.searchColumnName)
                    );
                } else if (this.activeTab.includes('相手元帳')) {
                    // 相手元帳の場合：searchColumnNameが、科目情報、部門情報、相手情報 のみ
                    this.filteredSearchEntries = this.searchEntries.filter(entry =>
                        ['status','勘定情報', '部門情報', '相手情報'].includes(entry.searchColumnName)
                    );
                } else if (this.activeTab.includes('PJ元帳')) {
                    // PJ元帳の場合：searchColumnNameが、科目情報、部門情報、PJ情報 のみ
                    this.filteredSearchEntries = this.searchEntries.filter(entry =>
                        ['status','勘定情報', '部門情報', 'PJ情報'].includes(entry.searchColumnName)
                    );
                } else if (this.activeTab.includes('消費税ck画面')) {
                    this.filteredSearchEntries = this.searchEntries.filter(entry =>
                        ['status'].includes(entry.searchColumnName)
                    );
                } else {
                    // 他のタブの場合は全ての項目を表示
                    this.filteredSearchEntries = this.searchEntries;
                }
                
                this.columnDefs = [rowNumberColumn, ...data.map(column => {
                    let filterType;
                    let filterParams = {
                        excelMode: 'windows',
                        buttons: ['apply'],
                    }; // デフォルトの設定を先に指定しておく
                    
                    // カラムの型に応じてfilterTypeを設定
                    switch (column.columnType) {
                        case 'int': case 'decimal':case 'decimal(10,4)':
                            // filterType = 'agNumberColumnFilter';
                            filterType = 'agSetColumnFilter';
                            if (column.columnType === 'decimal'||column.columnType === 'decimal(10,4)') {
                                filterParams = {
                                    numberParser: (text) => {
                                        if (!text) return null;
                                        let value = text.replace(/,/g, '');
                                        return isNaN(value) ? null : parseFloat(value);
                                    },
                                    numberFormatter: (value) => {
                                        if (value === null || value === undefined) return null;
                                        if (column.columnType === 'decimal(10,4)') {
                                            // decimal(10,4)の場合、小数点以下4桁まで表示
                                            return new Intl.NumberFormat('ja-JP', {
                                                minimumFractionDigits: 4,
                                                maximumFractionDigits: 4
                                            }).format(value);
                                        } else {
                                            // その他のdecimalやint型の場合
                                            return new Intl.NumberFormat('ja-JP', {
                                                minimumFractionDigits: 0,
                                                maximumFractionDigits: 2
                                            }).format(value);
                                        }
                                    }
                                };
                            }
                            break;
                        case 'date': case 'datetime':
                            filterType = 'agSetColumnFilter';
                            // filterType = 'agDateColumnFilter';
                            // filterType = 'agTextColumnFilter';
                            break;
                        default:
                            filterType = 'agSetColumnFilter';
                            // filterType = 'agTextColumnFilter';
                            break;
                    }
                    // console.log('tableName:',tableName,'activeTab:', this.activeTab);
                    if (this.activeTab === '債務画面'||this.activeTab === '債権画面') {
                        column.validation = (column.columnName === '消込ck') ? 'required' : 'readonly';
                        if (this.activeTab === '債務画面'){
                            switch(column.columnName) {
                                case '借方税込': column.columnName = '税込源泉前';break;
                                case '借方税額': column.columnName = '源泉税額';break;
                                case '貸方税込': column.columnName = '税込源泉後';break;
                                case '貸方税額': column.columnName = '消込ck金額';break;
                            }
                        } else if (this.activeTab === '債権画面'){
                            switch(column.columnName) {
                                case '借方税込': column.columnName = '税込計上額';break;
                                case '借方税額': column.columnName = '-支払手数料'; column.validation = 'required';break;
                                case '貸方税込': column.columnName = '-/+為替損益'; column.validation = 'required';break;
                                case '貸方税額': column.columnName = '入金額';break;
                            }
                        }
                    } else if (this.activeTab === '源泉画面') {
                        column.validation = 'readonly';
                    }
                    const isSortable = !(
                        this.activeTab.includes('仕訳画面') ||
                        this.activeTab.includes('元帳')
                    );
                    // ソート可能にする
                    let colDef = {
                        sortable: isSortable,
                        resizable: true,
                        field: column.columnName,
                        filter: filterType,
                        floatingFilter: true,
                        filterParams: filterParams, // ここで設定したfilterParamsを使用
                        editable: column.validation !== "readonly",
                        cellClassRules: {
                            'invalid-cell': (params) => !this.isValid(params.value, column.columnType, column.validation),
                        },
                        // columnTypeとvalidationをcolDefに追加
                        columnType: column.columnType,
                        validation: column.validation,
                        process: column.process,
                        mapping: column.mapping,

                        valueFormatter: params => {
                            if (params.colDef.columnType === 'decimal' && params.value != null && params.value !== '') {
                                // 数値を#,##0形式に書式化
                                // return new Intl.NumberFormat().format(params.value);
                                const formattedValue = new Intl.NumberFormat('ja-JP', {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 2
                                }).format(Math.abs(params.value));
                                
                                return params.value < 0 ? `(${formattedValue})` : formattedValue;
                            } else if (params.colDef.columnType === 'datetime' && params.value != null && params.value !== '') {
                                // 日時を'yyyy-mm-dd hh:mm:ss'形式に書式化
                                let date = new Date(params.value);
                                return date.toISOString().replace('T', ' ').substring(0, 19).replace(/\//g, '-');
                            } 
                            return params.value; // nullまたは空文字の場合はそのまま返す
                        },
                        cellStyle: params => {
                            if (params.colDef.columnType === 'decimal' ||params.colDef.columnType === 'decimal(10,4)' || params.colDef.columnType === 'int') {
                                const style = { textAlign: 'right' };
                                if (params.value < 0) {
                                    style.color = 'blue';
                                }
                                return style;                                
                            }
                            return null;
                        },                        
                        valueParser: params => {
                            if (params.colDef.columnType === 'decimal') {
                                // 確認：params.newValueは文字列ですか？
                                if (typeof params.newValue === 'string') {
                                    // , を削除して数値に変換
                                    let value = params.newValue.replace(/,/g, '');
                                    return isNaN(value) ? null : parseFloat(value);
                                } else {
                                    // params.newValueが文字列でない場合、そのまま返します
                                    return params.newValue;
                                }
                            }
                            return params.newValue;
                        },
                        valueSetter: (params) => {
                            if (!this.isValid(params.newValue, params.colDef.columnType, params.colDef.validation)) {
                                if (this.showErrorAlerts) {
                                    const userConfirmed = confirm('・無効な入力です。\n・このalertを繰り返さない場合はCancel');
                                    if (!userConfirmed) {
                                        this.showErrorAlerts = false; // ユーザーがキャンセルを選択した場合、それ以上の警告を表示しない
                                    }
                                }
                                return false; // 値を設定せずに終了
                            }
                            params.data[params.colDef.field] = params.newValue; // 新しい値をセット
                            return true; // 新しい値を設定
                        },
                    };
                    // validationが'readonly'の場合にheaderClassを設定
                    if (column.validation === 'readonly') {
                        colDef.headerClass = 'readonly-header';
                    } else if (column.validation.includes('required')){
                        colDef.headerClass = 'required-header';
                    }       
                    // もしcolumnTypeがdatetimeまたはdateであれば、cellRendererを設定する
                    if (column.columnType === 'datetime') {
                        colDef.cellRenderer = (params) => renderer(params, 'datetime');
                    } else if (column.columnType === 'date') {
                        colDef.cellRenderer = (params) => renderer(params, 'date');
                    }
                    if (!tableName.includes('元帳')) {
                        this.setRichSelectEditorForColumn(column.columnName, colDef); //元帳以外だと必要に応じて入力規則設定
                    }
                    // tableNameが'相手口座'または'自社口座'で、かつfieldが'支店情報'の場合の特別な設定
                    if ((tableName === '相手口座' || tableName === '自社口座') && column.columnName === '支店情報') {
                        colDef.cellEditor = 'agRichSelectCellEditor';

                        colDef.cellEditorParams = (params) => {
                            const bankInfo = params.data ? params.data['銀行情報'] : null;
                            // 初期値
                            const initialValues = ['データを取得中...'];
                            // 銀行情報が未設定の場合
                            if (!bankInfo || bankInfo.trim() === '') {
                                console.log('銀行情報が無効です:', bankInfo);
                                return { 
                                    values: ['銀行情報を選択してください'],
                                    allowTyping: true,  // 文字入力を許可
                                    searchType: 'matchAny',  // 部分一致検索を有効化
                                    filterList: true  // リストのフィルタリングを有効化
                                };
                            }
                            // 銀行コードが変更された場合、リストを再取得しエディタを再起動
                            if (params.data['cachedCd'] !== bankInfo) {
                                // console.log('銀行コードが変更されました。再取得します:', bankInfo);
                                params.data['cachedCd'] = bankInfo;
                                params.data['tmpリスト'] = initialValues;

                                this.fetchBranchData(bankInfo)
                                    .then((branchList) => {
                                        // console.log('取得したtmpリスト:', branchList);
                                        params.data['tmpリスト'] = branchList;
                                        params.api.stopEditing();
                                        setTimeout(() => {
                                            params.api.startEditingCell({
                                                rowIndex: params.node.rowIndex,
                                                colKey: params.column.colId,
                                            });
                                        }, 100);
                                    })
                                    .catch((error) => {
                                        console.error('tmp情報取得エラー:', error);
                                        params.data['tmpリスト'] = ['取得失敗(再試行してください)'];
                                        params.api.refreshCells({ rowNodes: [params.node] });
                                    });
                            }
                            // キャッシュ済みリストまたは初期値を返す際にも検索オプションを追加
                            return { 
                                values: params.data['tmpリスト'] || initialValues,
                                allowTyping: true,  // 文字入力を許可
                                searchType: 'matchAny',  // 部分一致検索を有効化
                                filterList: true,  // リストのフィルタリングを有効化
                                valueListMaxHeight: 220  // リストの最大高さを設定
                            };
                        };

                        colDef.valueSetter = (params) => {
                            if (params.newValue === null || params.newValue === '') {
                                params.data['支店情報'] = null; // データをクリア
                                return true;
                            }
                            if (params.newValue) {
                                params.data['支店情報'] = params.newValue; // 新しい値を設定
                                return true;
                            }
                            return false;
                        };
                    }

                    if (tableName.includes('仕訳') && colDef.field === '伝票日付') {
                        colDef.cellEditor = 'agRichSelectCellEditor';
                        colDef.cellEditorParams = {
                            values: this.generateDateList(),  // 会計期間内の日付リストを提供
                            searchType: 'matchAny',  // 部分一致で検索
                            allowTyping: true,  // ユーザーが直接入力できるようにする
                        };
                    }
                    return colDef;  
                })];
                // 債務画面または債権画面の場合、消込ckカラムを左端に移動
                if (this.activeTab === '債務画面' || this.activeTab === '債権画面') {
                    // 現在のカラム定義を新しい配列に再構成
                    const reorderedColumns = [
                        this.columnDefs[0], // 行番号カラム
                        ...this.columnDefs.filter(col => col.field === '消込ck').slice(0, 1), // 最初の消込ckカラムのみ
                        ...this.columnDefs.filter(col => col.field !== '消込ck' && col !== this.columnDefs[0]) // その他のカラム（行番号を除く）
                    ];
                    this.columnDefs = reorderedColumns;
                }
                // gridOptionsの設定
                this.gridOptions.columnDefs = this.columnDefs;

            }, {
               errorMessage: 'カラム定義の取得に失敗しました'                
            });
        },
        async fetchBranchData(bankInfo) {
            const queries = [`支店情報^結合|支店情報|${bankInfo}`];
            const response = await fetch(`/api/get_masterDetail.php?queries=${encodeURIComponent(JSON.stringify(queries))}`);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            console.log('支店情報_data:', data);
            return data.map(info => info['支店情報']);
        },
        // 会計期間の日付を生成するメソッド
        generateDateList() {
            // targetPeriod から最大値（最新の期間）を取得
            const endMonth = Math.max(...this.targetPeriod.map(p => parseInt(p, 10)));

            // fyMappingからendMonthに対応するstartMonthを取得
            console.log('endMonth:',endMonth);
            console.log('this.fyMapping:',this.fyMapping);
            const entry = this.fyMapping.find(item => item['エンド月'] === endMonth);
            const startMonth = entry ? entry['スタート月'] : null;

            if (!startMonth || !endMonth) {
                console.error('startMonth または endMonth が見つかりません。');
                return;
            }

            // endDate を YYYY-MM-DD 形式で生成
            const endYear = Math.floor(endMonth / 100);  // 202410 → 2024
            const endMonthNum = endMonth % 100;  // 202410 → 10
            const endDate = new Date(endYear, endMonthNum - 1, 1);  // endMonthの初日
            endDate.setMonth(endDate.getMonth() + 1); // 次の月の初日に設定
            endDate.setDate(0); // endMonthの最終日を取得

            // startDate を YYYY-MM-DD 形式で生成
            const startYear = Math.floor(startMonth / 100);  // 202211 → 2022
            const startMonthNum = startMonth % 100;  // 202211 → 11
            const startDate = new Date(startYear, startMonthNum - 1, 1);  // startMonthの初日 (1日)
            
            const dateList = [];

            let currentDate = startDate;
            while (currentDate <= endDate) {
                const yyyy = currentDate.getFullYear();
                const mm = String(currentDate.getMonth() + 1).padStart(2, '0');
                const dd = String(currentDate.getDate()).padStart(2, '0');
                dateList.push(`${yyyy}-${mm}-${dd}`);
                currentDate.setDate(currentDate.getDate() + 1); // 1日ずつ進める
            }

            // allowedDatesを更新（YYYY-MM-DD形式の文字列として保存）
            this.allowedDates = dateList.map(date => this.formatDate(date));
            this.allowedDates.sort((a, b) => new Date(b) - new Date(a)); // 降順にソート

            return dateList;
        },
        isDateAllowed(dateStr) {
            const formattedDate = this.formatDate(dateStr);
            return this.allowedDates.includes(formattedDate);
        },
        // 日付を選択するメソッド
        selectDate(date) {
            this.simpleInput.伝票日付 = date;
            this.dateMenu = false;
        },
        // 日付を表示用にフォーマットするメソッド（例：MM/DD）
        formatDateDisplay(dateString) {
            const date = new Date(dateString);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}/${month}/${day}`;
        },
        async onCellValueChanged(params) {
            if (params.column.colId === '銀行情報' && params.newValue !== params.oldValue) {
                // 新しい銀行情報に基づいて支店情報を取得
                // 支店情報セルを更新するためにグリッドをリフレッシュ
                // params.api.refreshCells({columns: ['支店情報'],force: true});
            } else if (params.column.colId === '口座名称FB' && params.newValue !== params.oldValue) {
                if (params.newValue) {
                    // newValueを変換関数で処理
                    const convertedValue = this.convYSokuon(params.newValue);
                    // 対応する行のデータを取得
                    const rowData = params.data;
                    // 変換後の値を行のデータに設定
                    rowData['口座名称FB'] = convertedValue;
                    // Ag-GridのAPIを使用して行データを更新
                    params.api.applyTransaction({ update: [rowData] });
                }
            } else if (this.activeTab ==='債務画面' && params.column.colId === '消込ck' && params.newValue !== params.oldValue) {
                if (this.isDateForAp(params.newValue)) {
                    // 対応する行のデータを取得
                    const rowData = params.data;
                    rowData['消込ck金額'] = rowData['税込源泉後'];
                    // Ag-GridのAPIを使用して行データを更新
                    params.api.applyTransaction({ update: [rowData] });
                }
            } else if (this.activeTab ==='債権画面'){
                if ((params.column.colId === '消込ck' || params.column.colId === '-支払手数料' || params.column.colId === '-/+為替損益') && params.newValue !== params.oldValue) {
                    // 消込ckの場合、日付形式をチェック
                    if (params.column.colId === '消込ck' && !this.isDateForAp(params.newValue)) {
                        return; // 日付形式が正しくない場合、処理を中断
                    }
                    // 対応する行のデータを取得
                    const rowData = params.data;
                    // 各値がnullの場合は0に置き換える
                    const taxIncludedAmount = rowData['税込計上額'] || 0;
                    const paymentFee = rowData['-支払手数料'] || 0;
                    const exchangeGainLoss = rowData['-/+為替損益'] || 0;
                    // 入金額を計算
                    rowData['入金額'] = taxIncludedAmount*1 + paymentFee*1 + exchangeGainLoss*1;
                    // Ag-GridのAPIを使用して行データを更新
                    params.api.applyTransaction({ update: [rowData] });
                }
            } 
        },
        setRichSelectEditorForColumn(columnName, colDef) {
            // 締切年月の特別処理
            if (columnName === '締切年月') {
                colDef.cellEditor = 'agRichSelectCellEditor';
                colDef.cellEditorParams = params => {
                    const startYm = params.data['スタート月'];
                    const endYm = params.data['エンド月'];
                    const values = this.generateValidYearMonths(startYm, endYm);
                    
                    return {
                        values: values,
                        cellHeight: 20,
                        filterList: true,
                        searchType: 'matchAny',
                        allowTyping: true,
                        valueListMaxHeight: 220,
                    };
                };
                return;
            } else if (columnName === '予定日') {
                colDef.cellEditor = 'agTextCellEditor';  // agRichSelectCellEditorからagTextCellEditorに変更
                
                // 入力値のバリデーション
                colDef.valueSetter = (params) => {
                    const newValue = params.newValue;
                    
                    // 空白またはnaの場合
                    if (newValue === '' || newValue === 'na') {
                        params.data[params.colDef.field] = newValue;
                        return true;
                    }
                    
                    // YYYY-MM-DD形式の日付かチェック
                    const dateRegex = /^\d{4}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])$/;
                    if (dateRegex.test(newValue)) {
                        // 日付として有効かチェック
                        const [year, month, day] = newValue.split('-').map(Number);
                        const date = new Date(year, month - 1, day);
                        if (
                            date.getFullYear() === year &&
                            date.getMonth() === month - 1 &&
                            date.getDate() === day
                        ) {
                            params.data[params.colDef.field] = newValue;
                            return true;
                        }
                    }
                    
                    return false;
                };
                
                return;
            }

            // 既存の処理（MasterInfosに基づく処理）
            const matchingData = this.MasterInfos.filter(info => Object.keys(info).includes(columnName));
            if (matchingData.length > 0) {
                colDef.cellEditor = 'agRichSelectCellEditor';
                colDef.cellEditorParams = {
                    values: matchingData.map(info => info[columnName]),
                    filterList: true,
                    searchType: 'matchAny',
                    allowTyping: true,
                    valueListMaxHeight: 220,
                };
                
                colDef.valueSetter = (params) => {
                    const oldValue = params.data[params.colDef.field];
                    const newValue = params.newValue;
                    
                    if (newValue === '') {
                        params.data[params.colDef.field] = null;
                        return true;
                    }
                    
                    if (params.colDef.cellEditorParams.values.includes(newValue) || params.newValue !== oldValue) {
                        params.data[params.colDef.field] = newValue;
                        return true;
                    }
                    return false;
                };
            }
        },

        // 有効な年月を生成するヘルパーメソッドを追加
        generateValidYearMonths(startYm, endYm) {
            const values = [];
            // スタート月から1月前の年月を計算
            let startYear = Math.floor(parseInt(startYm) / 100);
            let startMonth = parseInt(startYm) % 100;
            
            // 1月前に調整
            if (startMonth === 1) {
                startYear--;
                startMonth = 12;
            } else {
                startMonth--;
            }
            
            let currentYm = startYear * 100 + startMonth;
            const endYmInt = parseInt(endYm);

            while (currentYm <= endYmInt) {
                values.push(currentYm.toString());
                
                let month = currentYm % 100;
                let year = Math.floor(currentYm / 100);
                
                if (month === 12) {
                    year++;
                    month = 1;
                } else {
                    month++;
                }                
                currentYm = year * 100 + month;
            }            
            return values;
        },
        // このMasterInfoはactiveTabによって可変するのでmasterMappingとは異なる
        async getMasterInfoForRichSelect(queries = null) {
            try {
                // 引数が渡されなかった場合、this.selectMasterInfo(this.activeTab) を使用
                queries = this.selectMasterInfo(this.activeTab);
                console.log('queries:',queries);
                this.MasterInfos = []; // MasterInfosを初期化

                for (const query of queries) {
                    // クエリを '^' と '|' で分割して各要素を取得
                    const [mappingNameWithKey, targetKey] = query.split('|');
                    const [mappingName, property] = mappingNameWithKey.split('^');

                    // Vuexストアから動的にマッピングデータを取得
                    const mappingData = this.$store.state[mappingName];

                    // mappingDataから指定されたプロパティを抽出し、targetKeyと共に配列に追加
                    if (mappingData) {
                        mappingData.forEach(item => {
                            this.MasterInfos.push({ [targetKey]: item[property] });
                        });
                    } else {
                        console.warn(`${mappingName} はVuexストアに存在しないマッピングです`);
                    }
                }
                
                // ハードコードされたマッピングデータを追加
                const hardcodedMappings = {
                    口座種別: { '1': '普通預金', '2': '当座預金', '3': '郵便貯金' },
                    権限: { '1': 'admin', '2': 'general', '3': 'viewer', '9': 'non-active' },
                    貸借表示: { '借方':'', '貸方':'' },
                    // 他のハードコードされたマッピングがあればここに追加
                };
                // ハードコードされたデータを MasterInfos に統合
                const transformedHardcodedMappings = [];
                Object.entries(hardcodedMappings).forEach(([key, valueMap]) => {
                    Object.entries(valueMap).forEach(([subKey, subValue]) => {
                        // transformedHardcodedMappings.push({ [key]: `${subKey}_${subValue}` });
                        transformedHardcodedMappings.push({ [key]: subValue ? `${subKey}_${subValue}` : `${subKey}` });
                    });
                });
                this.MasterInfos = [...this.MasterInfos, ...transformedHardcodedMappings];
                // データが正しく取得できているか確認
                // console.log('MasterInfos:', this.MasterInfos);
            } catch (error) {
                console.error('Error fetching master information:', error);
            }
        },
        tokyoTimezoneCellRenderer(params, columnType) {
            if (!params.value) {
                return '';
            }
            const date = new Date(params.value);
            let options;
            if (columnType === 'datetime') {
                options = {
                    timeZone: 'Asia/Tokyo',year: 'numeric',month: '2-digit',day: '2-digit',
                    hour: '2-digit',minute: '2-digit',second: '2-digit'
                };
            } else { // columnType is 'date'
                options = {
                    timeZone: 'Asia/Tokyo',year: 'numeric',month: '2-digit',day: '2-digit',
                };
            }
            return new Intl.DateTimeFormat('ja-JP', options).format(date).replace(/\//g, '-');
        },
        isValid(value, columnType, validation) {
            if (value === null || value === undefined || value === '') {
                return true; // 値が空の場合はtrueを返す
            }
            if (columnType === 'int' && !Number.isInteger(Number(value))) {
                return false; // 整数でない場合はエラー
            } else if (columnType === 'decimal' && isNaN(parseFloat(value))) {
                return false; // 小数もしくは整数でない場合はエラー
            } else if (validation === 'required' && (value === null || value === undefined || value === '')) {
                return false; // 未入力の場合はエラー
            } else {
                return true; // それ以外の場合はtrueを返す
            }
        },
        async getColumnDefsforPivot(tbType) {
            let tableName = this.determineTable(this.activeTab);            
            tableName = tableName.split('(')[0];
            console.log('tableName1:',tableName);
            // 基本的な列定義
            let baseColumnDefs = [];

            // '推移'を含まない場合にのみ部門情報と表示区分を追加
            if (tbType === 'PJ分析') {
                baseColumnDefs.push(
                    { field: 'PJ情報', rowGroup: true, enableRowGroup: true, filter: true },
                    { field: '表示区分', rowGroup: true, enableRowGroup: true, filter: true }
                );
            } else if (!tbType.includes('推移')) {
                baseColumnDefs.push(
                    { field: '部門情報', rowGroup: true, enableRowGroup: true, filter: true },
                    { field: '表示区分', rowGroup: true, enableRowGroup: true, filter: true }
                );
            }

            // 共通の列定義
            baseColumnDefs.push(
                { field: '勘定情報', rowGroup: true, enableRowGroup: true, filter: true },
                { field: '年月', pivot: true, enableRowGroup: true, enablePivot: true, filter: true },
                {
                    field: '税抜金額',
                    aggFunc: tbType === '残高' ? this.cumulativeSum : 'sum',
                    enableValue: true,
                    filter: true,
                    cellStyle: params => ({
                        'text-align': 'right',
                        color: params.value < 0 ? 'blue' : 'black'
                    }),
                    valueFormatter: params => {
                        if (params.value === undefined || params.value === null) {
                            return '';
                        }
                        return params.value < 0 
                            ? `(${Math.abs(params.value).toLocaleString('ja-JP', { minimumFractionDigits: 0, maximumFractionDigits: 0 })})`
                            : params.value.toLocaleString('ja-JP', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
                    }
                }
            );
            // tableNameに応じて異なるフィールドを追加
            switch (tableName) {
                case '相手TB': case '債権推移': case '債務推移':
                    baseColumnDefs.splice(3, 0, { field: '相手情報', rowGroup: true, enableRowGroup: true, filter: true });
                    break;
                case 'PJTB':
                    baseColumnDefs.splice(3, 0, { field: 'PJ情報', rowGroup: true, enableRowGroup: true, filter: true });
                    break;
            }
            this.columnDefs = baseColumnDefs;
             // すべてのカラム定義に `resizable: true` を追加する
            this.columnDefs = this.columnDefs.map(colDef => ({ ...colDef, resizable: true, sortable: false }));
            this.gridOptions.columnDefs = this.columnDefs;
            this.gridOptions.groupDefaultExpanded = 9;
            this.gridOptions.groupHideOpenParents = false;
            this.gridOptions.groupDisplayType ='multipleColumns';
            // this.gridOptions.groupDisplayType ='groupRows';
            // this.gridOptions.groupSuppressBlankHeader = false, // グループの空白行にヘッダーを表示
            this.gridOptions.groupRowRendererParams = {suppressCount: true,}; // グループカウントを非表示
            
            if (tbType === '発生'||tbType === 'PJ分析'||tbType === '部門PL'||this.activeTab.includes('推移')) {
                this.gridOptions.removePivotHeaderRowWhenSingleValueColumn = true;
                this.gridOptions.pivotRowTotals = 'after';
            } else {
                this.gridOptions.removePivotHeaderRowWhenSingleValueColumn = true;
                this.gridOptions.pivotRowTotals = false;
            }
            // 行のスタイルを設定するコールバック関数を追加
            this.gridOptions.getRowStyle = function(params) {
                // '推移'が含まれている場合、スタイルを適用しない
                if (tbType.includes('推移')) {
                    return {};
                }

                // 行がグループ化された行かつフィールドが特定の値の場合
                const targetField = '表示区分';

                if (params.node.group && params.node.field === targetField) {
                    return { 'border-bottom': '2px solid black' }; // 小計行にボーダーを追加
                }
                
                // その他の行にはスタイルを適用しない
                return {};
            };

            this.gridOptions.pivotMode = true;               
            // sideBarの設定を追加
            this.gridOptions.sideBar = 'filters';
        },
        // カスタム集計関数を定義
        cumulativeSum(params) {
            let result = 0;
            params.values.forEach((value) => {
                if (typeof value === 'number') {
                    result += value;
                }
            });
            return result;
        },
        async fetchTrendDataforPivot(selectedPeriod) {
            let tableName = this.determineTable(this.activeTab);            
            
            // status情報を取得
            const selectStatusEntry = this.filteredSearchEntries.find(entry => entry.searchColumnName === 'status');
            const selectStatus = selectStatusEntry ? selectStatusEntry.input : null;

            try {
                // 既存のデータをクリア
                this.rowData = []; 
                this.gridApi.setRowData(this.rowData);

                // JSON形式でデータを準備
                const postData = {
                    targetPeriod: this.targetPeriod,
                    selectPeriod: selectedPeriod,
                    tableName: tableName,
                    selectStatus: selectStatus,
                };
                // FetchリクエストでJSON形式を指定してPOSTデータを送信
                const response = await fetch('/api/select_Trend.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(postData)
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok'); // サーバーからのエラーレスポンス
                }

                // const fiscalYears = Object.values(this.targetPeriod).sort();
                let data = await response.json(); //通常のdata+re_Cd
                data = this.transformMasterMapping(data);

                if (data) {
                    this.$nextTick(() => {
                        this.gridApi.setRowData(data); // グリッドの表示を更新 
                        this.gridOptions.columnApi.setPivotMode(true);

                        // 初期の列の状態を設定(勘定TBと補助TB)_相手TB/PJTBは別途下で追加
                        let columnState = [];
                        let highlightfield = '';

                        switch (tableName) {
                            case '債権推移': case '債務推移':
                                columnState.push({ colId: '勘定情報', rowGroup: true, sort: 'asc' });
                                columnState.push({ colId: '年月', pivot: true });
                                columnState.push({ colId: '相手情報', rowGroup: true});
                                highlightfield = '勘定情報';
                                break;
                            case 'PJ分析': 
                                columnState.push({ colId: 'PJ情報', rowGroup: true, sort: 'asc' });
                                columnState.push({ colId: '表示区分', rowGroup: true, sort: 'asc' });
                                columnState.push({ colId: '勘定情報', rowGroup: true, sort: 'asc' });
                                columnState.push({ colId: '年月', pivot: true });
                                highlightfield = 'PJ情報';
                                break;
                            
                            case '部門PL':
                                columnState.push({ colId: '部門情報', rowGroup: true, sort: 'asc' });
                                columnState.push({ colId: '表示区分', rowGroup: true, sort: 'asc' });
                                columnState.push({ colId: '勘定情報', rowGroup: true, sort: 'asc' });
                                columnState.push({ colId: '年月', pivot: true });
                                highlightfield = '部門情報';
                                break;
                        }
                        
                        // columnState を gridOptions に適用
                        this.gridOptions.columnApi.applyColumnState({
                            state: columnState,
                            defaultState: {
                                pivot: false,
                                rowGroup: false,
                            },
                        });

                        //勘定情報に色を付ける処理
                        this.gridOptions.getRowClass = (params) => {
                            if (params.node.group && params.node.field === highlightfield) {
                                return 'highlight-row'; // '勘定情報' 列の集計行にスタイルを適用
                            }
                            return ''; // 該当しない場合はクラスを適用しない
                        };
                        
                    });
                    return;
                } else {
                    console.log('error');// 適切なエラーハンドリングをここに記述する
                }
            } catch (error) {
                console.error('Error fetching column defs:', error);
            }
        },
        async fetchTbDataforPivot(tbType, selectedPeriod) {
            let tableName = this.determineTable(this.activeTab);            
            tableName = tableName.includes('(') ? tableName.split('(')[0] : tableName;

            try {
                // 既存のデータをクリア
                this.rowData = []; 
                this.gridApi.setRowData(this.rowData);

                // 部門情報を取得
                const selectDeptEntry = this.filteredSearchEntries.find(entry => entry.searchColumnName === '部門情報');
                const selectDept = selectDeptEntry ? selectDeptEntry.input : null;

                // status情報を取得
                const selectStatusEntry = this.filteredSearchEntries.find(entry => entry.searchColumnName === 'status');
                const selectStatus = selectStatusEntry ? selectStatusEntry.input : null;

                // JSON形式でデータを準備
                const postData = {
                    targetPeriod: this.targetPeriod,
                    selectPeriod: selectedPeriod,
                    tableName: tableName,
                    selectDept: selectDept,
                    selectStatus: selectStatus,
                };
                // FetchリクエストでJSON形式を指定してPOSTデ���タを送信
                const response = await fetch('/api/select_TB.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(postData)
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok'); // サーバーからのエラーレスポンス
                }

                const fiscalYears = Object.values(this.targetPeriod).sort();
                const datas = await response.json(); //通常のdata+re_Cd

                const data = datas.data;  // 通常のデータ（配列）
                const re_Cd = datas.re_Cd;  // re_Cd を取得(前期繰越利益)

                console.log('re_Cd:', re_Cd);

                if (data) {
                    const addedData = this.createProfitDivisionRecords(data,tableName,re_Cd);   //ここも相手TBとPJTbでは対応が必要？                  
                    // console.log('addedData:',addedData);
                    this.$nextTick(() => {
                        if (tbType === '残高') {
                            // tbTypeが'残高'の場合に限り、科目と月を抽出し、加工データを生成する
                            const months = this.extractMonths(addedData);
                            let processedData='';
                            if (tableName.includes("相手TB")) {
                                const subjects = this.extractSubjectsOther(addedData, tableName);                            
                                processedData = this.createProcessedDataOther(addedData, subjects, months, fiscalYears, tableName,re_Cd); // 加工されたデータを生成
                            } else if (tableName.includes("PJTB")){
                                const subjects = this.extractSubjectsOther(addedData, tableName);                            
                                processedData = this.createProcessedDataOther(addedData, subjects, months, fiscalYears, tableName,re_Cd); // 加工されたデータを生成
                            } else {
                                const subjects = this.extractSubjects(addedData);                            
                                processedData = this.createProcessedData(addedData, subjects, months, fiscalYears,re_Cd); // 加工されたデータを生成
                            }
                            this.rowData = processedData; // 加工されたデータをrowDataに設定
                        } else {
                            // tbTypeが'残高'以外の場合は、取得したデータをそのまま使用
                            this.rowData = addedData;
                            
                        }
                        
                        // selectDeptがTrueの場合、部門調整レコードを追加
                        if (selectDept) {
                            const departmentAdjustments = this.createDepartmentAdjustments(this.rowData);
                            this.rowData = [...this.rowData, ...departmentAdjustments];
                        }
                        
                        this.rowData = this.transformMasterMapping(this.rowData);
                        this.gridApi.setRowData(this.rowData); // グリッドの表示を更新 
                        this.gridOptions.columnApi.setPivotMode(true);

                        // 初期の列の状態を設定(勘定TBと補助TB)_相手TB/PJTBは別途下で追加
                        let columnState = [];

                        // selectDept が null でない場合は '部門情報' を先に追加
                        if (selectDept) {
                            columnState.push({ colId: '部門情報', rowGroup: true, sort: 'asc' });
                        }

                        // '表示区分' と '勘定情報' を追加
                        columnState.push(
                            { colId: '表示区分', rowGroup: true, sort: 'asc' },
                            { colId: '勘定情報', rowGroup: true, sort: 'asc' } // '勘定情報' 列を左に固定
                        );

                        // ピボット列を追加
                        columnState.push({ colId: '年月', pivot: true });

                        // tableNameに応じて列の状態を変更
                        if (tableName === '相手TB') {
                            columnState.push({ colId: '相手情報', rowGroup: true});
                        } else if (tableName === 'PJTB') {
                            columnState.push({ colId: 'PJ情報', rowGroup: true});
                        }

                        // columnState を gridOptions に適用
                        this.gridOptions.columnApi.applyColumnState({
                            state: columnState,
                            defaultState: {
                                pivot: false,
                                rowGroup: false,
                            },
                        });

                        //表示区分に色を付ける処理
                        this.gridOptions.getRowClass = (params) => {
                            // ピボット行（グループ行）の場合にスタイルを適用
                            if (params.node.group && params.node.field === '表示区分') {
                                return 'highlight-row'; // '表示区分' 列の集計行にスタイルを適用
                            }

                            return ''; // 該当しない場合はクラスを適用しない
                        };
                    });
                    return;
                } else {
                    console.log('error');// 適切なエラーハンドリングをここに記述する
                }
            } catch (error) {
                console.error('Error fetching column defs:', error);
            }
        },
        createDepartmentAdjustments(data) {
            const departmentTotals = {};

            // 各部門、各月の合計を計算
            data.forEach(record => {
            const key = `${record.部門情報}_${record.年月}`;
            if (!departmentTotals[key]) {
                departmentTotals[key] = 0;
            }
            departmentTotals[key] += record.税抜金額 || 0;
            });

            // 調整レコードを作成
            const adjustments = [];
            Object.entries(departmentTotals).forEach(([key, total]) => {
            const [department, month] = key.split('_');
            adjustments.push({
                部門情報: department,
                表示区分: '99_部門調整',
                勘定情報: '部門調整',
                年月: parseInt(month),
                税抜金額: -total // マイナス値を設定
            });

            // tableNameに応じて追加のフィールドを設定
            if (this.activeTab.includes('相手TB')) {
                adjustments[adjustments.length - 1].相手情報 = 'other';
            } else if (this.activeTab.includes('PJTB')) {
                adjustments[adjustments.length - 1].PJ情報 = 'other';
            }
            });

            return adjustments;
        },
        // 既存のレコードから合計を計算し、条件に応じて新しいレコードを追加する
        createProfitDivisionRecords(data,tableName,re_Cd) {
            const profitDivisions = [
                '30_売上総利益',
                '40_営業利益',
                '55_経常利益',
                '70_税引前利益',
                '80_税引後利益',
            ];
            // 部門ごと、月ごとにデータをグループ化
            const groupedData = this.groupDataByDepartmentAndMonth(data);            
            // 利益区分ごとに集計            
            const profitDivisionRecords = [];
            
            Object.entries(groupedData).forEach(([groupKey]) => {
                const [department, month] = groupKey.split('_');

                profitDivisions.forEach(division => {
                    // 利益区分ごとに集計する金額を計算
                    const totalAmount = this.calculateProfitDivisionAmount([...data, ...profitDivisionRecords], division, month,department);

                    // 利益区分のレコードを作成して追加
                    const newRecord = {
                        部門情報: department,
                        表示区分: division,
                        勘定情報: division,
                        年月: month*1,
                        税抜金額: totalAmount
                    };
                    if (tableName === '相手TB') {newRecord.相手情報 = 'other';} else if (tableName === 'PJTB') {newRecord.PJ情報 = 'other';}
                    profitDivisionRecords.push(newRecord);
                    // '80_税引後利益'の場合、資本の部に「当期純利益」を追加
                    if (division === '80_税引後利益') {
                        const capitalRecord1 = {
                            部門情報: department, // 80_税引後利益 と同じ部門情報
                            表示区分: '15_資本', // 80_税引後利益 とは異なる表示区分
                            勘定情報: 'SS20_当期純利益', // 80_税引後利益 とは異なる勘定情報
                            年月: month*1, // 80_税引後利益 と同じ年月
                            税抜金額: totalAmount // 80_税引後利益 と同じ金額
                        };
                        if (tableName === '相手TB') {capitalRecord1.相手情報 = 'other';} else if (tableName === 'PJTB') {capitalRecord1.PJ情報 = 'other';}
                        profitDivisionRecords.push(capitalRecord1);
                        const capitalRecord2 = {
                            部門情報: department, // 80_税引後利益 と同じ部門情報
                            表示区分: '15_資本', // 80_税引後利益 とは異なる表示区分
                            勘定情報: re_Cd, //'SS10_前期繰越利益', // 80_税引後利益 とは異なる勘定情報
                            年月: month*1, // 80_税引後利益 と同じ年月
                            税抜金額: 0 // 80_税引後利益 と同じ金額
                        };
                        if (tableName === '相手TB') {capitalRecord2.相手情報 = 'other';} else if (tableName === 'PJTB') {capitalRecord2.PJ情報 = 'other';}
                        profitDivisionRecords.push(capitalRecord2);
                    }
                });
            });
            // 元のデータに利益区分のレコードを追加して返す
            const combinedData = [...data, ...profitDivisionRecords].sort(this.customSort);
            return combinedData;
        },
        customSort(a, b) {
            let tableName = this.determineTable(this.activeTab); 
            // 部門情報で比較
            if (a.部門情報 < b.部門情報) return -1;
            if (a.部門情報 > b.部門情報) return 1;
            
            // 部門情報が同じ場合は表示区分で比較
            if (a.表示区分 < b.表示区分) return -1;
            if (a.表示区分 > b.表示区分) return 1;
            
            // 表示区分も同じ場合は勘定情報で比較
            if (a.勘定情報 < b.勘定情報) return -1;
            if (a.勘定情報 > b.勘定情報) return 1;

            // 勘定情報が同じ場合は年月で比較
            if (a.年月 < b.年月) return -1;
            if (a.年月 > b.年月) return 1;

            // 相手TBやPJTBの場合の比較
            if (tableName.includes("相手TB")) {
                if (a.相手情報 < b.相手情報) return -1;
                if (a.相手情報 > b.相手情報) return 1;
            } else if (tableName.includes("PJTB")) {
                if (a.PJ情報 < b.PJ情報) return -1;
                if (a.PJ情報 > b.PJ情報) return 1;
            }

            // すべて同じ場合は0を返して順番を変えない
            return 0;
        },
        calculateProfitDivisionAmount(data, division, month, department) {
            const relevantDivisions = {
                '30_売上総利益': ['20_売上', '25_原価'],
                '40_営業利益': ['30_売上総利益', '35_SGA'],
                '55_経常利益': ['40_営業利益', '45_営外費', '50_営外益'],
                '70_税引前利益': ['55_経常利益', '60_特別利益', '65_特別損失'],
                '80_税引後利益': ['70_税引前利益', '75_税金'],
            };
            const monthInt = parseInt(month, 10);
            let totalAmount = 0;

            relevantDivisions[division].forEach(component => {
                // 部門と年月を使ってフィルタリング
                const componentAmount = data
                    .filter(r => (r.表示区分 === component || r.勘定情報 === component) && 
                                parseInt(r.年月, 10) === monthInt &&
                                r.部門情報 === department) // 部門情報を追加して絞り込み
                    .reduce((sum, record) => sum + (Number(record.税抜金額) || 0), 0);

                totalAmount += componentAmount;
            });
            return totalAmount;
        },
        groupDataByDepartmentAndMonth(data) {
            const groupedData = {};
            data.forEach(record => {
                const groupKey = `${record.部門情報}_${record.年月}`;
                if (!groupedData[groupKey]) {
                groupedData[groupKey] = [];
                }
                groupedData[groupKey].push(record);
            });
            return groupedData;
        },
        createProcessedData(data, subjects, months, fiscalYears, re_Cd) {
            const processedData = [];
            let cumulativeValues = {};
            let ss10_previousYearProfit = 0; // 前期繰越利益の累計値
            const subjectToDivisionMap = this.createSubjectToDivisionMap(data);
            const departments = [...new Set(data.map(item => item.部門情報))];

            let previousMonth = null; // 前のmonthの値を保持する変数
            let previousDepartment = null; // 前の部門情報を保持する変数 追加

            departments.forEach(department => {
                subjects.forEach(subject => {
                    // 新しい部門に切り替わった場合、累計をリセット 追加
                    if (previousDepartment !== null && previousDepartment !== department) {
                        cumulativeValues = {}; // 新しい部門ごとに累計値をリセット
                        ss10_previousYearProfit = 0; // 前期繰越利益の累計もリセット
                    }
                    cumulativeValues[subject] = 0; // 各科目の累計を0にリセット
                    months.forEach(month => {
                        // 会計年度の変わり目で累計値をリセット
                        if (previousMonth !== null && this.isNewFiscalYearStart(month, previousMonth, fiscalYears)) {
                            const divisionNum = parseInt(subjectToDivisionMap[subject].split('_')[0]);
                            if (!isNaN(divisionNum) && divisionNum >= 30) {
                                // PL項目のみリセット
                                cumulativeValues[subject] = 0;
                            } else if (subject === 'SS20_当期純利益') {
                                // 前期繰越利益に当期純利益を加算してリセット
                                ss10_previousYearProfit += cumulativeValues[subject];
                                cumulativeValues[subject] = 0;
                            }
                        }
                        const record = data.find(r => r.勘定情報 === subject && r.年月 === month && r.部門情報 === department);
                        let amountToAdd = record ? record.税抜金額 : 0;
                        cumulativeValues[subject] += amountToAdd;

                        processedData.push({
                            部門情報: department,
                            表示区分: subjectToDivisionMap[subject],
                            勘定情報: subject,
                            年月: month,
                            税抜金額: cumulativeValues[subject]
                        });
                        if (subject === 'SS20_当期純利益') {
                            processedData.push({
                                部門情報: department,
                                表示区分: subjectToDivisionMap[subject],
                                勘定情報: re_Cd,
                                年月: month,
                                税抜金額: ss10_previousYearProfit
                            }); //'SS10_前期繰越利益', => re_Cdへ                             
                        }
                        previousMonth = month; // 今回のmonthを次のループのために保存
                    });
                });
                previousDepartment = department; // 今回の部門を次のループのために保存 追加
            });
            return processedData;
            },
        //相手TB、PJTBの残高算出ロジック 
        createProcessedDataOther(data, subjects, months, fiscalYears, tableName, re_Cd) {
            const processedData = [];
            let cumulativeValues = {};
            let ss10_previousYearProfit = 0; // 前期繰越利益の累計値
            const subjectToDivisionMap = this.createSubjectToDivisionMap(data);
            const departments = [...new Set(data.map(item => item.部門情報))];
            
            let subjectOtherKey;
            if (tableName.includes("相手TB")) {
                subjectOtherKey = '相手情報';
            } else if (tableName.includes("PJTB")) {
                subjectOtherKey = 'PJ情報';
            }
            
            let previousMonth = null; // 前のmonthの値を保持する変数
            // subjectは、item.勘定情報 + '^' + item.相手情報 となっている！
            departments.forEach(department => {
                subjects.forEach(subject => {
                    cumulativeValues[subject] = 0; // 各科目の累計を0にリセット
                    months.forEach(month => {
                        // 会計年度の変わり目で累計値をリセット
                        let subjectParts = subject.split('^');
                        let subjectAccount = subjectParts[0];
                        let subjectOther = subjectParts[1];
                        
                        if (previousMonth !== null && this.isNewFiscalYearStart(month, previousMonth, fiscalYears)) {
                            const divisionNum = parseInt(subjectToDivisionMap[subjectAccount].split('_')[0]);
                            if (!isNaN(divisionNum) && divisionNum >= 30) {
                                // PL項目のみリセット
                                cumulativeValues[subject] = 0;
                            } else if (subjectAccount === 'SS20_当期純利益') {
                                // 前期繰越利益に当期純利益を加算してリセット
                                ss10_previousYearProfit += cumulativeValues[subject];
                                cumulativeValues[subject] = 0;
                            }
                        }
                        
                        if (subjectOtherKey === '相手情報'){
                            // console.log('subjectAccount:',subjectAccount,'subjectOther:',subjectOther,'data:',data);
                            const record = data.find(r => 
                                r.勘定情報 === subjectAccount &&
                                r.相手情報 === subjectOther &&
                                r.年月 === month &&
                                r.部門情報 === department
                            );
                            let amountToAdd = record ? record.税抜金額 : 0;
                            cumulativeValues[subject] += amountToAdd;

                            processedData.push({
                                部門情報: department,
                                表示区分: subjectToDivisionMap[subjectAccount],
                                勘定情報: subjectAccount,
                                相手情報: subjectOther,
                                年月: month,
                                税抜金額: cumulativeValues[subject]
                            });
                            if (subject === 'SS20_当期純利益') {
                                processedData.push({
                                    部門情報: department,
                                    表示区分: subjectToDivisionMap[subjectAccount],
                                    勘定情報: re_Cd,
                                    相手情報: subjectOther,
                                    年月: month,
                                    税抜金額: ss10_previousYearProfit
                                }); //'SS10_前期繰越利益', => re_Cdへ                            
                            }
                        } else if (subjectOtherKey === 'PJ情報'){
                            const record = data.find(r => 
                                r.勘定情報 === subjectAccount &&
                                r.PJ情報 === subjectOther &&
                                r.年月 === month &&
                                r.部門情報 === department
                            );
                            
                            let amountToAdd = record ? record.税抜金額 : 0;
                            cumulativeValues[subject] += amountToAdd;

                            processedData.push({
                                部門情報: department,
                                表示区分: subjectToDivisionMap[subjectAccount],
                                勘定情報: subjectAccount,
                                PJ情報: subjectOther,
                                年月: month,
                                税抜金額: cumulativeValues[subject]
                            });
                            if (subject === 'SS20_当期純利益') {
                                processedData.push({
                                    部門情報: department,
                                    表示区分: subjectToDivisionMap[subjectAccount],
                                    勘定情報: re_Cd,
                                    PJ情報: subjectOther,
                                    年月: month,
                                    税抜金額: ss10_previousYearProfit
                                }); //'SS10_前期繰越利益', => re_Cdへ                            
                            }                            
                        }                        
                        previousMonth = month; // 今回のmonthを次のループのために保存
                    });
                });
            });
            return processedData;
        },
        // 会計年度の開始を判断するヘルパー関数
        isNewFiscalYearStart(currentMonth, previousMonth, fiscalYears) {
            // fiscalYears配列内のどれかの会計年度末よりpreviousMonthが小または等しく、currentMonthが大きい場合にtrueを返す
            return fiscalYears.some(fiscalYearEnd => {
                return previousMonth <= fiscalYearEnd && currentMonth > fiscalYearEnd;
            });
        },
        createSubjectToDivisionMap(data) {
            const map = {};
            data.forEach(item => {
                if (!map[item.勘定情報]) {
                    map[item.勘定情報] = item.表示区分; // 勘定情報に対応する表示区分をマッピング
                }
            });
            return map;
        },
        // 科目の一覧をデータから抽出する
        extractSubjects(data) {
            const subjects = new Set(); // Setを使用して重複を避ける
            data.forEach(item => {
                subjects.add(item.勘定情報); // '勘定情報'列から科目を追加
            });
            return Array.from(subjects); // Setを配列に変換して返す
        },
        // 科目の一覧・相手をデータから抽出する
        extractSubjectsOther(data, tableName) {
            const subjects = new Set(); // Setを使用して重複を避ける
            if (tableName.includes("相手TB")) {
                data.forEach(item => {
                    const subjectInfo = item.勘定情報 + '^' + (item.相手情報 || 'other');
                    subjects.add(subjectInfo); // '勘定情報'列から科目を追加
                });    
            } else if (tableName.includes("PJTB")) {
                data.forEach(item => {
                    const subjectInfo = item.勘定情報 + '^' + (item.PJ情報 || 'other');
                    subjects.add(subjectInfo); // '勘定情報'列から科目を追加
                });    
            }
            console.log('subjects:',subjects);
            return Array.from(subjects); // Setを配列に変換して返す
        },
        // 月の一覧をデータから抽出する
        extractMonths(data) {
            const months = new Set(); // Setを使用して重複を避ける
            data.forEach(item => {
                months.add(item.年月); // '年月'列から月を追加
            });
            // console.log('months:',months);
            return Array.from(months).sort(); // Setを配列に変換、ソートして返す
        },
        changePivotMode() {
            this.pivotModeOn = !this.pivotModeOn;
            let tableName = this.determineTable(this.activeTab); // アクティブなタブに基づいてテーブル名を決定

            // 部門情報を取得
            const selectDeptEntry = this.filteredSearchEntries.find(entry => entry.searchColumnName === '部門情報');
            const selectDept = selectDeptEntry ? selectDeptEntry.input : null;
            
            let state = [];

            // selectDept が null でない場合は '部門情報' を追加
            if (selectDept) {
                state.push({ colId: '部門情報', rowGroup: this.pivotModeOn});
            }
            
            // '表示区分' と '勘定情報' を追加
            state.push(
                { colId: '表示区分', rowGroup: this.pivotModeOn },
                { colId: '勘定情報', rowGroup: this.pivotModeOn }
            );
            
            // tableNameに基づいて追加の列を構築
            if (tableName.includes('相手TB')) {
                state.push({ colId: '相手情報', rowGroup: this.pivotModeOn});
            } else if (tableName.includes('PJTB')) {
                state.push({ colId: 'PJ情報', rowGroup: this.pivotModeOn});
            }

            state.push({ colId: '年月', pivot: this.pivotModeOn });
            this.gridOptions.columnApi.setPivotMode(this.pivotModeOn);
            this.gridOptions.columnApi.applyColumnState({ state: state });
        },
        // 列がピン留めされているかどうかを切り替えるメソッド
        togglePinColumn() {
            const focusedCell = this.gridOptions.api.getFocusedCell();
            if (focusedCell) {
                const colId = focusedCell.column.colId;
                const columnState = this.gridOptions.columnApi.getColumnState();
                const column = columnState.find(col => col.colId === colId);
                const isPinned = column && column.pinned;
                this.gridOptions.columnApi.setColumnPinned(colId, isPinned ? null : 'left');
            } else {
                console.log('No cell is focused to toggle the pinning of the column');
            }
        },
        async fetchData() {
            try {
                let tableName = this.determineTable(this.activeTab); // アクティブなタブに基づいてテーブル名を決定
                let url = `/api/select_data.php`;
                // 特定のテーブル名に対してperiodsをパラメータに含める
                if (['補助残高', '相手残高', 'PJ残高'].includes(tableName)) {
                    const period = Object.values(this.targetPeriod).sort()[0];
                    tableName = period + '_' + tableName;
                } else if (['仕訳'].includes(tableName)){
                    const periodsArray = Object.values(this.targetPeriod).sort();
                    const period = periodsArray[periodsArray.length - 1];
                    tableName = period + '_' + tableName;
                }
                const postData = {
                    table: tableName,
                    columns: []
                };
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify(postData)
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok ' + response.statusText);
                }
                let data = await response.json();
                data = this.transformMasterMapping(data);

                if (!Array.isArray(data)) {
                    alert("ログアウトされました"); return;
                }
                
                // 外部関数を使用して getRowStyle を設定する
                const rowStyleFunction = this.applyRowStyleForData(tableName);
                if (rowStyleFunction) {
                    this.gridOptions.getRowStyle = rowStyleFunction;
                }
                // 企業情報テーブルのデータがフェッチされた場合の処理
                if (tableName === `企業情報`) {
                    // エンド月で降順にソート
                    this.rowData = data.sort((a, b) => b.エンド月 - a.エンド月);
                } else {
                    // その他のテーブル用の処理
                    this.rowData = data;
                    this.$nextTick(() => {this.autoSizeAllColumns();});
                }                
            } catch (error) {
                console.error('There was a problem with the fetch operation: ', error);
            }
        },
        onCellEditingStarted() {
            this.showErrorAlerts = true;
        },
        clearData() {
            this.rowData = []; // rowData を空の配列に設定
            if (this.gridOptions.api) { // グリッドの API が利用可能か確認
                this.gridOptions.api.setRowData([]); // グリッドの表示を更新
            }
            
            this.addNewRow(false); // 行を追加            
        },
        addNewRow(askForRowCount = true) {
            if (askForRowCount) {
                this.showRowSelector = true;
            } else {
                // falseの場合は直接10行追加
                this.selectAndAddRows(10, this.activeTab);
            }
        },
        selectAndAddRows(numRowsToAdd, activeTab) {
            // activeTabが現在のタブと一致しない場合は処理を中止
            if (this.activeTab !== activeTab) {
                return;
            }
            this.showRowSelector = false; // dialogを閉じる
            console.log('numRowsToAdd:', numRowsToAdd);

            const newRows = Array.from({ length: numRowsToAdd }, () => ({}));

            // 現在の行データを取得してrowDataを更新
            const currentRowData = [];
            this.gridApi.forEachNode(node => currentRowData.push(node.data));
            this.rowData = currentRowData;

            if (this.gridApi.isAnyFilterPresent()) {
                if (!confirm("フィルタが解除されます。継続しますか？")) {
                    return;
                }
            }

            this.isAddingRow = true;

            // 選択された行のインデックスを取得
            const selectedNodes = this.gridApi.getSelectedNodes();
            const selectedIndexes = selectedNodes.map(node => node.childIndex);
            let maxIndex;
            if (selectedIndexes.length > 0) {
                maxIndex = Math.max(...selectedIndexes);
            } else {
                const focusedCell = this.gridApi.getFocusedCell();
                if (focusedCell) {
                    maxIndex = focusedCell.rowIndex;
                } else {
                    maxIndex = this.rowData.length - 1;
                }
            }

            // フィルタとソートの状態をリセット
            this.gridApi.setFilterModel(null);
            this.gridApi.getModel().sortModel = [];

            // 指定されたインデックスに新しい複数行を挿入
            this.rowData.splice(maxIndex + 1, 0, ...newRows);

            // rowDataをag-gridに更新
            this.gridApi.setRowData(this.rowData);
            this.isAddingRow = false;
        },
        sideBarFilterClear() {
            this.gridApi.setFilterModel(null);
            this.gridApi.closeToolPanel();
        },
        ongridReady(params) {
            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;
            this.gridApi.closeToolPanel();

            // viewportChanged イベントリスナーを追加
            this.gridApi.addEventListener('viewportChanged', this.onViewportChanged);

            // gridApi が定義されている場合のみ列の自動サイズ調整を実行
            if (this.gridApi && this.gridColumnApi) {
                setTimeout(() => {
                    this.autoSizeAllColumns();
                }, 0);
            }

            // ag-grid のスクロールイベントにリスナーを追加
            if (this.gridApi) {
                this.gridApi.addEventListener('bodyScroll', this.handleScroll);
            }
        },
        pasteFromClipboard() {
            const pasteValue = (text) => {
                const focusedCell = this.gridApi.getFocusedCell();
                if (focusedCell) {
                    const rowIndex = focusedCell.rowIndex;
                    const colKey = focusedCell.column.getColId();
                    const column = this.gridApi.getColumnDef(colKey);

                    if (column.cellEditor === 'agRichSelectCellEditor') {
                        // agRichSelectCellEditorの場合、値を直接設定
                        const validValues = column.cellEditorParams.values;
                        if (validValues.includes(text)) {
                            this.gridApi.getRowNode(rowIndex).setDataValue(colKey, text);
                        } else {
                            alert('選択リストに含まれない値です。');
                        }
                    } else {
                        // 通常のセルの場合、値を直接設定
                        this.gridApi.getRowNode(rowIndex).setDataValue(colKey, text);
                    }
                    // セルの更新をトリガー
                    this.gridApi.refreshCells({
                        rowNodes: [this.gridApi.getRowNode(rowIndex)],
                        columns: [colKey],
                        force: true
                    });
                } else {
                    alert('セルが選択されていません。');
                }
            };

            if (navigator.clipboard && navigator.clipboard.readText) {
                navigator.clipboard.readText()
                    .then(text => {
                        pasteValue(text);
                    })
                    .catch(err => {
                        console.error('クリップボードからの読み取りに失敗しました:', err);
                        alert('クリップボードからのペーストに失敗しました。');
                    });
            } else {
                // クリップボードAPIがサポートされていない場合の代替手段
                const pastedText = prompt('テキストを貼り付けてください:');
                if (pastedText) {
                    pasteValue(pastedText);
                }
            }
        },
        getContextMenuItems(params) {
            this.$forceUpdate();
            const self = this;  // 'this' を変数に格納して、内部関数で使用する
            // let contextMenuItems = ['copy', 'copyWithHeaders'];
            let contextMenuItems = [
                {name: 'copy/paste/clear',
                subMenu: [
                    {name: '選択セルcopy',action: function() {self.gridApi.copySelectedRangeToClipboard(false);}},
                    {
                        name: self.activeTab.includes('TB') || self.activeTab.includes('消費税ck画面') ? 'ヘッダーcopy' : 'ヘッダーcopy',
                        action: function () {
                            if (self.activeTab.includes('TB') || self.activeTab.includes('消費税ck画面')) {
                                const selectedRanges = self.gridApi.getCellRanges();
                                if (selectedRanges && selectedRanges.length > 0) {
                                    const range = selectedRanges[0];
                                    const columns = range.columns;
                                    
                                    try {
                                        // ヘッダー行を作成
                                        const headers = columns
                                            .filter(col => col && col.getColDef()) // nullチェックを追加
                                            .map(col => {
                                                const colDef = col.getColDef();
                                                return colDef.headerName || colDef.field || '';
                                            })
                                            .join('\t');
                                        
                                        // 選択されたデータを取得
                                        let data = '';
                                        for (let rowIndex = range.startRow.rowIndex; rowIndex <= range.endRow.rowIndex; rowIndex++) {
                                            const rowNode = self.gridApi.getDisplayedRowAtIndex(rowIndex);
                                            if (rowNode && rowNode.data) {
                                                const rowData = columns
                                                    .filter(col => col && col.getColDef()) // nullチェックを追加
                                                    .map(col => {
                                                        const field = col.getColDef().field;
                                                        return rowNode.data[field] !== undefined ? rowNode.data[field] : '';
                                                    })
                                                    .join('\t');
                                                data += rowData + '\n';
                                            }
                                        }
                                        
                                        // ヘッダーとデータを結合してクリップボードにコピー
                                        const textToCopy = headers + '\n' + data;
                                        navigator.clipboard.writeText(textToCopy)
                                            .then(() => console.log('ヘッダー付きデータをコピーしました'))
                                            .catch(err => console.error('コピーに失敗しました:', err));
                                    } catch (error) {
                                        console.error('コピー処理中にエラーが発生しました:', error);
                                        alert('コピー処理中にエラーが発生しました');
                                    }
                                } else {
                                    alert('コピーする範囲が選択されていません');
                                }
                            } else {
                                // ヘッダーのみをコピー
                                const columnHeaders = self.gridApi
                                    .getColumnDefs()
                                    .filter(col => col.headerName !== '#') // 行番号列を除外
                                    .map(col => col.headerName || col.field) // ヘッダー名を取得
                                    .join('\t'); // タブ区切りでヘッダーを結合
                                
                                navigator.clipboard.writeText(columnHeaders) // クリップボードにコピー
                                    .then(() => console.log('ヘッダーをコピーしました:', columnHeaders))
                                    .catch(err => console.error('コピーに失敗しました:', err));
                            }
                        },
                    },
                    {name: 'ペースト',action: function() {self.pasteFromClipboard();}},
                    {name: '選択セルクリア',action: () => {this.clearSelectedCells();}},
                ]}
            ];
            let formattedSum = '';

            // データが存在するか、またはピボットモードかどうかを確認
            if ((!params.node || !params.node.data) && (!params.column || !params.column.isPivotActive)) {
                // データもピボットもない場合���Tab選択メニューのみを表示
                return [{
                        name: 'Tab選択',
                        subMenu: this.allTabs.map(tab => {
                            return {
                                name: tab.name,
                                action: function() {
                                    self.$store.commit('SET_ACTIVE_TAB', tab);
                                    console.log('SET_ACTIVE_TAB:',tab);
                                }
                            };
                        })
                    }
                ];
            }

            // activeTabの名前が'TB'を含んでいない場合に合計値を計算（TB又は推移だとエラーになる)
            if (!this.activeTab.includes('TB') && !this.activeTab.includes('推移')&& !this.activeTab.includes('PJ分析')&& !this.activeTab.includes('部門PL')) {
                const selectedCellsSum = this.calculateSelectedCellsSum();
                formattedSum = this.formatNumber(selectedCellsSum);

                contextMenuItems.unshift({
                    name: `合計値: ${formattedSum}`,
                    action: function() {
                        console.log('合計値:', formattedSum);
                    },
                    disabled: true,
                });
            }

            // Tab選択のサブメニューを追加
            contextMenuItems.push(
                {name: '列幅Fit', action: function() { self.executeAction('列幅Fit'); }},
                {
                    name: 'Tab選択',
                    subMenu: this.allTabs.map(tab => {
                        return {
                            name: tab.name,
                            action: function() {
                                self.$store.commit('SET_ACTIVE_TAB', tab);
                                console.log('SET_ACTIVE_TAB:',tab);
                            }
                        };
                    })
                }
            );

            if (this.activeTab.includes('仕訳画面')) {

                // 定型仕訳のsubMenuを動的に生成
                let uniqueTmpJeMapping = this.tmpJeMapping.reduce((acc, item) => {
                    // 仕訳名称が既に追加されていない場合のみ追加
                    if (!acc.some(existingItem => existingItem['仕訳名称'] === item['仕訳名称'])) {
                        acc.push(item);
                    }
                    return acc;
                }, []);

                let tmpJeSubMenu = uniqueTmpJeMapping.map(item => ({
                    name: item['仕訳名称'],
                    action: function() { self.fetchTmpJe(item['仕訳名称']); }
                }));

                // 簡易入力オプションを追加
                tmpJeSubMenu.unshift({
                    name: '■簡易入力表示■',
                    action: function() { self.showSimpleInput = true; }
                });
                // 特定のカラム名で右クリックされた場合のメニュー項目を設定
                if (params.column && params.column.getColId() === '消込ck') {
                    contextMenuItems.push(
                        {name: '消込ck変更', action: function() { self.executeAction('消込ck変更'); }},
                        // 他のカスタムメニュー項目...
                    );
                } else {
                    contextMenuItems.push(
                        {name: '加工可能にする',action: function() { self.executeAction('加工可能にする'); }},
                        {name: '定型仕訳呼出', subMenu: tmpJeSubMenu},
                    );
                }
            }
            if (this.activeTab.includes('仕訳変更ログ')) {
                contextMenuItems.push(
                    {name: '仕訳ログ呼出',action: function() { self.executeAction('仕訳ログ呼出'); }},
                    {name: 'ログ差分出力',action: function() { self.executeAction('ログ差分出力'); }},
                );
            }
            if (this.activeTab.includes('マスタ変更ログ')) {
                contextMenuItems.push(
                    {name: 'マスタログ呼出',action: function() { self.executeAction('マスタログ呼出'); }},
                    {name: 'ログ差分出力',action: function() { self.executeAction('ログ差分出力'); }},
                );
            }
            if (['消費税ck画面'].includes(this.activeTab)) {
                contextMenuItems.push(
                    {name: '消費税仕訳呼出',action: function() { self.executeAction('消費税仕訳呼出'); }},
                );
            }
            if (['債権画面'].includes(this.activeTab)) {
                contextMenuItems.push(
                    {name: '消込仕訳作成',action: function() { self.executeAction('消込仕訳作成'); }},
                    {name: '対象仕訳呼出',action: function() { self.executeAction('対象仕訳呼出'); }},
                );
            }
            if (['債務画面'].includes(this.activeTab)) {
                contextMenuItems.push(
                    {name: '支払仕訳作成',action: function() { self.executeAction('支払仕訳作成'); }},
                    {name: 'FBデータ作成',action: function() { self.executeAction('FBデータ作成'); }},
                    {name: '対象仕訳呼出',action: function() { self.executeAction('対象仕訳呼出'); }},
                );
            }
            if (['源泉画面'].includes(this.activeTab)) {
                contextMenuItems.push(
                    {name: '源泉仕訳作成',action: function() { self.executeAction('源泉仕訳作成'); }},
                    {name: '対象仕訳呼出',action: function() { self.executeAction('対象仕訳呼出'); }},
                );
            }
            if (this.activeTab.includes('元帳')) {
                contextMenuItems.push(
                    {name: 'ﾄﾞﾘﾙﾀﾞｳﾝ',action: function() { self.executeAction('ﾄﾞﾘﾙﾀﾞｳﾝ'); }},
                );
            }
            // 特定のタブがアクティブの時に表示するメニュー項目
            if (['勘定TB(残高)','補助TB(残高)','相手TB(残高)','PJTB(残高)'].includes(this.activeTab)) {
                contextMenuItems.push(
                    {name: 'ﾄﾞﾘﾙﾀﾞｳﾝ',action: function() { self.executeAction('ﾄﾞﾘﾙﾀﾞｳﾝ'); }},
                    {name: '元帳表示',action: function() { self.executeAction('元帳表示'); }},
                    {name: 'pivot切替',action: function() { self.executeAction('pivot切替'); }},
                    {name: '列固定切替',action: function() { self.executeAction('列固定切替'); }},
                    {name: 'sideBarClear',action: function() { self.executeAction('sideBarClear'); }},
                );
            } else if (['勘定TB(発生)','補助TB(発生)','相手TB(発生)','PJTB(発生)'].includes(this.activeTab)) {
                contextMenuItems.push(
                    {name: 'ﾄﾞﾘﾙﾀﾞｳﾝ',action: function() { self.executeAction('ﾄﾞﾘﾙﾀﾞｳﾝ'); }},
                    {name: 'pivot切替',action: function() { self.executeAction('pivot切替'); }},
                    {name: '列固定切替',action: function() { self.executeAction('列固定切替'); }},
                    {name: 'sideBarClear',action: function() { self.executeAction('sideBarClear'); }},
                );
            } else if (['債権推移','債務推移','PJ分析','部門PL'].includes(this.activeTab)) {
                contextMenuItems.push(
                    {name: 'ﾄﾞﾘﾙﾀﾞｳﾝ',action: function() { self.executeAction('ﾄﾞﾘﾙﾀﾞｳﾝ'); }},
                    {name: 'pivot切替',action: function() { self.executeAction('pivot切替'); }},
                    {name: 'sideBarClear',action: function() { self.executeAction('sideBarClear'); }},
                );
            }
            // データ操作のサブメニュー（条件によって表示を切り替える）
            let dataOperationSubMenu = [
            { name: 'DLﾒﾆｭｰ', action: function() { self.executeAction('DLﾒﾆｭｰ'); } }
            ];
            // 特定のタブがアクティブの時にはこれらのメニュー項目を追加しない(deactivate)
            const deactivatedTabs = [
                '債務画面',
                '債権画面',
                '源泉画面',
                '勘定TB(発生)',
                '補助TB(発生)',
                '相手TB(発生)',
                'PJTB(発生)',
                '勘定TB(残高)',
                '補助TB(残高)',
                '相手TB(残高)',
                'PJTB(残高)',
                '消費税ck画面',
                '債務推移',
                '債権推移',
            ];

            if (!deactivatedTabs.includes(this.activeTab.trim())) {
                dataOperationSubMenu = dataOperationSubMenu.concat([
                    { name: '登録実行', action: function() { self.executeAction('登録実行'); } },
                    { name: '更新実行', action: function() { self.executeAction('更新実行'); } },
                    { name: '削除実行', action: function() { self.executeAction('削除実行'); } },                    
                    { name: '最終更新', action: function() { self.executeAction('最終更新'); } },
                    { name: '承認実行', action: function() { self.executeAction('承認実行'); } },
                    { name: '差戻実行', action: function() { self.executeAction('差戻実行'); } },
                ]);
            }
            // データ操作サブメニューを追加
            contextMenuItems.push({
                name: 'データ操作',
                subMenu: dataOperationSubMenu
            });
            // その他の操作のサブメニューを追加
            contextMenuItems.push({
                name: 'その他の操作',
                subMenu: [
                    { name: '表示ｸﾘｱ', action: function() { self.executeAction('表示ｸﾘｱ'); } },
                    ...(!this.activeTab.includes('TB') ? [
                        ...(!this.activeTab.includes('仕訳画面') ? [
                            { name: '全件DL', action: function() { self.fetchData(); } },
                        ] : []),    
                        { name: '入力リスト更新', action: function() { self.executeAction('入力リスト更新'); } },
                        { name: '行の追加', action: function() { self.executeAction('行の追加'); } },
                    ] : []),
                    ...(this.activeTab.includes('仕訳画面') ? [
                        { name: '選択行のみ表示', action: function() { self.executeAction('選択行のみ表示'); } },
                        { name: '行ｸﾘｱ', action: function() { self.executeAction('行ｸﾘｱ'); } },
                        { name: '空白セル更新', action: function() { self.executeAction('空白セル更新'); } },
                        { name: '検索項目追加', action: function() { self.executeAction('検索項目追加'); } },                        
                    ] : [])
                ]
            });

            return contextMenuItems;
        },
        undo() {
            this.gridApi.undoCellEditing();
        },
        redo() {
            this.gridApi.redoCellEditing();
        },
        // 選択されたセルに値を適用する関数（複数セルへの同時入力）
        applyValueToSelectedCells(cellRanges) {
            this.gridOptions.api.stopEditing(); // 編集モードを終了
            const userInput = prompt('Enter value for selected cells:');

            if (userInput !== null) {
                let hasError = false; // エラーフラグを追加
                let firstCellToFocus = null; // 最初にフォーカスするセルを記録
                let errorMessage = ''; // 最初のエラーメッセージを保持

                cellRanges.forEach(range => {
                    let startRowIndex = Math.min(range.startRow.rowIndex, range.endRow.rowIndex);
                    let endRowIndex = Math.max(range.startRow.rowIndex, range.endRow.rowIndex);

                    for (let rowIndex = startRowIndex; rowIndex <= endRowIndex; rowIndex++) {
                        const rowNode = this.gridOptions.api.getRowNode(rowIndex);
                        const rowData = rowNode.data;

                        range.columns.forEach(col => {
                            if (col) {  // エラーがあっても処理は継続
                                const columnDef = col.getColDef();

                                // agRichSelectCellEditor の場合は選択肢にあるか確認
                                if (columnDef.cellEditor === 'agRichSelectCellEditor') {
                                    const validValues = columnDef.cellEditorParams.values;
                                    if (!validValues.includes(userInput)) {
                                        if (!hasError) {
                                            errorMessage = `Invalid value for ${columnDef.field}: ${userInput}`;
                                            hasError = true;  // エラーフラグを立てて処理を中断
                                        }
                                        // エラーがあっても最初のセルにフォーカスを設定
                                        if (!firstCellToFocus) {
                                            firstCellToFocus = { rowIndex, colId: columnDef.field };
                                        }
                                        return;  // このループの残りをスキップ
                                    }
                                }

                                // isValidForServer を呼び出し、バリデーションチェック
                                if (columnDef.validation !== 'readonly' && !this.isValidForServer(userInput, columnDef.columnType, columnDef.validation)) {
                                    if (!hasError) {
                                        errorMessage = `Invalid value for ${columnDef.field}: ${userInput}`;
                                        hasError = true;  // エラーフラグを立てて処理を中断
                                    }
                                    // エラーがあっても最初のセルにフォーカスを設定
                                    if (!firstCellToFocus) {
                                        firstCellToFocus = { rowIndex, colId: columnDef.field };
                                    }
                                    return;  // このループの残りをスキップ
                                }

                                // バリデーションが成功した場合のみ値をセット
                                rowNode.setDataValue(columnDef.field, userInput);

                                // '消込ck' カラムに値を入力した場合、'消込ck金額' カラムも更新
                                if (columnDef.field === '消込ck' && this.activeTab === '債務画面' && this.isDateForAp(userInput)) {
                                    rowData['消込ck金額'] = rowData['税込源泉後'];
                                }

                                // 最初のセルを記録 (エラーがない場合)
                                if (!firstCellToFocus) {
                                    firstCellToFocus = { rowIndex, colId: columnDef.field };
                                }
                            } else if (!col) {
                                console.error('カラムオブジェクトが見つかりません。', col);
                            }
                        });
                    }
                });

                // グリッドをリフレッシュして最初のセルにフォーカスを移動
                this.$nextTick(() => {
                    // エラーがあれば最初のエラーだけを表示
                    if (hasError) {
                        alert(errorMessage);  // 最初のエラーメッセージを表示
                    }

                    this.gridOptions.api.redrawRows();

                    // エラーが発生しても、最初に記録したセルをフォーカスして、キー移動を可能にする
                    if (firstCellToFocus) {
                        this.gridOptions.api.setFocusedCell(firstCellToFocus.rowIndex, firstCellToFocus.colId);
                    }
                });
            }
        },
        deselectAllCells() {
            if (this.gridOptions && this.gridOptions.api) {
                // console.log('deselectAll');
                this.gridOptions.api.deselectAll();
                // セル選択範囲を取得
                const cellRanges = this.gridOptions.api.getCellRanges();
                if (cellRanges.length > 0) {
                    // ag-Grid 3.x 以降では、clearRangeSelection メソッドを使用してセルの選択範囲をクリアする
                    this.gridOptions.api.clearRangeSelection();
                    // console.log('Cell selections cleared');
                }
            }
        },
        // 連続入力エリアの末端を見つけるための関数
        findNextFilledRow(startIndex, colId, direction) {
            const rowCount = this.gridOptions.api.getDisplayedRowCount();
            let currentIndex = startIndex;
            // 連続して値が入っている最終行を探す
            while (currentIndex >= 0 && currentIndex < rowCount) {
                const rowNode = this.gridOptions.api.getDisplayedRowAtIndex(currentIndex);
                // 値が入っているセルのチェック
                if (rowNode && rowNode.data) {
                    const cellValue = rowNode.data[colId];
                    if (cellValue !== null && cellValue !== undefined && cellValue !== '') {
                        // 値が入っているセルに到達したらその行でストップ
                        return currentIndex;
                    }
                }
                currentIndex += direction;
            }
            // 最後の行まで行った場合
            return direction > 0 ? rowCount - 1 : 0;
        },
        handleKeydown(event) {
            const activeStoreTab = this.$store.state.currentActiveTab; // アクティブなタブをVuexから取得
            if (activeStoreTab !== this.activeTab) {
                // 現在のタブがアクティブでない場合は処理を終了
                return;
            }
            // 矢印キーが押された場合は、この関数での処理を無視
            if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(event.key)) {
                // Ctrl+ArrowDown または Ctrl+Shift+ArrowDown の処理
                if (event.ctrlKey && event.key === 'ArrowDown') {
                    event.preventDefault();
                    event.stopPropagation();
                    
                    const api = this.gridOptions.api;
                    const focusedCell = api.getFocusedCell();

                    if (focusedCell) {
                        const colId = focusedCell.column.getId();
                        const currentRowIndex = focusedCell.rowIndex;
                        const rowCount = api.getDisplayedRowCount();
                        let targetRowIndex = this.findNextFilledRow(rowCount - 1, colId, -1);

                        // Shift キーが押されている場合は範囲選択
                        if (event.shiftKey) {
                            // 既存の選択範囲をクリア
                            api.clearRangeSelection();
                            
                            // 新しい範囲を選択
                            api.addCellRange({
                                rowStartIndex: currentRowIndex,
                                rowEndIndex: targetRowIndex,
                                columnStart: colId,
                                columnEnd: colId
                            });
                        } else {
                            // 通常の移動（既存の動作）
                            api.clearRangeSelection();
                            api.setFocusedCell(targetRowIndex, colId);
                        }
                        
                        // 必要に応じて選択した行が見えるようにスクロール
                        api.ensureIndexVisible(targetRowIndex);
                    }
                    return;
                }
            }

             // 行追加ダイアログが表示中か確認
            if (this.showRowSelector) {
                // Enter キーでデフォルトのボタンを実行
                if (event.key === 'Enter') {
                    const defaultRowCount = this.rowOptions[0]; // 最初のボタンの行数
                    this.selectAndAddRows(defaultRowCount, this.activeTab); // 行を追加
                    event.preventDefault(); // デフォルト動作を無効化
                }
                return; // ダイアログ中の場合はそれ以上の処理をしない
            }

            if (event.ctrlKey && event.key === 'Delete') {
                this.clearSelectedCells();
                event.preventDefault();
            }
            // Ctrl + Enterが押された時に処理を実行
            if (event.ctrlKey && event.key === 'Enter') {
                const selectedRanges = this.gridOptions.api.getCellRanges();
                if (selectedRanges.length > 0) {
                    this.applyValueToSelectedCells(selectedRanges);
                }
            }

            // Alt + 特定のキーが押された時に対応するメニューを探す
            const matchedMenu = this.menus.find(menu => {
                const firstChar = menu.label[0].toUpperCase();
                return event.altKey && (
                    event.code === `Digit${firstChar}` || event.code === `Key${firstChar}`
                );
            });

            // 対応するメニューが見つかった場合、そのアクションを実行
            if (matchedMenu) {
                const activeStoreTab = this.$store.state.currentActiveTab;
                if (activeStoreTab === this.activeTab) {
                    this.executeAction(matchedMenu.name);
                }
            }
        },
        async expandSelectedRangesForJournal(selectedRanges, callerFunction) {
            // callerFunctionが文字列であることを確認
            if (typeof callerFunction !== 'string') {
                callerFunction = String(callerFunction);
            }
            
            if (!this.activeTab.includes('仕訳')) {
                return selectedRanges;
            }

            let expansionCriteria = 'voucherNumber'; // デフォルトは伝票番号

            // if (callerFunction === 'updateData' || callerFunction === 'deleteData') {
            //     try {
            //         await this.$msgbox.confirm(
            //             '範囲の拡張方法を選択してください',
            //             '選択仕訳の拡張方法の選択',
            //             {
            //                 confirmButtonText: '伝票番号',
            //                 cancelButtonText: '最小番号',
            //                 type: 'info'
            //             }
            //         );
            //         expansionCriteria = 'voucherNumber';
            //     } catch (e) {
            //         expansionCriteria = 'minNumber'; // キャンセルされた場合は最小番号を選択したとみなす
            //     }
            // }

            const expandedRanges = [];
            const processedVouchers = new Set();

            for (let range of selectedRanges) {
                let startRowIndex = Math.min(range.startRow.rowIndex, range.endRow.rowIndex);
                let endRowIndex = Math.max(range.startRow.rowIndex, range.endRow.rowIndex);

                for (let i = startRowIndex; i <= endRowIndex; i++) {
                    const rowNode = this.gridOptions.api.getDisplayedRowAtIndex(i);
                    if (!rowNode || !rowNode.data) continue;
                    
                    const rowData = rowNode.data;
                    const voucherKey = expansionCriteria === 'voucherNumber' 
                        ? `${rowData['伝票日付']}_${rowData['伝票番号']}`
                        : `${rowData['伝票日付']}_${rowData['最小番号']}`;

                    if (!processedVouchers.has(voucherKey)) {
                        processedVouchers.add(voucherKey);

                        // 関連する行を見つける
                        const relatedRows = [];
                        this.gridOptions.api.forEachNode(node => {
                            if (!node || !node.data) return;
                            
                            if (expansionCriteria === 'voucherNumber') {
                                if (node.data['伝票日付'] === rowData['伝票日付'] && 
                                    node.data['伝票番号'] === rowData['伝票番号']) {
                                    relatedRows.push(node.rowIndex);
                                }
                            } else {
                                if (node.data['伝票日付'] === rowData['伝票日付'] && 
                                    node.data['最小番号'] === rowData['最小番号']) {
                                    relatedRows.push(node.rowIndex);
                                }
                            }
                        });

                        // 新しい範囲を作成
                        if (relatedRows.length > 0) {
                            expandedRanges.push({
                                startRow: { rowIndex: Math.min(...relatedRows) },
                                endRow: { rowIndex: Math.max(...relatedRows) },
                                columns: range.columns
                            });
                        }
                    }
                }
            }

            return expandedRanges.length > 0 ? expandedRanges : selectedRanges;
        },
        async deleteData() {
            this.userPermission = this.$store.state.userPermission.permission;
            this.userCd = this.$store.state.userPermission.user_cd;
             // 権限チェックを追加
             if (!['1_admin', '2_general'].includes(this.userPermission)) {
                alert('権限がありません');
                return;
            }

            let tableName = this.determineTable(this.activeTab);

            if (!this.gridOptions.api.getModel().getRowCount()) {
                return; // データが全くな場合はreturn
            }
            
            let selectedRanges = this.gridOptions.api.getCellRanges();
            if (!selectedRanges || !selectedRanges.length) {
                alert("セルが選択されていません");
                return;
            }

            // 仕訳テーブルの場合、選択範囲を拡張 同じ伝票日付・伝票番号のデータを選択範囲に含める
            if (tableName.includes('仕訳')) {
                selectedRanges = await this.expandSelectedRangesForJournal(selectedRanges, 'deleteData');
            }

            let idsToDelete = [];
            let infosToDelete = [];
            const hasBlankId = selectedRanges.some(range => {
                let startRowIndex = Math.min(range.startRow.rowIndex, range.endRow.rowIndex);
                let endRowIndex = Math.max(range.startRow.rowIndex, range.endRow.rowIndex);
                for (let i = startRowIndex; i <= endRowIndex; i++) {
                    const displayedRow = this.gridOptions.api.getDisplayedRowAtIndex(i);
                    if (!displayedRow) {
                        // この行は存在しないのでスキップ
                        continue;
                    }
                    const rowData = displayedRow.data;
                    if (!rowData) {
                        return true;
                    }
                    const id = rowData.id;  // 'id' はIDを格納している列のフィールド名
                    if (!id) {
                        alert("IDがブランクの行が選択されています");
                        return true;
                    }

                    // tableName に基づいた条件チェックを追加
                    if (tableName === '相手口座' && rowData['結合cd'] === '0|1') {
                        alert(`結合cd が 0|1 のレコードは変更できません。`);
                        return true;
                    }
                    if (tableName === '自社口座' && rowData['自社管理cd'] === 0) {
                        alert(`自社管理cd が 0 のレコードは変更できません。`);
                        return true;
                    }
                    if (tableName === '相手情報' && rowData['相手先cd'] === 0) {
                        alert(`相手先cd が 0 のレコードは変更できません。`);
                        return true;
                    }
                    if (tableName === '部門情報' && rowData['部門コード'] === 0) {
                        alert(`部門コードが 0 のレコードは変更できません。`);
                        return true;
                    }
                    if (tableName === 'PJ情報' && rowData['PJ_cd'] === 0) {
                        alert(`PJ_cd が 0 のレコードは変更できません。`);
                        return true;
                    }

                    idsToDelete.push(id);
                    if (tableName.includes('仕訳')) {
                        const ym = rowData['年月'];
                        if (!ym) {
                            alert("[年月]がブランクの行が選択されています");
                            return true;
                        }
                        infosToDelete.push(id+'_'+ym);
                    } 
                }
                return false;
            });
            // console.log('idsToDelete:',idsToDelete,'infosToDelete:',infosToDelete);

            if (hasBlankId) {
                return;
            }
            // ここでidsToDeleteを使ってデータベースにdelete文を送信する
            const confirmDelete = window.confirm('選択した行を削除しますか？');
            if (!confirmDelete) {
                // ユーザーが削除をキャンセルした場合
                return;
            }            
            
            if (['補助残高', '相手残高', 'PJ残高'].includes(tableName)) {
                const period = Object.values(this.targetPeriod).sort()[0];
                tableName = period + '_' + tableName;
                infosToDelete = idsToDelete;
            } else if (tableName.includes('仕訳')){   
                const periodsArray = Object.values(this.targetPeriod).sort();
                const period = periodsArray[periodsArray.length - 1];
                tableName = period + '_仕訳';
                // infosToDelete = infosToDelete;
            } else {
                infosToDelete = idsToDelete;
            }
            
            fetch('/api/delete_data.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    table: tableName,
                    rowIds: infosToDelete,
                    userCd: this.userCd,
                    userPermission: this.userPermission,
                }),
            })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    // 削除が成功した場合、データ表示から削除されたレコードをクリア
                    // 1. 現在の全行データを取得
                    const allRenderedNodes = this.gridOptions.api.getRenderedNodes();
                    const currentRowData = allRenderedNodes.map(node => node.data);
                    // 2. 削除されたIDを持つ行を除外
                    const updatedRowData = currentRowData.filter(row => !idsToDelete.includes(row.id));
                    // 3. 更新後のデータでAg-gridを更新
                    this.gridOptions.api.setRowData(updatedRowData);
                    alert('データが削除されました。');
                    // データの更新が成功したら MasterMapping を更新する
                    this.handleMasterDataUpdate()
                        .then(() => {
                            console.log('Master Mapping が更新されました。');
                        })
                        .catch(error => {
                            console.error('Master Mapping の更新に失敗しました:', error);
                    }); 
                } else {
                    alert(`データの削除に失敗しました。\n${data.message || ''}`);
                }
            })
            .catch(error => {
                alert('画面更新エラー:', error);
            });
        },
        isValidForServer(value, columnType, validation) {
            // 数値および小数点数値のバリデーション
            if (columnType === 'int' || columnType === 'decimal') {
                // 値がブランクの場合は、この時点でのバリデーションをパスさせる
                if (!value && validation !== 'required') {
                    return true;
                }
                let numericValue;
                if (typeof value === 'string') {
                    numericValue = parseFloat(value.replace(/,/g, ''));
                } else {
                    numericValue = value;
                }
                if (isNaN(numericValue)) {
                    console.log('Failed at numeric validation');
                    return false; // バリデーションに失敗した場合は false を返す
                }
            }
            // 日付バリデーション
            if (columnType === 'date' && !Date.parse(value)) {
                console.log('Failed at date validation');
                return false; // バリデーションに失敗した場合は false を返す
            }
            // 必須バリデーション
            if (validation === 'required' && 
                (typeof value !== 'string' && typeof value !== 'number' || 
                (value !== 0 && !value) || 
                (typeof value === 'string' && value.trim() === ''))) {
                console.log('Failed at required validation');
                return false; // バリデーションに失敗した場合は false を返す
            }
            return true; // バリデーションが成功した場合は true を返す
        },
        async approveExecute() {
            await this.approveOrReject('approve');
        },
        async rejectExecute() {
            await this.approveOrReject('reject');
        },
        async approveOrReject(approveType) {
            this.userPermission = this.$store.state.userPermission.permission;
             // 権限チェックを追加
             if (!['1_admin'].includes(this.userPermission)) {
                alert('権限がありません');
                return;
            }
            console.log('approveType:',approveType);
            if (!this.gridOptions.api.getModel().getRowCount()) {
                // データが全くない場合はreturn
                return;
            }
            let selectedRanges = this.gridOptions.api.getCellRanges();
            if (!selectedRanges || !selectedRanges.length) {
                alert("セルが選択されていません");
                return;
            }

            let tableName = this.determineTable(this.activeTab);

            // 仕訳テーブルの場合、選択範囲を拡張 同じ伝票日付・伝票番号のデータを選択範囲に含める
            if (tableName.includes('仕訳')) {
                selectedRanges = await this.expandSelectedRangesForJournal(selectedRanges, 'updateData');
            } else {
                return;
            }
            if (!tableName.includes('_')) {
                console.log('tableName:_が含まれてない',tableName);
                return; // '_' を含んでいない場合は処理を終了
            }
            const endYm = tableName.split('_')[0]*1;
            
            // fyMappingからendYmに対応するstartYmを取得
            const entry = this.fyMapping.find(item => item['エンド月'] === endYm);
            const startYm = entry ? entry['スタート月'] : null;
            console.log('startYm:',startYm,'endYm:',endYm);
            const updates = [];
            let exitFunction = false;
            
            for (let range of selectedRanges) {
                let startRowIndex = Math.min(range.startRow.rowIndex, range.endRow.rowIndex);
                let endRowIndex = Math.max(range.startRow.rowIndex, range.endRow.rowIndex);
                for (let i = startRowIndex; i <= endRowIndex; i++) {
                    const rowData = this.gridOptions.api.getDisplayedRowAtIndex(i).data;
                    const id = rowData.id;  // 'id' はIDを格納している列のフィールド名
                    if (!id) {
                        alert("IDがブランクの行が選択されています");
                        exitFunction = true;
                        break;
                    } else if (!rowData['status'].includes('申請')){
                        alert('承認対象ではない行が選択されています');
                        exitFunction = true;
                        break;
                    }
            
                    let updateData;
                    updateData = {
                        id: id,
                        status: rowData['status'],  // ステータス情報を追加
                        ym: rowData['年月']      // 年月情報を追加
                    };
                    updates.push({
                        id: id,
                        updatedValues: updateData,                        
                    });
                }
                if (exitFunction) break;
            }            
            if (exitFunction) return;  // フラグがセットされている場合、関数から抜け出します
            
            // ここで更新確認メッセージを表示
            const confirmUpdate = window.confirm('選択した行を'+approveType+'しますか？');
            if (!confirmUpdate) {
                // ユーザーが更新をキャンセルした場合
                return;
            }
            console.log('updates:',updates);
            fetch('/api/approve_or_reject.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    table: tableName,
                    updates: updates,
                    approveType: approveType,
                    userCd: this.userCd,
                }),
            })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    alert('データが'+approveType+'されました。');
                    
                } else {
                    alert(`データの${approveType}に失敗しました。\n${data.message || ''}`);
                }
            })
            .catch(error => {
                alert('画面更新エラー:', error);
            });

        },
        async updateData() {
            this.userPermission = this.$store.state.userPermission.permission;
            this.userCd = this.$store.state.userPermission.user_cd;
             // 権限チェックを追加
             if (!['1_admin', '2_general'].includes(this.userPermission)) {
                alert('登録権限がありません');
                return;
            }

            if (!this.gridOptions.api.getModel().getRowCount()) {
                // データが全くない場合はreturn
                return;
            }
            let selectedRanges = this.gridOptions.api.getCellRanges();
            if (!selectedRanges || !selectedRanges.length) {
                alert("セルが選択されていません");
                return;
            }

            let endYm = ''; let startYm = '';
            let tableName = this.determineTable(this.activeTab);

            // 仕訳テーブルの場合、選択範囲を拡張 同じ伝票日付・伝票番号のデータを選択範囲に含める
            if (tableName.includes('仕訳')) {
                selectedRanges = await this.expandSelectedRangesForJournal(selectedRanges, 'updateData');
            }
            
            if (['補助残高', '相手残高', 'PJ残高'].includes(tableName)) {
                const period = Object.values(this.targetPeriod).sort()[0];
                tableName = period + '_' + tableName;
            } else if (tableName.includes('仕訳')){  
                endYm = tableName.split('_')[0]*1;
                // startYm = this.fyMapping[endYm];
                // fyMappingからendYmに対応するstartYmを取得
                const entry = this.fyMapping.find(item => item['エンド月'] === endYm);
                const startYm = entry ? entry['スタート月'] : null;
                console.log('startYm:',startYm,'endYm:',endYm);
            }
            const updates = [];
            let exitFunction = false;
            let showConfirmOnce = true;
            let continueChecking = true;
            let allErrors = [];
            
            if (tableName.includes('仕訳')) {
                //相手科目を設定する処理
                const isVoucherValid = this.setCounterAccount(selectedRanges, this.gridOptions.api);
                if (!isVoucherValid) {
                    return; // 処理キャンセル
                }
            }

            for (let range of selectedRanges) {
                let startRowIndex = Math.min(range.startRow.rowIndex, range.endRow.rowIndex);
                let endRowIndex = Math.max(range.startRow.rowIndex, range.endRow.rowIndex);
                for (let i = startRowIndex; i <= endRowIndex; i++) {
                    const rowData = this.gridOptions.api.getDisplayedRowAtIndex(i).data;
                    const id = rowData.id;  // 'id' はIDを格納している列のフィールド名
                    if (!id) {
                        alert("IDがブランクの行が選択されています");
                        exitFunction = true;
                        break;
                    }
                    for (let colDef of this.gridOptions.columnDefs) {
                        const cellValue = rowData[colDef.field];
                        if (!this.isValidForServer(cellValue, colDef.columnType, colDef.validation)) {
                            alert(`エラー：列 "${colDef.field}" の値 "${cellValue}" が無効です。`);
                            exitFunction = true;
                            break;
                        }
                    }
                    if (exitFunction) break;

                    if (tableName.includes('仕訳')) {
                        let date = new Date(rowData['伝票日付']);
                        if (date.getFullYear() * 100 + (date.getMonth() + 1) !== rowData['年月']){
                            alert(`・月を跨ぐUpdateはできません。\n・月を跨ぐ変更は削除と登録を実行して下さい`);
                            exitFunction = true;
                        }
                        if (exitFunction) break;
                    }
                    
                    // ブランクの値をnullに変更
                    Object.keys(rowData).forEach(key => {
                        if (rowData[key] === "") {
                            rowData[key] = null;
                        }
                    });
                    
                    let updateData;
                    try {
                       updateData = this.insertCheck({...rowData}, tableName, startYm, endYm);
                    } catch (error) {
                        allErrors.push(`行 ${i + 1}: ${error.message}`);
                        if (continueChecking) {
                            if (showConfirmOnce) {
                            const userChoice = window.confirm(
                                `以下のエラーが見つかりました。残りのチェックを続けますか？\n${error.message}`
                            );

                            if (!userChoice) {
                                continueChecking = false;
                                exitFunction = true;
                                break;
                            } else {
                                showConfirmOnce = false;
                            }
                            }
                        }
                    }

                    updates.push({
                        id: id,
                        updatedValues: updateData
                    });
                }
                if (exitFunction) break;
            }            
            if (exitFunction) return;  // フラグがセットされている場合、関数から抜け出します
            
            // チェック完了後、エラーがある場合に一括表示
            if (allErrors.length > 0) {
                alert(`以下のエラーが見つかりました：\n\n${allErrors.join('\n')}`);
                return;
            }

            if (updates.length === 0) {
                alert("もう一度選択して処理を実行して下さい");
                return;
            }            
            // ここで更新確認メッセージを表示
            const confirmUpdate = window.confirm('選択した行を更新しますか？');
            if (!confirmUpdate) {
                // ユーザーが更新をキャンセルした場合
                return;
            }
            console.log('updates:',updates);
            fetch('/api/update_data.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    table: tableName,
                    updates: updates,
                    userCd: this.userCd,
                }),
            })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    alert('データが更新されました。');
                    // データの更新が成功したら MasterMapping を更新する
                    this.handleMasterDataUpdate()
                        .then(() => {
                            console.log('Master Mapping が更新されました。');
                        })
                        .catch(error => {
                            console.error('Master Mapping の更新に失敗しました:', error);
                    }); 
                    
                    if (tableName.includes('仕訳')){
                        this.dialogType = 'updateDisplay';
                        this.dialog = true;
                    }
                } else {
                    alert(`データの更新に失敗しました。\n${data.message || ''}`);
                }
            })
            .catch(error => {
                alert('画面更新エラー:', error);
            });
        },
        // 仕訳テーブルのレコードに対する相手科目の設定
        setCounterAccount(selectedRanges, gridOptionsApi) {
            let currentGroup = [];
            let currentTotalAmount = 0;
            let voucherTotals = {};
            let errorVouchers = [];

            for (let range of selectedRanges) {
                let startRowIndex = Math.min(range.startRow.rowIndex, range.endRow.rowIndex);
                let endRowIndex = Math.max(range.startRow.rowIndex, range.endRow.rowIndex);

                for (let i = startRowIndex; i <= endRowIndex; i++) {
                    const rowData = gridOptionsApi.getDisplayedRowAtIndex(i).data;

                    // '伝票日付' または '伝票番号' が空の場合、アラートを出して処理を中断
                    if (!rowData['伝票日付'] || !rowData['伝票番号']) {
                        alert(`エラー: 行 ${i + 1} の伝票日付または伝票番号が空です。`);
                        return false;
                    }

                    // '年月' がない場合、'伝票日付' から '年月' を生成
                    if (!rowData['年月']) {
                        const dateParts = rowData['伝票日付'].split('-');
                        rowData['年月'] = dateParts[0] + dateParts[1];  // 'yyyy-mm-dd' から 'yyyymm' をセット
                    }

                    const voucherKey = rowData['年月'] + '-' + rowData['伝票番号'];

                    if (!voucherTotals[voucherKey]) {
                        voucherTotals[voucherKey] = 0;
                    }

                    // 値が数値であることを確認し、空なら0として計算
                    const debitAmount = Number(rowData['借方税込']) || 0;
                    const creditAmount = Number(rowData['貸方税込']) || 0;

                    voucherTotals[voucherKey] += debitAmount - creditAmount;

                    currentTotalAmount += debitAmount - creditAmount;
                    currentGroup.push(rowData);

                    const isLastRowInRange = (i === endRowIndex);
                    const nextRow = isLastRowInRange ? null : gridOptionsApi.getDisplayedRowAtIndex(i + 1).data;
                    const willTotalChange = nextRow ? (currentTotalAmount + (Number(nextRow['借方税込']) || 0) - (Number(nextRow['貸方税込']) || 0) !== 0) : true;

                    // 税込金額の累計がゼロになり、かつ次のレコードで合計が変わる場合、または範囲の最後の行の場合、グループを処理
                    if (currentTotalAmount === 0 && willTotalChange || isLastRowInRange) {
                        if (currentGroup.length === 2) {
                            // 2行で合計がゼロの場合、相手科目を交換
                            currentGroup[0]['相手科目'] = currentGroup[1]['勘定及び補助'];
                            currentGroup[1]['相手科目'] = currentGroup[0]['勘定及び補助'];
                            for (let row of currentGroup) {
                                const matched_sub = row['相手科目'].match(/^(\d+)_.*?\|(\d+)_.*$/);
                                if (matched_sub) {
                                    const [, firstNumber, secondNumber] = matched_sub;
                                    // フォーマットして返す
                                    row['相手科目'] = `${firstNumber}.${secondNumber}`;
                                }   
                            }
                        } else {
                            // それ以外の場合は「諸口」を設定
                            currentGroup.forEach(row => row['相手科目'] = '諸口');
                        }
                        // グループと累計をリセット
                        currentGroup = [];
                        currentTotalAmount = 0;
                    }
                }
            }

            // 伝票番号ごとの合計がゼロでない場合のエラー確認
            for (const [key, total] of Object.entries(voucherTotals)) {
                if (Math.abs(total) > 0.0001) {  // 絶対値でゼロに近いかを確認
                    errorVouchers.push(key);
                }
            }

            if (errorVouchers.length > 0) {
                alert("エラー: 以下の伝票番号の合計がゼロではありません: " + errorVouchers.join(', '));
                return false; // 処理キャンセル
            }

            return true; // 処理続行
        },
        async insertData(insertType = null) {
            this.userPermission = this.$store.state.userPermission.permission;
            this.userCd = this.$store.state.userPermission.user_cd;
             // 権限チェックを追加
             if (!['1_admin', '2_general'].includes(this.userPermission)) {
                alert('登録権限がありません');
                return;
            }

            if (!this.gridOptions.api.getModel().getRowCount()) {
                // データが全くない場合はreturn
                return;
            }
            console.log('insertType:', insertType);
            let selectedRanges = this.gridOptions.api.getCellRanges();
            if (!selectedRanges || !selectedRanges.length) {
                alert("セルが選択されていません");
                return;
            }
            
            let endYm = ''; let startYm = '';
            let tableName = this.determineTable(this.activeTab);

            // 仕訳テーブルの場合、選択範囲を拡張 同じ伝票日付・伝票番号のデータを選択範囲に含める
            if (tableName.includes('仕訳')) {
                selectedRanges = await this.expandSelectedRangesForJournal(selectedRanges, 'insertData');
            }


            if (['補助残高', '相手残高', 'PJ残高'].includes(tableName)) {
                const period = Object.values(this.targetPeriod).sort()[0];
                tableName = period + '_' + tableName;
            } else if (tableName.includes('仕訳')){  
                endYm = tableName.split('_')[0]*1;
                // fyMappingからendYmに対応するstartYmを取得
                // startYm = this.fyMapping[endYm];
                const entry = this.fyMapping.find(item => item['エンド月'] === endYm);
                const startYm = entry ? entry['スタート月'] : null;
                
                console.log('startYm:',startYm,'endYm:',endYm);
            }
            const inserts = [];
            let exitFunction = false;
            let allErrors = [];
            let showConfirmOnce = true;
            let continueChecking = true;

            if (tableName.includes('仕訳')) {
                const isVoucherValid = this.setCounterAccount(selectedRanges, this.gridOptions.api);
                if (!isVoucherValid) {
                    return; // 処理キャンセル
                }
            }

            for (let range of selectedRanges) {
                let startRowIndex = Math.min(range.startRow.rowIndex, range.endRow.rowIndex);
                let endRowIndex = Math.max(range.startRow.rowIndex, range.endRow.rowIndex);
                for (let i = startRowIndex; i <= endRowIndex; i++) {
                    const rowData = this.gridOptions.api.getDisplayedRowAtIndex(i).data;
                    const id = rowData.id;
                    if (id) {
                        allErrors.push(`行 ${i + 1}: IDが設定されているレコードは登録済みです`);
                        if (!continueChecking) {
                            exitFunction = true;
                            break;
                        }
                    }
                    
                    let insertData = {...rowData};
                    delete insertData.id;
                    delete insertData.created;
                    delete insertData.modified;

                    // 空の文字列を null に変換
                    Object.keys(insertData).forEach(key => {
                        if (insertData[key] === "") {
                            insertData[key] = null;
                        }
                    });

                    try {
                        insertData = this.insertCheck(insertData, tableName, startYm, endYm);
                        for (let colDef of this.gridOptions.columnDefs) {
                            const cellValue = insertData[colDef.field];
                            if (colDef.validation !== 'readonly' && !this.isValidForServer(cellValue, colDef.columnType, colDef.validation)) {
                                throw new Error(`列 "${colDef.field}" の値 "${cellValue}" が無効です。`);
                            }
                        }
                        inserts.push(insertData);
                    } catch (error) {
                        allErrors.push(`行 ${i + 1}: ${error.message}`);
                        if (continueChecking) {
                            if (showConfirmOnce) {
                                const userChoice = window.confirm(
                                    `以下のエラーが見つかりました。残りのチェックを続けますか？\n${error.message}`
                                );

                                if (!userChoice) {
                                    continueChecking = false;
                                    exitFunction = true;
                                    break;
                                } else {
                                    showConfirmOnce = false;
                                }
                            }
                        }
                    }
                }
                if (exitFunction) break;
            }

            if (allErrors.length > 0) {
                alert(`以下のエラーが見つかりました：\n${allErrors.join('\n')}`);
                return;
            }

            if (inserts.length === 0) {
                alert("もう一度選択して処理を実行して下さい");
                return;
            }

            const confirmInsert = window.confirm('選択したデータを登録しますか？');
            if (!confirmInsert) {
                console.log('inserts:',inserts);
                return;
            }            
            fetch('/api/insert_data.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    table: tableName,
                    inserts: inserts,
                    insertType: insertType,
                }),
            })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    //console.log(`データが登録されました。登録件数: ${data.insertedCount}`);
                    alert(`データが登録されました。登録件数: ${data.insertedCount}`);
                    this.apJeVisible = false; this.arJeVisible = false; this.gensenJeVisible = false; this.menuVisible = true;

                    // データの更新が成功したら MasterMapping を更新する
                    this.handleMasterDataUpdate()
                    .then(() => {
                        console.log('Master Mapping が更新されました。');
                    })
                    .catch(error => {
                        console.error('Master Mapping の更新に失敗しました:', error);
                        alert(`Master Mapping の更新に失敗しました: ${error.message}`);
                    }); 

                    if (tableName.includes('仕訳')){
                        this.dialogType = 'updateDisplay';
                        this.dialog = true;
                    }
                } else {
                    alert(`データの登録に失敗しました。\n${data.message || 'エラーが発生しました。'}`);
                    this.menuVisible = true;
                }
            })
            .catch(error => {
                alert(`画面更新エラー: ${error.message}`);
                this.menuVisible = true;
            });
        },
        // Process を適用
        applyProcess(process, rowData, validationRules) {
            if (process.startsWith('split')) {
                console.log('process:', process);

                // 正規表現でマッチ
                const match = process.match(/split\(([^,]*),([^,]*),(\d+)\)/);
                if (match) {
                    const [, field, delimiter, index] = match;
                    console.log('field:', field);
                    console.log('delimiter:', delimiter);
                    console.log('index:', index);

                    // Validation のチェック
                    const validation = validationRules[field] || '';
                    const value = rowData[field];
                    if (
                        (!validation.includes('required')) &&
                        (value === null || value === undefined || String(value).trim() === '')
                    ) {
                        console.log(`Field "${field}" is not required and is empty. Skipping split process.`);
                        return value; // 値をそのまま返す
                    }

                    // field が空の場合はエラーをスロー
                    if (!field) {
                        throw new Error(`split の field が空です: ${process}`);
                    }

                    // split の適用
                    if (value === undefined || value === null) {
                        throw new Error(`split の対象フィールド "${field}" が存在しません: ${process}`);
                    }

                    console.log('value:', value);

                    // split 処理の前に delimiter をチェック
                    if (!String(value).includes(delimiter)) {
                        console.log(`Delimiter "${delimiter}" が値 "${value}" に含まれていません。split をスキップします。`);
                        return value; // 分割せずそのまま返す
                    }

                    const splitValues = String(value).split(delimiter);
                    console.log('splitValues:', splitValues);

                    if (parseInt(index) >= splitValues.length) {
                        console.log(`Index "${index}" が split の結果配列の範囲外です。`);
                        return value; // 配列範囲外の場合はそのまま返す
                    }

                    const result = splitValues[parseInt(index)];
                    console.log('String(value).split(delimiter)[parseInt(index)]:', result);
                    return result;
                } else {
                    throw new Error(`split の process フォーマットが不正です: ${process}`);
                }
            } else if (process.startsWith('concat')) {
                console.log('process:', process);
                const match = process.match(/concat\((.+)\)/);
                if (match) {
                    const fields = match[1].split(',').map((field) => field.trim());
                    console.log('fields:', fields);

                    return fields
                    .map((field) => {
                        const validation = validationRules[field] || '';
                        const value = rowData[field];

                        // フィールドが結合文字列の場合
                        if (field.length === 1) {
                            return field; // 1文字なら結合文字列とみなす
                        }

                        // Validation のチェック
                        if (
                            (!validation.includes('required')) &&
                            (value === null || value === undefined || String(value).trim() === '')
                        ) {
                            console.log(`Field "${field}" is not required and is empty. Skipping concat process.`);
                            return ''; // 空文字を返す
                        }

                        // フィールドがデータの場合
                        if (field in rowData) {
                            const stringValue = value !== undefined && value !== null ? String(value) : '';
                            
                            // 特殊文字のチェック（例外ケースを追加）
                            if (
                                field !== '勘定情報' && field !== '補助情報' &&
                                (stringValue.includes('_') || stringValue.includes('|') || stringValue.includes(':'))
                            ) {
                                throw new Error(`フィールド "${field}" の値に "_", "|", ":" を含めることはできません`);
                            }
                            
                            return stringValue;
                        } else {
                            throw new Error(`フィールド "${field}" が見つかりません`);
                        }
                    })
                    .join('');

                } else {
                    throw new Error(`concat の process フォーマットが不正です: ${process}`);
                }
            }
        },

        applyMatch(mapping, cellValue, validationRules) {
            const [mappingName, field] = mapping.match(/(.*)\((.*)\)/).slice(1);
            const mappingData = this.$store.state[mappingName]; // マッピングデータを取得
            console.log('mappingData:', mappingData);
            console.log('field:', field);
            console.log('cellValue:', cellValue);

            // Validation のチェック
            const validation = validationRules[field] || '';
            if (
                (!validation.includes('required')) &&
                (cellValue === null || cellValue === undefined || String(cellValue).trim() === '')
            ) {
                console.log(`Field "${field}" is not required and is empty. Skipping match process.`);
                return true; // 成功扱いとしてスキップ
            }

            if (!mappingData) {
                throw new Error(`マッピング "${mappingName}" が見つかりません`);
            }

            // Mapping データに一致するかチェック
            const isValid = mappingData.some((item) => String(item[field]) === String(cellValue));
            if (!isValid) {
                throw new Error(`列 "${field}" の値 "${cellValue}" が mapping 条件 "${mapping}" に一致しません`);
            }
            return true;
        },

        insertCheck(rowData, tableName, startYm, endYm, cache = null) {
            this.previousFyYm = this.$store.state.previousFyYm;
            this.userPermission = this.$store.state.userPermission.permission;
            this.userCd = this.$store.state.userPermission.user_cd;

            // キャッシュの初期化
            if (!cache) {
                cache = {};
            }

            if (rowData['口座名称FB'] != null && rowData['口座名称FB'] !== '') {
                rowData['口座名称FB'] = this.convYSokuon(rowData['口座名称FB']);
            }

            if (tableName !== '補助科目' && rowData['勘定及び補助'] != null && rowData['勘定及び補助'] !== '' && !rowData['勘定及び補助'].includes(':')) {
                console.log('rowData[勘定及び補助]_org', rowData['勘定及び補助']);
                const matched_sub = rowData['勘定及び補助'].match(/^(\d+)_.*?\|(\d+)_.*$/);
                if (matched_sub) {
                    const [, firstNumber, secondNumber] = matched_sub;
                    // フォーマットして返す
                    rowData['勘定及び補助'] = `${firstNumber}.${secondNumber}`;
                    console.log('rowData[勘定及び補助]_new', rowData['勘定及び補助']);
                } else {
                    throw new Error('[勘定及び補助]の入力形式が正しくありません');
                }
            }

            // キャッシュに sortedColumnDefs がない場合にのみ計算
            if (!cache.sortedColumnDefs) {
                const columnDefs = this.gridOptions.columnDefs;

                // columnCd に基づいてソート
                cache.sortedColumnDefs = [...columnDefs].sort((a, b) => {
                    const columnCdA = a.columnCd || 0;
                    const columnCdB = b.columnCd || 0;
                    return columnCdA - columnCdB;
                });

                // 事前計算されたプロセス関数と validation のキャッシュ
                cache.processFunctions = {};
                cache.validationRules = {};
                cache.sortedColumnDefs.forEach((colDef) => {
                    if (colDef.process) {
                        cache.processFunctions[colDef.field] = (row) => this.applyProcess(colDef.process, row, cache.validationRules);
                    }
                    if (colDef.validation) {
                        cache.validationRules[colDef.field] = colDef.validation;
                    }
                });
            }

            const sortedColumnDefs = cache.sortedColumnDefs;
            const processFunctions = cache.processFunctions;
            const validationRules = cache.validationRules;

            const errors = [];

            // 後でエラーチェック(①毎回、継続するか、最後までやるかの選択をさせる、②Bankは初回のみ該当銀行データをDLしておく)
            delete rowData['cachedCd']; // cachされたデータを削除
            delete rowData['tmpリスト']; // cachされたデータを削除

            // 各カラムのチェック
            sortedColumnDefs.forEach((colDef) => {
                const { field, process, mapping } = colDef;
                let cellValue = rowData[field];

                // Process の適用（キャッシュを使用）
                if (process && processFunctions[field]) {
                    try {
                        cellValue = processFunctions[field](rowData);
                        rowData[field] = cellValue; // Process 結果を上書き
                    } catch (error) {
                        errors.push(`列 "${field}" の process 処理中にエラー: ${error.message}`);
                    }
                }

                // Mapping の適用
                if (mapping) {
                    try {
                        this.applyMatch(mapping, cellValue, validationRules);
                    } catch (error) {
                        errors.push(error.message);
                    }
                }
            });

            if (errors.length > 0) {
                throw new Error(errors.join('\n'));
            }
            
            rowData['更新者'] = this.userCd;

            if (tableName == 'user') {
                const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                if (rowData['email'] && !emailRegex.test(rowData['email'])) {
                    throw new Error('メールアドレスの形式が正しくありません');
                }

                // 権限のチェック
                const validPermissions = ['1_admin', '2_general', '3_viewer', '9_non-active'];
                if (!validPermissions.includes(rowData['権限'])) {
                    throw new Error('権限は 1_admin, 2_general, 3_viewer, 9_non-active のいずれかを指定してください');
                }
    
            } else if (tableName === '企業情報') {
                // エンド月とスタート月の差を計算する関数
                const calculateMonthDiff = (endYm, startYm) => {
                    const endYear = Math.floor(endYm / 100);
                    const endMonth = endYm % 100;
                    const startYear = Math.floor(startYm / 100);
                    const startMonth = startYm % 100;
                    
                    return (endYear - startYear) * 12 + (endMonth - startMonth);
                };

                // エンド月とスタート月が存在する場合にチェック
                if (rowData['エンド月'] && rowData['スタート月']) {
                    const monthDiff = calculateMonthDiff(rowData['エンド月'], rowData['スタート月']);
                    
                    // 12ヶ月を超えているかチェック
                    if (monthDiff > 12) {
                        throw new Error(`エンド月とスタート月の差が12ヶ月を超えています（現在: ${monthDiff}ヶ月）`);
                    }
                    
                    // 決算月数と一致しているかチェック
                    if (monthDiff !== rowData['決算月数']) {
                        throw new Error(`エンド月とスタート月の差（${monthDiff}ヶ月）が決算月数（${rowData['決算月数']}ヶ月）と一致しません`);
                    }
                }

            } else if (tableName === '相手口座') {
                if (rowData['結合cd'] === '0|0') {
                    throw new Error(`結合cd が 0|0 のレコードは変更できません。`);
                }
            
            } else if (tableName === '自社口座') {
                if (rowData['自社管理cd'] === 0) {
                    throw new Error(`自社管理cd が 0 のレコードは変更できません。`);
                }
            
            } else if (tableName === '部門情報') {
                if (rowData['部門コード'] === 0) {
                    throw new Error(`部門コードが 0 のレコードは変更できません。`);
                }
                
            } else if (tableName === '税コード') {
                // 税率が0より大きい場合のエラーチェック
                if (rowData['税率'] > 0) {
                    // 内容に "課税売" または "課税仕" が含まれていない場合
                    if (!rowData['内容'].includes("課税売") && !rowData['内容'].includes("課税仕")) {
                        throw new Error("税率が0より大きい場合は、内容に'課税売'又は'課税仕'を含めて下さい");
                    }
                }
                
            } else if (tableName === '相手情報') {
                if (rowData['相手先cd'] === 0) {
                    throw new Error(`相手先cd が 0 のレコードは変更できません。`);
                }
                
            } else  if (tableName === 'PJ情報') {
                if (rowData['PJ_cd'] === 0) {
                    throw new Error(`PJ_cd が 0 のレコードは変更できません。`);
                }   
                
            } else if (tableName.includes('補助残高')) {
                if (Number(rowData['年月']) !== Number(this.previousFyYm)) {
                    throw new Error(`年月 "${rowData['年月']}" は前期の年月 "${this.previousFyYm}" と一致しません。`);
                } else if (rowData['勘定及び補助']) {
                    // accMapping 配列に '結合' プロパティが rowData['勘定情報'] と一致するエントリが存在するか確認 && rowData['勘定及び補助'].includes('.')
                    const matched_acc = this.accMapping.find(item => item['勘定コード'] == rowData['勘定及び補助'].split('.')[0]);
                    if (matched_acc) {
                        rowData['勘定情報'] = matched_acc['結合'];
                        rowData['表示区分'] = matched_acc['表示区分'];
                    } else {
                        console.log('該当する勘定コードが見つかりません');
                    }
                }
                
            } else if (tableName.includes('相手残高') || tableName.includes('PJ残高')) {
                if (Number(rowData['年月']) !== Number(this.previousFyYm)) {
                    throw new Error(`年月 "${rowData['年月']}" は前期の年月 "${this.previousFyYm}" と一致しません。`);
                } else if (rowData['勘定情報']) {
                    const matched_acc = this.accMapping.find(item => item['結合'] == rowData['勘定情報']);
                    if (matched_acc) {
                        rowData['表示区分'] = matched_acc['表示区分'];
                    } else {
                        console.log('該当する表示区分が見つかりません');
                    }
                } 
                
            } else if (tableName.includes('仕訳')) {
                // 「管理cd」と「仕訳名称」カラムを削除（定型処理の項目で不要）
                delete rowData['管理cd'];
                delete rowData['仕訳名称'];

                try {
                    if (rowData['勘定及び補助']) {
                        const matched_acc = this.accMapping.find(item => item['勘定コード'] == rowData['勘定及び補助'].split('.')[0]);
                        if (matched_acc) {
                            rowData['勘定情報'] = matched_acc['結合'];
                            rowData['表示区分'] = matched_acc['表示区分'];
                        } else {
                            console.log('該当する勘定コードが見つかりません');
                        }
                    }

                    if (rowData['振込情報'] && rowData['振込情報'] === '' && rowData['振込情報'] === null) {
                        rowData['振込情報'] = '0|0';
                    }

                    if (rowData['源泉請求'] && rowData['源泉請求'] === '' || rowData['源泉請求'] == null) {
                        rowData['源泉請求'] = '0';
                    }
                    if (rowData['消込ck'] && rowData['消込ck'] === '' || rowData['消込ck'] == null) {
                        rowData['消込ck'] = 'na';
                    }

                    if (this.userPermission === '1_admin') {
                        rowData['status'] = '承認完了';
                    } else if (this.userPermission === '2_general') {
                        rowData['status'] = '登録申請';
                    }
                    // 税コードに対応する税率を取得
                    if (rowData['税コード']) {
                        console.log('税コード:', rowData['税コード']);
                        const taxEntry = this.taxMapping.find(item => item['Taxコード'] == rowData['税コード']);
                        const taxRate = taxEntry ? parseFloat(taxEntry['税率']) : null;

                        if (taxRate == null) {
                            throw new Error(`税コード "${rowData['税コード']}" に対応する税率が見つかりません。`);
                        }
                        if (Math.abs(rowData['借方税込']) > 0) {
                        rowData['借方税額'] = Math.round(rowData['借方税込'] / (1 + taxRate / 100) * taxRate / 100);
                        // 借方税額が不正な場合にエラーをスロー
                        if (rowData['借方税額'] === '' || rowData['借方税額'] == null || isNaN(rowData['借方税額'])) {
                            throw new Error("[借方税額]が不正です");
                        }
                        rowData['貸方税込'] = 0; rowData['貸方税額'] = 0;

                        } else if (Math.abs(rowData['貸方税込']) > 0) {
                            rowData['貸方税額'] = Math.round(rowData['貸方税込'] / (1 + taxRate / 100) * taxRate / 100);
                            if (rowData['貸方税額'] === '' || rowData['貸方税額'] == null || isNaN(rowData['貸方税額'])) {
                                throw new Error("[貸方税額]が不正です");
                            }
                            rowData['借方税込'] = 0; rowData['借方税額'] = 0;
                        }
                    }
                    
                    // [年月]列の設定
                    const date = new Date(rowData['伝票日付']);
                    rowData['年月'] = date.getFullYear() * 100 + (date.getMonth() + 1);
                    // [税額]列の計算
                    rowData['税額'] = (rowData['借方税額'] || 0) - (rowData['貸方税額'] || 0);
                    // [税込金額]列の計算
                    rowData['税込金額'] = (rowData['借方税込'] || 0) - (rowData['貸方税込'] || 0);
                    // [税抜金額]列の計算
                    rowData['税抜金額'] = rowData['税込金額'] - rowData['税額'];                

                    if (rowData['年月'] < startYm || rowData['年月'] > endYm) {
                        throw new Error("[年月]が不正です");
                    }
                    if (rowData['予定日'] === '' || rowData['予定日'] == null || rowData['予定日'] === 'na') {
                        rowData['予定日'] = 'na';
                    } else {
                        const formattedDate = this.isDateForAp(rowData['予定日']);
                        if (formattedDate) {
                            rowData['予定日'] = formattedDate; // yyyy-mm-dd 形式に更新
                        } else {
                            console.log('予定日:', rowData['予定日']);
                            throw new Error(`[予定日]が日付ではありません: ${rowData['予定日']}`);
                        }
                    }
                    if (rowData['予定日'] !== 'na' && rowData['表示区分'].split('_')[0] * 1 > 20) {
                        // BS項目以外の予定日は強制的にnaにする
                        rowData['予定日'] = 'na';
                    }

                } catch (error) {
                    console.error(`カラムチェック中にエラーが発生しました: ${error.message}`);
                    throw error;
                }
            }
            return rowData;

        },
        convYSokuon(str) {
            const kanaMap = {
                'ア': 'ｱ', 'イ': 'ｲ', 'ウ': 'ｳ', 'エ': 'ｴ', 'オ': 'ｵ',
                'カ': 'ｶ', 'キ': 'ｷ', 'ク': 'ｸ', 'ケ': 'ｹ', 'コ': 'ｺ',
                'サ': 'ｻ', 'シ': 'ｼ', 'ス': 'ｽ', 'セ': 'ｾ', 'ソ': 'ｿ',
                'タ': 'ﾀ', 'チ': 'ﾁ', 'ツ': 'ﾂ', 'テ': 'ﾃ', 'ト': 'ﾄ',
                'ナ': 'ﾅ', 'ニ': 'ﾆ', 'ヌ': 'ﾇ', 'ネ': 'ﾈ', 'ノ': 'ﾉ',
                'ハ': 'ﾊ', 'ヒ': 'ﾋ', 'フ': 'ﾌ', 'ヘ': 'ﾍ', 'ホ': 'ﾎ',
                'マ': 'ﾏ', 'ミ': 'ﾐ', 'ム': 'ﾑ', 'メ': 'ﾒ', 'モ': 'ﾓ',
                'ヤ': 'ﾔ', 'ユ': 'ﾕ', 'ヨ': 'ﾖ',
                'ラ': 'ﾗ', 'リ': 'ﾘ', 'ル': 'ﾙ', 'レ': 'ﾚ', 'ロ': 'ﾛ',
                'ワ': 'ﾜ', 'ヲ': 'ｦ', 'ン': 'ﾝ',
                'ガ': 'ｶﾞ', 'ギ': 'ｷﾞ', 'グ': 'ｸﾞ', 'ゲ': 'ｹﾞ', 'ゴ': 'ｺﾞ',
                'ザ': 'ｻﾞ', 'ジ': 'ｼﾞ', 'ズ': 'ｽﾞ', 'ゼ': 'ｾﾞ', 'ゾ': 'ｿﾞ',
                'ダ': 'ﾀﾞ', 'ヂ': 'ﾁﾞ', 'ヅ': 'ﾂﾞ', 'デ': 'ﾃﾞ', 'ド': 'ﾄﾞ',
                'バ': 'ﾊﾞ', 'ビ': 'ﾋﾞ', 'ブ': 'ﾌﾞ', 'ベ': 'ﾍﾞ', 'ボ': 'ﾎﾞ',
                'パ': 'ﾊﾟ', 'ピ': 'ﾋﾟ', 'プ': 'ﾌﾟ', 'ペ': 'ﾍﾟ', 'ポ': 'ﾎﾟ',
                'ャ': 'ﾔ', 'ュ': 'ﾕ', 'ョ': 'ﾖ','）': ')', '（': '(',
                'ッ': 'ﾂ', 'ー': 'ｰ', 'ヴ': 'ｳﾞ', 
                // ｧ、ｨ、ｩ、ｪ、ｫに該当するかチェック
                'ｧ': 'ｱ', 'ｨ': 'ｲ', 'ｩ': 'ｳ', 'ｪ': 'ｴ', 'ｫ': 'ｵ',
                // ... 他の特殊なカタカナ文字も必要に応じて追加 ...
            };
            return str.split('').map(char => kanaMap[char] || char).join('');
        },
        clearFilters() {
            this.gridOptions.api.setFilterModel(null);
        },
        executeAction(action) {
            switch(action) {
                // case 'DLﾒﾆｭｰ': this.fetchData(); break;
                case 'DLﾒﾆｭｰ': 
                    switch (this.activeTab) {
                        case '勘定TB(発生)': case '補助TB(発生)': case '相手TB(発生)': case 'PJTB(発生)':
                        case '勘定TB(残高)': case '補助TB(残高)': case '相手TB(残高)': case 'PJTB(残高)':
                        case '消費税ck画面': case '債権推移': case '債務推移':case 'PJ分析': case '部門PL':   
                            this.togglePeriodList();
                            break;
                        case '仕訳変更log': case '仕訳承認log': case 'マスタ変更log':
                            this.toggleSearchList();
                            break;
                        case '債権画面': case '債務画面':case '源泉画面':
                            this.toggleArApList();
                            break;
                        default: 
                            if (this.activeTab.includes('元帳')) { 
                                this.togglePeriodList();
                            } else {
                                this.toggleSearchList();
                            }
                    }
                    break;
                case '登録実行': this.insertData();break;
                case 'importﾌｫｰﾑ': this.importFormOut();break;
                case 'import実行': this.importData();break;
                case '他ｿﾌﾄから転記': this.importFromOther();break;
                case '更新実行': this.updateData(); break;
                case '削除実行': this.deleteData(); break;
                case '表示ｸﾘｱ': this.clearData(); break;
                case '行の追加': this.addNewRow(); break;
                case '承認実行': this.approveExecute(); break;
                case '差戻実行': this.rejectExecute(); break;
                case '最終更新': this.lastUpdated(); break;
                case 'ﾌｨﾙﾀｸﾘｱ': this.clearFilters(); break;
                case '表示切替': this.openDisplaySwitch(); break;
                case 'ﾄﾞﾘﾙﾀﾞｳﾝ': this.downloadDrilldownData(); break;
                case '元帳表示': this.openLedgerDisplay(); break;
                case '加工可能にする': this.jeRowIdClear(); break;
                case '行ｸﾘｱ': this.jeRowClear(); break;
                case 'pivot切替': this.changePivotMode(); break;
                case '列固定切替': this.togglePinColumn(); break;
                case 'sideBarClear': this.sideBarFilterClear(); break;
                case '列幅Fit': this.autoSizeAllColumns(); break;
                case '消込仕訳作成': this.createArJe(); break;
                case '支払仕訳作成': this.createApJe(); break;
                case 'FBデータ作成': this.createFbData(); break;
                case '源泉仕訳作成': this.createGensenJe(); break;
                case '対象仕訳呼出': this.showApArGensenJe(); break;
                case '消費税仕訳呼出': this.showCtaxJe(); break;
                case '消込ck変更': this.modifyClearing();break;
                case '入力リスト更新': this.updateMasterData();break;
                case '空白セル更新': this.updateBlankCells();break;
                case '仕訳ログ呼出': this.jeLogDownload();break;
                case 'マスタログ呼出': this.masterLogDownload();break;
                case 'ログ差分出力': this.LogDiffDownload();break;
                case '選択行のみ表示': this.showSelectRowOnly();break;
                case '検索項目追加': this.addSearchItem(); break;
                default: console.log('未知のアクション:', action);
            }
        },
        async fetchTmpJe(tmpJeKey) {
            // データが既に存在する場合は、ユーザーに確認
            if (this.rowData && this.rowData.length > 0 && this.rowData.some(row => Object.keys(row).length > 0)) { // データが存在するか確認
                const userConfirmed = confirm('既存の表示データが消えますが、実行しますか？');
                if (!userConfirmed) {
                return; // ユーザーがキャンセルした場合は処理を中止
                }
            }

            let tableQueries = [];
            const tableName = '定型処理';
            const conditions = [{
                    operator: "AND",
                    terms: [{
                    column: "仕訳名称",
                    operator: "LIKE",
                    value: "%" + tmpJeKey + "%",
                    dataType: "varchar"
                }]
            }];
            tableQueries.push({ tableName, conditions });

            try {
                const response = await fetch('/api/search_data.php', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ tableQueries })
                });

                if (!response.ok) {
                throw new Error('Network response was not ok ' + response.statusText);
                }

                let data = await response.json();

                // ID列をクリアする処理
                data.forEach(row => {
                    if (row.id !== undefined) {
                        row.id = null; // ID列の値を消す
                    }
                });

                this.rowData = data; // 新しいデータをセット

                // 簡易入力画面の表示条件をチェック
                if (data.length === 2) {
                    this.showSimpleInput = true;
                    this.initializeSimpleInput(data);
                } else {
                    this.showSimpleInput = false;
                }

                this.$nextTick(() => {this.autoSizeAllColumns();});

                const rowStyleFunction = this.applyRowStyleForData(tableName);
                if (rowStyleFunction) {
                this.gridOptions.getRowStyle = rowStyleFunction;
                }

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },
        initializeSimpleInput(data) {
            // 借方データを使用して簡易入力フォームを初期化
            const debitRow = data[0];
            this.simpleInput = {
                伝票日付: debitRow.伝票日付 || '',
                // 税込金額: debitRow.借方税込 || '',
                税込金額: 0,
                相手情報: debitRow.相手情報 || '',
            };
        },

        confirmSimpleInput() {
            // 税込金額のチェック
            if (!this.simpleInput.税込金額 || parseFloat(this.simpleInput.税込金額) === 0) {
                alert('税込金額を入力してください。');
                return; // 処理をキャンセル
            }

            // 簡易入力画面の値を仕訳データに反映
            if (this.rowData.length === 2) {
                const [debitRow, creditRow] = this.rowData;
                const taxIncludedAmount = parseFloat(this.simpleInput.税込金額);

                // 借方行の更新
                debitRow.伝票日付 = this.simpleInput.伝票日付;
                debitRow.借方税込 = taxIncludedAmount;
                debitRow.借方税額 = 0;
                debitRow.貸方税額 = 0;
                debitRow.相手情報 = this.simpleInput.相手情報;
                
                // 貸方行の更新
                creditRow.伝票日付 = this.simpleInput.伝票日付;
                creditRow.貸方税込 = taxIncludedAmount;
                creditRow.借方税額 = 0;
                creditRow.貸方税額 = 0;
                creditRow.相手情報 = this.simpleInput.相手情報;
                
                this.gridApi.setRowData(this.rowData);
                this.showSimpleInput = false;
            }
        },
        cancelSimpleInput() {
            this.showSimpleInput = false;
        },
        async updateBlankCells() {
            const selectedRanges = this.gridOptions.api.getCellRanges();
            if (!selectedRanges || !selectedRanges.length) {
                alert("セルが選択されていません");
                return;
            }

            // 対象とする列のキーリスト
            const targetColumns = ['伝票日付', '伝票番号', '部門情報', '税コード', '摘要', 'PJ情報', '相手情報'];

            // 選択範囲の処理
            for (let range of selectedRanges) {
                let startRowIndex = Math.min(range.startRow.rowIndex, range.endRow.rowIndex);
                let endRowIndex = Math.max(range.startRow.rowIndex, range.endRow.rowIndex);

                for (let i = startRowIndex + 1; i <= endRowIndex; i++) {  // 2行目以降を処理するため、startRowIndex + 1から始める
                    const currentRowData = this.gridOptions.api.getDisplayedRowAtIndex(i).data;
                    const previousRowData = this.gridOptions.api.getDisplayedRowAtIndex(i - 1).data;

                    // 対象となる各カラムの値がブランクかどうかを確認し、ブランクなら上の行の値をコピーする
                    for (let columnKey of targetColumns) {
                        const currentCellValue = currentRowData[columnKey];
                        const previousCellValue = previousRowData[columnKey];

                        // currentCellValueがnull/undefinedか、空文字列なら、上の行の値をコピーする
                        if (currentCellValue == null || (typeof currentCellValue === 'string' && currentCellValue.trim() === '')) {
                            currentRowData[columnKey] = previousCellValue;
                        }
                    }

                    // 更新された行データをグリッドに反映
                    this.gridOptions.api.getDisplayedRowAtIndex(i).setData(currentRowData);
                }
            }

            // グリッドの再描画
            this.gridOptions.api.refreshCells();
            // 行スタイルを適用
            // const rowStyleFunction = this.applyRowStyleForData(this.activeTab);
            // if (rowStyleFunction) {
            //     this.gridOptions.getRowStyle = rowStyleFunction;
            // }
            // ここでgetRowStyleを設定する (罫線を引く)
            this.gridOptions.getRowStyle = function(params) {
                if (params.node.rowIndex === 0) {
                    return {};
                }
                let currentVoucherNo = params.data.伝票番号;
                let currentVoucherDate = params.data.伝票日付;
                
                let previousNode = params.api.getDisplayedRowAtIndex(params.node.rowIndex - 1);
                if (!previousNode) {
                    return {};
                }
                
                let previousVoucherNo = previousNode.data.伝票番号;
                let previousVoucherDate = previousNode.data.伝票日付;

                // 伝票番号と伝票日付の両方が異なる場合に罫線を引く
                if (currentVoucherNo !== previousVoucherNo || currentVoucherDate !== previousVoucherDate) {
                    return { 'border-top': '1.5px solid black' };
                }
                return {};
            };
            // グリッドをリフレッシュしてスタイルを適用
            this.gridApi.redrawRows();
        },
        async showApArGensenJe(){            
            let tableQueries = [];
            let conditionsByYear = {};
            const searchType = '最小番号';
            
            const selectedRanges = this.gridOptions.api.getCellRanges();
            if (!selectedRanges || !selectedRanges.length) {alert("セルが選択されていません"); return;}

            for (let range of selectedRanges) {
                let startRowIndex = Math.min(range.startRow.rowIndex, range.endRow.rowIndex);
                let endRowIndex = Math.max(range.startRow.rowIndex, range.endRow.rowIndex);
                for (let i = startRowIndex; i <= endRowIndex; i++) {
                    const rowData = this.gridOptions.api.getDisplayedRowAtIndex(i).data;
                    const fiscalYearEnd = this.getFiscalYearEnd(rowData.年月);
                    if (!conditionsByYear[fiscalYearEnd]) {
                        conditionsByYear[fiscalYearEnd] = { terms: [] };
                    }
                    conditionsByYear[fiscalYearEnd].terms.push({ column: "id", operator: "=", value: rowData.id.toString(), dataType: "int" });
                }
            }

            this.targetPeriod.sort().forEach(period => {
                const periodTableName = `${period}_仕訳`;
                const conditions = conditionsByYear[period];
                if (conditions) {
                    tableQueries.push({ tableName: periodTableName, searchType, conditions: [{ operator: "OR", terms: conditions.terms }] });
                }
            });

            let queries = ['呼出仕訳'];
            let tabType = '仕訳画面';
            this.$store.commit('SET_QUERIES', queries); // Vuex ストアに queries を保存
            this.$store.commit('SET_TABLEQUERIES', tableQueries); // Vuex ストアに tableQueries を保存

            const tabs = this.$store.state.allTabs; // 既存のタブ一覧を取得
            const maxNum = this.findMaxTabNumber(tabType, tabs);
            
            let newTabName = tabType;
            if (maxNum > 0 || tabs.some(tab => tab.name === tabType)) {
                // 新しいタブ名を生成（例: 仕訳画面01, 仕訳画面02, ...)
                newTabName = `${tabType}${(maxNum + 1).toString().padStart(2, '0')}`;
            }

            await this.$store.dispatch('addTab', newTabName);
            await this.$store.commit('SET_ACTIVE_TAB', { id: newTabName, name: newTabName });
            await this.$nextTick();
            
        },
        async showCtaxJe(){            
            let tableQueries = [];
            let conditionsByYear = {};
            const searchType = '最小番号';
            
            const selectedRanges = this.gridOptions.api.getCellRanges();
            if (!selectedRanges || !selectedRanges.length) {alert("セルが選択されていません"); return;}

            for (let range of selectedRanges) {
                let startRowIndex = Math.min(range.startRow.rowIndex, range.endRow.rowIndex);
                let endRowIndex = Math.max(range.startRow.rowIndex, range.endRow.rowIndex);
                
                // 期間選択スライダーから選択された年月を取得
                const selectedPeriod = this.tickLabels[this.selectedPeriodIndex];
                if (!selectedPeriod) {
                    console.error('期間が選択されていません');
                    return;
                }

                for (let i = startRowIndex; i <= endRowIndex; i++) {
                    const rowData = this.gridOptions.api.getDisplayedRowAtIndex(i).data;
                    if (!rowData) continue;

                    const fiscalYearEnd = this.getFiscalYearEnd(selectedPeriod); // 選択された期間から会計年度を取得
                    if (!conditionsByYear[fiscalYearEnd]) {
                        conditionsByYear[fiscalYearEnd] = { terms: [] };
                    }
                    
                    // 必要なデータが存在するかチェック
                    if (rowData.勘定及び補助 && rowData.税コード) {
                        // 条件を配列として追加（年月は期間選択から取得）
                        conditionsByYear[fiscalYearEnd].terms.push(
                            { column: "年月", operator: "<=", value: selectedPeriod.toString(), dataType: "int" },
                            { column: "勘定及び補助", operator: "=", value: rowData.勘定及び補助, dataType: "varchar" },
                            { column: "税コード", operator: "=", value: rowData.税コード, dataType: "varchar" }
                        );
                    } else {
                        console.warn('必要なデータが不足しています:', rowData);
                    }
                }
            }

            this.targetPeriod.sort().forEach(period => {
                const periodTableName = `${period}_仕訳`;
                const conditions = conditionsByYear[period];
                if (conditions) {
                    tableQueries.push({ tableName: periodTableName, searchType, conditions: [{ operator: "AND", terms: conditions.terms }] });
                }
            });

            let queries = ['呼出仕訳'];
            let tabType = '仕訳画面';
            this.$store.commit('SET_QUERIES', queries); // Vuex ストアに queries を保存
            this.$store.commit('SET_TABLEQUERIES', tableQueries); // Vuex ストアに tableQueries を保存

            const tabs = this.$store.state.allTabs; // 既存のタブ一覧を取得
            const maxNum = this.findMaxTabNumber(tabType, tabs);
            
            let newTabName = tabType;
            if (maxNum > 0 || tabs.some(tab => tab.name === tabType)) {
                // 新しいタブ名を生成（例: 仕訳画面01, 仕訳画面02, ...)
                newTabName = `${tabType}${(maxNum + 1).toString().padStart(2, '0')}`;
            }

            await this.$store.dispatch('addTab', newTabName);
            await this.$store.commit('SET_ACTIVE_TAB', { id: newTabName, name: newTabName });
            await this.$nextTick();
            
        },
        modifyClearing() {
            const selectedRanges = this.gridOptions.api.getCellRanges();
            const userInput = prompt('日付(yyyy-mm-dd)かnaを入力して下さい');

            // ユーザー入力が null でないかつ、日付または 'na' であることを確認
            if (userInput !== null && (this.isDateForAp(userInput) || userInput.toLowerCase() === 'na')) {
                // ここにユーザー入力を適用するロジックを追加
            } else if (userInput !== null) {
                // 入力が要件を満たさない場合、警告を表示し、処理をキャンセル
                alert('入力された値が無効です。日付（yyyy-mm-dd）または "na" を入力してください。');
                return;
            }

            if (userInput !== null && selectedRanges.length > 0) {
                selectedRanges.forEach(range => {
                    let startRowIndex = Math.min(range.startRow.rowIndex, range.endRow.rowIndex);
                    let endRowIndex = Math.max(range.startRow.rowIndex, range.endRow.rowIndex);

                    for (let rowIndex = startRowIndex; rowIndex <= endRowIndex; rowIndex++) {
                        const rowNode = this.gridOptions.api.getRowNode(rowIndex);
                        if (rowNode) {
                            range.columns.forEach(col => {
                                if (col) {
                                    const columnDef = col.getColDef();
                                    if (columnDef.field === '消込ck') { // 特定のカラムの場合のみ更新
                                        rowNode.setDataValue(columnDef.field, userInput);
                                        // ここに必要な追加のロジックを入れる
                                        const confirmUpdate = window.confirm('データベースの値も更新しますか？');
                                        if (!confirmUpdate) {return;}
                                        const periodsArray = Object.values(this.targetPeriod).sort();
                                        const period = periodsArray[periodsArray.length - 1];
                                        const updates = []; // 更新データを格納する配列
                                        updates.push({
                                            id: rowNode.data.id,
                                            updatedValues: { 消込ck: userInput }
                                        });
                                        console.log('updates:',updates,'period:',period);
                                        fetch('/api/update_data.php', {
                                            method: 'POST',
                                            headers: {'Content-Type': 'application/json',},
                                            body: JSON.stringify({
                                                table: period + '_仕訳',
                                                updates: updates,
                                                updateType: '消込ck',
                                            }),
                                        })
                                        .then(response => response.json())
                                        .then(data => {
                                            if (data.success) {
                                                alert('データが更新されました。');
                                            } else {
                                                alert('データの更新に失敗しました。');
                                            }
                                        })
                                        .catch(error => {
                                            alert('画面更新エラー:', error);
                                        });
                                    }
                                }
                            });
                        }
                    }
                });
                this.$nextTick(() => {this.gridOptions.api.redrawRows();});
            }
        },
        formatDate(date) {
            const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
            return new Date(date).toLocaleDateString('en-CA', options); // カナダのロケールを使用して形式を指定
        },
        async createGensenJe() {
            let queries = ['源泉仕訳'];
            let tabType = '仕訳画面';
            let date = this.selectedGensenPayDate;
            if (date===null){
                alert('源泉納付日を選択して下さい');return;
            } else {
                date = this.formatDate(date);
                console.log('date:',date);
            }

            // rowDataをループして条件に合致するデータを抽出
            for (let i = 0; i < this.rowData.length; i++) {
                // let row = this.rowData[i];
                let row = {...this.rowData[i]}; 
                if (row['源泉請求']&&row['源泉請求'].includes('|')){
                    // 条件に該当するレコードを加工

                    row['id'] =''; row['伝票番号'] ='1';row['created'] ='';row['modified'] ='';
                    row['年月'] ='';row['税抜金額'] ='';row['税額'] ='';row['税込金額'] ='';
                    row['表示区分'] ='';row['最小番号'] ='';row['相手科目'] ='';row['勘定情報'] ='';
                    row['status'] ='';row['更新者'] ='';row['備考'] ='';row['仕訳区分'] ='';
                    row['通貨'] ='';row['外貨金額'] =''; row['消込ck']='';
                    row['伝票日付']=date;row['貸方税込']=0;row['借方税額']=0;row['貸方税額']=0;
                    row['摘要']='源泉支払_'+row['摘要']; row['借方税込'] = row['源泉請求'].split('|')[1]*1;
                    
                    // 条件に該当するレコードを追加
                    queries.push({...row});

                    // レコードのコピーを作成して変更を加える
                    let row2 = {...row};
                    row2['勘定及び補助']=this.selectedDepositAccount;
                    row2['貸方税込']=row2['借方税込'];row2['借方税込']=0;row2['税コード']=0;row2['予定日']='';
                    row2['相手情報']='other';row2['PJ情報']='other';row2['源泉請求']='';row2['振込情報']='0|0';
                    row2['消込ck']='';
                    // 変更されたレコードを追加
                    queries.push(row2);
                }
            }
            this.$store.commit('SET_QUERIES', queries); // Vuex ストアに queries を保存
            const tabs = this.$store.state.allTabs; // 既存のタブ一覧を取得
            const maxNum = this.findMaxTabNumber(tabType, tabs);
            
            let newTabName = tabType;
            if (maxNum > 0 || tabs.some(tab => tab.name === tabType)) {
                // 新しいタブ名を生成（例: 仕訳画面01, 仕訳画面02, ...)
                newTabName = `${tabType}${(maxNum + 1).toString().padStart(2, '0')}`;
            }

            await this.$store.dispatch('addTab', newTabName);
            await this.$store.commit('SET_ACTIVE_TAB', { id: newTabName, name: newTabName });
            await this.$nextTick();
        },
        async createArJe() {
            let queries = ['消込仕訳'];
            let tabType = '仕訳画面';
            let previousDate = null;
            
            if (this.selectedDepositAccount==null){
                alert('預金科目を選択して下さい');
                return;
            }

            // rowDataをループして条件に合致するデータを抽出
            for (let i = 0; i < this.rowData.length; i++) {
                // let row = this.rowData[i];
                let row = {...this.rowData[i]}; 
                if (this.isDateForAp(row['消込ck'])) {
                    let currentDate = row['消込ck']; // 日付のみ取得

                    if (previousDate && currentDate !== previousDate) {
                        alert('1つの日付で実行して下さい。\n処理を中断します。');
                        return; // 処理を中断
                    }
                    previousDate = currentDate;
                    // 条件に該当するレコードを加工
                    const tmp_id = row['年月']+'_'+row['id'];
                    const tmp_charge = row['-支払手数料'] || 0;
                    const tmp_forex = row['-/+為替損益'] || 0;
                    const tmp_amt = row['税込計上額'];
                    const tmp_aite = row['相手情報'];
                    console.log('tmp_forex:',tmp_forex);

                    row['id'] =''; row['伝票番号'] ='1';row['created'] ='';row['modified'] ='';
                    row['年月'] ='';row['税抜金額'] ='';row['税額'] ='';row['税込金額'] ='';
                    row['表示区分'] ='';row['最小番号'] ='';row['相手科目'] ='';row['勘定情報'] ='';
                    row['status'] ='';row['更新者'] ='';row['備考'] ='';row['仕訳区分'] ='';
                    row['通貨'] ='';row['外貨金額'] ='';
                    row['伝票日付']=row['消込ck'];row['借方税込']=0;row['借方税額']=0;row['貸方税額']=0;
                    row['摘要']='消込_'+row['摘要']; row['消込ck']=tmp_id;
                    row['貸方税込'] = tmp_amt;
                    delete row['税込計上額'];delete row['-支払手数料'];delete row['-/+為替損益'];delete row['入金額'];
                    
                    // 条件に該当するレコードを追加
                    queries.push({...row});

                    // レコードのコピーを作成して変更を加える(預金)
                    let row2 = {...row};
                    row2['勘定及び補助']=this.selectedDepositAccount;
                    row2['借方税込']=tmp_amt*1+tmp_charge*1+tmp_forex*1;row2['借方税額']=0;
                    row2['貸方税込']=0;row2['貸方税額']=0;row2['税コード']=0;row2['予定日']='';
                    row2['相手情報']='other';row2['PJ情報']='other';row2['源泉請求']='';row2['振込情報']='0|0';
                    row2['消込ck']='na';
                    // 変更されたレコードを追加
                    queries.push(row2);

                    // レコードのコピーを作成して変更を加える(支払手数料)
                    if (Math.abs(tmp_charge) > 0) {
                        if (this.selectedDepositAccount==null||this.selectedFeeTax==null){
                            alert('手数料の科目・補助と税コードを選択して下さい');
                            return;
                        }
                        let row3 = {...row};
                        row3['勘定及び補助']=this.selectedPaymentFee;
                        row3['借方税込']=-tmp_charge;row3['借方税額']=0;row3['貸方税込']=0;row3['貸方税額']=0;
                        row3['税コード']=this.selectedFeeTax.split('_')[0];row3['予定日']='';
                        row3['相手情報']=tmp_aite;row3['PJ情報']='other';row3['源泉請求']='';row3['振込情報']='0|0';
                        row3['消込ck']='na';
                        // 変更されたレコードを追加
                        queries.push(row3);
                    }
                    
                    // レコードのコピーを作成して変更を加える(為替損益)
                    if (Math.abs(tmp_forex) > 0) {
                        if (this.selectedExchangeGainLoss==null||this.selectedExchangeGainLoss=='勘定及び補助を設定して下さい'){
                            alert('為替損益の勘定及び補助を選択して下さい');
                            return;
                        }
                        let row4 = {...row};
                        row4['勘定及び補助']=this.selectedExchangeGainLoss;
                        if (tmp_forex < 0) {
                            row4['借方税込']=-tmp_charge; row4['貸方税込']=0;
                        } else {
                            row4['貸方税込']=tmp_charge; row4['借方税込']=0;
                        }
                        row4['借方税額']=0;row4['貸方税額']=0;
                        row4['税コード']=0;row4['予定日']='';
                        row4['相手情報']=tmp_aite;row4['PJ情報']='other';row4['源泉請求']='';row4['振込情報']='0|0';
                        row4['消込ck']='na';
                        // 変更されたレコードを追加
                        queries.push(row4);
                    }
                }
            }

            this.$store.commit('SET_QUERIES', queries); // Vuex ストアに queries を保存

            const tabs = this.$store.state.allTabs; // 既存のタブ一覧を取得
            const maxNum = this.findMaxTabNumber(tabType, tabs);
            
            let newTabName = tabType;
            if (maxNum > 0 || tabs.some(tab => tab.name === tabType)) {
                // 新しいタブ名を生成（例: 仕訳画面01, 仕訳画面02, ...)
                newTabName = `${tabType}${(maxNum + 1).toString().padStart(2, '0')}`;
            }

            await this.$store.dispatch('addTab', newTabName);
            await this.$store.commit('SET_ACTIVE_TAB', { id: newTabName, name: newTabName });
            await this.$nextTick();
        },
        async createApJe() {
            // 債務画面でrowDataが空の場合、処理を中断
            if (this.activeTab.includes('債務画面') && (!this.rowData || this.rowData.length === 0)) {
                alert('表示データがありません。処理を中断します。');
                return;
            }

            let queries = ['支払仕訳'];
            let previousDate = null;
            let tabType = '仕訳画面';
            
            // bankSccMappingが既に存在するかチェック
            if (!this.bankOrgMapping || Object.keys(this.bankOrgMapping).length === 0) {
                try {
                    this.getMasterMappingFromServer()
                    .then(() => {
                        console.log('Master Mapping が更新されました。');
                    })
                    .catch(error => {
                        console.error('Master Mapping の更新に失敗しました:', error);
                    }); // get_masterMapping.phpからすべてのマスターデータを一括で取得
                    
                } catch (error) {
                    console.error('Error fetching bank organization mapping:', error);
                    alert('銀行マッピングの取得に失敗しました。再試行してください。');
                    return;
                }
            }
            
            // rowDataをループして条件に合致するデータを抽出
            for (let i = 0; i < this.rowData.length; i++) {
                // let row = this.rowData[i];
                let row = {...this.rowData[i]}; 
                if (this.isDateForAp(row['消込ck'])) {
                    let currentDate = row['消込ck']; // 日付のみ取得
                    let gensen = row['源泉請求']; //厳選情報の取得
                    let gensenAmt = 0;
                    if (gensen && gensen.includes('|')){
                        gensenAmt = gensen.split('|')[1]*1;
                        if (this.selectedwithHoldingAcc==null){
                            alert('預り金の科目を選択して下さい');
                            return;
                        }
                    }

                    if (previousDate && currentDate !== previousDate) {
                        alert('1つの日付で実行して下さい。\n処理を中断します。');
                        return; // 処理を中断
                    }
                    previousDate = currentDate;
                    // 条件に該当するレコードを加工
                    const tmp_id = row['年月']+'_'+row['id'];
                    row['id'] =''; row['伝票番号'] ='1';row['created'] ='';row['modified'] ='';
                    row['年月'] ='';row['税抜金額'] ='';row['税額'] ='';row['税込金額'] ='';
                    row['表示区分'] ='';row['最小番号'] ='';row['相手科目'] ='';row['勘定情報'] ='';
                    row['status'] ='';row['更新者'] ='';row['備考'] ='';row['仕訳区分'] ='';
                    row['通貨'] ='';row['外貨金額'] ='';
                    row['伝票日付']=row['消込ck'];row['貸方税込']=0;row['借方税額']=0;row['貸方税額']=0;
                    row['摘要']='支払_'+row['摘要']; row['消込ck']=tmp_id;
                    if (Object.prototype.hasOwnProperty.call(row, '税込源泉後')) {
                        row['借方税込'] = row['税込源泉後'];
                        delete row['税込源泉前'];delete row['源泉税額'];delete row['税込源泉後'];delete row['消込ck金額'];
                    }
                    // 条件に該当するレコードを追加
                    queries.push({...row});

                    // レコードのコピーを作成して変更を加える
                    let row2 = {...row};
                    const bankCode = row['振込情報'].split('|')[1].split(':')[1];
                    const bankOrgScc = this.bankOrgMapping.find(item => item['自社管理cd'] == bankCode);
                    row2['勘定及び補助'] = bankOrgScc ? bankOrgScc['勘定及び補助'] : null;

                    row2['貸方税込']=row2['借方税込']-gensenAmt;row2['借方税込']=0;row2['税コード']=0;row2['予定日']='';
                    row2['相手情報']='other';row2['PJ情報']='other';row2['源泉請求']='';row2['振込情報']='0|0';
                    row2['消込ck']='';
                    // 変更されたレコードを追加
                    queries.push(row2);

                    if (gensen && gensen.includes('|')){
                        // 条件に該当するレコードを追加
                        let row3 = {...row};
                        row3['勘定及び補助']=this.selectedwithHoldingAcc;
                        row3['貸方税込']=gensenAmt;row3['借方税込']=0;row3['税コード']=0;row3['予定日']='';
                        row3['相手情報']='other';row3['PJ情報']='other';row3['源泉請求']=gensen;row3['振込情報']='0|0';
                        row3['消込ck']=tmp_id;
                        // 変更されたレコードを追加
                        queries.push(row3);
                    }
                }
            }

            this.$store.commit('SET_QUERIES', queries); // Vuex ストアに queries を保存
            
            const tabs = this.$store.state.allTabs; // 既存のタブ一覧を取得
            const maxNum = this.findMaxTabNumber(tabType, tabs);
            
            let newTabName = tabType;
            if (maxNum > 0 || tabs.some(tab => tab.name === tabType)) {
                // 新しいタブ名を生成（例: 仕訳画面01, 仕訳画面02, ...)
                newTabName = `${tabType}${(maxNum + 1).toString().padStart(2, '0')}`;
            }
            console.log('newTabName:',newTabName);

            await this.$store.dispatch('addTab', newTabName);
            await this.$store.commit('SET_ACTIVE_TAB', { id: newTabName, name: newTabName });
            await this.$nextTick();
        },

        findMaxTabNumber(tabType, tabs) {
            const regex = new RegExp(`^${tabType}(\\d{2})$`);
            let maxNum = 0;
            tabs.forEach(tab => {
                const match = tab.name.match(regex);
                if (match) {
                const num = parseInt(match[1], 10);
                if (num > maxNum) {
                    maxNum = num;
                }
                }
            });
            return maxNum;
        },
        async createFbData(){
            // データのソート
            const sortedData = this.rowData.sort((a, b) => {
                if (a['消込ck'] !== b['消込ck']) {
                    return a['消込ck'].localeCompare(b['消込ck']);
                }
                return a['振込情報'].localeCompare(b['振込情報']);
            });
            // 日付のチェック
            const uniqueDates = new Set(sortedData.map(row => row['消込ck']).filter(this.isDateForAp));
            if (uniqueDates.size === 0) {
                alert('日付が入力されていません。');
                return;
            }
            if (uniqueDates.size > 1) {
                alert('複数の異なる日付が存在します。\n一つの日付のみ許容します。');
                return;
            }
            // bankOrgMappingが存在しないか空の場合のみ、データを取得
            if (!this.bankOrgMapping || Object.keys(this.bankOrgMapping).length === 0) {
                try {
                    this.getMasterMappingFromServer()
                    .then(() => {
                        console.log('Master Mapping が更新されました。');
                    })
                    .catch(error => {
                        console.error('Master Mapping の更新に失敗しました:', error);
                    }); // get_masterMapping.phpからすべてのマスターデータを一括で取得
                    
                } catch (error) {
                    console.error('Error fetching bank organization mapping:', error);
                    alert('銀行マッピングの取得に失敗しました。再試行してください。');
                    return;
                }
            }

            // Setから最初の要素（日付）を取得
            const dateStr = Array.from(uniqueDates)[0];
            // 日付文字列から月と日を抽出
            const mmdd = dateStr.slice(5).replace('-', ''); // '1230' になる
            const fileName = `${dateStr}_fb_data.txt`;
            
            // 消込ck金額の計算とボディデータの作成
            let currentBankCode = ''; // 現在の振込元銀行コードを追跡
            let subtotal = 0;
            let currentKey = '';
            let lastRow = null;
            let totalAmount = 0; // 合計金額
            let recordCount = 0; // レコードの件数
            const bodyData = [];
            let orgBank = '';
            let orgBankArray = [];

            for (const [index, row] of sortedData.entries()) {
                const bankCode = row['振込情報'].split('|')[1].split(':')[1];
                const key = row['消込ck'] + row['振込情報']; // ここでkeyを初期化

                // 最初の振込元銀行コードを設定
                if (!currentBankCode && this.isDateForAp(row['消込ck'])) {
                    currentBankCode = bankCode;
                    const orgBankRecord = this.bankOrgMapping.find(item => item['自社管理cd'] == bankCode);
                    orgBank = orgBankRecord ? orgBankRecord['結合cd'] : null;
                    orgBankArray = orgBank.split('|');
                }

                // 'na' または日付でない、または振込元銀行コードが一致しない場合は処理を中断
                if (row['消込ck'] === 'na' || !this.isDateForAp(row['消込ck']) || bankCode !== currentBankCode) {
                    if (bankCode !== currentBankCode) {
                        alert('2つ以上の振込元銀行コードがあります。振込元銀行は1つのみを選択してください。');
                        return; // ここで関数からリターン
                    }
                    continue; // 次のイテレーションに進む
                }

                if (key !== currentKey) {
                    if (lastRow && subtotal > 0) {
                        lastRow['消込ck金額'] = subtotal; // 前のキーの小計を設定
                        bodyData.push(this.createBodyData(lastRow)); // 前の行を出力データに追加
                        totalAmount += subtotal; // 合計金額に小計を加算
                        recordCount++; // レコード件数をインクリメント
                    }
                    currentKey = key;
                    subtotal = parseFloat(row['税込源泉後']);
                } else {
                    subtotal += parseFloat(row['税込源泉後']);
                }

                // 最後の行または次の行でキーが変わる場合は小計を設定
                if (index === sortedData.length - 1 || (sortedData[index + 1]['消込ck'] + sortedData[index + 1]['振込情報'] !== key)) {
                    row['消込ck金額'] = subtotal;
                    bodyData.push(this.createBodyData(row)); // 現在の行を出力データに追加
                    totalAmount += subtotal; // 合計金額に小計を加算
                    recordCount++; // レコード件数をインクリメント
                } else {
                    row['消込ck金額'] = 0; // 途中の行の消込ck金額は0に設定
                }
                // 次のループの準備
                lastRow = row;
            }            

            // ヘッダーデータの作成
            const createHeaderData = () => {
                // 100|5_三菱UFJ銀行|1_本店|1_普通預金|1234657|ﾃｽﾄ
                return [
                    '1',//1 データ区分_1
                    '21',//2 種別コード_2
                    '0',//3 コード区分_1 省略可(スペース)
                    ' '.repeat(10),//4 依頼人コード_10
                    ' '.repeat(40),//5 依頼人名_40 省略可(スペース)
                    mmdd,//6 振込指定日_4 mmdd
                    orgBankArray[1].split('_')[0].toString().padStart(4, '0'),//7 仕向金融機関番号_4 省略可(スペース)
                    ' '.repeat(15),//8 仕向金融機関名_15 省略可(スペース)
                    orgBankArray[2].split('_')[0].toString().padStart(3, '0'),//9 仕向支店番号_3
                    ' '.repeat(15),//10 仕向支店名_15 省略可(スペース)
                    orgBankArray[3].split('_')[0],//11 預金種目（依頼人）_1 普通：1、当座：2
                    orgBankArray[4].split('_')[0].toString().padStart(7, '0'),//12 口座番号（依頼人）_7
                    ' '.repeat(17),//13 空きエリア?17
                ].join(''); 
            };
            // トレーラ・レコードの作成
            const createTrailerRecord = () => {
                return `8${recordCount.toString().padStart(6, '0')}${totalAmount.toFixed(0).padStart(12, '0')}${' '.repeat(101)}`;
            };

            // エンド・レコードの作成
            const createEndRecord = () => {
                return `9${' '.repeat(119)}`; // '9' + 119スペース
            };

            // CSVデータの組み立て
            const headerData = createHeaderData();
            const trailerRecord = createTrailerRecord();
            const endRecord = createEndRecord();
            const csvContent = [headerData, bodyData.join('\n'), trailerRecord, endRecord].join('\n');

            // CSVデータをBlobとしてダウンロード可能にする
            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement('a');
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', fileName);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            // 完了メッセージを表示
            alert('FBデータをダウンロードフォルダに出力しました。\nファイル名: ' + fileName);
        },
        createBodyData(rowData) {
            // ... ボディデータの作成ロジック ...
            // let beneBank = rowData['振込情報'].split('|');
            let beneBank = rowData['振込情報'].split(':')[1].split('|');

            return [
                '2',//1 データ区分_1
                beneBank[1].split('_')[0].padStart(4, '0'),//2 被仕向金融機関番号_4
                ' '.repeat(15),//3 被仕向金融機関名_15  左詰、スペース埋め
                beneBank[2].split('_')[0].padStart(3, '0'),//4 被仕向支店番号_3
                ' '.repeat(15),//5 被仕向支店名_15  左詰、スペース埋め
                '0'.repeat(4),//6 手形交換所番号_4 省略可（スペース）
                beneBank[3].split('_')[0],//7 預金種目_1 普通：1、当座：2、貯蓄：4
                beneBank[4].toString().padStart(7, '0'),//8 口座番号_7
                beneBank[5].padEnd(30, ' ').slice(0, 30),//9 受取人名_30
                rowData['消込ck金額'].toFixed(0).padStart(10, '0'),//10 振込金額_10
                '0'.repeat(1),//11 新規コード_1 省略可（スペース）
                '0'.repeat(10),//12 顧客コード1_10 省略可（スペース）
                '0'.repeat(10),//13 顧客コード2_10 省略可（スペース）
                '7',//14 振込指定区分_1  テレ為替: 7、文書為替: 8
                ' '.repeat(1),//15 識別表示_1  Yまたはスペース
                ' '.repeat(7),//16 空きエリア_7
            ].join(''); 
        },
        openLedgerDisplay(){
            this.dialogType ='ledgerDisplay';
            this.dialog = true;
        },
        getLastDayOfMonth(yearMonth) {
            const year = parseInt(yearMonth.substring(0, 4));
            const month = parseInt(yearMonth.substring(4, 6));
            const lastDay = new Date(year, month, 0).getDate(); // 月の0日は前月の最後の日になる
            return `${year}-${month.toString().padStart(2, '0')}-${lastDay}`;
        },
        async fetchLedgerFromTb() {
            await this.handleAsyncOperation(async () => {
                const tableQueries = this.$store.state.tableQueries; // Vuex ストアから tableQueries を取得
                const tbType = this.activeTab.substring(0, 4);
                console.log('tbType:',tbType);

                if (tableQueries) {
                    // console.log('outputType:', tableQueries[0].outputType);
                    try {
                        const response = await fetch('/api/search_data.php', {
                            method: 'POST',
                            headers: {'Content-Type': 'application/json'},
                            body: JSON.stringify({ tableQueries })
                        });
                        if (!response.ok) {
                            throw new Error('Network response was not ok: ' + response.statusText);
                        }
                        let data = await response.json();
                        const queries = this.$store.state.queries; // Vuex ストアから queries を取得
                        console.log('queries:',queries);
                        // queries が配列ではない場合、何もせずにリターン
                        if (!Array.isArray(queries)) {
                            return;
                        }

                        // 部門情報の有無を判断（最初の要素のみ確認）
                        const hasDepartmentInfo = queries.length > 0 && queries[0].departmentInfo !== null;

                        // 新しいデータを作成して rowData に追加する
                        const newRows = queries.map(query => {
                            console.log('Processing query:', query); // クエリの内容をログ出力
                            const currentYearMonth = query.headerName *1;
                            // 1月前の年月を計算する
                            const date = new Date(query.headerName.slice(0, 4), query.headerName.slice(4) - 1);
                            date.setMonth(date.getMonth() - 1);
                            const previousYear = date.getFullYear();
                            const previousMonth = (date.getMonth() + 1).toString().padStart(2, '0');
                            const previousHeaderName = `${previousYear}${previousMonth}`;
                            const voucherDate = this.getLastDayOfMonth(previousHeaderName); // 伝票日付を月末日として設定

                            // 対応する勘定及び補助と年月でフィルターをかけて、前月の増減を計算する（部門は未対応?)
                            const calculateCurrentMonthIncrements = (data, tbType, query, currentYearMonth) => {
                                let filterCondition;
                                // console.log('query.accountInfo:',query.accountInfo,'query.otherInfo:',query.otherInfo);
                                switch (tbType) {
                                    case '勘定元帳':
                                        filterCondition = entry => entry.勘定情報 === query.accountInfoKey; 
                                        break;
                                    case '補助元帳':
                                        filterCondition = entry => entry.勘定及び補助 === query.accountInfoKey;
                                        break;
                                    case '相手元帳':
                                        filterCondition = entry => entry.勘定情報 === query.accountInfoKey && entry.相手情報 === query.otherInfoKey;
                                        break;
                                    case 'PJ元帳':
                                        filterCondition = entry => entry.勘定情報 === query.accountInfoKey && entry.PJ情報 === query.otherInfoKey;
                                        break;
                                    default:
                                        return 0;
                                }
                                // 部門情報が選択されている場合、フィルター条件に追加
                                if (query.departmentInfo !== null) {
                                    const originalFilterCondition = filterCondition;
                                    filterCondition = entry => entry.部門情報 === query.departmentInfoKey && originalFilterCondition(entry);
                                }
                                console.log('Filter condition:', filterCondition);
                                console.log('Current year month:', currentYearMonth);

                                const result = data
                                    .filter(entry => entry.年月 === currentYearMonth)
                                    .filter(filterCondition);

                                console.log('Filtered data:', result);

                                return result.reduce((sum, entry) => sum + (entry.税抜金額 || 0), 0);
                            };
                            let currentMonthIncrements = calculateCurrentMonthIncrements(data, tbType, query, currentYearMonth);

                            // 前月残高を計算
                            const previousMonthBalance = query.value - currentMonthIncrements;

                            // オブジェクトの初期化
                            let resultObject = {
                                年月: currentYearMonth * 1, // 1月前の年月 (intにする)
                                伝票日付: voucherDate, // 伝票日付を月末日として設定
                                借方税抜: previousMonthBalance >= 0 ? previousMonthBalance : 0, // 借方税抜
                                貸方税抜: previousMonthBalance < 0 ? -previousMonthBalance : 0, // 貸方税抜
                                摘要: '繰越残高',
                                伝票番号: 0
                            };
                            // 部門情報が選択されている場合、resultObjectに追加
                            if (query.departmentInfo !== null) {
                                resultObject['部門情報'] = query.departmentInfoKey;
                            }

                            // '勘定及び補助' または '勘定情報' のキーを条件に基づいて設定
                            if (tbType === '勘定元帳') {
                                resultObject['勘定情報'] = query.accountInfoKey;
                            } else if (tbType === '補助元帳') {
                                resultObject['勘定及び補助'] = query.accountInfoKey;
                            } else if (tbType === '相手元帳') {
                                resultObject['勘定情報'] = query.accountInfoKey;
                                resultObject['相手情報'] = query.otherInfoKey;
                            } else if (tbType === 'PJ元帳') {
                                resultObject['勘定情報'] = query.accountInfoKey;
                                resultObject['PJ情報'] = query.otherInfoKey;
                            }
                            
                            console.log('Result object:', resultObject); // 結果オブジェクトをログ出力

                            return resultObject;
                        });
                        

                        // ソート前の data に新しい行を追加する
                        data = [...newRows, ...data];

                        let sortedData = sortDataByType(data, tbType, hasDepartmentInfo);
                        this.rowData = calculateBalancePerAccountAndMonth(sortedData, tbType, 'fromTB', hasDepartmentInfo);
                        this.rowData = this.transformMasterMapping(this.rowData);

                        // ag-Gridにデータを設定
                        this.gridApi.setRowData(this.rowData);
                        this.gridOptions.getRowStyle = (params) => getRowStyle(params, tbType);
                        this.$nextTick(() => {this.autoSizeAllColumns();});
                        
                        this.$store.commit('RESET_TABLE_QUERIES');
                        this.$store.commit('RESET_QUERIES');
                    } catch (error) {
                        console.error('Error fetching data:', error);
                    }
                }
            });
        },
        checkIfTabExists(tabName) {
            // Vuex ストアの state.allTabs 配列を参照してタブの存在を確認
            return this.$store.state.allTabs.some(tab => tab.name === tabName);
        },
        async showSelectedLedger(){
            let tbType = this.determineTable(this.activeTab);
            tbType = tbType.split('(')[0];
            let ledgerType = '';
            
            if (tbType==='勘定TB') {ledgerType = '勘定元帳';} 
            else if (tbType==='補助TB') {ledgerType = '補助元帳';} 
            else if (tbType==='相手TB') {ledgerType = '相手元帳';} 
            else if (tbType==='PJTB') {ledgerType = 'PJ元帳';}   

            let selectedCells = this.gridApi.getCellRanges();
            if (selectedCells.length === 0) {
                return;
            }
            let queries = [];
     
            for (let range of selectedCells) {
                let cellDetails = this.getSelectedCellInfo(range, ledgerType);
                cellDetails.forEach(details => {
                    let query = this.constructSelectQuery(details); // この行は、detailsが適切な形式になっていることを前提としています。
                    queries.push(query);
                });
            }
            
            // 部門情報の一意性をチェック
            const uniqueDepartmentInfoKeys = [...new Set(queries.map(query => query.departmentInfoKey))];
            if (uniqueDepartmentInfoKeys.length > 1) {
                alert('複数の部門が選択されています。1つの部門のみを選択してください。');
                return; // 処理を中断
            }

            const selectDept = uniqueDepartmentInfoKeys[0] || null;

            let conditions = [];
            if (ledgerType ==='勘定元帳') {
                conditions = [
                    {operator: "OR",terms: queries.map(query => ({
                        column: '勘定情報',operator: 'LIKE', value: `%${query.accountInfoKey}%`,dataType: 'varchar'
                    }))},
                    {operator: "OR",terms: queries.map(query => ({
                        column: '年月',operator: '=',value: query.headerName,dataType: 'int'
                    }))}
                ];
            } else if (ledgerType ==='補助元帳') {
                conditions = [
                    {operator: "OR",terms: queries.map(query => ({
                        column: '勘定及び補助',operator: 'LIKE', value: `%${query.accountInfoKey}%`,dataType: 'varchar'
                    }))},
                    {operator: "OR",terms: queries.map(query => ({
                        column: '年月',operator: '=',value: query.headerName,dataType: 'int'
                    }))}
                ];
            } else if (ledgerType ==='相手元帳') {
                conditions = [
                    {operator: "OR",terms: queries.map(query => ({
                        column: '勘定情報',operator: 'LIKE', value: `%${query.accountInfoKey}%`,dataType: 'varchar'
                    }))},
                    {operator: "OR",terms: queries.map(query => ({
                        column: '相手情報',operator: 'LIKE', value: `%${query.otherInfoKey}%`,dataType: 'varchar'
                    }))},
                    {operator: "OR",terms: queries.map(query => ({
                        column: '年月',operator: '=',value: query.headerName,dataType: 'int'
                    }))}
                ];
            } else if (ledgerType ==='PJ元帳') {
                conditions = [
                    {operator: "OR",terms: queries.map(query => ({
                        column: '勘定情報',operator: 'LIKE', value: `%${query.accountInfoKey}%`,dataType: 'varchar'
                    }))},
                    {operator: "OR",terms: queries.map(query => ({
                        column: 'PJ情報',operator: 'LIKE', value: `%${query.otherInfoKey}%`,dataType: 'varchar'
                    }))},
                    {operator: "OR",terms: queries.map(query => ({
                        column: '年月',operator: '=',value: query.headerName,dataType: 'int'
                    }))}
                ];
            }
             // 部門情報が選択されている場合、条件に追加
             if (selectDept) {
                conditions.push({
                    operator: "AND",
                    terms: [{
                        column: '部門情報',
                        operator: '=',
                        value: selectDept,
                        dataType: 'varchar'
                    }]
                });
            }
            
            this.$store.commit('SET_QUERIES', queries); // Vuex ストアに queries を保存
            let tableQueries = [];
            
            console.log('queries:',queries);
            console.log('conditions:',conditions);
            this.targetPeriod.sort().forEach(period => {
                const periodTableName = `${period}_仕訳`;
                let queryObject = { 
                    tableName: periodTableName, 
                    conditions,
                    outputType: ledgerType // 出力目的を示す新しいフィールドを追加
                };
                tableQueries.push(queryObject);
            });
            
            this.$store.commit('SET_TABLEQUERIES', tableQueries); // Vuex ストアに tableQueries を保存

            // if (this.checkIfTabExists(ledgerType)) {
            //     await this.$store.dispatch('removeTab', ledgerType);
            // }
            const tabs = this.$store.state.allTabs; // 既存のタブ一覧を取得
            const maxNum = this.findMaxTabNumber(ledgerType, tabs);
            
            let newTabName = ledgerType;
            if (maxNum > 0 || tabs.some(tab => tab.name === ledgerType)) {
                // 新しいタブ名を生成（例: 仕訳画面01, 仕訳画面02, ...)
                newTabName = `${ledgerType}${(maxNum + 1).toString().padStart(2, '0')}`;
            }
            console.log('newTabName:',newTabName);

            await this.$store.dispatch('addTab', newTabName);
            // 正しい形式でミューテーションをコミット=> この時点でcolumnDefsもDLされる   
            await this.$store.commit('SET_ACTIVE_TAB', { id: newTabName, name: newTabName });
            await this.$nextTick();

            this.dialog = false;
        },
        showAllLegder(){
            //後で実装
            this.dialog = false;
        },  
        async lastUpdated() {
            let tableName = this.determineTable(this.activeTab);
            if (['補助残高', '相手残高', 'PJ残高'].includes(tableName)) {
                const period = Object.values(this.targetPeriod).sort()[0];
                tableName = period + '_' + tableName;
            }
            
            let tableQueries = [];
            let conditions = ['lastUpdate'];

            if (tableName.includes('仕訳')) {
                this.targetPeriod.sort().forEach(period => {
                    const periodTableName = `${period}_${tableName.includes('_') ? tableName.split('_')[1] : tableName}`;
                    tableQueries.push({ tableName: periodTableName, conditions });
                });
            } else {
                tableQueries.push({ tableName, conditions });
            }

            try {
                const response = await fetch('/api/search_data.php', {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({ tableQueries })
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok: ' + response.statusText);
                }
                const data = await response.json();
                this.rowData = this.transformMasterMapping(data);
                this.$nextTick(() => {
                    this.autoSizeAllColumns();
                });
                // ダイアログを閉じる
                this.dialog = false;
            } catch (error) {
                console.error('Error fetching data:', error);
                // エラーが発生した場合もダイアログを閉じる
                this.dialog = false;
            }
        },
        openDisplaySwitch(){
            this.dialogType ='displaySwitch';
            this.dialog = true;
        },
        closeDialog(){
            this.dialog = false;
        },
        callColumnsVisibility(shouldShow, type){ 
            let columnKeys = [];
            columnKeys = this.gridOptions.columnApi.getColumns().map(col => col.getColDef().field);
            this.toggleColumnsVisibility(columnKeys, true);
            
            if (shouldShow === false) {
                if (this.activeTab.includes('仕訳画面')) {
                    if (type === 1) {
                        this.toggleColumnsVisibility([
                            'PJ情報', '相手科目', '勘定情報', '源泉請求', '消込ck', '年月',
                            '税抜金額', '税額', '税込金額', '表示区分', '更新者', '備考', '仕訳区分',
                            '通貨', '外貨金額', 'created', 'modified'
                        ], shouldShow);
                    } 
                } else if (this.activeTab.includes('債権画面')) {
                    if (type === 1) {
                        this.toggleColumnsVisibility([
                            'PJ情報', '源泉請求', '年月', '税抜金額', '税額', '税込金額', '表示区分', '最小番号', 
                            '相手科目', '勘定情報', '更新者', '備考', '仕訳区分', '通貨', '外貨金額', 
                            'created', 'modified','振込情報', '部門情報'
                        ], shouldShow);
                    }
                } else if (this.activeTab.includes('債務画面')||this.activeTab.includes('源泉画面')) {
                    if (type === 1) {
                        this.toggleColumnsVisibility([
                            'PJ情報', '年月', '税抜金額', '税額', '税込金額', '表示区分', '最小番号', 
                            '相手科目', '勘定情報', '更新者', '備考', '仕訳区分', '通貨', '外貨金額', 
                            'created', 'modified'
                        ], shouldShow);
                    }
                } else {
                    this.toggleColumnsVisibility(['created', 'modified'], shouldShow);
                }
            }
            this.dialog = false;
            this.autoSizeAllColumns();
        },
        toggleColumnsVisibility(columnKeys, isVisible) {
            columnKeys.forEach(columnKey => {
                 // カラムキーの存在を確認
                const col = this.gridOptions.columnApi.getColumn(columnKey);
                if (col) {
                    this.gridOptions.columnApi.setColumnVisible(columnKey, isVisible);
                }
            });
        },
        jeRowIdClear(){
            // 選択されたセル範囲を取得
            const selectedRanges = this.gridOptions.api.getCellRanges();
            // 選択された各行に対して操作を行う
            selectedRanges.forEach(range => {
                for (let rowIndex = range.startRow.rowIndex; rowIndex <= range.endRow.rowIndex; rowIndex++) {
                    const rowNode = this.gridOptions.api.getRowNode(rowIndex);
                    // 行ノードが存在する場合にのみ処理を行う
                    if (rowNode) {
                        // 'id' 列の値のみをクリアするための新しいデータオブジェクトを作成
                        const clearedData = {
                            ...rowNode.data,
                            id: '',
                            created: '',
                            modified: '',
                            伝票日付: '',
                            予定日: '',
                            振込情報: '',
                            源泉請求: '',
                            年月: '',
                            税抜金額: '',
                            税額: '',
                            税込金額: '',
                            表示区分: '',
                            最小番号: '',
                            相手科目: '',
                            勘定情報: '',
                            消込ck: '',
                            status: '',
                            備考: '',
                            仕訳区分: '',
                            通貨: '',
                            外貨金額: '',
                        };
                        // 更新されたデータで行ノードを更新
                        rowNode.setData(clearedData);
                    }
                }
            });
            // グリッドのセルを強制的にリフレッシュ
            this.gridOptions.api.refreshCells({ force: true });
        },
        jeRowClear() { 
            // 選択されたセル範囲を取得
            const selectedRanges = this.gridOptions.api.getCellRanges();
            selectedRanges.forEach(range => {
                // 選択範囲に含まれる各行に対する操作
                for (let rowIndex = range.startRow.rowIndex; rowIndex <= range.endRow.rowIndex; rowIndex++) {
                    const rowNode = this.gridOptions.api.getRowNode(rowIndex);
                    if (rowNode) {
                        // gridColumnApi または getAllDisplayedColumns が undefined の場合はスキップ
                        if (!this.gridOptions.columnApi || !this.gridOptions.columnApi.getAllDisplayedColumns) {
                            console.warn('gridColumnApi is not ready or getAllDisplayedColumns is undefined');
                            return;
                        }
                        // 行のデータをクリアするために、すべてのキーを空文字列に設定
                        const clearedData = {};
                        this.gridOptions.columnApi.getAllDisplayedColumns().forEach(col => {
                            clearedData[col.getColId()] = '';
                        });
                        // 更新されたデータで行ノードを更新
                        rowNode.setData(clearedData);
                    }
                }
            });
            // グリッドのセルを強制的にリフレッシュ
            this.gridOptions.api.refreshCells({ force: true });
        },
        async getMasterMappingFromServer() {
            return await this.handleAsyncOperation(async (signal) => {
                try {
                    // 一括でマッピングデータを取得
                    const response = await fetch('/api/get_masterMapping.php', {
                        method: 'GET',
                        headers: { 'Content-Type': 'application/json' },
                        signal
                    });

                    if (!response.ok) {
                        throw new Error('マスターデータの一括取得に失敗しました');
                    }

                    // サーバーから返されたJSONデータを各Mapping配列に格納
                    const masterData = await response.json();

                    // console.log('Master Data:', masterData); // サーバーから返ってきたデータを確認
                    console.log('Category Mapping:', masterData['表示区分']); // 表示区分があるかを確認

                    this.$store.commit('setAccMapping', masterData['勘定科目']);
                    this.$store.commit('setCategoryMapping', masterData['表示区分']);
                    this.$store.commit('setSubMapping', masterData['補助科目']);
                    this.$store.commit('setBumonMapping', masterData['部門情報']);
                    this.$store.commit('setAiteMapping', masterData['相手情報']);
                    this.$store.commit('setPjMapping', masterData['PJ情報']);
                    this.$store.commit('setTaxMapping', masterData['税コード']);
                    this.$store.commit('setFbMapping', masterData['相手口座']);
                    this.$store.commit('setTmpJeMapping', masterData['定型処理']);
                    this.$store.commit('setFyMapping', masterData['企業情報']);
                    this.$store.commit('setUserMapping', masterData['user']);
                    this.$store.commit('setbankOrgMapping', masterData['自社口座']);
                    this.$store.commit('setbankCdMapping', masterData['銀行情報']);
                    // ymMappingの生成と保存
                    const periods = Object.values(this.targetPeriod).sort();
                    const maxPeriod = periods[periods.length - 1];
                    const periodEntry = masterData['企業情報'].find(entry => parseInt(entry['エンド月'], 10) === parseInt(maxPeriod, 10));

                    if (periodEntry) {
                        const start = periodEntry['スタート月'];
                        const ymMapping = this.generateYearMonth(start, maxPeriod);
                        this.$store.commit('setYmMapping', ymMapping);
                    } else {
                        console.warn('企業情報が存在しないため、ymMappingを生成できません。');
                    }

                    // マスターデータの取得完了をマーク
                    this.$store.commit('setMasterMappingFetched', true);

                    return true;
                } catch (error) {
                    console.error('マスターデータの取得中にエラーが発生しました:', error);
                    throw error;
                }
            }, {
                errorMessage: 'マスターデータの取得に失敗しました',
                timeout: 60000
            });
        },
        async downloadDrilldownData(){
            await this.handleAsyncOperation(async () => {
                if (this.activeTab.includes('TB')) {
                    await this.DrilldownDatafromTB();
                } else if (this.activeTab.includes('元帳')) {
                    await this.DrilldownDatafromLedger();
                }else if (this.activeTab.includes('推移')||this.activeTab==='部門PL'||this.activeTab==='PJ分析') {
                    await this.DrilldownDatafromTrend();
                }
            });
        },
        async DrilldownDatafromTrend() {
            let selectedCells = this.gridApi.getCellRanges();
            if (selectedCells.length === 0) {
                return;
            }
            let queries = [];
            let tbType = this.determineTable(this.activeTab);
            
            for (let range of selectedCells) {
                let cellDetails = this.getSelectedCellInfo(range, tbType);
                cellDetails.forEach(details => {
                    let query = this.constructSelectQuery(details);
                    queries.push(query);
                });
            }
            console.log('queries',queries,'tbType:',tbType);
            
            let queriesForStyle = queries.map(query => {
                let conditions = [];
     
                if (tbType === '債権推移' || tbType === '債務推移') {
                    conditions = [
                        { column: '勘定情報', value: query.accountInfoKey },
                        { column: '相手情報', value: query.otherInfoKey },
                        { column: '年月', value: query.headerName * 1 }
                    ];
                } else if (tbType === 'PJ分析') {
                    conditions = [
                        { column: 'PJ情報', value: query.otherInfoKey },    
                        { column: '勘定情報', value: query.accountInfoKey },
                        { column: '年月', value: query.headerName * 1 }
                    ];
                } else if (tbType === '部門PL') {
                    conditions = [
                        { column: '部門情報', value: query.otherInfoKey },
                        { column: '勘定情報', value: query.accountInfoKey },
                        { column: '年月', value: query.headerName * 1 }
                    ];
                }
                return { conditions };
            });
            this.$store.commit('SET_QUERIES_FOR_STYLE', queriesForStyle);
            
            let tableQueries = [];
            let conditionsByYear = {};
            const searchType = '最小番号DD';
            let newConditionGroup = [];

            queries.forEach(query => {
                const fiscalYearEnd = this.getFiscalYearEnd(query.headerName*1);
                console.log('fiscalYearEnd:', fiscalYearEnd, 'query.headerName:', query.headerName);
                
                // targetPeriodのチェックを削除し、すべての期間を処理
                if (!conditionsByYear[fiscalYearEnd]) {
                    conditionsByYear[fiscalYearEnd] = [];
                }
                
                 // tbTypeに応じてtermsの内容を動的に設定
                let terms = [];
                if (tbType === '債権推移' || tbType === '債務推移') {
                    terms = [
                        { column: "勘定情報", operator: "=", value: query.accountInfoKey, dataType: "varchar" },
                        { column: "相手情報", operator: "=", value: query.otherInfoKey, dataType: "varchar" },
                        { column: "年月", operator: "=", value: query.headerName, dataType: "int" },
                        { column: "消込ck", operator: "=", value: 'na', dataType: "varchar" }
                    ];
                } else if (tbType === 'PJ分析') {
                    terms = [
                        { column: "PJ情報", operator: "=", value: query.otherInfoKey, dataType: "varchar" },    
                        { column: "勘定情報", operator: "=", value: query.accountInfoKey, dataType: "varchar" },
                        { column: "年月", operator: "=", value: query.headerName, dataType: "int" }
                    ];
                } else if (tbType === '部門PL') {
                    terms = [
                        { column: "部門情報", operator: "=", value: query.departmentInfoKey, dataType: "varchar" },    
                        { column: "勘定情報", operator: "=", value: query.accountInfoKey, dataType: "varchar" },
                        { column: "年月", operator: "=", value: query.headerName, dataType: "int" }
                    ];
                }

                newConditionGroup = {
                    operator: "AND",
                    terms: terms
                };
                conditionsByYear[fiscalYearEnd].push(newConditionGroup);
            });

            // 各期間のテーブルクエリを作成
            Object.keys(conditionsByYear).forEach(period => {
                const periodTableName = `${period}_仕訳`;
                const conditionGroups = conditionsByYear[period];
                
                if (conditionGroups && conditionGroups.length > 0) {
                    const groupedConditions = conditionGroups.map(group => ({ operator: "OR", ...group }));
                    tableQueries.push({
                        tableName: periodTableName,
                        searchType,
                        conditions: groupedConditions
                    });
                }
            });

            let queries_ = ['呼出仕訳'];
            let tabType = '仕訳画面';
            this.$store.commit('SET_TABLEQUERIES', tableQueries); // Vuex ストアに tableQueries を保存
            const tabs = this.$store.state.allTabs; // 既存のタブ一覧を取得
            const maxNum = this.findMaxTabNumber(tabType, tabs);
            
            let newTabName = tabType;
            if (maxNum > 0 || tabs.some(tab => tab.name === tabType)) {
                // 新しいタブ名を生成（例: ��訳画面01, 仕���画面02, ...)
                newTabName = `${tabType}${(maxNum + 1).toString().padStart(2, '0')}`;
            }
            
            this.$store.commit('SET_QUERIES', queries_); // Vuex ストアに queries_ を保存
            await this.$store.dispatch('addTab', newTabName);
            await this.$store.commit('SET_ACTIVE_TAB', { id: newTabName, name: newTabName });
            await this.$nextTick();
            this.selectedDisplayType === '部分表示';
            this.callColumnsVisibility(false, 1);
            this.$nextTick(() => {this.autoSizeAllColumns();});
        },
        async DrilldownDatafromLedger() {
            let selectedCells = this.gridApi.getCellRanges();
            if (selectedCells.length === 0) {
                return;
            }

            let queries = [];
            
            // 選択されたセルの範囲を処理
            for (let range of selectedCells) {
                let startRowIndex = range.startRow.rowIndex;
                let endRowIndex = range.endRow.rowIndex;

                // 選択された行データを取得
                for (let rowIndex = startRowIndex; rowIndex <= endRowIndex; rowIndex++) {
                    let rowNode = this.gridApi.getDisplayedRowAtIndex(rowIndex);

                    if (!rowNode || !rowNode.data) continue;

                    let rowData = rowNode.data;

                    // 年月と伝票番号を抽出して条件に使用
                    let yearMonth = rowData['年月'];  // 必要に応じてカラム名は変更してください
                    let voucherNumber = rowData['伝票番号'];  // 必要に応じてカラム名は変更してください

                    // これらの情報を使ってクエリ条件を作成
                    let query = {
                        conditions: [
                            { column: '年月', value: yearMonth },
                            { column: '伝票番号', value: voucherNumber }
                        ]
                    };
                    queries.push(query);
                }
            }

            // 検索条件に使用する新しい条件グループを生成
            let tableQueries = [];
            let conditionsByYear = {};
            const searchType = '伝票番号';  // 仕訳検索時に使用するタイプ

            const periods = Object.values(this.targetPeriod).sort(); // ソートされた配列から最小値を取得
            const maxPeriod = periods[periods.length - 1]; // 最小値を用いてクエリを作成

            queries.forEach(query => {
                
                // 新しい条件グループを追加
                let newConditionGroup = {
                    operator: "AND",
                    terms: query.conditions.map(condition => ({
                        column: condition.column,
                        operator: "=",
                        value: condition.value,
                        dataType: (condition.column === '伝票番号' ? "int" : "varchar")
                    }))
                };
                // conditionsByYear[maxPeriod] が存在しない場合は初期化
                if (!conditionsByYear[maxPeriod]) {
                    conditionsByYear[maxPeriod] = [];
                }
                conditionsByYear[maxPeriod].push(newConditionGroup);
            });

            // 条件を基にクエリを構築
            Object.keys(conditionsByYear).forEach(yearMonth => {
                const periodTableName = `${yearMonth}_仕訳`;  // 適切な仕訳テーブル名に変更
                const conditionGroups = conditionsByYear[yearMonth];

                if (conditionGroups && conditionGroups.length > 0) {
                    const groupedConditions = conditionGroups.map(group => ({ operator: "OR", ...group }));
                    tableQueries.push({
                        tableName: periodTableName,
                        searchType,
                        conditions: groupedConditions
                    });
                }
            });

            // クエリの保存とタブの設定
            let queries_ = ['呼出仕訳'];
            let tabType = '仕訳画面';
            this.$store.commit('SET_TABLEQUERIES', tableQueries); // Vuex ストアに tableQueries を保存
            const tabs = this.$store.state.allTabs; // 既存のタブ一覧を取得
            const maxNum = this.findMaxTabNumber(tabType, tabs);

            let newTabName = tabType;
            if (maxNum > 0 || tabs.some(tab => tab.name === tabType)) {
                // 新しいタブ名を生成（例: 仕訳画面01, 仕訳画面02, ...）
                newTabName = `${tabType}${(maxNum + 1).toString().padStart(2, '0')}`;
            }

            this.$store.commit('SET_QUERIES', queries_); // Vuex ストアに queries_ を保存
            await this.$store.dispatch('addTab', newTabName);
            await this.$store.commit('SET_ACTIVE_TAB', { id: newTabName, name: newTabName });

            // 表示形式を適切にセット
            this.selectedDisplayType = '部分表示';  // 表示形式が最小であることを示す
            this.callColumnsVisibility(false, 1);  // カラムの可視性設定
            this.$nextTick(() => {
                this.autoSizeAllColumns();
            });
        },
        async DrilldownDatafromTB() {
            let selectedCells = this.gridApi.getCellRanges();
            if (selectedCells.length === 0) {
                return;
            }
            // Assuming you have a function to handle API requests
            let queries = [];
            let tbType = this.determineTable(this.activeTab);
            tbType = tbType.split('(')[0];
            let ledgerType = '';
            
            if (tbType==='勘定TB') {ledgerType = '勘定元帳';} 
            else if (tbType==='補助TB') {ledgerType = '補助元帳';} 
            else if (tbType==='相手TB') {ledgerType = '相手元帳';} 
            else if (tbType==='PJTB') {ledgerType = 'PJ元帳';}   
     
            for (let range of selectedCells) {
                let cellDetails = this.getSelectedCellInfo(range, ledgerType);
                cellDetails.forEach(details => {
                    let query = this.constructSelectQuery(details); // この行は、detailsが適切な形式になっていることを前提としています。
                    queries.push(query);
                });
            }
            console.log('queries',queries,'tbType:',tbType);
            
            // 部門情報を取得
            const selectDeptEntry = this.filteredSearchEntries.find(entry => entry.searchColumnName === '部門情報');
            const selectDept = selectDeptEntry ? selectDeptEntry.input : null;

            // 保存された queries_ をコンポーネントのデータとして保存
            // 変形後の queriesForStyle 配列を生成する
            let queriesForStyle = queries.map(query => {
    
                let conditions = [];
                switch (tbType) {
                    case '勘定TB':
                        conditions = [
                            { column: '勘定情報', value: query.accountInfoKey },
                            { column: '年月', value: query.headerName * 1 }
                        ];
                        break;
                    case '補助TB':
                        conditions = [
                            { column: '勘定及び補助', value: query.accountInfoKey },
                            { column: '年月', value: query.headerName * 1 }
                        ];
                        break;
                    case '相手TB':
                        conditions = [
                            { column: '勘定情報', value: query.accountInfoKey },
                            { column: '相手情報', value: query.otherInfoKey },
                            { column: '年月', value: query.headerName * 1 }
                        ];
                        break;
                    case 'PJTB':
                        conditions = [
                            { column: '勘定情報', value: query.accountInfoKey },
                            { column: 'PJ情報', value: query.otherInfoKey },
                            { column: '年月', value: query.headerName * 1 }
                        ];
                        break;
                    default:
                        conditions = [
                            { column: '勘定及び補助', value: query.accountInfoKey },
                            { column: '年月', value: query.headerName * 1 }
                        ];
                }

                // 部門情報が選択されている場合、条件に追加
                if (selectDept) {
                    conditions.push({ column: '部門情報', value: query.departmentInfoKey });
                }

                return { conditions };
            });
            // console.log('queriesForStyle', queriesForStyle);
            this.$store.commit('SET_QUERIES_FOR_STYLE', queriesForStyle);
            
            let tableQueries = [];
            let conditionsByYear = {};
            const searchType = '最小番号DD';
            let newConditionGroup =[];

            queries.forEach(query => {
                const fiscalYearEnd = this.getFiscalYearEnd(query.headerName*1);
                console.log('fiscalYearEnd:',fiscalYearEnd,'query.heaederName:',query.headerName);
                if (!conditionsByYear[fiscalYearEnd]) {
                    conditionsByYear[fiscalYearEnd] = []
                }
                switch (tbType) {
                case '勘定TB': 
                    newConditionGroup = {
                        operator: "AND", terms: [
                        { column: "勘定情報", operator: "=", value: query.accountInfoKey, dataType: "varchar" },
                        { column: "年月", operator: "=", value: query.headerName, dataType: "int" }]
                    };
                    break;
                case '補助TB': 
                    newConditionGroup = {
                        operator: "AND", terms: [
                        { column: "勘定及び補助", operator: "=", value: query.accountInfoKey, dataType: "varchar" },
                        { column: "年月", operator: "=", value: query.headerName, dataType: "int" }]
                    };
                    break;
                case '相手TB': 
                    newConditionGroup = {
                        operator: "AND", terms: [
                        { column: "勘定情報", operator: "=", value: query.accountInfoKey, dataType: "varchar" },
                        { column: "相手情報", operator: "=", value: query.otherInfoKey, dataType: "varchar" },
                        { column: "年月", operator: "=", value: query.headerName, dataType: "int" }]
                    };
                    break;
                case 'PJTB': 
                    newConditionGroup = {
                        operator: "AND", terms: [
                        { column: "勘定情報", operator: "=", value: query.accountInfoKey, dataType: "varchar" },
                        { column: "PJ情報", operator: "=", value: query.otherInfoKey, dataType: "varchar" },
                        { column: "年月", operator: "=", value: query.headerName, dataType: "int" }]
                    };
                    break;
                }
                // selectDept が null でない場合は部門情報を含める
                if (selectDept) {
                    newConditionGroup.terms.push({ column: "部門情報", operator: "=", value: query.departmentInfoKey, dataType: "varchar" });
                }

                conditionsByYear[fiscalYearEnd].push(newConditionGroup);
            });
            
            this.targetPeriod.sort().forEach(period => {
                const periodTableName = `${period}_仕訳`;
                const conditionGroups = conditionsByYear[period];
                console.log('conditionGroups:',conditionGroups);
                if (conditionGroups && conditionGroups.length > 0) {
                    const groupedConditions = conditionGroups.map(group => ({ operator: "OR", ...group }));
                    tableQueries.push({
                        tableName: periodTableName,
                        searchType,
                        conditions: groupedConditions
                    });
                }
            });

            let queries_ = ['呼出仕訳'];
            let tabType = '仕訳画面';
            this.$store.commit('SET_TABLEQUERIES', tableQueries); // Vuex ストアに tableQueries を保存
            const tabs = this.$store.state.allTabs; // 既存のタブ一覧を取得
            const maxNum = this.findMaxTabNumber(tabType, tabs);
            
            let newTabName = tabType;
            if (maxNum > 0 || tabs.some(tab => tab.name === tabType)) {
                // 新しいタブ名を生成（例: 仕訳���面01, 仕訳画面02, ...)
                newTabName = `${tabType}${(maxNum + 1).toString().padStart(2, '0')}`;
            }
            
            this.$store.commit('SET_QUERIES', queries_); // Vuex ストアに queries_ を保存
            await this.$store.dispatch('addTab', newTabName);
            await this.$store.commit('SET_ACTIVE_TAB', { id: newTabName, name: newTabName });
            await this.$nextTick();
            this.selectedDisplayType === '部分表示';
            this.callColumnsVisibility(false, 1);
            this.$nextTick(() => {this.autoSizeAllColumns();});
        },
        getSelectedCellInfo(range, ledgerType) {
            let cellInfo = [];

            if (range.startRow && range.endRow) {
                let startRowIndex = Math.min(range.startRow.rowIndex, range.endRow.rowIndex);
                let endRowIndex = Math.max(range.startRow.rowIndex, range.endRow.rowIndex);

                for (let rowIndex = startRowIndex; rowIndex <= endRowIndex; rowIndex++) {
                    let rowNode = this.gridApi.getDisplayedRowAtIndex(rowIndex);
                    range.columns.forEach(col => {
                        let pivotColId = col.getColId();
                        let headerName = col.getColDef().headerName;
                        let value = rowNode.aggData[pivotColId];
                        
                        let details = this.getAccountingDetails(rowNode, headerName, value, ledgerType);
                        cellInfo.push(details);
                    });
                }
            }
            return cellInfo;
        },
        getAccountingDetails(rowNode, headerName, value, ledgerType) {
            let details = {
                headerName: headerName,
                value: value,
                accountInfoKey: null,
                displayCategoryKey: null,
                departmentInfoKey: null,
                accountInfo: null,
                displayCategory: null,
                departmentInfo: null,
                otherInfoKey: null,
                otherInfo: null // otherKeyに対応する情報を格納するプロパティ
            };
            // 親ノードを辿りながら必要な情報を取得
            let currentNode = rowNode;
            while (currentNode) {
                console.log('currentNode:',currentNode);
                if (ledgerType.includes('推移')) {
                    if (currentNode && currentNode.field === '相手情報') {
                        details.otherInfo = currentNode.field;
                        details.otherInfoKey = currentNode.key;
                    } else if (currentNode && currentNode.field === '勘定情報') {
                        details.accountInfo = currentNode.field;
                        details.accountInfoKey = currentNode.key;
                    }
                } else if (ledgerType === 'PJ分析') {
                    if (currentNode && currentNode.field === 'PJ情報') {
                        details.otherInfo = currentNode.field;
                        details.otherInfoKey = currentNode.key;
                    } else if (currentNode && currentNode.field === '勘定情報') {
                        details.accountInfo = currentNode.field;
                        details.accountInfoKey = currentNode.key;
                    }
                } else if (ledgerType === '部門PL') {
                    if (currentNode && currentNode.field === '勘定情報') {
                        details.accountInfo = currentNode.field;
                        details.accountInfoKey = currentNode.key;
                    } else if (currentNode && currentNode.field === '部門情報') {
                        details.departmentInfo = currentNode.field;
                        details.departmentInfoKey = currentNode.key;
                    }
                } else {
                    // '勘定情報'をチェック
                    if (currentNode && currentNode.field === '勘定情報') {
                        details.accountInfo = currentNode.field;
                        details.accountInfoKey = currentNode.key;
                    }
                    // '表示区分'をチェック
                    else if (currentNode && currentNode.field === '表示区分') {
                        details.displayCategory = currentNode.field;
                        details.displayCategoryKey = currentNode.key;
                    }
                    // '部門情報'をチェック
                    else if (currentNode && currentNode.field === '部門情報') {
                        details.departmentInfo = currentNode.field;
                        details.departmentInfoKey = currentNode.key;
                        break; // 部門情報が最上位だと仮定してループを抜ける
                    }
                    // 'otherInfoKey'をチェック (勘定情報は上で拾われるからここに到達しない)
                    else if (currentNode && currentNode.field === ledgerType.substring(0, 2) + '情報') {
                        details.otherInfo = currentNode.field;
                        details.otherInfoKey = currentNode.key;
                        // otherKeyに関する処理が終わったらループを抜ける
                    }
                }
                // 親ノードへ移動
                currentNode = currentNode.parent;
            }
            return details
        },
        constructSelectQuery(pivotKeys) {
            // Construct your SQL query based on the pivotKeys
            // This is just a placeholder function, implement it based on your backend requirements.
            console.log('query:',pivotKeys);
            return pivotKeys; // Your SQL query here
        },
        async fetchDrilldownData(query) {
            console.log('query:',query);
        }
    },
    async mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
             // ag-Gridのセルにフォーカスがある場合のみペーストを処理
        });
        window.addEventListener('paste', this.handlePasteEvent);

        try {
            await this.getMasterMappingIfNeeded();
        } catch (error) {
            console.error('Error fetching master mapping:', error.message || error);
            this.showErrorMessage('マスター情報の取得に失敗しました。再試行してください。');
            // エラーが発生した場合に処理を停止し、以後の処理は実行しない
            return;
        }

        const queries = this.$store.state.queries; // Vuex ストアから queries を取得
        const tableQueries = this.$store.state.tableQueries; // Vuex ストアから tableQueries を取得
        const queriesForStyle = this.$store.state.queriesForStyle; // Vuex ストアから queriesForStyle を取得

        // タブごとの処理を実行
        switch (this.activeTab) {
            case '債権推移': case '債務推移': 
                this.filteredSearchEntries = [
                    {searchColumnName: 'status',searchColumnType: 'varchar', input: '',reserve: ''},
                ];
                break;
            case '勘定TB(発生)': case '勘定TB(残高)': 
            case '補助TB(発生)': case '補助TB(残高)': 
            case '相手TB(発生)': case '相手TB(残高)': 
            case 'PJTB(発生)': case 'PJTB(残高)': 
            case 'PJ分析': case '部門PL': 
            // case '消費税ck画面': 
                this.filteredSearchEntries = [
                    {searchColumnName: 'status',searchColumnType: 'varchar', input: '',reserve: ''},
                    {searchColumnName: '部門情報',searchColumnType: 'varchar', input: '',reserve: ''},
                ];
                break;

            default:
                if (this.activeTab.includes('元帳')) {
                    await this.getMasterInfoForRichSelect(); // 勘定及び補助を先に取得
                    await this.getColumnDefsFromServer(); // カラム定義を取得
                    this.fetchLedgerFromTb(); // Ledgerからデータをフェッチ
                    this.togglePeriodList(); // 期間リストのトグル
                    return;
                }

                // 勘定及び補助とカラム定義を取得
                await this.getMasterInfoForRichSelect();
                await this.getColumnDefsFromServer();

                if (this.activeTab === '消費税ck画面') {
                    this.filteredSearchEntries = [
                        {searchColumnName: 'status',searchColumnType: 'varchar', input: '',reserve: ''},
                    ];
                }
                
                // 仕訳処理に基づいてデータを表示
                if (queries && queries.length > 1) {
                    const updatedQueries = queries.slice(1);

                    switch (queries[0]) {
                        case '支払仕訳':
                            this.rowData = updatedQueries;
                            this.apJeVisible = !this.apJeVisible;
                            break;
                        case '消込仕訳':
                            this.rowData = updatedQueries;
                            this.arJeVisible = !this.arJeVisible;
                            break;
                        case '源泉仕訳':
                            this.rowData = updatedQueries;
                            this.gensenJeVisible = !this.gensenJeVisible;
                            break;
                        default:
                            break;
                    }

                    this.menuVisible = !this.menuVisible;
                    this.$store.commit('RESET_QUERIES');
                } else if (queries && queries[0] === '呼出仕訳') {
                    try {
                        const response = await fetch('/api/search_data.php', {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({ tableQueries })
                        });

                        if (!response.ok) {
                            throw new Error('Network response was not ok: ' + response.statusText);
                        }

                        let rowData = await response.json();
                        this.rowData = this.transformMasterMapping(rowData);

                        // データが空の場合
                        if (!rowData || rowData.length === 0) {
                            if (queries[0] === '呼出仕訳') {
                                // 現在のアクティブタブを取得
                                alert('結果はありません。取引が存在する勘定情報を選択してください。');
                                
                                console.log('this.activeTab:',this.activeTab);                            
                                await this.$store.dispatch('removeTab', this.activeTab);
                                this.$store.commit('RESET_QUERIES');

                                return; // 処理を中断
                            }                            
                        }

                        this.rowData = rowData;
                        this.queriesForStyle = queriesForStyle;

                        // getRowStyleの設定 (罫線を引く処理)
                        this.gridOptions.getRowStyle = function (params) {
                            if (params.node.rowIndex === 0) {
                                return {};
                            }

                            let currentVoucherNo = params.data.伝票番号;
                            let currentVoucherDate = params.data.伝票日付;
                            let previousNode = params.api.getDisplayedRowAtIndex(params.node.rowIndex - 1);

                            if (!previousNode) {
                                return {};
                            }

                            let previousVoucherNo = previousNode.data.伝票番号;
                            let previousVoucherDate = previousNode.data.伝票日付;

                            // 伝票番号と伝票日付の両方が異なる場合に罫線を引く
                            if (currentVoucherNo !== previousVoucherNo || currentVoucherDate !== previousVoucherDate) {
                                return { 'border-top': '1.5px solid black' };
                            }

                            return {};
                        };

                        // グリッドをリフレッシュしてスタイルを適用
                        this.gridApi.redrawRows();
                    } catch (error) {
                        console.error('Error fetching data:', error);
                    }

                    this.$store.commit('RESET_TABLE_QUERIES');
                    this.$store.commit('RESET_QUERIES');
                    this.callColumnsVisibility(false, 1);
                    this.$nextTick(() => {this.autoSizeAllColumns();});
                }
        }

        // タブに基づく処理を続ける
        switch (this.activeTab) {
            case '勘定TB(発生)': case '補助TB(発生)': case '相手TB(発生)': case 'PJTB(発生)': 
            case '勘定TB(残高)': case '補助TB(残高)': case '相手TB(残高)': case 'PJTB(残高)': 
            case 'PJ分析': case '部門PL': case '消費税ck画面': 
            case '債権推移': case '債務推移': 
                this.togglePeriodList();
                break;
            case '仕訳変更ログ': case '仕訳承認ログ': case 'マスタ変更ログ':
                this.toggleSearchList();
                this.addNewRow(false);  // falseを渡して行数入力をスキップし、10行追加
                break;
            case '債権画面': case '債務画面': case '源泉画面': 
                this.toggleArApList();
                this.callColumnsVisibility(false, 1);
                break;
            default:
                if (!this.activeTab.includes('仕訳画面')) {
                    await this.fetchData();
                    // this.addNewRow(false);  // falseを渡して行数入力をスキップし、10行追加
                    this.callColumnsVisibility(false, 1);
                } else if (this.activeTab.includes('仕訳画面') && this.rowData.length === 0) {
                    // 仕訳画面で、かつrowDataが空の場合、10行を追加
                    this.addNewRow(false);  // falseを渡して行数入力をスキップし、10行追加
                    this.callColumnsVisibility(false, 1);
                    this.$nextTick(() => { this.autoSizeAllColumns(); });
                }
        }
    },    
    beforeUnmount() {
        // コンポーネントが破棄される前に、リスナーを解除
        if (this.gridApi) {
            this.gridApi.removeEventListener('bodyScroll', this.handleScroll);
        }
        // ペーストイベントリスナーの削除
        const gridContainer = document.querySelector('.ag-theme-alpine');
        if (gridContainer) {
            gridContainer.removeEventListener('paste', this.handlePasteEvent);
        }
        window.removeEventListener('resize', this.onResize);
    },
    created() {
        window.addEventListener('keydown', this.handleKeydown);
        this.undoRedoCellEditingLimit = 5;
        // this.generateDateList(); // コンポーネント作成時に日付リストを生成
        this.simpleInput.伝票日付 = this.getInitialDate(); // 初期日付を設定
        // pivotModeにおけるカラムのフィルタ設定を定義する関数
        this.processPivotResultColDef = (colDef) => {
            colDef.filter = 'agNumberColumnFilter';
            colDef.floatingFilter = true;
        };
        // sideBarの設定をここで定義
        this.sideBar = 'filters';
        // gridOptionsにsideBarの設定を反映させる
        this.gridOptions = {
            sideBar: this.sideBar,
            // ピボット結果のカラム定義にプロセス関数を適用する設定
            processPivotResultColDef: this.processPivotResultColDef,
        };
        // this.$vuetify.locale.current = 'ja';
        this.searchEntries.forEach((entry, index) => {
            if (['created', 'modified'].includes(entry.searchColumnName)) {
                this.openDateTimeDialog(index);
            }
        });
        console.log('Component created, searchEntries:', this.searchEntries); // デバッグ情報
    },
    unmounted() {
      window.removeEventListener('keydown', this.handleKeydown);
    },
    watch: {
        isProcessing(newVal) {
            // isProcessing に基づいて isLoading を設定
            this.isLoading = newVal;
        },
      activeTab(newTab) {
        // タブが切り替わった時に発火する
        switch (newTab) {
            case '勘定TB(発生)': case '補助TB(発生)': case '相手TB(発生)': case 'PJTB(発生)':
            case '勘定TB(残高)': case '補助TB(残高)': case '相手TB(残高)': case 'PJTB(残高)':
                console.log('togglePeriodList を実行します'); // togglePeriodList を実行するタイミングを確認
                this.togglePeriodList();
                break;
            case '債権画面': case '債務画面': case '源泉画面':
                this.toggleArApList(); // 債権・債務画面に切り替わったらtoggleArApListを実行
                break;
            default:
                // if (newTab.includes('仕訳')) {this.updateMasterData();}
                //this.toggleSearchList(); // それ以外のタブに切り替わった場合はtoggleSearchListを実行
        }},
        currentActiveTab() {
            this.$nextTick(() => {this.autoSizeAllColumns();});
        },
        // targetPeriod の変更を監視
        targetPeriod() {
            // console.log('targetPeriodが変更されました:', "new:",newVal, 'old:',oldVal);
            // アクティブなタブが '仕訳' を含むか確認
            if (this.activeTab.includes('仕訳')) {
                // 日付リストを生成
                const newDateList = this.generateDateList();
                // '仕訳'タブに対応するcolumnDefsを更新
                const updatedColumnDefs = this.columnDefs.map(colDef => {
                    if (colDef.field === '伝票日付') {
                        return {
                            ...colDef,
                            cellEditorParams: {
                                ...colDef.cellEditorParams,
                                values: newDateList  // 新しい日付リストを設定
                            }
                        };
                    }
                    return colDef;
                });
                // グリッドに新しい columnDefs を適用
                this.gridOptions.api.setColumnDefs(updatedColumnDefs);
            } else {
                console.log('仕訳画面ではないため、日付リストの更新は行いません。');
            }
        }
    },
  };
  </script>
  
<style>
.custom-text {
    font-size: 0.8rem;  /* Adjusted font size */
    line-height: 1.2;  
}
  /* 以下のスタイルはAG-gridのテーマに合わせて調整してください */
.tight-list .v-list-item {
    padding: 2px 0;  /* これは例です。適切な値に調整してください */
}
v-btn {
  position: relative; /* or 'fixed' depending on the desired effect */
  /* Add any other styles if needed */
}

.styled-table {
width: 100%;
border-collapse: collapse;
margin: 25px 0;
font-size: 0.9em;
text-align: left;
box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table th,
.styled-table td {
padding: 3px 15px;
border: 1px solid black; /* テーブルヘッダとテーブルデータセルに罫線を追加border-bottom: 1px solid #ddd; */
}

.styled-table th {
background-color: #f2f2f2;
}

.styled-table tbody tr {
transition: background-color 0.2s ease;
}

.styled-table tbody tr:hover {
background-color: #f5f5f5;
}

.styled-table th:first-child {
width: 250px;  /* カラム名の列幅を調整 */
}

.styled-table tbody tr:nth-child(even) {
  background-color: #f5f5f5; /* 偶数行の背景色を設定 */
}

.period-list .v-slider {
    margin-top: 20px;
}

.readonly-header {
    color: red;
    font-weight: bold;
}
.required-header {
    color: blue;
    font-weight: bold;
}
/* 行の背景色を変更するためのスタイルを追加（条件付書式) */
.highlight-row {
  background-color: yellow !important; /* 背景色を黄色に強制設定 */
  color: black !important; /* テキスト色を黒に強制設定（必要に応じて） */
}
/* 行数フィールドの高さを指定 */
.row-number-input {
    border: 2px solid #999; /* 灰色のボーダー */
    border-radius: 4px;
    box-sizing: border-box;
}
/* 部門コードフィールドには別のスタイルを適用することも可能 */
.department-code-field input {
    height: auto; /* デフォルトの高さや別の高さに設定 */
}
.wide-btn {
  padding: 0 10px;
  min-width: 10px;
  height: 20px;
  border-radius: 4px;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.v-icon {
  margin-left: 5px;
}
.text-black {
  color: black !important;
}
/* コンパクトなリストスタイル */
.compact-list-item-title {
  font-size: 14px; /* 文字サイズを小さく */
  line-height: 1; /* 行間を狭く */
}

.compact-list-item {
  padding: 4px 0; /* リストアイテムの上下の余白を小さく */
}
/* 選択されたアイテムに適用するスタイル */
.selected-item {
  background-color: #90caf9 !important; /* light mode: 明るい青 */
}
.ag-theme-alpine .highlight-row {
  background-color: #b3e5fc !important; /*/* 水色 */
}
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}
.menu-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.menu-fixed-items {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.row-number-input,
.display-type-select {
  height: 40px;
  padding: 0 5px;
  font-size: 12px;
  text-align: center;
  margin-right: 5px;
  background-color: #e3f2fd !important; /* 薄い灰色の背景 */
  border: 1px solid #ccc; /* グレーのボーダー */
  border-radius: 4px; /* 角を少し丸くする */
}
/* オプション: ホバー時のスタイル */
.display-type-select:hover {
  border-color: #999; /* ホバー時にボーダーを少し濃い色に */
}

/* オプション: フォーカス時のスタイル */
.display-type-select:focus {
  outline: none;
  border-color: #66afe9; /* フォーカス時のボーダー色 */
  box-shadow: 0 0 5px rgba(102, 175, 233, 0.5); /* フォーカス時の薄い影 */
}
.row-number-input {
  width: 80px;
}

.display-type-select {
  width: 100px;
}

.menu-scroll-wrapper {
  flex-grow: 1;
  overflow-x: auto;
  white-space: nowrap;
  padding-bottom: 5px; /* スクロールバーのためのスペース */
}

.menu-button {
  margin-right: 5px;
}

/* スクロールバーのスタイル（オプション） */
.menu-scroll-wrapper::-webkit-scrollbar {
  height: 5px;
}

.menu-scroll-wrapper::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 2.5px;
}

.menu-scroll-wrapper::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
.menu-button.v-btn--outlined {
  border-color: rgba(0, 0, 0, 0.12); /* 輪郭の色を薄いグレーに */
  border-width: 1px; /* 輪郭の太さを1pxに */
}
.no-data-message {
    padding-left: 36px; /* チェックボックスの幅に合わせて調整 */
    color: #757575; /* オプション：メッセージの色を薄くする */
}
.simple-input {
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.v-text-field {
  width: 100%;
  max-width: 250px;
}
.tax-summary-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 15px;
}

.tax-summary-header {
    font-size: 1rem;
    margin-bottom: 10px;
}

.tax-summary-table {
    width: auto;
    border-collapse: collapse;
    border: 1px solid #ddd;
    margin-left: 0;  /* 左マージンを削除 */
}

.tax-summary-table th,
.tax-summary-table td {
    padding: 8px;
    border: 1px solid #ddd;
}

.tax-summary-table th {
    background-color: #f5f5f5;
}

.text-right {
    text-align: right;
}
.row-selector-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 4px;
  min-width: 300px;
}

.modal-header {
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.modal-body {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin-bottom: 20px;
}

.row-option-button {
  padding: 10px;
  border: 1px solid #ddd;
  background: #f8f9fa;
  border-radius: 4px;
  cursor: pointer;
}

.row-option-button:hover {
  background: #e9ecef;
}

.modal-footer {
  text-align: center;
}

.cancel-button {
  padding: 8px 20px;
  background: #6c757d;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.cancel-button:hover {
  background: #5a6268;
}
.progress-content {
    background-color: yellow;
    padding: 2rem;
    border-radius: 8px;
    text-align: center;
    min-width: 300px;
}

.processing-info {
    margin: 1rem 0;
    padding: 1rem;
    background-color: #f5f5f5;
    border-radius: 4px;
}

.processing-info div {
    margin: 0.5rem 0;
}
</style>