<template>
  <v-app style="background-color: #d0d0d0;">
      <router-view></router-view>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  mounted() {
      this.startInactivityTimer();
      window.addEventListener('mousemove', this.resetInactivityTimer);
      window.addEventListener('keydown', this.resetInactivityTimer);
  },
  beforeUnmount() {
      window.removeEventListener('mousemove', this.resetInactivityTimer);
      window.removeEventListener('keydown', this.resetInactivityTimer);
  },
  methods: {
      startInactivityTimer() {
          this.inactivityTimeout = setTimeout(() => {
              this.$store.dispatch('logoutUser');
          }, 20 * 60 * 1000);
      },
      resetInactivityTimer() {
          clearTimeout(this.inactivityTimeout);
          this.startInactivityTimer();
      }
  }
}
</script>