<template>
    <div class="layout-container">
        <!-- 広告エリア -->
        <div v-if="shouldShowAds" class="ads-container">
            <div v-if="!isMobileDevice">
                <!-- 開発環境用の仮の広告枠 -->
                <div v-if="isDevelopment" class="dev-ad-box">
                    <p>広告枠</p>
                    <p>160 x 600</p>
                </div>
                
                <!-- 本番環境用のAdSense広告 -->
                <div v-else>
                    <ins class="adsbygoogle"
                        style="display:inline-block;width:160px;height:600px"
                        data-ad-client="YOUR-AD-CLIENT-ID"
                        data-ad-slot="YOUR-AD-SLOT-ID"
                        data-ad-format="vertical">
                    </ins>
                </div>
            </div>
        </div>

        <!-- メインコンテンツ -->
        <div class="main-content" :class="{ 'full-width': !shouldShowAds }">
            <div>
                <!-- エラーダイアログ -->
                <v-dialog v-model="showErrorDialog" max-width="300px">
                    <v-card>
                    <v-card-title class="headline">エラー</v-card-title>
                    <v-card-text>{{ errorMessage }}</v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeErrorDialog">閉じる</v-btn>
                    </v-card-actions>
                    </v-card>
                </v-dialog>
        
              <!-- ローディングオーバーレイ -->
              <div v-if="isLoading" class="loading-overlay">
                処理中...
              </div>
              <v-container fluid class="pa-0">  
                <!-- 上部のボタン群 -->
                <div class="upper-menu-container">
                  <div class="upper-menu-scroll">
                    <!-- 会社ロゴ -->
                    <div class="upper-menu-item">
                      <span class="custom-text" style="height: 25px;">
                        <img src="@/assets/accrua.png" alt="会社ロゴ" style="height: 25px;">
                      </span>
                    </div>
          
                    <!-- ログインユーザーボタン -->
                    <div class="upper-menu-item">
                      <v-btn
                        id="userMenuActivator"
                        text
                        class="custom-text user-menu-button light-gray-bg"
                        style="height: 25px;"
                      >
                        ログインユーザー: {{ loginDetails.username }}
                      </v-btn>
                      
                    <!-- ログインユーザーメニュー -->
                    <v-menu
                    v-model="userMenu"
                    :close-on-content-click="false"
                    activator="#userMenuActivator"
                    >
                    <v-list class="user-menu-list">
                        <v-list-item>
                        <v-list-item-title>{{ loginDetails.username }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item>
                        <v-list-item-title>{{ loginDetails.email }}</v-list-item-title>
                        </v-list-item>
                        <!-- API-KEY_FOR_EXCELメニューを非表示に -->
                        <v-list-item v-if="false" @click="generateAndCopyApiKey">
                            <v-list-item-title style="font-size: 14px;">API-KEY_FOR_EXCEL</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="showCompanyRegistrationForm">
                        <v-list-item-title style="font-size: 14px;">会社新規登録</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="showPaymentDialog" style="cursor: pointer;">
                        <v-list-item-title style="font-size: 14px;">支払設定</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="logout">
                        <v-list-item-title style="font-size: 14px;">ログアウト</v-list-item-title>
                        </v-list-item>
                    </v-list>
                    </v-menu>
        
                    <!-- クレジットカード入力フォーム用のダイアログ -->
                    <v-dialog v-model="paymentDialog" max-width="500px">
                    <v-card>
                        <v-card-title>
                        <span class="headline">クレジットカード情報</span>
                        </v-card-title>
                        <v-card-text>
                        <!-- 期間選択のドロップダウンメニュー -->
                        <v-select
                            v-model="selectedPlan"
                            :items="plans"
                            item-title="label"
                            item-value="duration"
                            label="期間を選択"
                            required
                        ></v-select>
        
                        <!-- 選択した期間に基づいて表示される金額 -->
                        <p v-if="selectedPlan" style="margin-bottom: 16px;">選択した金額: {{ selectedPlanAmount }} JPY</p>
        
                        <div class="payment-logos">
                            <img :src="require('@/assets/jcb.png')" alt="JCB" />
                            <img :src="require('@/assets/visa.png')" alt="VISA" />
                            <img :src="require('@/assets/master.png')" alt="MasterCard" />
                        </div>
                        <div id="card-element" class="card-element"></div>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" @click="handleSubmit">支払を行う</v-btn>
                            <v-btn text @click="paymentDialog = false">キャンセル</v-btn>
                        </v-card-actions>
                    </v-card>
                    </v-dialog>    
                    </div>
                    
                    <!-- 対象会社ボタン -->
                    <div class="upper-menu-item">
                        <v-menu
                            v-model="showCompanyDialog"
                            :close-on-content-click="false"
                            :nudge-width="200"
                            offset-y
                            max-width="300"
                        >
                        <template v-slot:activator="{ props }">
                            <v-btn
                            v-bind="props"
                            style="height: 25px; color: white; max-width: 200px;"
                            text
                            class="custom-text user-menu-button light-gray-bg text-truncate"
                            id="selectCompany"
                            color="secondary"
                            >
                            対象会社: {{ loginDetails.targetCompany }}
                            </v-btn>
                        </template>
        
                        <v-card class="pa-3" color="white" style="max-width: 300px;">
                            <v-card-title class="text-black">対象会社選択</v-card-title>
                            <v-card-text>
                            <v-text-field
                                v-model="companyFilterText"
                                placeholder="入力して検索"
                                label="検索"
                                dense
                            ></v-text-field>
        
                            <!-- リスト部分をスクロール可能に -->
                            <div style="max-height: 300px; overflow-y: auto; overflow-x: auto;">
                                <v-list dense class="company-list">
                                <v-list-item
                                    v-for="item in filteredCompanyOptions"
                                    :key="item"
                                    class="company-list-item"
                                    @click="selectAndApplyCompany(item)"
                                    :class="{ 'selected-item': selectedCompany === item }"
                                >
                                    <v-list-item-title class="company-list-item-title text-truncate">{{ item }}</v-list-item-title>
                                </v-list-item>
                                </v-list>
                            </div>
                            </v-card-text>
                        <v-card-actions>
                            <v-btn text @click="showCompanyDialog = false">閉じる</v-btn>
                            <br><v-btn text @click="showWireguardStatus">WireGuard</v-btn>
                        </v-card-actions>
                        </v-card>
                    </v-menu>
        
                    <!-- WireGuard設定状況ダイアログ -->
                    <v-dialog v-model="showWireguardStatusDialog" max-width="400px">
                        <v-card>
                            <v-card-title>WireGuard設定状況</v-card-title>
                            <v-card-text>
                                <p>現在の状態: {{ wireguardStatus ? '設定済み' : '未設定' }}</p>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn @click="wireguardOnOff(true)" :disabled="wireguardStatus">設定</v-btn>
                                <v-btn @click="wireguardOnOff(false)" :disabled="!wireguardStatus">解除</v-btn>
                                <v-btn @click="showWireguardStatusDialog = false">キャンセル</v-btn>
                            </v-card-actions>
                            <v-card-text>
                                <v-select
                                    v-model="selectedDevice"
                                    :items="deviceOptions"
                                    label="デバイスを選択"
                                    required
                                ></v-select>
                                <div class="d-flex justify-end">
                                <v-btn 
                                    color="grey lighten-3" 
                                    text 
                                    @click="setupWireGuard"
                                    class="mt-2"
                                >
                                    新規端末登録
                                </v-btn>
                            </div>
                            </v-card-text>
                        </v-card>
                    </v-dialog>
        
                    <!-- 会社情報登録ダイアログ -->
                    <v-dialog v-model="showRegistrationDialog" persistent max-width="600px">
                        <v-card>
                        <v-card-title>会社情報登録</v-card-title>
                        <v-card-text>
                            <v-text-field
                                label="会社cd(数値4桁)"
                                v-model="companyInfo.code"
                            ></v-text-field>
                            <v-text-field
                                label="会社名"
                                v-model="companyInfo.name"
                            ></v-text-field>
                            <v-text-field
                                label="開始年月(yyyymm)"
                                v-model="companyInfo.fiscalYearStart"
                            ></v-text-field>
                            <v-text-field
                                label="終了年月(yyyymm)"
                                v-model="companyInfo.fiscalYearEnd"
                            ></v-text-field>
                            <v-text-field
                                label="管理者ユーザーcd(数値4桁)"
                                v-model="companyInfo.adminUserCd"
                            ></v-text-field>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="closeDialog">キャンセル</v-btn>
                            <v-btn color="blue darken-1" text @click="registerCompany">登録</v-btn>
                        </v-card-actions>
                        </v-card>
                    </v-dialog>
        
                    <!-- ワンタイムパスワード入力ダイアログ -->
                    <v-dialog v-model="showOtpDialog" persistent max-width="500px">
                        <v-card>
                        <v-card-title>emailアドレスに送信した番号を入力</v-card-title>
                        <v-card-text>
                            <v-text-field
                            label="ワンタイムパスワード"
                            v-model="otp"
                            ></v-text-field>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="grey" text @click="cancelOtp">キャンセル</v-btn>
                            <v-btn color="green darken-1" text @click="verifyOtp">実行</v-btn>
                        </v-card-actions>
                        </v-card>
                    </v-dialog>
                    </div>
          
                    <!-- 対象期間ボタン -->
                    <div class="upper-menu-item">
                        <v-menu
                        v-model="showPeriodDialog"
                        :close-on-content-click="false"
                        :nudge-width="200"
                        offset-y
                        max-width="300"
                        >
                        <template v-slot:activator="{ props }">
                            <v-btn
                            v-bind="props"
                            style="height: 25px; color: white; max-width: 200px;"
                            text
                            class="custom-text user-menu-button light-gray-bg text-truncate"
                            id="selectPeriods"
                            color="secondary"
                            :disabled="!loginDetails.targetCompany"
                            @click="openPeriodDialog"
                            >
                            対象期間: {{ formattedTargetPeriod }}
                            </v-btn>
                        </template>
        
                        <v-card class="pa-3" color="white" style="max-width: 300px;">
                            <v-card-title class="text-black">対象期間選択</v-card-title>
                            <v-card-text>
                            <v-text-field
                                v-model="filterText"
                                placeholder="入力して検索"
                                label="検索"
                                dense
                            ></v-text-field>
        
                            <!-- リスト部分をスクロール可能に -->
                            <div style="max-height: 300px; overflow-y: auto;">
                                <v-list dense class="period-list">
                                <v-list-item
                                    v-for="item in filteredPeriodOptions"
                                    :key="item"
                                    class="period-list-item"
                                    @click="togglePeriodSelection(item)"
                                    :class="{ 'selected-item': selectedPeriods.includes(item) }"
                                >
                                    <v-list-item-title class="period-list-item-title">{{ item }}</v-list-item-title>
                                </v-list-item>
                                </v-list>
                            </div>
                            </v-card-text>
                        <v-card-actions>
                            <v-btn text @click="applyPeriods">確定</v-btn>
                            <v-btn text @click="clearPeriodSelection">クリア</v-btn>
                            <v-btn text @click="showPeriodDialog = false">閉じる</v-btn>
                        </v-card-actions>
                        </v-card>
                    </v-menu>
                    </div>
                  </div>
                </div>      
                </v-container>
            </div>
        
            <div class="menu-container">
                <div class="menu-scroll">
                    <v-btn
                        v-for="(menu, index) in menus"
                        :key="index"
                        :ref="`btn-${index}`"
                        @click="showSubMenu(menu.name)"
                        class="menu-button transparent-button"
                        outlined
                    >
                        {{ menu.label }}
                        <v-menu activator="parent" v-model="menu.isOpen">
                            <v-list>
                                <template v-for="(item, itemIndex) in menu.items" :key="itemIndex">
                                    <!-- サブメニューを持つ項目 -->
                                    <v-list-item v-if="item.isSubmenu">
                                        <v-menu offset-x open-on-hover>
                                            <template v-slot:activator="{ props }">
                                                <v-list-item-title 
                                                    v-bind="props"
                                                    :style="menuItemStyle"
                                                >
                                                    {{ item.label }} ▶
                                                </v-list-item-title>
                                            </template>
                                            <v-list>
                                                <v-list-item
                                                    v-for="(subItem, subIndex) in item.items"
                                                    :key="subIndex"
                                                    @click="executeAction(subItem.action)"
                                                >
                                                    <v-list-item-title :style="menuItemStyle">
                                                        {{ subItem.label }}
                                                    </v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </v-list-item>
                                    
                                    <!-- 通常の項目 -->
                                    <v-list-item
                                        v-else
                                        @click="executeAction(item.action)"
                                        :ref="`${menu.name}-${itemIndex}`"
                                        :disabled="item.label === '2) user' && isUserMenuDisabled"
                                    >
                                        <v-list-item-title :style="menuItemStyle">
                                            {{ item.label }}
                                        </v-list-item-title>
                                    </v-list-item>
                                </template>
                            </v-list>
                        </v-menu>
                    </v-btn>
                </div>
            </div>
            
            <v-card>
                <v-toolbar style="height: 45px;" density="compact" color="primary">
                    <v-tabs 
                        v-model="currentTab"
                        show-arrows
                        center-active
                        :slider-size="4"
                        class="tab-container"
                        :touch="false"
                    >
                        <v-tab 
                            v-for="tab in tabs"
                            :key="tab.id" 
                            :value="tab.id"
                            :class="{ 'custom-active-tab': currentTab === tab.id }"
                            class="custom-tab"
                            @click="executeAction(tab.action)" 
                        > 
                            <span class="tab-text" density="compact">{{tab.name}}</span>
                            <v-btn class="small-btn" @click.stop="removeTab(tab.id)">☒</v-btn>
                        </v-tab>
                    </v-tabs>
                </v-toolbar>
                
                <v-window v-model="currentTab" @input="tabChanged" style="background-color: #ffffff;" :touch="false">
                    <v-window-item v-for="tab in tabs" :key="tab.id" :value="tab.id">
                        <div v-show="currentTab === tab.id">
                            <!-- タブの内容。例えば、各タブに関連するコンポーネントを表示 -->
                            <MainComponent ref="myGridRef" :active-tab="tab.name" :tabs="tabs" @request-tab-switch="executeAction" />
                        </div>
                    </v-window-item>
                </v-window>
            </v-card>
            <!-- トースト通知 -->
            <div v-if="showToast" class="toast">{{ toastMessage }}</div>
        </div>
    </div>
</template>
  
<script>
import { ref, watch, computed, nextTick, onMounted } from 'vue';
import { useStore, mapState, mapGetters } from 'vuex';
import MainComponent from './MainComponent.vue';
import { useRouter } from 'vue-router';

export default {
    components: {
        MainComponent,
    },
    data() {
        return {
            isDevelopment: process.env.NODE_ENV === 'development',
            isMobileDevice: false,
            selectedPlan: null, // 選択されたプラン
            plans: [
                { label: "1 month - 2,000 JPY", duration: 1, amount: 2000 },
                { label: "12 months - 20,000 JPY", duration: 12, amount: 20000 },
            ],
            paymentDialog: false, // ダイアログの開閉フラグ
            stripe: null,
            card: null,
            isFetchCompanyExecuted: false,
            showWireguardStatusDialog: false,
            wireguardStatus: false,
            showWireGuardDialog: false,
            selectedDevice: '',
            deviceOptions: ['Windows PC','Mac','iPhone','Android','iPad','Other'],
            isInitialLogin: true, // 初回ログインフラグを追加
            companyFilterText: '',
            selectedCompany: null,
            showPeriodDialog: false,
            filterText: '',
            isLoading: false,  // グローバルな非同期処理フラグ
            showToast: false,
            toastMessage: '',
            userPermission: null, // ユーザーの権限
            showOtpDialog: false,
            otp: '',
            selectedPeriods: [],
            isMenuOpen: false, 
            userMenu: null,
            focusedMenuIndex: null, // フォーカスされているメニュー項目のインデックス
            companyInfo: { // 会社情報登録用のデータバインディング
                code: '',
                name: '',
                fiscalYearStart: '',
                fiscalYearEnd: '',
                adminUserCd: '', 
            },
            menus: [
                {
                name: '全般情報',
                label: 'q)全般情報',
                isOpen: false,
                items: [
                    { label: '1) 企業情報', action: '企業情報' },
                    { label: '2) user', action: 'user' },
                    { label: '3) 部門情報', action: '部門情報' },
                    { label: '4) 伝票番号', action: '伝票番号' }
                ]},
                {
                name: '科目情報',
                label: 'w)科目情報',
                isOpen: false,
                items: [
                    { label: '1) 勘定科目', action: '勘定科目' },
                    { label: '2) 補助科目', action: '補助科目' },
                    { label: '3) 表示区分', action: '表示区分' },
                    { label: '4) 税コード', action: '税コード' }
                ]},
                {
                name: '相手情報',
                label: 'r)相手情報',
                isOpen: false,
                items: [
                    { label: '1) 相手情報', action: '相手情報' },
                    { label: '2) 相手口座', action: '相手口座' },
                    { label: '3) 自社口座', action: '自社口座' },
                    { label: '4) PJ情報', action: 'PJ情報' }
                ]},
                {
                name: '仕訳画面',
                label: 'a)仕訳画面',
                isOpen: false,
                items: [
                    { label: '1) 仕訳画面', action: '仕訳画面' },
                    { label: '2) 債権画面', action: '債権画面' },
                    { label: '3) 債権推移', action: '債権推移' },
                    { label: '4) 債務画面', action: '債務画面' },
                    { label: '5) 債務推移', action: '債務推移' },
                    { label: '6) 源泉画面', action: '源泉画面' },
                    { label: '7) 定型処理', action: '定型処理' }
                ]},
                {
                name: '期首残高',
                label: 's)期首残高',
                isOpen: false,
                items: [
                    { label: '1) 補助残高', action: '補助残高' },
                    { label: '2) 相手残高', action: '相手残高' },
                    { label: '3) PJ残高', action: 'PJ残高' }
                ]},
                {
                name: 'TB画面',
                label: 'x)TB画面',
                isOpen: false,
                items: [
                    { label: '1) 勘定TB(発生)', action: '勘定TB(発生)' },
                    { label: '2) 勘定TB(残高)', action: '勘定TB(残高)' },
                    { label: '3) 補助TB(発生)', action: '補助TB(発生)' },
                    { label: '4) 補助TB(残高)', action: '補助TB(残高)' },
                    { label: '5) 相手TB(発生)', action: '相手TB(発生)' },
                    { label: '6) 相手TB(残高)', action: '相手TB(残高)' },
                    { label: '7) PJTB(発生)', action: 'PJTB(発生)' },
                    { label: '8) PJTB(残高)', action: 'PJTB(残高)' },
                ]},
                {
                name: '元帳画面',
                label: 'z)元帳画面',
                isOpen: false,
                items: [
                    { label: '1) 勘定元帳', action: '勘定元帳' },
                    { label: '2) 補助元帳', action: '補助元帳' },
                    { label: '3) 相手元帳', action: '相手元帳' },
                    { label: '4) PJ元帳', action: 'PJ元帳' }
                ]},
                {
                name: '分析画面',
                label: 'c)分析画面',
                isOpen: false,
                items: [
                    { label: '1) PJ分析', action: 'PJ分析' },
                    { label: '2) 部門PL', action: '部門PL' }
                ]},
                {
                name: 'その他等',
                label: 'v)その他等',
                isOpen: false,
                items: [
                    { label: '1) 翌期繰越処理', action: '翌期繰越処理' },
                    { label: '2) 決算期の変更', action: '決算期の変更' },
                    { label: '3) 消費税ck画面', action: '消費税ck画面' },
                    { 
                        label: '4) ログ', 
                        isSubmenu: true,
                        items: [
                            { label: '仕訳変更ログ', action: '仕訳変更ログ' },
                            { label: '仕訳承認ログ', action: '仕訳承認ログ' },
                            { label: 'マスタ変更ログ', action: 'マスタ変更ログ' }
                        ]
                    }
                ]},
                // 他のメニューも同様に追加可能
            ],
            menuItemStyle: {
                fontSize: '0.8rem'
            }
        };
    },
    setup() {
        const currentTab = ref(null);  // ここでcurrentTabを定義
        const menuVisible = ref(false); // refを使用してreactiveなプロパティを作成
        const store = useStore();
        const router = useRouter();
        const companyOptions = ref([]);
        const periodOptions = ref([]);
        const showErrorDialog = ref(false);
        const errorMessage = ref('');
        const isFetchCompanyExecuted = ref(false);
        const showRegistrationDialog = ref(false);
        const showCompanyDialog = ref(false);
        const isLoginRedirect = ref(false);
        const loginDetails = computed(() => store.getters.loginDetails);
        // console.log('loginDetails',loginDetails);
        const tabs = ref([]); // ここでタブのリストを定義
        
        const logout = async () => {
            try {
                // サーバーサイドでのログアウト処理を呼び出し
                await fetch('/api/logout.php', { method: 'POST' });
                // ログアウト成功後の処理
                store.commit('logout'); // Vuexストアのログアウト状態を更新
                router.push('/login'); // ログインページにリダイレクト
            } catch (error) {
                console.error('ログアウト中にエラーが発生しました:', error);
                // エラーが発生した場合でもログインページにリダイレクト
                router.push('/login');
            }
        };

        const fetchCompany = async () => {
            if (isFetchCompanyExecuted.value) {
                console.log('fetchCompany: 既に実行済み');
                return;
            }

            try {
                const response = await fetch('/api/get_company.php');
                const data = await response.json();
                
                if (!response.ok) {
                throw new Error(data.message || 'サーバーエラーが発生しました');
                }

                if (data.success === false) {
                if (data.showAlert) {
                    errorMessage.value = data.error;
                    showErrorDialog.value = true;
                    console.log("showErrorDialog:", showErrorDialog.value);

                    if (data.error === 'WireGuardを有効にしてから接続してください。') {
                    // WireGuard設定ダイアログを表示
                        showErrorDialog.value = true;
                    }
                }
                if (data.sessionCleared) {
                    console.log("sessionCleared:", data.sessionCleared);
                    // セッションクリア後のリダイレクトを遅延させる
                    setTimeout(() => {
                        router.push('/login');
                    }, 3000); // 3秒後にリダイレクト
                }
                    return;
                }

                if (Array.isArray(data)) {
                    companyOptions.value = data.map(item => item.companyName).filter(Boolean);
                } else if (typeof data === 'object' && data !== null) {
                    companyOptions.value = Object.values(data).map(item => item.companyName).filter(Boolean);
                } else {
                    throw new Error('予期しないデータ形式です');
                }
                
                isFetchCompanyExecuted.value = true;

                if (companyOptions.value.length === 0) {
                    console.warn('利用可能な会社がありません');
                    showCompanyRegistrationForm();
                } else {
                    // openCompanyDialog();
                    console.log('fetchCompany: 完了, companyOptions:', companyOptions.value);
                }
                
            } catch (error) {
                console.error('Error fetching company:', error);
                errorMessage.value = error.message === 'Failed to fetch' 
                ? 'ネットワークエラーが発生しました。インターネット接続を確認してください。'
                : `会社情報の取得に失敗しました: ${error.message}`;
                showErrorDialog.value = true;
                companyOptions.value = []; // エラー時は空の配列をセット
            }
        };

        const checkCompanyRegistration = async () => {
            if (companyOptions.value.length > 0 && companyOptions.value.some(company => company !== '未選択')) {
                console.log('有効な会社が既に選択されています', companyOptions.value);
                return; // 既に有効な会社が選択されている場合は処理をスキップ
            }

            await fetchCompany(); // 会社情報を取得

            if (companyOptions.value.length === 0 || 
                (companyOptions.value.length === 1 && companyOptions.value[0] === '未選択')) {
                console.log('会社情報登録ダイアログを表示',companyOptions.value);
                showRegistrationDialog.value = true;
            }
        };

        const showCompanyRegistrationForm = () => {
            console.log('showCompanyRegistrationForm called');
            showRegistrationDialog.value = true;
            showCompanyDialog.value = false;
        };

        const openCompanyDialog = () => {
            showCompanyDialog.value = true;
        };

        const changeTargetPeriod = (item) => {
            store.commit('setTargetPeriod', item); // Vuexのステートを更新
        };
        
        // const activeTab = computed(() => store.state.currentActiveTab);
        const activeTab = computed({
            get: () => store.state.currentActiveTab,
            set: (val) => store.commit('SET_ACTIVE_TAB', val)
        });

        const switchToTab = (tabName) => {
            const foundTab = tabs.value.find(tab => tab.name === tabName);
            if (foundTab) {
                currentTab.value = foundTab.id;
            }
        };
        const allTabsFromStore = computed(() => store.state.allTabs);

        onMounted(() => {
            console.log('Component mounted');
            // fetchCompany();
            checkCompanyRegistration();
        });

        // タブが変更されたときに反映させるための処理
        watch(allTabsFromStore, (newTabs) => {
            tabs.value = newTabs;
        });

        watch(activeTab, (newActiveTab) => {
            if (newActiveTab && newActiveTab.id) {
                currentTab.value = newActiveTab.id;
                nextTick(() => {
                    // console.log('myGridFef:', this.$refs.myGridRef);  
                    // if (this.$refs.myGridRef) {
                    //     this.$refs.myGridRef.deselectAllCells();
                    // }
                });
            }
        });

        watch(() => router.currentRoute.value.path, (newPath) => {
            if (newPath !== '/login') {
                isLoginRedirect.value = false;
            }
        });

        return {
            logout,
            tabs, // 他のプロパティと一緒に返す
            currentTab,  // ここでcurrentTabを返します
            switchToTab,
            menuVisible,
            loginDetails,
            companyOptions,
            changeTargetPeriod,
            showRegistrationDialog,
            checkCompanyRegistration,
            showErrorDialog,
            errorMessage,
            periodOptions,
            fetchCompany,
            showCompanyDialog,
            openCompanyDialog,
            showCompanyRegistrationForm,
            //maintenanceDate: '2024/09/30 - 2024/10/01', // システムメンテナンス日付
            //newFeature: 'データのインポート機能を追加しました', // 新機能情報
            // ...他の必要なデータやメソッド...
        };
    },
    computed: {
        ...mapState([
            'allTabs', 
            'currentActiveTab'
        ]),
        ...mapGetters([
            'shouldShowAds'
        ]),
        selectedPlanAmount() {
            const plan = this.plans.find(plan => plan.duration === this.selectedPlan);
            const amount = plan ? plan.amount : 0;
            return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        unformattedSelectedPlanAmount() {
            const plan = this.plans.find(plan => plan.duration === this.selectedPlan);
            return plan ? plan.amount : 0; // 整数値のまま返す
        },
        isActiveTab(tab) {
            return this.currentActiveTab === tab.name;
        },
        filteredPeriodOptions() {
            if (!this.filterText) return this.periodOptions;
            return this.periodOptions.filter(item => 
                item.toLowerCase().includes(this.filterText.toLowerCase())
            );
        },
        filteredCompanyOptions() {
            if (!this.companyFilterText) return this.companyOptions;
            return this.companyOptions.filter(item => 
            item.toLowerCase().includes(this.companyFilterText.toLowerCase())
            );
        },
        formattedTargetPeriod() {
            let periods = this.loginDetails.targetPeriod;
            // periodsが配列かどうかと、要素があるかを確認
            if (!Array.isArray(periods) || periods.length === 0) {
                return '未選択';
            }
            // 配列の年月を昇順にソートする
            periods = periods.sort((a, b) => a - b);
            // 表示のためにソートされた期間を処理
            if (periods.length === 1) {
                return `${periods[0]}`;
            }
            if (periods.length === 2) {
                return `${periods[0]}-${periods[1]}`;  // 小さい方が左、大きい方が右になる
            }
            return `${periods[0]}-${periods[periods.length - 1]}`;  // 小さい方から大きい方へ
        },
        isUserMenuDisabled() {
            // userPermissionがnullではなくなった後に条件を評価
            return this.userPermission !== '1_admin';
        }
    },
    methods:{
        showPaymentDialog() {
            const companyName = this.loginDetails.targetCompany;
            if (companyName === '未選択'){
                this.userMenu = false;
                alert('対象会社を選択して下さい'); return;
            }
            // ダイアログを開く前に、既存のカード要素をアンマウント
            if (this.card) {
                this.card.unmount();
                this.card = null; // 新しいカード要素を作成するために null に設定
            }

            this.paymentDialog = true; // ダイアログを開く
            this.userMenu = false;

            this.$nextTick(() => {
                if (!this.stripe) {
                    this.stripe = Stripe('pk_test_51QGr9X2KDua4DFgZ5EZzUYoO4FFWjLCSrUNujN5Zve2bCI2W3qFwcHmCvHsSI4BdxtZB4pfzed5c68Ty6a7LMG3400Txsb9N5t');
                }
                const elements = this.stripe.elements();

                const style = {
                base: {
                    color: '#32325d',
                    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                    fontSmoothing: 'antialiased',
                    fontSize: '16px',
                    '::placeholder': {
                    color: '#aab7c4'
                    }
                },
                invalid: {
                    color: '#fa755a',
                    iconColor: '#fa755a'
                }
                };

                this.card = elements.create('card', { style, hidePostalCode: true });
                this.card.mount('#card-element');
            });
        },
        async handleSubmit() {
            const companyName = this.loginDetails.targetCompany;
            if (companyName === '未選択'){
                this.userMenu = false;
                alert('対象会社を選択して下さい'); return;
            }
            if (!this.selectedPlan) {
                alert("期間を選択してください");
                return;
            }

            const { token, error } = await this.stripe.createToken(this.card);
            if (error) {
                alert("クレジットカード情報が正しく入力されていません。");
                console.error(error);
                return;
            } else {
                const response = await fetch('/api/process_payment.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: `stripeToken=${token.id}&amount=${this.unformattedSelectedPlanAmount}&duration=${this.selectedPlan}&companyName=${companyName}`,
                });
                const result = await response.json();
                if (result.status === 'success') {
                    alert('支払いが成功しました');
                    this.paymentDialog = false; // ダイアログを閉じる
                    this.card.unmount(); // カード要素をアンマウント
                    this.card = null; // 次回のマウント時に新しいカード要素を作成
                } else if (result.status === 'partial_success') {
                    alert('支払いは成功しましたが、処理中にエラーが発生しました');
                    this.paymentDialog = false; // ダイアログを閉じる
                    this.card.unmount(); // カード要素をアンマウント
                    this.card = null; // 次回のマウント時に新しいカード要素を作成
                } else if (result.status === 'failed') {
                    alert('支払いに失敗しました: ' + result.message);
                } else {
                    alert('予期しないエラーが発生しました: ' + result.message);
                }
            }
        },
        closeErrorDialog() {
            this.showErrorDialog = false;
            this.isLoginRedirect = true;
            if (this.errorMessage === 'WireGuardを有効にしてから接続してください。') {
                this.showPeriodDialog = false;
                this.showWireguardStatus();
                this.showWireguardStatusDialog = true;
            }
            this.errorMessage = '';
        },
        showWireGuardSetupDialog() {
            this.showWireGuardDialog = true;
        },
        
        async setupWireGuard() {
            if (!this.selectedDevice) {
                alert('デバイスを選択してください。');
                return;
            }
            
            try {
                const response = await fetch('/api/wireguard_setup.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    client_name: this.loginDetails.username,
                    client_email: this.loginDetails.email,
                    device_info: this.selectedDevice,
                }),
                });
                const data = await response.json();
                if (data.success) {
                    alert("WireGuard設定が成功しました。メールが送信されました。");
                } else {
                    alert("WireGuard設定中にエラーが発生しました: " + data.message);
                }
            } catch (error) {
                console.error("Error:", error);
                alert("エラーが発生しました。");
            }
            
            this.showWireGuardDialog = false;
        },
        async showWireguardStatus() {
            console.log("loginDetails:", this.loginDetails);
            if (this.loginDetails.targetCompany && this.loginDetails.wireguard) {
                const targetCompanyData = this.loginDetails.wireguard.find(
                    company => company.name === this.loginDetails.targetCompany
                );
                if (targetCompanyData) {
                this.wireguardStatus = targetCompanyData.wireguard === 1;
                this.showWireguardStatusDialog = true;
                } else {
                console.error('選択された会社のWireGuard情報が見つかりません。');
                alert('WireGuard状態の取得に失敗しました。');
                }
            } else {
                console.error('targetCompanyデータまたはwireguardデータが利用できません。');
                alert('WireGuard状態の取得に失敗しました。');
            }
        },
            
        async wireguardOnOff(setOn) {
            try {
                // if (setOn && !this.selectedDevice) {
                //     alert('デバイスを選択してください。');
                //     return;
                // }
                const response = await fetch('/api/wireguard_onoff.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ 
                        setOn,
                        device: setOn ? this.selectedDevice : null
                    }),
                });
                const data = await response.json();
                if (data.success) {
                    this.wireguardStatus = setOn;
                    alert(setOn ? 'WireGuardを設定しました。' : 'WireGuardを解除しました。');
                    this.showWireguardStatusDialog = false;  // ダイアログを閉じる
                } else {
                    throw new Error(data.message || 'WireGuard設定の変更に失敗しました。');
                }
            } catch (error) {
                console.error('Error changing WireGuard status:', error);
                alert(error.message || 'WireGuard設定の変更中にエラーが発生しました。');
            }
        },
        async fetchPeriods() {
            console.log("fetchPeriods started");
            try {
                // 対象会社が選択されているかチェック
                if (!this.loginDetails.targetCompany || this.loginDetails.targetCompany === '未選択') {
                    this.errorMessage = '対象会社を選択してください。';
                    this.showErrorDialog = true;
                    return;
                }

                const companyName = encodeURIComponent(this.loginDetails.targetCompany);
                console.log("Fetching data for company:", companyName);
                const response = await fetch(`/api/get_periods.php?companyName=${companyName}`);
                console.log("Response received:", response);
                const data = await response.json();
                console.log("Parsed data:", data.periods);

                if (data.success === false) {
                    if (data.showAlert) {
                        this.errorMessage = data.error;
                        this.showErrorDialog = true;
                        console.log("showErrorDialog:", this.showErrorDialog);

                        if (data.error === 'WireGuardを有効にしてから接続してください。') {
                            this.showErrorDialog = true;
                        }
                    } 
                } else {
                    // データが存在することを確認
                    if (!data.periods || !Array.isArray(data.periods)) {
                        throw new Error('期間データが正しい形式ではありません。');
                    }

                    // 正常な応答処理                    
                    this.periodOptions = data.periods.map(period => period.エンド月);
                    // periodsデータをストアに保存
                    this.$store.commit('setPeriods', data.periods);
                    // userPermissionの設定（必要に応じて）
                    this.$store.commit('setUserPermission', {
                        permission: data.permission,
                        user_cd: data.user_cd
                    });
                    this.userPermission = this.$store.state.userPermission.permission;
                    console.log('User Permission:', this.userPermission);
                    console.log('User CD:', this.$store.state.userPermission.user_cd);
                }
            } catch (error) {
                console.error('Error in fetchPeriods:', error);
                this.errorMessage = 'データの取得中にエラーが発生しました。';
                this.showErrorDialog = true;
                throw error; // エラーを上位に伝播させる
            }
        },
        async selectAndApplyCompany(company) {
            try {
                this.isLoading = true;  // ローディング表示開始
                this.selectedCompany = company;
                await this.changeTargetCompany(company);
                
                // 対象期間を取得
                if (company) {
                    try {
                        await this.fetchPeriods();
                        this.showCompanyDialog = false;  // 成功時のみダイアログを閉じる
                    } catch (error) {
                        console.error('期間取得エラー:', error);
                        this.errorMessage = '対象期間の取得に失敗しました。';
                        this.showErrorDialog = true;
                        // エラー時は選択をリセット
                        this.selectedCompany = null;
                        this.$store.commit('setTargetCompany', null);
                    }
                }
            } catch (error) {
                console.error('会社選択エラー:', error);
                this.errorMessage = '会社情報の更新に失敗しました。';
                this.showErrorDialog = true;
            } finally {
                this.isLoading = false;  // ローディング表示終了
            }
        },
        async changeTargetCompany(company) {
            this.$store.commit('setTargetCompany', company);
            this.$store.commit('resetTargetPeriod');
            this.tabs = [];
            this.currentTab = null;

            try {
                const companyName = encodeURIComponent(company);
                const response = await fetch(`/api/get_periods.php?companyName=${companyName}`);
                const data = await response.json();
                
                if (data.success === false) {
                    if (data.showAlert) {
                        this.errorMessage = data.error;
                        this.showErrorDialog = true;
                    }
                } else {
                    this.periodOptions = data.periods.map(period => period.エンド月);
                }
            } catch (error) {
                console.error('Error fetching periods:', error);
            }
        },
        checkInitialLogin() {
            if (this.isInitialLogin && (!this.loginDetails.targetCompany || this.loginDetails.targetCompany === '')) {
                this.handleCompanySelection();
            }
            this.isInitialLogin = false; // フラグを更新して再度表示されないようにする
        },
        handleCompanySelection() {
            // まず、会社リストを取得
            this.fetchCompany().then(() => {
                // 会社リストが空の場合のみ、会社情報登録フォームを表示
                if (this.companyOptions.length === 0) {
                    this.showCompanyRegistrationForm();
                } else {
                    // 会社リストがある場合は、会社選択ダイアログを表示
                    this.openCompanyDialog();
                }
            });
        },
        
        selectCompany(company) {
            this.selectedCompany = company;
        },
        applyCompany() {
            if (this.selectedCompany) {
            this.changeTargetCompany(this.selectedCompany);
            this.showCompanyDialog = false;
            }
        },
        async openPeriodDialog() {
             // 対象会社が選択されているか確認
            if (!this.loginDetails.targetCompany) {
                alert('対象会社を選択してください。');
                return;
            }
            await this.clearPeriodSelection();
            // 常に期間を再取得する
            await this.fetchPeriods();
            this.showPeriodDialog = true;
        },
        togglePeriodSelection(item) {
            const index = this.selectedPeriods.indexOf(item);
            if (index === -1) {
                this.selectedPeriods.push(item);
            } else {
                this.selectedPeriods.splice(index, 1);
            }
        },        
        async clearPeriodSelection() {
            this.selectedPeriods = [];
        },
        async executeAsyncAction(action) {
            if (this.isLoading) return;
            this.isLoading = true;
            try {
                await action();
            } finally {
                this.isLoading = false;
            }
        },
        generateAndCopyApiKey() {
            return this.executeAsyncAction(async () => {
            const companyName = this.loginDetails.targetCompany;
            if (companyName === '未選択'){
                alert('対象会社を選択して下さい'); return;
            } else if (!this.loginDetails.targetPeriod || this.loginDetails.targetPeriod.length === 0) {
                alert('対象会社/対象期間を選択してください'); return;
            }
            try {
                // companyNameをクエリパラメータとして追加
                const url = new URL('/api/get_apiKey.php', window.location.origin);
                url.searchParams.append('companyName', companyName);

                const response = await fetch(url);
                const data = await response.json(); // APIキーが含まれる想定

                if (data.apiKey) {
                    // クリップボードにコピー
                    // const copy_data = companyName +'|'+ data.apiKey;
                    const copy_data = companyName +'|'+ this.loginDetails.targetPeriod +'|'+ data.apiKey;
                    await navigator.clipboard.writeText(copy_data);
                    // alert('APIキーがクリップボードにコピーされました。');
                    this.showToastWithTimer('APIキーがクリップボードにコピーされました。', 3000);
                    this.userMenu = false; // メニューを閉じる
                } else {
                    throw new Error('APIキーがレスポンスに含まれていません。');
                }
            } catch (error) {
                console.error('API-KEYの取得に失敗しました:', error);
                alert('API-KEYの取得に失敗しました。対象会社/対象期間を選択して下さい');
            }
            });
        },
        showToastWithTimer(message, duration) {
            this.toastMessage = message;
            this.showToast = true;
            setTimeout(() => {
                this.showToast = false;
            }, duration);
        },
        closeDialog() {
            this.showRegistrationDialog = false;
            // 会社オプションが存在する場合のみ、対象会社選択ダイアログを表示
            // if (this.companyOptions.length > 0) {
            //     this.showCompanyDialog = true;
            // }
        },
        cancelOtp() {
            this.showOtpDialog = false; // OTP入力ダイアログを閉じる
            this.otp = ''; // OTP入力フィールドをクリア
            this.showCompanyDialog = true;
        },
        registerCompany() {
            return this.executeAsyncAction(async () => {
                // 入力バリデーション
                if (!/^\d{4}$/.test(this.companyInfo.code) || 
                    !/^\d{6}$/.test(this.companyInfo.fiscalYearStart) ||
                    !/^\d{6}$/.test(this.companyInfo.fiscalYearEnd) ||
                    !/^\d{4}$/.test(this.companyInfo.adminUserCd) ||  // 追加
                    this.companyInfo.name.trim() === '') {
                    alert('入力データが無効です。');
                    return;
                }

                // fiscalYearStartとfiscalYearEndの差分チェック（既存のコード）
                const startYear = parseInt(this.companyInfo.fiscalYearStart.substring(0, 4));
                const startMonth = parseInt(this.companyInfo.fiscalYearStart.substring(4, 6));
                const endYear = parseInt(this.companyInfo.fiscalYearEnd.substring(0, 4));
                const endMonth = parseInt(this.companyInfo.fiscalYearEnd.substring(4, 6));
                const startTotalMonths = startYear * 12 + startMonth;
                const endTotalMonths = endYear * 12 + endMonth;
                const monthsDifference = endTotalMonths - startTotalMonths;

                if (monthsDifference <= 0 || monthsDifference > 11) {
                    alert('開始年月と終了年月が12か月超離れている場合は無効です。');
                    return;
                }

                try {
                    const response = await fetch('/api/register_company.php', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            userId: this.loginUserId,
                            companyCode: this.companyInfo.code,
                            companyName: this.companyInfo.name,
                            fiscalYearStart: this.companyInfo.fiscalYearStart,
                            fiscalYearEnd: this.companyInfo.fiscalYearEnd,
                            adminUserCd: this.companyInfo.adminUserCd  // 追加
                        })
                    });
                    const data = await response.json();
                    if (data.success) {
                        // this.loginDetails.targetCompany = this.companyInfo.name;
                        this.showRegistrationDialog = false;
                        this.$nextTick(() => {
                            this.showOtpDialog = true;
                        });
                    } else {
                        alert('登録に失敗しました。');
                    }
                } catch (error) {
                    // console.error('Error registering company:', error);
                    alert('登録に失敗しました。会社cdが登録済みでないか確認して下さい');
                }}
            );
        },
        verifyOtp() {
            return this.executeAsyncAction(async () => {
            // OTPがブランクの場合にエラーを表示
            if (!this.otp.trim()) {
                alert('ワンタイムパスワードを入力してください。');
                return;
            }
            try {
                const response = await fetch('/api/confirm_registration.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ otp: this.otp })
                });
                if (!response.ok) {
                    throw new Error('ネットワークレスポンスが正しくありません。');
                }
                const data = await response.json();
                if (data.success) {
                    alert(data.message); // 成功メッセージを表示
                    this.showOtpDialog = false; // OTP入力ダイアログを閉じる
                    
                    // 会社情報を再取得
                    await this.fetchCompany();
                    
                    // 新しく登録した会社を選択
                    await this.selectAndApplyCompany(this.companyInfo.name);
                    
                    // companyOptionsを更新
                    if (!this.companyOptions.includes(this.companyInfo.name)) {
                        this.companyOptions.push(this.companyInfo.name);
                    }
                    
                    // Vuexストアも更新
                    this.$store.commit('setTargetCompany', this.companyInfo.name);

                } else {
                    alert(data.message); // サーバーからのエラーメッセージを表示
                }
            } catch (error) {
                console.error('Error verifying OTP:', error);
                alert('ワンタイムパスワード検証中にエラーが発生しました。'); // エラーアラート
            }
            });
        },
        tabChanged(tabId) {
            const tab = this.tabs.find(t => t.id === tabId);
            if (tab) {
                this.$store.commit('SET_ACTIVE_TAB', tab.name);
            }
            this.$nextTick(() => {
                // console.log('refs:', this.$refs.myGridRef);
                const gridRefs = this.$refs.myGridRef;
                if (gridRefs) {
                    gridRefs.forEach(gridRef => {
                        if (typeof gridRef.deselectAllCells === 'function') {
                            gridRef.deselectAllCells();
                        }
                    });
                }
            });
        },
        removeTab(tabId) {
            return this.executeAsyncAction(async () => {
            const tabs1 = this.tabs;
            // const tabIndex = this.tabs.findIndex(tab => tab.id === tabId);
            const tabs = this.$store.state.allTabs; // Vuex ストアから allTabs を取得
            const tabs2 = tabs;
            let tabIndex = tabs.findIndex(tab => tab.id === tabId);

            if (tabIndex !== -1) {
                // Vuex ストアのミューテーションを使用してタブを削除
                await this.$store.dispatch('removeTab', tabId);
                
                if (tabs1 !== tabs2) {
                    // なぜかズレるので例外処理を入れる
                    let tabIndex = tabs1.findIndex(tab => tab.id === tabId);
                    if (tabIndex !== -1) {
                        this.tabs.splice(tabIndex, 1); 
                    }
                }
                this.$nextTick(() => {
                    const updatedTabs = this.$store.state.allTabs; // タブが更新された後の状態を取得
                    if (updatedTabs.length > 0) {
                        // もし削除されたタブが最後のタブであれば、新しい最後のタブをアクティブにする
                        this.currentTab = (tabIndex < updatedTabs.length) ? updatedTabs[tabIndex].id : updatedTabs[updatedTabs.length - 1].id;
                    } else {
                        this.currentTab = ''; // タブがない場合は currentTab を空文字列に設定
                    }
                    // currentTabが設定されているか確認
                    if (this.currentTab) {
                        console.log('Setting active tab to:', this.currentTab);
                        this.tabChanged(this.currentTab);  // 新しいアクティブタブを反映
                    } else {
                        console.log('No active tab to set');
                    }
                });
            }
            });
        },
        addTab(tabId) {
            // タブが存在しない場合にのみ追加します
            if (!this.tabs.some(tab => tab.id === tabId)) {
                const tabData = { id: tabId, name: tabId, action: tabId };
                this.tabs.push(tabData);
                
                // Vuex の allTabs にもタブを追加
                this.$store.commit('ADD_TAB', tabData);
                // this.currentTab = tabId;
                // this.tabChanged(this.currentTab);
            }
        },
        updateTab(tabIndex, updatedTab) {
            this.$store.commit('UPDATE_TAB', { tabIndex, updatedTab });
            this.tabs[tabIndex] = updatedTab;
        },
        applyPeriods() {
            this.menuVisible = false; // メニューを閉じる
            // 選択された期間を数値に変換しソート
            const sortedPeriods = this.selectedPeriods
            .map(period => parseInt(period, 10))
            .sort((a, b) => a - b);
            // 連続しているかチェック
            let isConsecutive = true;
            for (let i = 1; i < sortedPeriods.length; i++) {
                if (sortedPeriods[i] !== sortedPeriods[i - 1] + 100) { // 100は年の差を示しています。
                    isConsecutive = false;
                    break;
                }
            }
            if (isConsecutive) {
                 // 選択された期間の最小値を取得
                const minPeriod = sortedPeriods[0];                
                // storeのperiodsから対応するエントリーを検索
                const fyEntry = this.$store.state.periods.find(entry => 
                    parseInt(entry['エンド月']) === minPeriod
                );
                if (fyEntry) {
                    // スタート月を取得し、1ヶ月前の年月を計算
                    const startYm = parseInt(fyEntry['スタート月']);
                    let year = Math.floor(startYm / 100);
                    let month = startYm % 100;
                    // 1ヶ月前を計算
                    if (month === 1) {
                        year--;
                        month = 12;
                    } else {
                        month--;
                    }
                    // previousFyYmを生成（YYYYMM形式）
                    const previousFyYm = year * 100 + month;
                    // storeにセット
                    this.$store.commit('setPreviousFyYm', previousFyYm);
                    console.log('previousFyYm:',previousFyYm);
                }
                this.$store.commit('setTargetPeriod', this.selectedPeriods);
                this.showPeriodDialog = false; // ダイアログを閉じる
            } else {
                // エラーメッセージを表示（必要に応じて修正）
                console.log('selectedPeriods:', this.selectedPeriods);
                alert("選択された期間は連続していません。連続した期間を選択してください。");
            }
        },
        
        setTargetCompany(company) {
            this.loginDetails.targetCompany = company;
        },

        setTargetPeriod(period) {
            this.loginDetails.targetPeriod = period;
        },
        doNothing(){
            '何もしない(仮)'
        },
        showSubMenu(menuIndex) {
            if (this.menus[menuIndex] && !this.menus[menuIndex].isOpen) {
                this.menus[menuIndex].isOpen = true;
            }
        },
        closeMenu(action) {
            for (let menu of this.menus) {
                for (let item of menu.items) {
                    if (item.action === action) {
                        menu.isOpen = false;
                        return; // actionを見つけたらループを終了
                    }
                }
            }
        },
        // ... existing code ...
        async changeFiscalYear() {
            this.userPermission = this.$store.state.userPermission.permission;
             // 権限チェックを追加
             if (!['1_admin'].includes(this.userPermission)) {
                alert('権限がありません');
                return;
            }

            return this.executeAsyncAction(async () => {
                const userInput = prompt('変更後の決算年月を入力してください（西暦6桁 例:202403）');
                
                // キャンセルされた場合
                if (userInput === null) {return;}

                // 入力値の検証
                if (!userInput || 
                    userInput.length !== 6 || 
                    isNaN(userInput) || 
                    parseInt(userInput.substring(4, 6)) < 1 || 
                    parseInt(userInput.substring(4, 6)) > 12) {
                    alert('有効な年月（YYYYMM）を入力してください');
                    return;
                }

                try {
                    const response = await fetch('/api/change_period.php', {
                        method: 'POST',
                        headers: {'Content-Type': 'application/json'},
                        body: JSON.stringify({
                            companyName: this.loginDetails.targetCompany,  // 会社名を追加
                            updatedPeriod: userInput
                        })
                    });
                    
                    const data = await response.json();  // レスポンスをJSONとしてパース
                    
                    if (data.status === 'success') {  // data.success から data.status === 'success' に変更
                        alert('決算期の変更が完了しました');
                        // 必要に応じて期間リストを再取得
                        await this.fetchPeriods();
                    } else {
                        throw new Error(data.message || '決算期の変更に失敗しました');
                    }
                } catch (error) {
                    console.error('Error changing fiscal year:', error);
                    alert(error.message || '決算期の変更中にエラーが発生しました');
                }
            });
        },
        carryOverNextYear() {
            this.userPermission = this.$store.state.userPermission.permission;
            this.userCd = this.$store.state.userPermission.user_cd;
             // 権限チェックを追加
             if (!['1_admin'].includes(this.userPermission)) {
                alert('権限がありません');
                return;
            }

            return this.executeAsyncAction(async () => {
                let maxPeriod = Math.max(...this.selectedPeriods); 
                console.log(maxPeriod);
                if (typeof maxPeriod !== 'number' || isNaN(maxPeriod) || !isFinite(maxPeriod)) {
                    alert('対象期間を再度選択して下さい');
                    return;
                }
                const year = Math.floor(maxPeriod / 100);
                const month = maxPeriod % 100;
                const nextPeriod = (year+1)*100+month;
                // console.log(maxPeriod, nextPeriod, year, month);

                const confirmAction = window.confirm(`本当に翌期繰越を実行しますか？現在の対象年度 ${maxPeriod} が翌期に繰り越されます`);
                if (!confirmAction) {
                    return; // ユーザーが更新をキャンセルした場合
                }
                const userInput = prompt('西暦6桁を入力して下さい', nextPeriod);
                // 入力が数値で、長さが6であるかどうか
                if (!userInput) {
                    alert('入力が必要です。'); return;
                }
                const isNumber = !isNaN(userInput) && userInput.trim().length === 6 && !isNaN(parseFloat(userInput));
                if (!isNumber) {
                    alert('入力が無効です。西暦6桁の数値を入力してください。'); return false;
                }

                // postDataオブジェクトにmaxPeriodとuserInputを格納
                const postData = JSON.stringify({
                    currentPeriod: maxPeriod,
                    nextPeriod: userInput*1,
                    userCd: this.userCd
                });
                // POSTリクエストを送信
                try {
                    const response = await fetch('/api/carryOver.php', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: postData
                    });
                    const data = await response.json();
                    // レスポンスの形式を変更して詳細な情報を処理
                    if (data.success === false) {
                        alert(data.message);
                        return;
                    }
                    
                    if (data === "success") {
                        alert('繰越処理が成功しました。');
                    } else {
                        alert('繰越処理に失敗しました。');
                    }
                } catch (error) {
                    console.error('繰越処理の実行中にエラーが発生しました:', error);
                    alert('通信エラーが発生しました。');
                }
            });
        },
        executeAction(action) {            
            // selectCompanyがブランクの場合は警告を表示し、処理を中断
            if (!this.loginDetails.targetCompany) {
                alert('会社を選択してください');
                return;
            } else if (!this.loginDetails.targetPeriod || this.loginDetails.targetPeriod.length === 0) {
                alert('対象会社/対象期間を選択してください');
                return;
            }

            if (this.userPermission !=='1_admin') {
                if (action==='user') {
                    alert('権限がありません');
                    return;
                }
            }

            switch(action) {
            case '企業情報':case 'user': case '部門情報':case '伝票番号': 
            case '勘定科目':case '補助科目':case '表示区分': case '税コード':
            case '相手情報': case '相手口座':case '自社口座':case 'PJ情報': 
            case '仕訳画面':case '元帳画面': case '債権画面':case '債権推移':case '債務画面': case '債務推移':case '源泉画面':case '定型処理':
            case '補助残高': case '相手残高':case 'PJ残高':
            case '勘定TB(発生)': case '補助TB(発生)':case '相手TB(発生)':case 'PJTB(発生)': 
            case '勘定TB(残高)': case '補助TB(残高)':case '相手TB(残高)':case 'PJTB(残高)': 
            case '勘定元帳': case '補助元帳':case '相手元帳':case 'PJ元帳': 
            case 'PJ分析': case '部門PL': case '消費税ck画面':case '仕訳変更ログ':case '仕訳承認ログ':case 'マスタ変更ログ':
                this.addTab(action);
                this.currentTab = action; // タブの値を'journalEntry'に設定
                this.tabChanged(this.currentTab); // ここで tabChanged を呼び出します
                this.closeMenu(action);
                break;
                // 他のメニューアクションに対する処理をここに追加...
            case '翌期繰越処理':
                this.carryOverNextYear();
                break;
            case '決算期の変更':
                this.changeFiscalYear();
                break;
            default:
                //仕訳画面と元帳は 01,02,,,という形で複数ありうるので以下を追加
                if (action.includes('仕訳画面')||action.includes('元帳')) {
                    this.addTab(action);
                    this.currentTab = action; // タブの値を'journalEntry'に設定
                    this.tabChanged(this.currentTab); // ここで tabChanged を呼び出します
                    this.closeMenu(action);
                } else {
                    console.log('未知のアクション:', action);
                }
            }
        },
        handleKeydown(event) {
            // if (event.key === 'Enter') {
            //     event.preventDefault();
            //     return;
            // }
            const matchedMenuIndex = this.menus.findIndex(menu => {
                // return event.ctrlKey && event.shiftKey && event.altKey && event.code === `Key${menu.label[0].toUpperCase()}`;
                return event.altKey && event.code === `Key${menu.label[0].toUpperCase()}`;
            });
            if (matchedMenuIndex > -1) {
                this.$refs[`btn-${matchedMenuIndex}`][0].$el.focus();
                this.showSubMenu(matchedMenuIndex);  // この行を追加します。
            }
        },
    },
    created() {
      window.addEventListener('keydown', this.handleKeydown);
      
    },
    unmounted() {
      window.removeEventListener('keydown', this.handleKeydown);
    },
    mounted() {
        // モバイルデバイスチェック
        this.isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        
        // 本番環境での広告スクリプト読み込み
        if (!this.isDevelopment && !this.isMobileDevice) {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
        }

        if (this.tabs.length > 0) {
            this.currentTab = this.tabs[0].id;
        }
        this.$nextTick(() => {
            if (this.loginDetails.targetCompany && this.loginDetails.targetCompany !== '未選択') {
                // 対象会社が選択されている場合は何もしない
                return;
            }
            this.checkInitialLogin(); // コンポーネントがマウントされた後にチェックを行う
        });
        // console.log('User Permission:', this.userPermission);
    },
    watch: {
        'loginDetails.targetCompany': {
            immediate: true,
            handler(newValue) {
                if (this.isInitialLogin && (!newValue || newValue === '')) {
                    this.checkInitialLogin();
                }
            }
        },
    },
};
</script>

<style scoped>
.custom-text {
    font-size: 0.8rem;  /* Adjusted font size */
    line-height: 1.2;  
}
.tight-list .v-list-item {
    padding: 0.5px 0;  /* これは例です。適切な値に調整してください */
}
v-btn {
  position: relative; /* or 'fixed' depending on the desired effect */
  /* Add any other styles if needed */
}
.custom-active-tab {
  background-color: #4CAF50;
  color: white;
}
.v-tab:hover {
  color: black !important;
}
.tab-text {
    line-height: 1; /* line-height調整 */
    vertical-align: top; /* vertical-align調整 */
}
.small-btn {
    height: 20px;
    min-width: 20px;
    padding: 0 3px; /* padding調整 */
    line-height: 1; /* line-height調整 */
    vertical-align: top; /* vertical-align調整 */
}
.toast {
  /* トースト通知のスタイル */
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: black;
  color: white;
  padding: 10px;
  border-radius: 5px;
}
.v-menu {
  overflow: hidden;
}
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 20px;
    z-index: 9999;
}
.menu-container {
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
}
  
.menu-scroll {
    display: inline-flex;
    padding: 8px 0;
}
  
.menu-button {
    height: 30px;
    margin-right: 8px;
flex-shrink: 0;
}
.upper-menu-container {
width: 100%;
overflow-x: auto;
white-space: nowrap;
margin-bottom: 8px;
}

.upper-menu-scroll {
display: inline-flex;
padding: 8px 0;
}

.upper-menu-item {
margin-right: 16px;
flex-shrink: 0;
}
.transparent-button {
background-color: transparent !important;
border: 1px solid #000000 !important; /* ボーダーの色 */
color: #000000 !important; /* テキストの色を黒に変更 */
}

.transparent-button:hover {
background-color: rgba(25, 118, 210, 0.1) !important; /* ホバー時の背景色 */
}

.light-gray-bg {
background-color: #f5f5f5 !important; /* すごく薄い灰色 */
}

.user-menu-button {
/* background-color: transparent !important; */
border: 1px solid #000000 !important;
color: #000000 !important;
}

.user-menu-button:hover {
background-color: rgba(0, 0, 0, 0.1) !important;
}

.user-menu-list {
background-color: white !important;
}

.user-menu-list .v-list-item {
color: black !important;
}

.user-menu-list .v-list-item:hover {
background-color: rgba(0, 0, 0, 0.1) !important;
}
/* チェックボックスのラベルの色も調整 */
.user-menu-list .v-checkbox label {
color: rgba(0, 0, 0, 1) !important;
}
.compact-list-item-title {
font-size: 16px;
line-height: 0.5;
}

.compact-list-item {
padding: 4px 0;
}

.selected-item {
background-color: #90caf9 !important;
}
.period-list {
  padding: 0;
}

.period-list-item {
  padding: 8px 0;
}

.period-list-item-title {
  font-size: 16px;
  line-height: 1.5;
}

.selected-item {
  background-color: #90caf9 !important;
}
.payment-logos {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

.payment-logos img {
  width: 50px;
  height: auto;
  margin: 0 10px;
}

.card-element {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 4px;
  background-color: #f9f9f9;
}
/* 既存のスタイルに以下を追加 */
.layout-container {
    display: flex;
    width: 100%;
}

.ads-container {
    width: 160px;
    flex-shrink: 0;
}

.main-content {
    flex-grow: 1;
    margin-left: 10px; /* 広告がある場合のマージン */
}

/* 広告がない場合はマージンを削除し、全幅で表示 */
.main-content.full-width {
    margin-left: 0;
    width: 100%;
}

.dev-ad-box {
    width: 160px;
    height: 600px;
    border: 1px dashed #999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #666;
    font-size: 14px;
}

/* モバイル対応 */
@media (max-width: 768px) {
    .ads-container {
        display: none;
    }
    .main-content {
        margin-left: 0;
        width: 100%;
    }
}
</style>